import React from 'react';
import { SmartContainer } from '../../../styles/Containers';
import { StyledRating } from '../../../styles/matieralui/Rating';
import { useUserRating } from './redux/userrating.hooks';
import { StyledList, StyledListItem, StyledListItemText } from '../../../styles/matieralui/List';
import styled from 'styled-components';

const StyledEmoji = styled(StyledListItem)`
    font-size: 2em;
    /* border: 1px solid currentColor !important; */
    margin-bottom: 0.25em !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
    /* width: 100% !important; */

    .MuiListItemText-root {
        margin-left: 1em;
        text-align: left;
        opacity: 0.5;
    }
`
const StyledEmojiList = styled(StyledList)`
    /* width: 100% !important; */
`

const StyledEmojiListItem = styled(StyledListItem)`
`

export const EmojiEmotions = [
    {
        value: 1,
        title: "😡",
        description: "Angry",
    },
    {
        value: 2,
        title: "😔",
        description: "Down",
    },
    {
        value: 3,
        title: "😕",
        description: "Anxious",
    },
    {
        value: 4,
        title: "😐",
        description: "Fine",
    },
    {
        value: 5,
        title: "😀",
        description: "Happy",
    },
    {
        value: 6,
        title: "🤩",
        description: "Great",
    }
]


export const UserRating = ({ value, type = 'unknown', meditationId = null, seriesId = null, onChange }) => {

    const { newUserRating } = useUserRating({ type, meditationId, seriesId });

    const handleChange = (e, v) => {
        newUserRating(v);
        if (!onChange) return;
        onChange(v);
    }

    const handleEmojiChange = (v) => {
        newUserRating(v);
        if (!onChange) return;
        onChange(v);
    }

    return (
        <SmartContainer width="100%" flex align="center" justify="center">
            {/* <StyledRating value={value} precision={1} onChange={handleChange} /> */}
            <StyledEmojiList>
                {EmojiEmotions.map(option => (

                    <StyledEmoji button onClick={() => handleEmojiChange(option.value)}>
                        {option.title}
                        <StyledListItemText>{option?.description}</StyledListItemText>
                    </StyledEmoji>

                ))}
            </StyledEmojiList>
        </SmartContainer>
    )
}