import React from 'react';
import { SmartContainer } from '../../styles/Containers';
import { StyledH1 } from '../../styles/StyledHTML'
// TCC Components
export const NotFound = () => {
    return (
        <SmartContainer flex width="100%">
            <SmartContainer width="100%" padding={4}>
                <StyledH1>Page Not Found</StyledH1>
            </SmartContainer>
        </SmartContainer>
      );
}