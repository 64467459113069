import React from 'react';
import styled, { css } from "styled-components";
import { SpaceIconX } from "../../styles/Icons";
import { StyledIconButton } from "../../../styles/matieralui/IconButton";
import { Theme } from '../../../styles/Theme';
export const CloseButton = (props) => {
    return (
        <StyledIconButton {...props}><SpaceIconX /></StyledIconButton>
    )
}

export const StyledCloseButton = styled(CloseButton)`
    background-color: ${Theme.colors.baseAlternate} !important;
    box-shadow: ${Theme.shadow.two};
    margin-bottom: 1em !important;
`