import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../../App';
import { FullScreenLoader } from '../../../components/Loaders/FullScreenLoader';
import { respondTo } from '../../../styles/Theme';

// Styles
import { SmartContainer, Stack, StyledGrid, StyledGridItem, Surface } from '../../../styles/Containers'
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledH5, StyledP } from '../../../styles/StyledHTML';
import { StyledList, StyledListItem } from '../../../styles/matieralui/List';

// Redux
import { getSeries, searchSeries } from '../Series/redux/series.actions';
import { FullSeries, findSeries } from '../Series/redux/series.selectors';
import { StyledH1, StyledImg } from '../../../styles/StyledHTML';

import { StyledMediaImage } from '../../components/Display/MediaAsset';

import { useAnimatedPageMount } from '../../services/SpaceUtils';
import { animated } from 'react-spring';
import { StackOnMobile } from '../../../styles/Containers';
import { MeditationListItem } from '../Meditations/MeditationListItem';
import { FullMeditations } from './redux/meditations.selectors';
import { getMeditation } from './redux/meditations.actions';
import { StyledRating } from '../../../styles/matieralui/Rating';
import { ScreenFlow } from '../../components/Display/ScreenFlow';
import { MeditationFlow } from './MeditationFlow';

const StyledMeditation = styled(StyledListItem)`
    padding-top: 1em !important;
    padding-bottom: 1em !important;
`

export const PageMeditate = () => {

    /**
     * TODO:
     * [ ] Check if Series is Free
     * [ ] If Free play it.
     * [ ] If Not redirect to billing.
     */

    // New Modal
    const dispatch = useDispatch();
    const fullSeries = FullSeries();
    const fullMeditations = FullMeditations();
    const { id, meditationId } = useParams();
    const [activeSeries, setActiveSeries] = useState(null);
    const [activeMeditation, setActiveMeditation] = useState(null);
    const { animatedProps } = useAnimatedPageMount();


    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Load Everything
        if (!fullSeries?.items) dispatch(searchSeries(1, 999, null, 'published'))

    }, [])

    // Get This Series
    useEffect(() => { dispatch(getSeries(id)) }, [id])
    useEffect(() => { dispatch(getMeditation(meditationId)) }, [meditationId])
    useEffect(() => {
        if (fullMeditations?.activeItem) setActiveMeditation(fullMeditations.activeItem);
    }, [fullMeditations?.activeItem])

    const goTo = (path) => dispatch(push(path))


    // --------------------------
    // Renders
    // --------------------------


    // Render Page
    return (
        <App stackNav="Series" back="back" backLabel="Back">
            {fullSeries?.itemsLoading && (<FullScreenLoader />)}
            {activeMeditation && (

                <animated.div style={animatedProps}>

                    <MeditationFlow seriesId={id} meditationId={meditationId} />

                </animated.div>


            )}
        </App>
    )
}