import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { HorizontalStack, SmartContainer, Stack } from '../../../styles/Containers';
import { StyledB, StyledH3, StyledH6, StyledImg, StyledP } from '../../../styles/StyledHTML';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { FormInput } from '../../../components/Forms/FormInput';

import logo from '../assets/revenue-cat-logo.jpeg';
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledSwitch } from '../../../styles/matieralui/Form';

import { useSaveIntegration } from '../integrations.hooks';
import { IntegrationCard } from '../IntegrationCard';
import { IntegrationFormHeader } from '../IntegrationFormHeader';

export const RevenueCatForm = ({ meta, open = false, network = false }) => {

    const key = 'revenuecat';
    const title = "Revenue Cat";
    const description = "In App Purchases";

    // Use Integration Save
    const { openForm, setOpenForm, saveIntegration, thinking } = useSaveIntegration({ open, network });

    // Form Fields
    const [pkey, setPublicKey] = useState('');
    const [skey, setSecretKey] = useState('');
    const [appId, setAppId] = useState('');
    const [enabled, setEnabled] = useState(false);

    // Set Default values
    useEffect(() => {
        if (!meta) return;
        if (meta[key] && meta[key].enabled) setEnabled(meta[key].enabled);
        if (meta[key] && meta[key].pkey) setPublicKey(meta[key].pkey);
        if (meta[key] && meta[key].skey) setSecretKey(meta[key].skey);
        if (meta[key] && meta[key].appId) setAppId(meta[key].appId);
    }, [meta])


    // Format Save Data
    const handleSave = () => {
        const m = { ...meta };
        if (!m[key]) m[key] = {};
        m[key].enabled = enabled;
        m[key].pkey = pkey;
        m[key].skey = skey;
        m[key].appId = appId;
        saveIntegration(m);
    }

    return (
        <SmartContainer thinking={thinking}>
            <IntegrationCard clickable onClick={() => setOpenForm(true)}
                title={title}
                description={description}
                logo={logo}
            />

            <StyledModal
                open={openForm}
                onClose={() => setOpenForm(false)}
            >
                <Stack>
                    <IntegrationFormHeader title={title} logo={logo}>
                        <StyledSwitch color="primary" checked={enabled} name="enabled" onChange={(e) => setEnabled(e.target.checked)} />
                    </IntegrationFormHeader>

                    <FormInput label="RevenueCat App ID" type="text" onChange={setAppId} value={appId} helperText="Revenue Cat App Id" />
                    <FormInput label="Public Key" type="text" onChange={setPublicKey} value={pkey} helperText="These are encrypted before storage" />
                    <FormInput label="Secret Key" type="text" onChange={setSecretKey} value={skey} helperText="These are encrypted before storage" />
                    <StyledButton variant="contained" color="primary" onClick={handleSave}>Save</StyledButton>
                </Stack>

            </StyledModal>
        </SmartContainer>
    )
}