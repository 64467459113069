import { clearActionType, failedActionType, loadingActionType, successActionType, wipeActionType } from './utils'

export const modelActionName = (model, action) => `[${model.toUpperCase()}] ${action} ${model}`;

export const DefaultModelReducers = (model, otherReducers = null) => {

    return (state = {}, action) => {

        // Add Reducers
        if(otherReducers !== null){
            const otherResult = otherReducers(model,state,action);
            if(otherResult !== false) return otherResult;
        }

        // Default Reducers
        switch (action.type) {
            case `[${model.toUpperCase()}] Wipe`:
                return {};
            
            // SEARCH Actions
            case modelActionName(model, 'Search'):
            case failedActionType(modelActionName(model, 'Search')):
            case successActionType(modelActionName(model, 'Search')):
            case clearActionType(modelActionName(model, 'Search')):
            
            // GET Actions
            case modelActionName(model, 'Get'):
            case failedActionType(modelActionName(model, 'Get')):
            case successActionType(modelActionName(model, 'Get')):
            case clearActionType(modelActionName(model, 'Get')):

            // CREATE Actions
            case modelActionName(model, 'Create'):
            case failedActionType(modelActionName(model, 'Create')):
            case clearActionType(modelActionName(model, 'Create')):

            // UPDATE Actions
            case modelActionName(model, 'Update'):
            case failedActionType(modelActionName(model, 'Update')):
            case clearActionType(modelActionName(model, 'Update')):

            // DELETE Actions
            case modelActionName(model, 'Delete'):
            case failedActionType(modelActionName(model, 'Delete')):
            case clearActionType(modelActionName(model, 'Delete')):

            // FORCE DELETE Actions
            case modelActionName(model, 'Force Delete'):
            case failedActionType(modelActionName(model, 'Force Delete')):
            case clearActionType(modelActionName(model, 'Force Delete')):

            // RESTORE Actions
            case modelActionName(model, 'Restore'):
            case failedActionType(modelActionName(model, 'Restore')):
            case clearActionType(modelActionName(model, 'Restore')):

            // REPLACE TAGS Action
            case modelActionName(model, 'Replace Tags'):
            case failedActionType(modelActionName(model, 'Replace Tags')):
            case successActionType(modelActionName(model, 'Replace Tags')):
            case clearActionType(modelActionName(model, 'Replace Tags')):
            
            // REVISIONS Actions
            case modelActionName(model, 'Get Revisions'):
            case failedActionType(modelActionName(model, 'Get Revisions')):
            case successActionType(modelActionName(model, 'Get Revisions')):
            case clearActionType(modelActionName(model, 'Get Revisions')):
            
            // DUPLICATE Actions
            case modelActionName(model, 'Duplicate'):
            case failedActionType(modelActionName(model, 'Duplicate')):
            case clearActionType(modelActionName(model, 'Duplicate')):

                return Object.assign({},state,{...action.payload})
            
            // Creation State Update
            case successActionType(modelActionName(model, 'Create')):
                const newCreationState = Object.assign({},state,{...action.payload})
                if(newCreationState?.items && newCreationState?.items?.rows){
                    newCreationState.items.rows.push(action.payload.create)
                    newCreationState.items.count += 1;
                }
                return newCreationState;
            
            // Duplicate State
            case successActionType(modelActionName(model, 'Duplicate')):
                const duplicateState = Object.assign({},state,{...action.payload})
                if(duplicateState?.items && duplicateState?.items?.rows){
                    duplicateState.items.rows.push(action.payload.duplicate)
                    duplicateState.items.count += 1;
                }
                return duplicateState;
    
            // Update State Update
            case successActionType(modelActionName(model, 'Update')):
                const updateState = Object.assign({},state,{...action.payload})

                // Replace the object in the array. If it's there.
                if(updateState.items && updateState.items.rows){
                    const items = updateState.items.rows.map(item => {
                        if(item.id === action.payload.update.id) {return action.payload.update;}
                        return item;
                    })
                    updateState.items.rows = [...items];
                }

                // Replace the Active Item
                if(state?.activeItem?.id && state?.activeItem?.id === action.payload.update.id){

                    // First Deleted the existing active item.
                    delete updateState.activeItem;
                    updateState.activeItem = action.payload.update
                }
                return updateState;
            
            // Deletion State Update
            case successActionType(modelActionName(model, 'Delete')):  
                const deleteState = Object.assign({},state,{...action.payload})
                if(deleteState.items && deleteState.items.rows){
                    const newDeleteItems = deleteState.items.rows.filter(item => item.id !== action.payload.delete.id)
                    deleteState.items.rows = [...newDeleteItems];
                }
                return deleteState;
            case successActionType(modelActionName(model, 'Force Delete')):                
                const forceDeleteState = Object.assign({},state,{...action.payload})
                if(forceDeleteState.items && forceDeleteState.items.rows){
                    const newDeleteItems = forceDeleteState.items.rows.filter(item => item.id !== action.payload.forceDelete.id)
                    forceDeleteState.items.rows = [...newDeleteItems];
                }
                return forceDeleteState; 
            
            // Restore State Update
            case successActionType(modelActionName(model, 'Restore')):                
                const restoreState = Object.assign({},state,{...action.payload})
                if(restoreState.items && restoreState.items.rows){
                    const newRestoreItems = restoreState.items.rows.filter(item => item.id !== action.payload.restore.id)
                    restoreState.items.rows = [...newRestoreItems];
                }
                return restoreState; 
                
            // Default
            default:
                return state
        }
    }
}