import React from 'react';
import { useDispatch } from 'react-redux';



// TCC Redux
import { FullUI } from '../../redux/UI/ui.selectors'
import { closeToast } from '../../redux/UI/ui.actions'
// Components
import { RecoverForm } from './RecoverForm'
import { Toast } from '../Toast/Toast'
// Styles
import { SmartContainer, Surface, Stack } from '../../styles/Containers'

// SPACE CULTURE
import logo from '../../space/assets/logo-black-short.png';
import { StyledImg } from '../../styles/StyledHTML';



export const RecoverPage = () => {

    // Read State
    const dispatch = useDispatch()
    const ui = FullUI();

    return (
        <SmartContainer padding={10}>
            <Stack flex direction="column" align="center" justify="center">
                <SmartContainer maxWidth="200px">
                    <StyledImg alt="Space Maker" src={logo} />
                </SmartContainer>

                <RecoverForm />

            </Stack>
            {ui.toast?.open && (<Toast onClose={() => dispatch(closeToast())} open={ui.toast?.open} severity={ui.toast?.severity} message={ui.toast?.message} />)}
        </SmartContainer>
    )
}