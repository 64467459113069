import styled, { css } from 'styled-components';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { RichTextEditor } from '../../components/Forms/RichText';
import { Theme, setSpace } from '../Theme'
import { SmartContainer } from '../Containers'

export const StyledRadioGroup = styled(RadioGroup)``;
export const StyledRadio = styled(Radio)``;
export const StyledFormControl = styled(FormControl)``;
export const StyledFormGroup = styled(FormGroup)``;
export const StyledFormControlLabel = styled(FormControlLabel)``;
export const StyledFormLabel = styled(FormLabel)``;
export const StyledSwitch = styled(Switch)``;
export const StyledTextField = styled(TextField)`
    
    &.MuiFormControl-root { width: 100%; }
    .MuiFilledInput-underline:before { display: none; }
    /* .MuiFilledInput-underline { background-color: ${Theme.elements.input.background};} */
    /* .MuiInputLabel-filled, .MuiFilledInput-input { color: ${Theme.elements.input.text}; } */
    .MuiInputBase-root { border-radius: ${Theme.radius}; }

    ${props => (props.inverse) ? css`
        /* .MuiFilledInput-underline { background-color: ${Theme.elements.input.inverse};} */
        /* .MuiInputLabel-filled, .MuiFilledInput-input { color: ${Theme.elements.input.inverseText}; } */
    ` : ``}

`;
export const StyledDatePicker = styled(TextField)`
    &.MuiFormControl-root {
        width: 100%;
    }
    .MuiFilledInput-underline {
        &:before {
            display: none;
        }
        /* background-color: ${Theme.elements.input.background}; */
    }
    /* .MuiInputLabel-filled {
        color: ${Theme.elements.input.text};
    }
    .MuiFilledInput-input {
        color: ${Theme.elements.input.text};
    } */
    .MuiInputBase-root {
        border-radius: ${Theme.radius}
    }
`

export const StyledSelect = styled(Select)`
    &.MuiFormControl-root {
        width: 100%;
    }
    .MuiFilledInput-underline {
        &:before {
            display: none;
        }
        /* background-color: ${Theme.elements.input.background}; */
    }
    /* .MuiInputLabel-filled {
        color: ${Theme.elements.input.text};
    }
    .MuiFilledInput-input {
        color: ${Theme.elements.input.text};
    } */
    .MuiInputBase-root {
        border-radius: ${Theme.radius}
    }
`;

export const StyledSelectControl = styled(FormControl)`
    &.MuiFormControl-root {
        width: 100%;
    }
    .MuiFilledInput-underline {
        &:before {
            display: none;
        }
        /* background-color: ${Theme.elements.input.background}; */
    }
    /* .MuiInputLabel-filled {
        color: ${Theme.elements.input.text};
    }
    .MuiFilledInput-input {
        color: ${Theme.elements.input.text};
    } */
    .MuiInputBase-root {
        border-radius: ${Theme.radius}
    }
`;

export const StyledIconInput = styled(SmartContainer)`
    position: relative;
    .MuiIconButton-root {
        position: absolute;
        right: ${setSpace(1)};
        top: 50%;
        transform: translateY(-50%);
        color: ${Theme.elements.input.text};
    }
`

export const StyledAutocomplete = styled(Autocomplete)``

export const StyledCheckedbox = styled(Checkbox)``

// Rich Text
export const StyledInlineRichText = styled(RichTextEditor)`
    

    border-radius: ${Theme.radius};
    width: 100%;
    .DraftEditor-root {
        background-color: transparent !important;
        padding: 1.5em;
        padding-top:1em;
    }
    .public-DraftEditor-content {
        min-height: 200px;
    }
    .richtext-toolbar > div {
        border: 0px;
    }
    .richtext {
        padding: 0.5em !important;
    }
    padding: 0.5em !important;
    .richtext-label {
        font-size: 0.75rem !important;
        display: inline-block;
        display: none;
    }
    .richtext-input .MuiInputBase-root {
        border: 0px !important;
    }
    [data-rich-text-editor-controls]{
        border-radius: 1000px;
        padding-left: 0.5em;
        width: auto ;
    }
`

