import React from 'react'; 
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';


// Styles
import { SmartContainer, Surface } from '../../styles/Containers'
import { setSpace } from '../../styles/Theme'
import { StyledB, StyledH1, StyledP } from '../../styles/StyledHTML'



export const TermsPage = () => {
   

    return (
        <SmartContainer flex justify="center" padding={4}>
            <Surface shadow="two" minWidth="90%">
                <StyledH1>Terms &amp; Conditions</StyledH1>
                <StyledB light>Version 1.0.0</StyledB>
                <StyledP>Terms goes here.</StyledP>
            </Surface>
        </SmartContainer>
    )
}