import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'


// Components
import { App } from '../../App';
import { Surface, Stack } from '../../styles/Containers'
import { FormInputAddressSearch } from '../../components/Forms/FormInputAddressSearch';



import { StyledH1, StyledH6, StyledP } from '../../styles/StyledHTML';
import { MapView } from '../../components/Maps/MapView';



export const MapsLab = () => {
    const [location,setLocation] = useState(null);
    const handleMarkerClick = (m) => {
        alert("Marker Click")
        console.log(m,"THE MARKER")
    }
    const marker = [
        {
            coord: [49.1951, 16.6068]
        }
    ]
    return (
        <App title="Lab: Date Inputs">
            <Stack>
                <Surface>
                    <Stack>
                        <FormInputAddressSearch variant="outlined" label="Location" onChange={setLocation} value={location} />
                        <MapView markers={marker} onMarkerClick={handleMarkerClick} />
                    </Stack>
                </Surface>
            </Stack>
        </App>
    )
}