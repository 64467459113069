import React from 'react';
import styled from 'styled-components';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const BreadcrumbsStyles = styled(Breadcrumbs)``

export const StyledBreadcrumbs = ({links}) => {
    return(
        <BreadcrumbsStyles>
            {links.map(item => (<Link key={item.href} href={item.href} color="inherit">{item.title}</Link>))}
        </BreadcrumbsStyles>
    )
}