import React, { useEffect } from 'react';
export const Recaptcha = ({onSuccess}) => {

    const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?`
        window.handleRecaptchaSubmit = (token) => {
           if(!token) return false;
           onSuccess(token)
        }
        document.body.appendChild(script)
    },[])

    return (
        <div className="g-recaptcha" data-sitekey={siteKey} data-callback="handleRecaptchaSubmit"></div>
    )
}