import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { App } from '../../App';
import { MediaLibrary } from './MediaLibrary';
import { RouteBreadCrumbs } from '../../routes';

// The Component
export const MediaPage = () => {

    const dispatch = useDispatch();
    const handleMediaLibrarySelection = (item) => dispatch(push(`/media/${item.id}`))

    // Render Page
    return (
        <App title="Media" breadcrumbs={[RouteBreadCrumbs.dashboard]}>
            <MediaLibrary onChange={handleMediaLibrarySelection} />
        </App>
    )
}

