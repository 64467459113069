import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { uiMeditationPlaying } from '../../../redux/UI/ui.actions';
import { SmartContainer } from '../../../styles/Containers';
import { StyledH1 } from '../../../styles/StyledHTML';
import { Screen } from '../../components/Display/Screen';
import { PageBreathingComplete } from './PageBreathingComplete';
import { PageBreathingSelect } from './PageBreathingSelect';
import { PageBreathingStart } from './PageBreathingStart';
const StyledCountdownText = styled(SmartContainer)`
  position: relative;
  z-index: 2;
  background-color: #f2e0c0;
  width: 100px;
  height: 100px;
  margin: auto;
  border: 5px solid #fbbf9b;
  border-radius: 120px;
`;

const StyledCountdown = styled(StyledH1)`
  margin-top: 10px;
  color: white;
`;

export const BreathingFlow = () => {
    const screenAlign = "space-between";
    const dispatch = useDispatch();
    const [activeScreen, setActiveScreen] = useState("select");
    const [time, setTime] = useState(2);
    const [exercise, setExercise] = useState();
    const [countdown, setCountdown] = useState(3);
    const getTime = (data) => {
        setTime(data);
    };

    const getScreen = (data) => {
        if (data === 'start') dispatch(uiMeditationPlaying(true));
        else if (data === 'complete') dispatch(uiMeditationPlaying(false));
        else if (data === 'select') dispatch(uiMeditationPlaying(false));
        setActiveScreen(data);
    };

    const getExercise = (data) => {
        setExercise(data);
    };


    useEffect(() => { setCountdown(3) }, [])

    useEffect(() => {
        if (activeScreen === "loading") {
            //  Countdown Intro Timer
            setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            setTimeout(() => {
                setActiveScreen("start");
                dispatch(uiMeditationPlaying(true));
            }, 3000);
        }
    });
    // Render Page
    return (
        <SmartContainer
            height="100vh"
            flex
            direction="column"
            align="center"
            textAlign="center"
            justify="space-between"
            width="100%"
        >
            {activeScreen === "select" && (
                <Screen align="center" justify="center" flex width="100%" height="100vh">
                    <PageBreathingSelect time={getTime} exercise={getExercise} screen={getScreen} />
                </Screen>
            )}
            {activeScreen === "loading" && (
                <Screen align="center" justify="center" flex width="100%" height="100vh">
                    <StyledCountdownText><StyledCountdown>{countdown}</StyledCountdown></StyledCountdownText>
                </Screen>
            )}
            {activeScreen === "start" && (
                <Screen align="center" justify="center" flex width="100%" height="100vh">
                    <PageBreathingStart time={time} screen={getScreen} exercise={exercise} />
                </Screen>
            )}
            {activeScreen === "complete" && (
                <Screen>
                    <PageBreathingComplete screen={getScreen} />
                </Screen>
            )}
        </SmartContainer>
    );
}