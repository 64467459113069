export const setCookie = (c_name, value, exdays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value + "; path=/";
}

export const getCookie = (c_name) => {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == c_name) {
            return unescape(y);
        }
    }
}

export const openInNewTab = (url) => {
    const win = window.open(url, '_blank');
    if (win != null) {
        win.focus();
    }
}


export const getInitials = (name) => {
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
}

export const isEmail = (email) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return (email.match(regex)) ? true : false
}

export const uniqueId = (length = 3) => {
    // return Math.floor(Math.random() * (length*100))
    return Math.random().toString().slice(2, (length + 2))
}

export const getQueryParam = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
}

export const splitAndTrim = (string, splitOn = ',') => {
    if (string.indexOf(splitOn) > -1) {
        const items = string.split(splitOn);
        const formatted = items.map(tag => tag.replace(/ /g, ''))
        return formatted;
    } else {
        return [string];
    }
}

export const fullName = (user) => {
    return `${user.firstName} ${user.lastName}`;
}

export const isPaymentPastDue = (account) => {
    return (account?.paymentStatus === 'pastdue') ? true : false;
}

export const hasPaymentFailed = (account) => {
    return (account?.paymentStatus === 'failed') ? true : false;
}

export const isAccountCanceled = (account) => {
    return (account?.subscriptionStatus === 'canceled') ? true : false;
}

export const restrictAccessBecauseOfPayment = (account) => {
    if (hasPaymentFailed(account)) return true;
    if (isAccountCanceled(account)) return true;
    return false;
}

export const isPayingCustomer = (account, validPlans) => {
    // No Account: Get Account
    if (!account) return false;

    // Fails if no plan
    if (!account?.plan) return false;

    const plans = validPlans?.split('|');

    // Get Current Plan
    const accountPlan = account?.plan.toLowerCase();

    // Validate The Account isn't Canceled or Failing too many payments
    if (restrictAccessBecauseOfPayment(account)) return false;

    // Check if account has access
    const plan = plans.filter(plan => {
        if (plan.toLowerCase() === accountPlan) return plan;
        return false;
    })

    if (plan?.length > 0) return true;
    return false;
}

export const loadScript = (src, position, id) => {
    if (!position) return;

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export const postMessage = (action, data) => {


    let targetWindow = (!window.parent) ? window : window.parent;
    if (window?.ReactNativeWebView) {
        targetWindow = window.ReactNativeWebView;
    }

    const a = JSON.stringify({ action, data });
    targetWindow.postMessage(a);
}

export const isStripeSubscription = (subId) => {
    // TODO: This isn't perfect.
    return (subId.indexOf('sub_') > -1) ? true : false;
}

export const isNativeApp = () => {
    return (window?.ReactNativeWebView) ? true : false;
}

export const isAndroid = () => {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) return true;
    return false;
}

export const storeWithExpiry = async (key, value, minutes) => {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + (minutes * 60000),
    }
    window.localStorage.setItem(key, JSON.stringify(item))
    return item;
}

export const loadWithExpiry = async (key) => {
    const itemStr = window.localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        window.localStorage.removeItem(key)
        return null
    }
    return item.value
}