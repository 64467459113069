import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NetworkApp } from '../../../components/Network/NetworkApp';
import { FullAuth } from '../../../redux/Auth/auth.selectors';
import { FullNetwork } from '../../../redux/Network/network.selectors';
import { RouteBreadCrumbs } from '../../../routes';
import { HorizontalStack, SmartContainer, Stack, Surface } from '../../../styles/Containers';
import { StyledB, StyledH3, StyledH6, StyledP } from '../../../styles/StyledHTML';
import { useSummary } from '../UserProgress/redux/userprogress.actions';
import { EmojiEmotions } from '../UserRatings/UserRating';


export const PageNetworkStats = () => {
    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();
    const fullAuth = FullAuth();
    const { id, app } = useParams();
    const networkId = parseInt(id);

    const goTo = (path) => dispatch(push(path))

    const { summary } = useSummary();

    return (
        <NetworkApp title="Network Stats" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            <Stack>
                <StyledH6>Platform Events</StyledH6>
                {summary?.summary && (
                    <HorizontalStack flex>
                        {Object.keys(summary?.summary).map(key => (
                            <Surface width="100%">
                                <StyledP><StyledB>{key}</StyledB></StyledP>
                                <StyledP light>{summary.summary[key].Meditation.name}</StyledP>
                                <StyledP light small>{summary.summary[key].data.total} Times</StyledP>
                            </Surface>
                        ))}
                    </HorizontalStack>
                )}

                <HorizontalStack flex>
                    {EmojiEmotions.map(option => (
                        <SmartContainer flex align="center" justify="center" direction="column">
                            <StyledP>{option.title}</StyledP>
                            <StyledP light>{option.value}</StyledP>
                        </SmartContainer>
                    ))}
                </HorizontalStack>
                <StyledP><StyledB>Average Before Rating</StyledB> {summary?.ratings?.before?.average_rating}</StyledP>
                <StyledP><StyledB>Average After Rating</StyledB> {summary?.ratings?.after?.average_rating}</StyledP>
            </Stack>
        </NetworkApp>
    )
}