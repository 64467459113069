import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import { SmartContainer, Stack, Surface, StyledStyledSideBarContainer } from '../../styles/Containers'

// Components
import { FormInput } from '../../components/Forms/FormInput';
import { PublishingForm } from '../../components/Publishing/PublishingForm';

// Redux
import { FullMediaTags } from './redux/media.selectors';
import { updateMediaTag, deleteMediaTag, updateNetworkMediaTag, deleteNetworkMediaTag } from './redux/media.actions';

// ---------------------------
// Edit Example Taxonomy Form
// ---------------------------

export const EditMediaTagForm = ({item,network=false}) => {

    const dispatch = useDispatch();
    const store = FullMediaTags();

    // Name
    const [name,setName] = useState('');
    const [nameError,setNameError] = useState(false);
    const [nameHelperText,setNameHelperText] = useState('The title of the Product.')

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    // Sync Props
    useEffect(() => {
        if(item?.name) setName(item.name);
    },[item])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (publishing) => {


        // Payload
        const payload = {
            name,
        }
        if(network === true) dispatch(updateNetworkMediaTag(item.id, payload));
        else dispatch(updateMediaTag(item.id, payload));
    }

       
    // --------------------------
    // Render Form
    // --------------------------

    return (
        <StyledStyledSideBarContainer thinking={store?.updateLoading} minWidth="300px">
            <SmartContainer>
                <Surface>
                    <Stack>
                        <FormInput label="Name" type="text" onChange={setName} value={name} helperText={nameHelperText} error={nameError} />            
                    </Stack>
                </Surface>
            </SmartContainer>
            <SmartContainer>
                <Stack>
                    <Surface>                        
                        <PublishingForm 
                            item={item} 
                            onSubmit={handleSubmit} 
                            disable={disableSubmit}
                            deleteAction={(network === true) ? deleteNetworkMediaTag : deleteMediaTag}
                            selector={FullMediaTags}
                            route={(network === true) ? "/network/media/tags" : "/media/tags"}
                            withScheduling={false}
                            withStatus={false}
                            />
                    </Surface>
                </Stack>
            </SmartContainer>
        </StyledStyledSideBarContainer>
    )
}