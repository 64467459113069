import React, { useEffect } from 'react';
import { App } from '../../../App';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { SmartContainer, Stack, StyledGridByFour, StyledGridItem } from '../../../styles/Containers';
import { Surface } from '../../../styles/Containers';
import { StyledB, StyledH5, StyledP } from '../../../styles/StyledHTML';
import { attemptLogout } from '../../../redux/Auth/auth.actions';
import { config } from '../../../config';
import { isNativeApp, isPayingCustomer, postMessage } from '../../../services/Utils';
import { validPlans } from '../../services/SpaceUtils';
import { FullAccount } from '../../../redux/Account/account.selectors';
export const PageSpaceAccount = () => {
    const dispatch = useDispatch();
    const goTo = (path) => dispatch(push(path));
    const handleLogout = () => dispatch(attemptLogout());
    const nativeApp = isNativeApp();
    const fullAccount = FullAccount();
    const customerIsPaying = isPayingCustomer(fullAccount?.account, validPlans);
    const bounce = (url, target = null) => {

        if (target === '_blank') {
            window.open(url, '_blank').focus();
        } else {
            window.location.href = url;
        }
    }
    const handleReminders = () => {
        postMessage('LOCAL_REMINDERS', { title: "Reminders", message: "There's no better time than now.", back: document?.referrer });
    }
    return (
        <App>
            <Stack>
                <StyledGridByFour>
                    {!customerIsPaying && (<StyledGridItem clickable onClick={() => bounce(config.buyMeACoffee, '_blank')}><Surface width="100%"><StyledB>Help Keep This App Free</StyledB><StyledP light>Support Space Culture</StyledP></Surface></StyledGridItem>)}
                    <StyledGridItem clickable onClick={() => bounce('https://discord.com/invite/DjMrdG5nMG', '_blank')}><Surface width="100%"><StyledB>Discord Community</StyledB><StyledP light>Join a community of Space Makers</StyledP></Surface></StyledGridItem>
                    <StyledGridItem clickable onClick={() => goTo('/profile')}><Surface width="100%"><StyledB>My Profile</StyledB><StyledP light>Email &amp; Name</StyledP></Surface></StyledGridItem>
                    {nativeApp && (<StyledGridItem clickable onClick={handleReminders}><Surface width="100%"><StyledB>Notifications</StyledB><StyledP light>Daily Reminders</StyledP></Surface></StyledGridItem>)}
                    <StyledGridItem clickable onClick={() => goTo('/billing')}><Surface width="100%"><StyledB>Billing</StyledB><StyledP light>Manage your subscription</StyledP></Surface></StyledGridItem>
                    <StyledGridItem clickable onClick={handleLogout}><Surface width="100%"><StyledB>Log Out</StyledB><StyledP light>Sign Out of your account</StyledP></Surface></StyledGridItem>
                </StyledGridByFour>
                <SmartContainer textAlign="center">
                    <StyledP small light>Version: {config.appVersion} </StyledP>
                </SmartContainer>
            </Stack>
        </App>
    )
}