import styled, { css } from 'styled-components';
import { Theme, setSpace, respondTo } from './Theme';
import { SkeletonAnimation } from './GlobalStyles';

/**
 * Smart Container
 * This is a div that we can control for everything.
 */

export const SmartContainer = styled.div`



    // Position
    ${props => (props.position) ? css`position: ${props.position};` : ''}
    ${props => (props.top) ? css`top: ${props.top};` : ''}
    ${props => (props.bottom) ? css`bottom: ${props.bottom};` : ''}
    ${props => (props.left) ? css`left: ${props.left};` : ''}
    ${props => (props.right) ? css`right: ${props.right};` : ''}

    // Flex
    ${props => (props.flex) ? css`display: flex;` : ''}
    ${props => (props.align) ? css`align-items: ${props.align};` : ''}
    ${props => (props.justify) ? css`justify-content: ${props.justify};` : ''}
    ${props => (props.flexValue) ? css`flex: ${props.flexValue};` : ''}
    ${props => (props.direction) ? css`flex-direction: ${props.direction};` : ''}
    ${props => (props.wrap) ? css`flex-wrap: wrap;` : ''}

    // Height
    ${props => (props.height) ? css`height: ${props.height};` : ''}
    ${props => (props.minHeight) ? css`min-height: ${props.minHeight};` : ''}

    // Width
    ${props => (props.width) ? css`width: ${props.width};` : ''}
    ${props => (props.minWidth) ? css`min-width: ${props.minWidth};` : ''}
    ${props => (props.maxWidth) ? css`max-width: ${props.maxWidth};` : ''}

    // Shadow
    // Shadows
   ${props => (props.shadow) ? css` box-shadow: ${Theme.shadow[props.shadow]};` : ``}


    // Default Padding
    ${props => (props.padding) ? css`padding: ${setSpace(props.padding)};` : ''}

    // Default Margin
    ${props => (props.margin) ? css`margin: ${setSpace(props.margin)};` : ''}
    ${props => (props.forceMargin) ? css`margin: ${props.forceMargin};` : ''}

    // Background
    ${props => (props.background) ? css`background: ${props.background}; background-size: cover; background-position: center center;` : ''}

    // Text Align
    ${props => (props.textAlign) ? css`text-align: ${props.textAlign};` : ''}

    // Cursor
    ${props => (props.cursor) ? css`cursor: ${props.cursor};` : ''}

    // Clickable
    ${props => (props.clickable) ? css`cursor: pointer;` : ''}

    ${props => (props.overflow) ? css`overflow: ${props.overflow};` : ''}
    ${props => (props.zIndex) ? css`z-index: ${props.zIndex};` : ''}
    ${props => (props.opacity) ? css`opacity: ${props.opacity};` : ''}
    ${props => (props.overflow) ? css`overflow: ${props.overflow};` : ''}
    ${props => (props.border) ? css`border: ${props.border};` : ''}

    // Skeleton
    ${props => (props.skeleton) ? css`
         opacity: 0.5;
         pointer-events: none;
         animation: ${SkeletonAnimation} 2500ms linear infinite;
         p, h6, h5, h4, h3, h2, h1 {
         //    height: 1em;
         //    min-width: 100px;
         //    display: inline-block;
            background-color: currentColor;
            opacity: 0.25;
         }
   ` : ''}

      // Thinking
      ${props => (props.thinking) ? css`
         opacity: 0.25;
         pointer-events: none;
         transition: 500ms all;
         /* transform: ${Theme.scale.down}; */
      ` : ''}
   

      // Absolute Center
      ${props => (props.absoluteCenter) ? css`
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%,-50%);
      ` : ''}
      

      // Native App SafeArea
      ${props => (props.SafeArea) ? css`

        padding-top: 20px;
      ` : ''}
    
`;

/**
 * Surface
 */

export const Surface = styled(SmartContainer)`
    padding: ${setSpace(4)};
    border-radius: ${Theme.radius};
   

   // Variant
   ${props => (props.variant === 'inverse') ? css` background-color: ${Theme.colors.base};` : `background-color: ${Theme.colors.baseAlternate};`}
   
   // Shadows
   ${props => (props.shadow) ? css` box-shadow: ${Theme.shadow[props.shadow]};` : `box-shadow: ${Theme.shadow.none};`}
 `;


export const Stack = styled(SmartContainer)`
    > * { margin-bottom: ${setSpace(4)} !important; }
    > *:last-child { margin-bottom: 0 !important; }
 `
export const LargeStack = styled(Stack)`
   padding: ${setSpace(4)};
   > * { margin-bottom: ${setSpace(20)} !important; }
   > *:last-child { margin-bottom: 0 !important; }
   ${respondTo('mobile', css`
      > * { margin-bottom: ${setSpace(14)} !important; }
   `)}
`

export const MediumStack = styled(Stack)`
   > * { margin-bottom: ${setSpace(12)} !important; }
   > *:last-child { margin-bottom: 0 !important; }
`
export const HorizontalStack = styled(SmartContainer)`
 > * { margin-right: ${setSpace(4)} !important; }
 > *:last-child { margin-right: 0 !important; }
`

export const StyledGrid = styled(SmartContainer)`
justify-content: flex-start;
flex-wrap: wrap;
display: flex;
// Default to Four
> * {
   width: 25%;
   
   ${props => (props.columns === 1) ? css`width:100%;` : ``}
   ${props => (props.columns === 2) ? css`width:50%;` : ``}
   ${props => (props.columns === 3) ? css`width:33.3%;` : ``}
   ${props => (props.columns === 4) ? css`width:25%;` : ``}
   ${props => (props.columns === 5) ? css`width:20%;` : ``}
   ${props => (props.columns === 6) ? css`width:16.6%;` : ``}
   ${props => (props.columns === 7) ? css`width:14.2%;` : ``}
   ${props => (props.columns === 8) ? css`width:12.5%;` : ``}
   @media (max-width: 1024px) { width: 50%; }
   @media (max-width: 768px) { width: 100%; }
}


`

export const StyledGridByFour = styled(SmartContainer)`
   justify-content: flex-start;
   flex-wrap: wrap;
   display: flex;
   > * {
      width: 25%;
      @media (max-width: 1024px) { width: 50%; }
      @media (max-width: 768px) { width: 100%; }
   }
 `
export const StyledGridByTwo = styled(SmartContainer)`
   justify-content: flex-start;
   flex-wrap: wrap;
   display: flex;
   > * {
      width: 50%;
      @media (max-width: 768px) { width: 100%; }
 }
`
export const StyledStyledSideBarContainer = styled(SmartContainer)`
   justify-content: flex-start;
   flex-wrap: wrap;
   display: flex;
   >*:first-child {
      width: 70%;
      margin-right: 2.5%;
      @media (max-width: 768px) { width: 100%; margin-right: 0%; margin-bottom: ${setSpace(4)} }
   }
   >*:last-child {
      width: 25%;
      margin-left: 2.5%;
      @media (max-width: 768px) { width: 100%; margin-left: 0%; }
   }
 `

export const StyledGridItem = styled(SmartContainer)`
    padding: ${setSpace(2)};
    display: flex;
`;

export const StyledRichTextContent = styled(SmartContainer)`
   // The Opposite of a CSS Reset
   h1 { font-size: 38px; }
   h2 { font-size: 34px; }
   h3 { font-size: 30px; }
   h4 { font-size: 26px; }
   h5 { font-size: 20px; }
   h6 { font-size: 16px; }
   b, strong { font-weight: bold; }
   blockquote { padding: 1em; }
   pre {
      background-color: rgba(0,0,0,0.25);
      padding: 1em;
   }
   ul {
      list-style: disc;
   }
   li {
      margin-left: 1em;
   }
`

export const StyledImagePlaceholder = styled(SmartContainer)`
    width: 100%;
    height: auto;
    background-color: rgba(0,0,0,0.15);
    border-radius: ${Theme.radius};
    box-shadow: ${Theme.shadow.one};
`;

export const ResponsiveEmbed = styled(SmartContainer)`
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

export const StackOnMobile = styled(SmartContainer)`

    ${respondTo('mobile', css`
        display: block !important;
        > * {
            width: 100% !important;
            margin-bottom: ${setSpace(4)} !important;
        }
        > *:last-child { margin-bottom: 0 !important; }
    `)}
`

// SPACE
export const StyledBottomContainer = styled(SmartContainer)`
   width: 100%;
   position: fixed;
   bottom: 100px;
   left: 0;
   right: 0;
   padding-left: 1em;
   padding-right: 1em;
   z-index: 9999;
`

export const StyledScrollContainer = styled(SmartContainer)`
   overflow: scroll;
   width: 100%;
   max-height: 50vh;
`
export const StyledFadeIn = styled(SmartContainer)`
   transition: all 500ms;
    ${props => (props.visible) ? css`opacity:1;` : 'opacity:0'}
`