import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SmartContainer, Stack } from '../../../styles/Containers';
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { StyledB, StyledImg, StyledP } from '../../../styles/StyledHTML';
import logo from '../../assets/logo512.png';
import IosShareIcon from '@mui/icons-material/IosShare';
const StyledInstallButton = styled(StyledButton)`
    
    &.MuiButtonBase-root {
        background-color: #3478f6 !important;
        box-shadow: none;
        border-radius: 1000px;
        font-size: 10px;
        font-weight: bold;
        width: 100px;
    }
`

const StyledAppIcon = styled(SmartContainer)`
    img { 
        border-radius: 8px;
    }
`

const useIsIOS = () => {

    const [ios, setIOS] = useState(false);

    const checkForIOS = () => {
        if (navigator.standalone) {
            return false;
        }
        const ua = window.navigator.userAgent;
        const webkit = !!ua.match(/WebKit/i);
        const isIPad = !!ua.match(/iPad/i);
        const isIPhone = !!ua.match(/iPhone/i)
        const isIOS = isIPad || isIPhone;
        const isSafari = isIOS && webkit && !ua.match(/CriOS/i);
        if (isSafari && isIOS) return true;
        return false;
    }

    useEffect(() => {
        const check = checkForIOS();
        setIOS(check);
    }, [])

    return {
        ios,
    }

}

export const InstallApp = () => {



    const { ios } = useIsIOS();

    const [showIOSPrompt, setShowIOSPrompt] = useState(false);

    // IF ALREADY INSTALLED
    if (navigator.standalone) return false;

    // IF IOS
    // if (!ios) return null; // For Now we want to show this to everyone.

    return (
        <SmartContainer>
            <StyledInstallButton variant='contained' onClick={() => setShowIOSPrompt(true)}>INSTALL APP</StyledInstallButton>
            <StyledModal open={showIOSPrompt} onClose={() => setShowIOSPrompt(false)}>
                <Stack width="100%" textAlign="center" flex direction="column" align="center" >
                    <StyledAppIcon maxWidth="100px">
                        <StyledImg alt="Space Maker" src={logo} />
                    </StyledAppIcon>
                    <StyledB>Progressive Web App Install</StyledB>
                    <StyledP>Making Space for God is about removing distractions. You don't need another push notification or app that's tracking your location. We don't do that. Let this icon be the reminder for you.</StyledP>
                    <SmartContainer>
                        On your phone, Tap &nbsp;
                        <IosShareIcon />
                        &nbsp; then "Add to Home Screen"
                    </SmartContainer>
                    <StyledButton onClick={() => setShowIOSPrompt(false)}>close</StyledButton>
                </Stack>
            </StyledModal>
        </SmartContainer>
    )
}