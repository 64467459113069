import { useSpring } from "react-spring";
import moment from "moment";
import { useEffect, useRef } from "react";

export const validPlans = 'space_basic_monthly|space_basic_yearly';

export const storeWithExpiry = async (key, value, minutes) => {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + (minutes * 60000),
    }
    window.localStorage.setItem(key, JSON.stringify(item))
    return item;
}

export const loadWithExpiry = async (key) => {
    const itemStr = window.localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        window.localStorage.removeItem(key)
        return null
    }
    return item.value
}

export const useAnimatedPageMount = () => {
    const props = useSpring({
        to: {
            opacity: 1,
            transform: "translate3d(0,0,0)"
        },
        from: {
            opacity: 0,
            transform: "translate3d(10px,0px,0)"
        },
        delay: 0,
        config: { duration: 300 }
    })
    return {
        animatedProps: props
    }
}
export const useAnimatedMeditationScreen = () => {
    const props = useSpring({
        to: {
            opacity: 1,
        },
        from: {
            opacity: 0,
        },
        delay: 50,
        config: { duration: 400 }
    })
    return {
        animatedProps: props
    }
}


export const useAnimatedFadeIn = () => {
    const props = useSpring({
        to: {
            opacity: 1,
        },
        from: {
            opacity: 0,
        },
        delay: 50,
        config: { duration: 400 }
    })
    return {
        animatedProps: props
    }
}

export const useAnimatedBackButton = () => {
    const props = useSpring({
        to: {
            opacity: 1,
            transform: "translate3d(0,0,0)"
        },
        from: {
            opacity: 0,
            transform: "translate3d(10px,0,0)"
        },
        delay: 150,
        config: { duration: 300 }
    })
    return {
        animatedProps: props
    }
}

export const formatProgress = (ref) => {

    const hours = 'HH:mm:ss';
    const minutes = 'mm:ss';


    const args = {
        currentTime: ref.current.currentTime,
        duration: ref.current.duration,
        percent: parseFloat(parseFloat((ref.current.currentTime / ref.current.duration) * 100).toFixed(2)),
    }

    args.formattedCurrentTime = (args.currentTime > 3600) ? moment.utc(ref.current.currentTime * 1000).format(hours) : moment.utc(ref.current.currentTime * 1000).format(minutes);
    args.formattedDuration = (args.duration > 3600) ? moment.utc(ref.current.duration * 1000).format(hours) : moment.utc(ref.current.duration * 1000).format(minutes);
    return args;
}

export const PROGRESS_NOT_STARTED = 'notstarted';
export const PROGRESS_IN_PROGRESS = 'inprogress';
export const PROGRESS_COMPLETED = 'complete';
export const PROGRESS_PAUSED = 'paused';
export const STATS_MEDITATION_COMPLETED = 'stats_meditation_complete';
export const STATS_SERIES_COMPLETED = 'stats_series_complete';
export const STATS_SERIES_RESTARTED = 'stats_series_restart';

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};