// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@mui/material/styles';
import { fontWeight } from '@mui/system';
import { css } from 'styled-components';

export const space = 0.25;
export const spaceUnit = 'em';
export const setSpace = (value) => (space * value) + spaceUnit;

export const respondTo = (breakpoint, content) => {
  return css`
  @media(max-width: ${Breakpoints[breakpoint]}){
    ${content};
  }
  `;
}

const Breakpoints = {
  'mobile': '768px',
  'tablet': '1024px',
  'desktop': '1300px',
  'large': '1800px'
}

const SpaceStyles = {
  fonts: {
    // heading: "'p22-underground-pc', sans-serif",
    // headingWeight: 100,
    // headingLineHeight: "100%",
    // body: "'p22-underground', sans-serif",
    // bodyWeight: 400,
    // bodyLineHeight: "150%",
    lineHeight: "120%",
    heading: "le-monde-livre-std, serif",
    headingWeight: 100,
    headingLineHeight: "100%",
    body: "proxima-nova, sans-serif",
    // body: "letter-gothic-std, monospace",
    bodyWeight: 400,
    bodyLineHeight: "100%",
  },
}
// p22-underground-pc, sans-serif;
const pink = '#f2b3e7';
const SpaceTheme = {
  colors: {
    // base: '#efefef', // NIKE
    // baseText: '#000000', // NIKE
    base: '#FEFEFE',
    baseText: '#202020',
    baseDark: '#FAFAFA',
    // primary: '#f59235', // Orange
    // primaryText: '#030000', // Orange
    primary: '#000000',
    primaryText: '#FFFFFF',
    secondary: '#ee2573',
    secondaryText: '#ffffff',
    dark: '#878787',
    darkText: '#000000',
    // baseAlternate: '#f5f5f5',
    // baseAlternateText: '#000000',
    baseAlternate: '#efefef',
    baseAlternateText: '#000',
    baseAlternateLight: '#FAFAFA',
    error: '#FF5134',
    warning: '#FFBADD',
    success: '#BADDDD',
    orangeRGB: '252,78,39',
    iosBlue: '#006ee6',
    pink: pink,
  },
  radius: '12px',
  radiusLarge: '24px',
  shadow: {
    none: '0px 0px 0px rgba(0,0,0,0)',
    one: '0px 4px 8px -4px rgba(0,0,0,0.25)',
    two: '0px 8px 16px -8px rgba(0,0,0,0.4)',
    three: '0px 16px 32px -16px rgba(0,0,0,0.4)',
  },
  scale: {
    none: 'scale3d(1,1,1)',
    one: 'scale3d(1.025,1.025,1.025)',
    down: 'scale3d(0.975,0.975,0.975)'
  },
  transition: {
    one: 'all 250ms',
    two: 'all 500ms',
    three: 'all 1000ms'
  },
  lineHeight: "150%",
  breakpoints: Breakpoints,
  SpaceStyles: SpaceStyles
}

SpaceTheme.elements = {
  input: {
    background: SpaceTheme.colors.base,
    text: '#555555',
    inverse: SpaceTheme.colors.baseAlternate,
    inverseText: SpaceTheme.colors.baseAlternateText
  }
}



const LightMode = {
  colors: {
    base: '#F7F7F7',
    baseText: '#000',
    baseDark: '#E6E6E6',
    primary: '#6363D2',
    primaryText: '#FFF',
    secondary: '#C6E6FF',
    secondaryText: '#FFF',
    dark: '#0F1F2E',
    darkText: '#FFF',
    baseAlternate: '#FFF',
    baseAlternateText: '#000',
    error: '#FF5134',
    warning: '#FFBADD',
    success: '#BADDDD'
  },
  radius: '8px',
  radiusLarge: '24px',
  shadow: {
    none: '0px 0px 0px rgba(99,99,210,0)',
    one: '0px 2px 4px -1px rgba(99,99,210,0.3)',
    two: '0px 8px 16px -8px rgba(99,99,210,0.6)',
    three: '0px 16px 32px -16px rgba(99,99,210,0.6)',
  },
  scale: {
    none: 'scale3d(1,1,1)',
    one: 'scale3d(1.025,1.025,1.025)',
    down: 'scale3d(0.975,0.975,0.975)'
  },
  transition: {
    one: 'all 250ms',
    two: 'all 500ms',
    three: 'all 1000ms'
  },
  lineHeight: "150%",
  breakpoints: Breakpoints,
  SpaceStyles: SpaceStyles
}

LightMode.elements = {
  input: {
    background: LightMode.colors.base,
    text: '#555555',
    inverse: LightMode.colors.baseAlternate,
    inverseText: LightMode.colors.baseAlternateText
  }
}



const DarkMode = {
  colors: {
    base: '#000',
    baseText: '#FFF',
    primary: '#42e883',
    secondary: '#C6E6FF',
    dark: '#0F1F2E',
    baseAlternate: '#232323',
    error: '#FF5134',
    warning: '#FFBADD',
    success: '#BADDDD'
  },
  radius: '8px',
  radiusLarge: '24px',
  shadow: {
    none: 'inset 0px 0px 0px rgba(66,232,131,0)',
    one: 'inset 0px 0px 0px 0px rgba(66,232,131,0.3)',
    two: 'inset 0px 0px 0px 0px rgba(66,232,131,0.6)',
    three: 'inset 0px 0px 0px 0px rgba(66,232,131,0.6)',
  },
  scale: {
    none: 'scale3d(1,1,1)',
    one: 'scale3d(1.025,1.025,1.025)',
    down: 'scale3d(0.975,0.975,0.975)'
  },
  transition: {
    one: 'all 250ms',
    two: 'all 500ms',
    three: 'all 1000ms'
  },
  lineHeight: "150%",
  breakpoints: Breakpoints
}
DarkMode.elements = {
  input: {
    background: DarkMode.colors.base,
    text: '#CCC',
    inverse: DarkMode.colors.baseAlternate,
    inverseText: DarkMode.colors.baseAlternateText
  },
  button: {
    // disabled: {
    //   text: 'rgba(255,255,255,0.35)'
    // }
  }
}



// Set the Theme
export const Theme = SpaceTheme;

export const MaterialTheme = createTheme({
  palette: {
    // mode: 'dark', // For Dark Mode
    primary: {
      // light: will be calculated from palette.primary.main,
      main: Theme.colors.primary,
      // contrastText: Theme.colors.base
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: Theme.colors.secondary,
    },
    base: {
      main: Theme.colors.base,
      contrastText: Theme.colors.baseText
    },
    baseAlternate: {
      main: Theme.colors.baseAlternate,
    },
    typography: {
      fontFamily: SpaceStyles.fonts.body,
      body1: {
        fontFamily: SpaceStyles.fonts.body,
        fontWeight: SpaceStyles.fonts.bodyWeight
      }
    }
  }
});