import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { SmartContainer, StyledFadeIn, Stack, StyledBottomContainer, StyledScrollContainer, Surface, HorizontalStack } from '../../../styles/Containers';
import { StyledRating } from '../../../styles/matieralui/Rating';
import { UserRating } from '../UserRatings/UserRating';
import { Screen } from '../../components/Display/Screen';
import { StyledA, StyledH6, StyledImg, StyledP, StyledB } from '../../../styles/StyledHTML';
import { StyledButton } from '../../../styles/matieralui/Button';
import { UserNote } from '../UserNotes/UserNote';
import { MediaPreview } from '../../../modules/Media/MediaPreview';
import { MeditationPlayer } from './MeditationPlayer';
import { getMeditation } from './redux/meditations.actions';
import { FullMeditations } from './redux/meditations.selectors';
import { FullSeries } from '../Series/redux/series.selectors';
import { getSeries } from '../Series/redux/series.actions';
import { Favorite } from '../UserFavorites/Favorite';
// import NoSleep from '../../services/NoSleep.min';
import NoSleep from 'nosleep.js';
import { BreathAnimation } from '../../styles/AnimatedGradient';
import { StyledMediaImage, useMediaAsset } from '../../components/Display/MediaAsset';
import { mediaPath } from '../../../modules/Media/MediaUtils';
import { FullAccount } from '../../../redux/Account/account.selectors';
import { isAndroid, isNativeApp, isPayingCustomer, postMessage } from '../../../services/Utils';
import { validPlans } from '../../services/SpaceUtils';
import { Link, Redirect } from 'react-router-dom';
import { FullUI } from '../../../redux/UI/ui.selectors';
import { InstallApp } from '../../components/Display/InstallApp';
import appIcon from '../../assets/logo512.png';
import { useHasCompletedMeditation } from '../UserProgress/redux/userprogress.hooks';
import { FullScreenLoader } from '../../../components/Loaders/FullScreenLoader';
import { config } from '../../../config';
import { BuyMeACoffeeButton } from '../../components/BuyMeACoffee/BuyMeACoffeeButton';
const StyledLink = styled.a`
    display: block;
`


export const MeditationFlow = ({ seriesId, meditationId }) => {

    const dispatch = useDispatch();
    const fullMeditations = FullMeditations();
    const fullSeries = FullSeries();
    const fullAccount = FullAccount();
    const ui = FullUI();
    const [activeScreen, setActiveScreen] = useState('loading');
    const [lastInSeries, setLastInSeries] = useState(false);
    const nativeApp = isNativeApp();
    const android = isAndroid();
    const [medData, setMedData] = useState(null)


    const { hasCompletedMeditationLoading, hasCompletedMeditation } = useHasCompletedMeditation(meditationId);


    var noSleep = useMemo(() => { return new NoSleep(); }, []);
    const keepAwakeTrigger = useRef(null);

    const goTo = (path) => dispatch(push(path));

    const { assetLoading, assetPath } = useMediaAsset({ id: fullMeditations?.activeItem?.meta?.socialSquare, network: true });
    const socialPath = mediaPath(assetPath)
    useEffect(() => {
        if (keepAwakeTrigger?.current) keepAwakeTrigger.current.addEventListener('click', begin)
    }, [keepAwakeTrigger])

    const begin = (e) => {
        if (!isNativeApp()) noSleep.enable();
        setActiveScreen('rate-before')
    }

    // Load Meditation
    useEffect(() => {
        if (fullMeditations?.activeItem?.id !== meditationId) dispatch(getMeditation(meditationId))
    }, [meditationId])

    useEffect(() => {
        if (fullSeries?.activeItem?.id !== seriesId) dispatch(getSeries(seriesId))
    }, [seriesId])

    useEffect(() => {
        if (fullSeries?.activeItem && fullMeditations?.activeItem) {
            const lastMedInSeries = fullSeries?.activeItem?.meditations[fullSeries?.activeItem?.meditations.length - 1];
            if (lastMedInSeries?.meditationId === meditationId) {
                setLastInSeries(true);
            }

        }
    }, [fullSeries?.activeItem, fullMeditations?.activeItem])

    useEffect(() => {
        if (hasCompletedMeditationLoading === null) return;
        if (hasCompletedMeditationLoading === true) return;
        if (!fullMeditations?.activeItem) return;

        // If the progress check has returned & we have an active Meditation, show the start screen.
        // if (hasCompletedMeditation === true) setActiveScreen('restart');
        // else setActiveScreen('breathe');
        setActiveScreen('breathe');


    }, [fullMeditations?.activeItem, hasCompletedMeditationLoading])

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [activeScreen])

    const guidePreference = null; // TODO User Preferences.
    const selectedMedia = fullMeditations?.activeItem?.meta?.audio?.find(item => {
        if (!guidePreference) return item; // Return First Option.
        else if (item?.guide === guidePreference) return item;
        return false;
    })

    // ---------------------------
    // RESTRICTIONS
    // ---------------------------


    // Free Plan Check
    const customerIsPaying = isPayingCustomer(fullAccount?.account, validPlans);
    if (fullSeries?.activeItem && fullSeries?.activeItem?.id != seriesId) return (<SmartContainer>Loading...</SmartContainer>)
    if (fullSeries?.activeItem && fullSeries?.activeItem?.meta?.plan !== 'free') {

        if (!customerIsPaying) return (<Redirect to="/billing" />)
    }

    const free = (fullSeries?.activeItem?.meta?.plan === 'free') ? true : false;

    // const screenAlign = (activeScreen === 'done' || activeScreen === 'note') ? 'flex-start' : 'center';
    const screenAlign = 'flex-start';

    const handleLinkClick = (e, payload) => {

        // Guard: Only run native code in native app
        if (!nativeApp) return;

        // Don't Leave
        e.preventDefault();

        // Share:
        postMessage('SHARE', payload);


    }


    return (
        <SmartContainer height="80vh" flex direction="column" align="center" justify={screenAlign} width="100%" border="10px">
            {activeScreen === 'loading' && (
                <FullScreenLoader />
            )}

            {activeScreen === 'breathe' && (
                <Screen>
                    <Stack flex direction="column" align="center">
                        <SmartContainer flex direction="column" align="center">
                            <StyledH6>Pause</StyledH6>
                            <StyledH6>Take a Deep Breath</StyledH6>
                        </SmartContainer>
                        {fullMeditations?.activeItem?.meta?.description && (
                            <StyledScrollContainer maxWidth="700px" dangerouslySetInnerHTML={{ __html: fullMeditations?.activeItem?.meta?.description }} />)}

                        <StyledBottomContainer flex align="center" justify="center">
                            <Stack flex align="center" justify="center" direction="column">
                                <StyledButton ref={keepAwakeTrigger} variant='contained' onClick={begin}>Begin</StyledButton>
                                {hasCompletedMeditation && (<StyledP small light><StyledA onClick={() => setActiveScreen('restart')}>Skip to end</StyledA></StyledP>)}
                            </Stack>
                        </StyledBottomContainer>
                    </Stack>
                </Screen>
            )}

            {activeScreen === 'rate-before' && (
                <Screen align="center" justify="center" flex>
                    <Stack flex direction="column" align="center" maxWidth="300px" textAlign="center">
                        <StyledH6>How do you feel?</StyledH6>
                        <StyledP>It's important to express how we feel. Take a moment to evaluate yourself and where you are at.</StyledP>
                        <UserRating type="before" seriesId={seriesId} meditationId={meditationId} onChange={() => setActiveScreen('meditate')} />
                    </Stack>
                </Screen>
            )}

            {activeScreen === 'meditate' && (
                <Screen align="center" justify="center" flex>
                    <Stack flex direction="column" align="center" justify="center" textAlign="center">
                        <StyledFadeIn textAlign="center" visible={!ui?.meditationPlaying}>
                            <Stack>
                                <StyledH6>Let's begin.</StyledH6>
                                <StyledP>Click play and listen as the guide leads you through today's meditation.</StyledP>
                                {medData && (<StyledP light small>Today's Meditation lasts {medData?.formattedDuration}</StyledP>)}
                            </Stack>
                        </StyledFadeIn>
                        <MeditationPlayer
                            lastInSeries={lastInSeries}
                            audioId={selectedMedia?.file}
                            seriesId={seriesId}
                            meditationId={meditationId}
                            onComplete={() => setActiveScreen('rate-after')}
                            free={free}
                            onLoad={(d) => setMedData(d)}
                        />
                    </Stack>
                </Screen>
            )}

            {activeScreen === 'rate-after' && (
                <Screen align="center" justify="center" flex>
                    <Stack flex direction="column" align="center" maxWidth="300px" textAlign="center">
                        <StyledH6>How do you feel now?</StyledH6>
                        <StyledP>Take a moment to evaluate yourself again.</StyledP>
                        <UserRating type="after" seriesId={seriesId} meditationId={meditationId} onChange={() => setActiveScreen('note')} />

                    </Stack>
                </Screen>
            )}

            {activeScreen === 'note' && (
                <Screen justify="center" flex >
                    <Stack flex direction="column" align="center" minWidth="300px" width="100%">
                        <StyledH6>Reflect</StyledH6>
                        <StyledP>Take a moment to reflect on your quiet time.</StyledP>
                        <UserNote hideTitle={true} value={fullMeditations?.activeItem?.meta?.prompt} seriesId={seriesId} meditationId={meditationId} onChange={() => setActiveScreen('done')} />
                    </Stack>
                </Screen>
            )}

            {(activeScreen === 'done' || activeScreen === 'restart') && (//done
                <Screen>
                    <Stack width="100%">

                        {activeScreen === 'done' && (
                            <SmartContainer flex direction="column" align="center">
                                <StyledH6>👏👏👏</StyledH6>
                                <StyledB>Well Done Today!</StyledB>
                                <StyledP light>Here's your next steps</StyledP>
                            </SmartContainer>
                        )}

                        {activeScreen === 'restart' && (
                            <SmartContainer flex direction="column" align="center">
                                <StyledH6>👏👏👏</StyledH6>
                                <StyledB>Great Job</StyledB>
                                <StyledP light>You've completed this meditation before.</StyledP>
                            </SmartContainer>
                        )}

                        {/* {free && !customerIsPaying && (
                            <StyledLink href="/billing">
                                <Surface>
                                    <StyledP><StyledB>Subscribe</StyledB></StyledP>
                                    <StyledP light>Unlock new content and help keep this free for others.</StyledP>
                                </Surface>
                            </StyledLink>
                        )} */}

                        {!customerIsPaying && (
                            <StyledLink href={config.buyMeACoffee}>
                                <Surface>
                                    <StyledP><StyledB>Help Keep This Free Forever</StyledB></StyledP>
                                    <StyledP light>$1 goes a long way. We want this to reach as many people as possible. If this has helped you, help us help others.</StyledP>
                                    <br />
                                    <StyledP><BuyMeACoffeeButton /></StyledP>
                                </Surface>
                            </StyledLink>
                        )}



                        <StyledLink onClick={(e) => handleLinkClick(e, { type: "link", title: "Invite A Friend", description: "Send this to someone that you think could need it.", url: "https://spaceculture.co/invite" })} target="_blank" href="https://spaceculture.co/invite">
                            <Surface>
                                <StyledP>Invite A Friend</StyledP>
                                <StyledP light>Send this to someone that you think could need it.</StyledP>
                            </Surface>
                        </StyledLink>



                        {fullMeditations?.activeItem?.meta?.socialSquare && (
                            <StyledLink onClick={(e) => handleLinkClick(e, { type: "file", title: "Spread The Love", description: "Share today's social graphic", url: socialPath })} download href={socialPath} target="_blank">
                                <Surface>
                                    <HorizontalStack flex align="center">
                                        <SmartContainer maxWidth="70px">
                                            <StyledMediaImage shadow="one" radius id={fullMeditations?.activeItem?.meta?.socialSquare} network={true} />
                                        </SmartContainer>
                                        <SmartContainer>
                                            <StyledP>Download the Share Image</StyledP>
                                            <StyledP light>Share today's social media graphic</StyledP>
                                        </SmartContainer>
                                    </HorizontalStack>
                                </Surface>
                            </StyledLink>
                        )}

                        <StyledLink target="_blank" href="https://discord.com/invite/DjMrdG5nMG">
                            <Surface>
                                <StyledP>Join The Community</StyledP>
                                <StyledP light>Chat about today's meditation</StyledP>
                            </Surface>
                        </StyledLink>


                        {activeScreen === 'done' && (
                            <Stack flex justify="center" align="center" direction="column">
                                <SmartContainer><StyledButton variant="contained" onClick={() => goTo('/')}>Finish</StyledButton></SmartContainer>
                            </Stack>
                        )}

                        {activeScreen === 'restart' && (
                            <SmartContainer flex align="center" justify="center">
                                <StyledButton variant="contained" onClick={() => setActiveScreen('breathe')}>Restart</StyledButton>
                            </SmartContainer>
                        )}

                        <StyledP>&nbsp;</StyledP>
                        <StyledP>&nbsp;</StyledP>
                        <StyledP>&nbsp;</StyledP>
                    </Stack>




                </Screen>
            )}


            {!android && (<BreathAnimation />)}

        </SmartContainer>
    )
}