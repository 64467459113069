import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import { SketchPicker, ChromePicker } from 'react-color';
import { SmartContainer, HorizontalStack } from '../../styles/Containers';
import { StyledButton } from '../../styles/matieralui/Button';
import { StyledPopover } from '../../styles/matieralui/Popover';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';

const StyledSketchPicker = styled(SketchPicker)`
    &.sketch-picker {
        box-shadow: none !important;
    }
`
const StyledChromePicker = styled(ChromePicker)`
    &.chrome-picker {
        box-shadow: none !important;
    }
`

export const StyledColorSwatch = styled(StyledButton)`
    min-height: 2em;
    &.MuiButton-contained,
    &.MuiButton-contained.Mui-focusVisible,
    &.MuiButton-contained:hover {
        ${props => (props.activeColor) ? css`background-color: ${props.activeColor};` : ''}
    }
`

export const ColorPicker = (props) => {
    const [showPicker,setShowPicker] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const closePopover = () =>{
        setAnchorEl(null)
        setShowPicker(false);
    }
    const showPopover = (e) => {
        // Show the Popover
        setShowPicker(true);

        // Anchor it to the currently item
        setAnchorEl(e.currentTarget)
    }
    return (
        <SmartContainer>
            <StyledColorSwatch activeColor={props.value} variant="contained" onClick={showPopover}>{props?.label}</StyledColorSwatch>
            
            <StyledPopover open={showPicker} anchorEl={anchorEl} onClose={() => closePopover()}>
                <StyledChromePicker disableAlpha={true} color={props.value} onChange={(color) => props.onChange(color.hex)} />
            </StyledPopover>
        
        </SmartContainer>

    )
}

export const ColorSelector = (props) => {
    const { options } = props;
    return (
        <HorizontalStack flex>
            {options.map(color => (<StyledColorSwatch activeColor={color} onClick={() => props.onChange(color)} variant="contained" />))}  
            <ColorPicker value={props.value} onChange={props.onChange} label={<ColorizeOutlinedIcon/>} />
        </HorizontalStack>

    )
}