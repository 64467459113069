import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { HorizontalStack, SmartContainer, Stack } from '../../../styles/Containers';
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledP, StyledB } from '../../../styles/StyledHTML';
import { searchNotePrompts } from './redux/noteprompts.actions';
import { FullNotePrompts } from './redux/noteprompts.selectors';
import { FullUserNotes } from '../UserNotes/redux/usernotes.selectors';
import { createUserNote } from '../UserNotes/redux/usernotes.actions';
import moment from 'moment';
import { StyledIconButton } from '../../../styles/matieralui/IconButton';
import { SpaceIconRefresh } from '../../styles/Icons';
export const NotePromptShuffler = () => {
    const dispatch = useDispatch();
    const store = FullNotePrompts();
    const storeUserNotes = FullUserNotes();

    const [activeItem, setActiveItem] = useState(null);

    const shuffle = () => {
        if (!store?.items) return null;
        const item = store?.items?.rows[Math.floor(Math.random() * store?.items?.rows.length)];
        setActiveItem(item);
    }

    const handleStart = () => {
        const args = {
            name: `${activeItem.name} :: ${moment().format('MMMM Do YYYY')}`,
            content: activeItem.content
        }
        dispatch(createUserNote(args))

    }

    useEffect(() => { if (storeUserNotes.createSuccess) dispatch(push(`/journal/${storeUserNotes.create.id}`)) }, [storeUserNotes?.createLoading])


    useEffect(() => {
        if (!store?.items) dispatch(searchNotePrompts(1, 9999, null, 'published'))
    }, []);

    useEffect(() => {
        if (store?.items) shuffle();
    }, [store?.items]);


    return (
        <Stack thinking={storeUserNotes?.createLoading}>
            {!activeItem && (<StyledB>Take a deep breath...</StyledB>)}
            {activeItem && (
                <Stack width="100%" textAlign="left">
                    <HorizontalStack align="center" flex justify="space-between" width="100%">
                        <StyledP><StyledB>Get Started Journaling</StyledB></StyledP>
                        <StyledIconButton onClick={shuffle}><SpaceIconRefresh /></StyledIconButton>
                    </HorizontalStack>

                    <StyledB>{activeItem?.name}</StyledB>

                    <SmartContainer width="100%" textAlign="left" dangerouslySetInnerHTML={{ __html: activeItem.content }} />

                    <StyledButton color="base" onClick={handleStart} fullWidth variant='contained'>Start Journaling</StyledButton>

                </Stack>
            )}

        </Stack>
    )
}