import React, {useState,useEffect} from 'react';
import moment from 'moment';

import { StyledDatePicker } from '../../styles/matieralui/Form'

export const DatePicker = (props) => {

    const format = (props.format) ? props.format : 'YYYY-MM-DD';
    const theDate = (props.value) ? moment(props.value).format(format) : null;
    const handleChange = (e) => props.onChange(e.target.value)
    
    return (
        <StyledDatePicker {...props}
            value={theDate}
            type="date"
            onChange={handleChange}
            variant="filled"
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}
export const DateTimePicker = (props) => {
    const format = (props.format) ? props.format : 'YYYY-MM-DDTHH:mm:ss';
    const theDate = (props.value) ? moment(props.value).format(format) : null;
    const handleChange = (e) => props.onChange(e.target.value)
    return (
        <StyledDatePicker {...props} 
            value={theDate}
            type="datetime-local" 
            onChange={handleChange} 
            variant="filled"
            InputLabelProps={{
                shrink: true,
            }}
            
        />
    );
}
export const TimePicker = (props) => {

    const handleChange = (e) => props.onChange(e.target.value)
    return (
        <StyledDatePicker {...props}
            // value={theDate}
            defaultValue="07:30"
            type="time"
            onChange={handleChange}
            variant="filled"
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}