import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../../App';
import { FullScreenLoader } from '../../../components/Loaders/FullScreenLoader';
import { RevisionSelector } from '../../../components/Publishing/RevisionSelector';

// Styles
import { SmartContainer, Stack, Surface } from '../../../styles/Containers'

// Redux
import { getSeries, getSeriesRevision } from './redux/series.actions';
import { FullSeries } from './redux/series.selectors';
import { FormSeries } from './FormSeries';
import { RouteBreadCrumbs } from '../../../routes';


export const PageNetworkEditSeries = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullSeries();
    const { id } = useParams();
    const [activeItem, setActiveItem] = useState(null);

    // --------------------------
    // Effects
    // --------------------------

    // Get This Item
    useEffect(() => { dispatch(getSeries(id)) }, []);

    // Set Active Item
    useEffect(() => {
        if (store?.activeItem) setActiveItem(store.activeItem);
    }, [store?.activeItem])


    // --------------------------
    // Interactions
    // --------------------------

    const handleRevisionChange = (revision) => {

        // Clear Revision
        if (!revision) setActiveItem(store.activeItem);
        else {

            const mergedData = { ...revision.fields, id: revision.modelId }
            setActiveItem(mergedData);
        }
    }

    // --------------------------
    // Renders
    // --------------------------

    // Add Revisions
    const sideBarChildren = () => (
        <Surface>
            <RevisionSelector
                label="Revisions"
                item={store?.activeItem}
                model="Series"
                modelId={store?.activeItem?.id}
                selector={FullSeries}
                action={getSeriesRevision}
                onChange={handleRevisionChange}
            />
        </Surface>
    )


    // Render Page

    return (
        <App title="Edit Series" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network, RouteBreadCrumbs.networkSeries]}>

            {store?.activeItemLoading && (<FullScreenLoader />)}
            {activeItem && (
                <Stack thinking={store?.deleteLoading || store?.updateLoading}>
                    <FormSeries item={activeItem} childrenSide={sideBarChildren()} />
                </Stack>
            )}
        </App>
    )
}