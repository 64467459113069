import { APIGet, APIPut, APIPost, APIDelete, APIBase } from '../../services/API';
import { dispatchLoading, dispatchError, dispatchSuccess, dispatchClearState } from '../utils'


export const createCheckoutSession = (networkId, priceId, args = {}) => dispatch => {
    
    const action = '[BILLING] Create Checkout Session';
    const prefix = 'checkoutSession';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/billing/checkout/session`,{priceId, networkId, args})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') dispatch(dispatchError(action,prefix,json.message))
        else dispatch(dispatchSuccess(action, prefix, json.message, json.data.session));
    })
    .catch(e => {dispatch(dispatchError(action,prefix,e.message))})
}

export const createCustomerPortalSession = (networkId) => dispatch => {
    
    const action = '[BILLING] Create Portal Session';
    const prefix = 'portalSession';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/billing/portal/session`,{networkId})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') dispatch(dispatchError(action,prefix,json.message))
        else dispatch(dispatchSuccess(action, prefix, json.message, json.data.session));
    })
    .catch(e => {dispatch(dispatchError(action,prefix,e.message))})
}

export const createCheckoutSessionPurchase = (networkId, args) => dispatch => {
    
    const action = '[BILLING] Create Checkout Session Purchase';
    const prefix = 'purchaseCheckoutSession';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/billing/purchase/session`,{networkId, args})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') dispatch(dispatchError(action,prefix,json.message))
        else dispatch(dispatchSuccess(action, prefix, json.message, json.data.session));
    })
    .catch(e => {dispatch(dispatchError(action,prefix,e.message))})
}