import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './store'
import { PublicRoute } from './components/PublicRoute'
import { PrivateRoute } from './components/PrivateRoute';
import { PublicScopedRoute } from './components/PublicScopedRoute';
import { App } from './App'

// -----------------------------
// Starter Routes
// -----------------------------

// Pages
import { LoginPage } from './components/Auth/LoginPage'
import { SignupPage } from './components/Auth/SignupPage'
import { DashboardPage } from './components/Dashboard/DashboardPage'
import { RecoverPage } from './components/Auth/RecoverPage'
import { ResetPage } from './components/Auth/ResetPage'
import { NotFound } from './pages/NotFound/NotFound'
import { ProfilePage } from './components/User/ProfilePage'
import { TermsPage } from './components/Public/TermsPage'

// Account Pages
import { SettingsPage } from './components/Account/SettingsPage'
import { BillingPage } from './components/Billing/BillingPage'
import { BillingSuccessPage } from './components/Billing/BillingSuccessPage'
import { TeamPage } from './components/Account/TeamPage'
import { UserPage } from './components/User/UserPage'
import { AccountSwitcherPage } from './components/User/AccountSwiterPage'
import { SwitchAccount } from './components/Auth/SwitchAccount'

// Network Admin
import { NetworkDashboard } from './components/Network/NetworkDashboard'
import { NetworkUsersPage } from './components/Network/NetworkUsersPage'
import { NetworkAccountsPage } from './components/Network/NetworkAccountsPage'
import { NetworkEditAccountsPage } from './components/Network/NetworkEditAccountsPage'
import { NetworkNetworksPage } from './components/Network/NetworkNetworksPage'
import { EditNetworkPage } from './components/Network/EditNetworkPage'
import { NetworkEditUserPage } from './components/Network/NetworkEditUserPage'
import { NetworkIntegrationPage } from './components/Network/NetworkIntegrationsPage'

// Network Products
import { NetworkProductPage } from './modules/NetworkProducts/NetworkProductPage';
import { EditNetworkProductPage } from './modules/NetworkProducts/EditNetworkProductPage';
import { ViewNetworkProductPage } from './modules/NetworkProducts/ViewNetworkProductPage';
import { ViewNetworkProductSuccessPage } from './modules/NetworkProducts/ViewNetworkProductSuccessPage';
import { PublicNetworkProductPage } from './modules/NetworkProducts/PublicNetworkProductPage';
import { PublicNetworkProductSuccessPage } from './modules/NetworkProducts/PublicNetworkProductSuccessPage';

// Network Orders
import { AccountNetworkOrdersPage } from './modules/NetworkOrders/AccountNetworkOrdersPage';
import { AccountViewNetworkOrderPage } from './modules/NetworkOrders/AccountViewNetworkOrderPage';
import { NetworkOrdersPage } from './modules/NetworkOrders/NetworkOrdersPage';
import { ViewNetworkOrderPage } from './modules/NetworkOrders/ViewNetworkOrderPage';

// Labs
import { LabsPage } from './modules/Labs/LabsPage';
import { RepeaterLab } from './modules/Labs/RepeaterLab';
import { TextInputsLab } from './modules/Labs/TextInputsLab';
import { DateInputsLab } from './modules/Labs/DateInputsLab';
import { RadioLab } from './modules/Labs/RadioLab';
import { DesignSystem } from './modules/Labs/DesignSystem';
import { MediaLab } from './modules/Labs/MediaLab';
import { PayWallLab } from './modules/Labs/PayWallLab';
import { VideoLab } from './modules/Labs/VideoLab';
import { ColorPickerLab } from './modules/Labs/ColorPickerLab';




// Example Content
import { ExampleContentPage } from './modules/ExampleContent/ExampleContentPage';
import { EditExampleContentPage } from './modules/ExampleContent/EditExampleContentPage';

// Example Taxonomy
import { ExampleTaxonomyPage } from './modules/ExampleTaxonomy/ExampleTaxonomyPage';
import { EditExampleTaxonomyPage } from './modules/ExampleTaxonomy/EditExampleTaxonomyPage';

// Media Library
import { MediaPage } from './modules/Media/MediaPage';
import { NetworkMediaPage } from './modules/Media/NetworkMediaPage';
import { EditMediaPage } from './modules/Media/EditMediaPage';
import { MediaTagsPage } from './modules/Media/MediaTagsPage';
import { EditMediaTagPage } from './modules/Media/EditMediaTagPage';
import { NetworkEditMediaPage } from './modules/Media/NetworkEditMediaPage';
import { NetworkMediaTagsPage } from './modules/Media/NetworkMediaTagsPage';
import { NetworkEditMediaTagPage } from './modules/Media/NetworkEditMediaTagPage';
import { MapsLab } from './modules/Labs/MapsLab'
import { PageNetworkContent } from './modules/NetworkContent/PageNetworkContent'
import { PageEditNetworkContent } from './modules/NetworkContent/PageEditNetworkContent'
import { ViewNetworkContent } from './modules/NetworkContent/ViewNetworkContent'
import { MediaProcessingLab } from './modules/Labs/MediaProcessingLab'

// -----------------------------
// App Routes
// -----------------------------

import { PageNetworkMeditations } from './space/modules/Meditations/PageNetworkMeditations';
import { PageNetworkEditMeditation } from './space/modules/Meditations/PageNetworkEditMeditation';
import { PageNetworkGuides } from './space/modules/Guides/PageNetworkGuides'
import { PageNetworkEditGuide } from './space/modules/Guides/PageNetworkEditGuide'
import { PageNetworkSeries } from './space/modules/Series/PageNetworkSeries'
import { PageNetworkEditSeries } from './space/modules/Series/PageNetworkEditSeries'
import { PageNetworkTags } from './space/modules/Tags/PageNetworkTags'
import { PageNetworkEditTag } from './space/modules/Tags/PageNetworkEditTag'
import { SeriesPage } from './space/modules/Series/PageSeries'
import { PageSeriesLanding } from './space/modules/Series/PageSeriesLanding'
import { PageSpaceAccount } from './space/components/Dashboard/PageSpaceAccount'
import { PageJournal } from './space/modules/UserNotes/PageJournal';
import { PageJournalEntry } from './space/modules/UserNotes/PageJournalEntry'

import { validPlans } from './space/services/SpaceUtils'
import { PageMeditate } from './space/modules/Meditations/PageMeditate'
import { PageTagLanding } from './space/modules/Tags/PageTagLanding'
import { PageNetworkNotePrompts } from './space/modules/NotePrompts/PageNetworkNotePrompts'
import { PageNetworkEditNotePrompt } from './space/modules/NotePrompts/PageNetworkEditNotePrompt'
import { PageOnboarding } from './space/modules/Meditations/PageOnboarding'
import { SpaceNetworkPage } from './space/modules/Network/SpaceNetworkPage'
import { PageNetworkStats } from './space/modules/Stats/PageNetworkStats'
import { PageBreathing } from './space/modules/Breathing/PageBreathing'
import { PageNetworkBreathing } from './space/modules/Breathing/PageNetworkBreathing'
import { PageNetworkEditBreathing } from './space/modules/Breathing/PageNetworkEditBreathing'

// --------------------------
// Scroll To Top on Route Change
// --------------------------

history.listen(() => { window.scrollTo(0, 0); })


/**
 * The main routes of the application. Utilize this function to add more.
 */
export const routes = (
  <ConnectedRouter history={history}>
    <Switch>

      <PublicRoute exact path="/login" title="Login" component={LoginPage} />
      <PublicRoute exact path="/signup" title="Signup" component={SignupPage} />
      <PublicRoute exact path="/recover-password" title="Recover Password" component={RecoverPage} />
      <PublicRoute exact path="/reset-password/:token" title="Reset Password" component={ResetPage} />
      <PublicRoute exact path="/terms" title="Terms" component={TermsPage} />

      {/***** Account Pages *****/}
      <PrivateRoute exact path="/" title="Dashboard" component={DashboardPage} />
      <PrivateRoute exact path="/profile" title="Profile" component={ProfilePage} />
      <PrivateRoute exact path="/settings" title="Settings" component={SettingsPage} role="owner" />
      <PrivateRoute exact path="/billing" title="Billing" component={BillingPage} role="owner" bypassCheck="paymentStatus|subscriptionStatus" />
      <PrivateRoute exact path="/billing/success" title="Billing" component={BillingSuccessPage} role="owner" bypassCheck="paymentStatus|subscriptionStatus" />
      <PrivateRoute exact path="/team" title="Team" component={TeamPage} role="owner|admin" plan="basic_monthly|basic_yearly|premium_monthly|premium_yearly" upgradeMessage="Upgrade to gain access to managing team members." />
      <PrivateRoute exact path="/teamtest" component={TeamPage} role="owner|admin" />
      <PrivateRoute exact path="/user/:id" title="User" component={UserPage} role="owner|admin" />
      <PrivateRoute exact path="/accounts" title="Accounts" component={AccountSwitcherPage} />
      <PrivateRoute exact path="/account/:id" title="Account" component={SwitchAccount} />

      {/***** Network Admin *****/}
      <PrivateRoute exact path="/network" title="Network Dashboard" component={NetworkDashboard} networkRole="networkadmin|networkeditor" />
      <PrivateRoute exact path="/network/accounts" title="Network Accounts" component={NetworkAccountsPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/accounts/:id" title="Network Edit Accounts" component={NetworkEditAccountsPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/users" title="Network Users" component={NetworkUsersPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/users/:id" title="Network User" component={NetworkEditUserPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/networks" title="Network List" component={NetworkNetworksPage} networkRole="superadmin" />
      <PrivateRoute exact path="/network/edit/:id" title="Network Edit" component={EditNetworkPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/:id/integrations" title="Network Integrations" component={NetworkIntegrationPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/:id/integrations/:app" title="Network Integrations" component={NetworkIntegrationPage} networkRole="networkadmin" />

      {/***** Network Products & Orders *****/}
      <PrivateRoute exact path="/network/products" title="Network Products" component={NetworkProductPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/products/:id" title="Network Product" component={EditNetworkProductPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/billing/products/:id" title="Network Billing" component={ViewNetworkProductPage} role="owner" />
      <PrivateRoute exact path="/billing/products/:id/success" title="Network Billing" component={ViewNetworkProductSuccessPage} role="owner" />
      <PrivateRoute exact path="/billing/orders" title="Network Orders" component={AccountNetworkOrdersPage} role="owner" />
      <PrivateRoute exact path="/billing/orders/:id" title="Network Order" component={AccountViewNetworkOrderPage} role="owner" />
      <PrivateRoute exact path="/network/orders" title="Network Orders" component={NetworkOrdersPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/orders/:id" title="Network Order" component={ViewNetworkOrderPage} networkRole="networkadmin" />
      <PublicScopedRoute exact path="/products/:id" title="Products" component={PublicNetworkProductPage} />
      <PublicScopedRoute exact path="/products/:id/success" title="Product" component={PublicNetworkProductSuccessPage} />

      {/***** Media *****/}
      <PrivateRoute exact path="/media" title="Media" component={MediaPage} role="owner|admin|editor" />
      <PrivateRoute exact path="/media/tags" title="Media Tags" component={MediaTagsPage} role="owner|admin|editor" />
      <PrivateRoute exact path="/media/tags/:id" title="Media Tag" component={EditMediaTagPage} role="owner|admin|editor" />
      <PrivateRoute exact path="/media/:id" title="Media Item" component={EditMediaPage} role="owner|admin|editor" />
      <PrivateRoute exact path="/network/media" title="Network Media" component={NetworkMediaPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/media/tags" title="Network Media Tags" component={NetworkMediaTagsPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/media/tags/:id" title="Network Media Tag" component={NetworkEditMediaTagPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/media/:id" title="Netork Media Item" component={NetworkEditMediaPage} networkRole="networkadmin" />


      {/***** Labs *****/}
      <PrivateRoute exact path="/labs" title="Lab" component={LabsPage} />
      <PrivateRoute exact path="/labs/repeater" title="Lab" component={RepeaterLab} />
      <PrivateRoute exact path="/labs/textinputs" title="Lab" component={TextInputsLab} />
      <PrivateRoute exact path="/labs/dateinputs" title="Lab" component={DateInputsLab} />
      <PrivateRoute exact path="/labs/radio" title="Lab" component={RadioLab} />
      <PrivateRoute exact path="/labs/design" title="Lab" component={DesignSystem} />
      <PrivateRoute exact path="/labs/media" title="Lab" component={MediaLab} />
      <PrivateRoute exact path="/labs/media/processing" title="Lab" component={MediaProcessingLab} />
      <PrivateRoute exact path="/labs/video" title="Lab" component={VideoLab} />
      <PrivateRoute exact path="/labs/colorpicker" title="Lab" component={ColorPickerLab} />
      <PrivateRoute exact path="/labs/maps" title="Maps" component={MapsLab} />

      <PublicRoute exact path="/labs/paywall" title="Lab: PayWall" component={PayWallLab} />

      {/***** This App *****/}
      {/***** Network Pages *****/}
      <PrivateRoute exact path="/network/meditations" title="Network Meditation" component={PageNetworkMeditations} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/meditations/:id" title="Edit Network Meditation" component={PageNetworkEditMeditation} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/guides" title="Network Guides" component={PageNetworkGuides} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/guides/:id" title="Edit Network Guide" component={PageNetworkEditGuide} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/series" title="Network Series" component={PageNetworkSeries} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/series/:id" title="Edit Network Series" component={PageNetworkEditSeries} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/tags" title="Network Tags" component={PageNetworkTags} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/tags/:id" title="Edit Network Tag" component={PageNetworkEditTag} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/noteprompts" title="Network Note Prompts" component={PageNetworkNotePrompts} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/noteprompts/:id" title="Edit Note Prompts" component={PageNetworkEditNotePrompt} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/breathing" title="Network Breathing" component={PageNetworkBreathing} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/breathing/:id" title="Edit Network Breathing" component={PageNetworkEditBreathing} networkRole="networkadmin" />


      {/***** User Pages *****/}
      <PrivateRoute exact path="/account" title="My Account" component={PageSpaceAccount} />
      <PrivateRoute exact path="/series" title="Browse Series" component={SeriesPage} />
      <PrivateRoute exact path="/series/:id" title="Series" component={PageSeriesLanding} />
      <PrivateRoute exact path="/series/:id/play/:meditationId" title="Meditate" component={PageMeditate} />
      {/* plan={validPlans} upgradeMessage="Purchase a subscription to continue." /> */}
      <PrivateRoute exact path="/journal" title="Journal" component={PageJournal} />
      <PrivateRoute exact path="/journal/:id" title="Journal" component={PageJournalEntry} />
      <PrivateRoute exact path="/topic/:id" title="Topic" component={PageTagLanding} />
      <PrivateRoute exact path="/onboarding" title="Onboarding" component={PageOnboarding} />
      <PrivateRoute exact path="/network/:id/info" title="Space Network Settings" component={SpaceNetworkPage} networkRole="networkadmin" />
      <PrivateRoute exact path="/network/:id/stats" title="Space Stats" component={PageNetworkStats} networkRole="networkadmin" />
      <PrivateRoute exact path="/breathing" title="Breathing" component={PageBreathing} />


      <PublicRoute path="/*" component={NotFound} />
    </Switch>
  </ConnectedRouter>
)

export const RouteBreadCrumbs = {
  dashboard: { href: '/', title: 'Dashboard' },
  team: { href: '/team', title: 'Team' },
  media: { href: '/media', title: 'Media' },
  mediaTags: { href: '/media/tags', title: 'Media Tags' },
  billing: { href: '/billing', title: 'Billing' },
  billingOrders: { href: '/billing/orders', title: 'Orders' },

  // Network
  network: { href: '/network', title: 'Network' },
  networkUsers: { href: '/network/users', title: 'Users' },
  networkMedia: { href: '/network/media', title: 'Network Media' },
  networkMediaTags: { href: '/network/media/tags', title: 'Network Media Tags' },
  networkOrders: { href: '/network/orders', title: 'Orders' },
  networkProducts: { href: '/network/products', title: 'Products' },

  // Space
  networkGuides: { href: '/network/guides', title: 'Guides' },
  networkSeries: { href: '/network/series', title: 'Series' },
  networkMeditations: { href: '/network/meditations', title: 'Meditations' },
  networkTags: { href: '/network/tags', title: 'Tags' },
  networkNotePrompts: { href: '/network/noteprompts', title: 'Note Prompts' },
  networkBreathing: { href: '/network/breathing', title: 'Breathing' },
}