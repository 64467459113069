import React, { useState, useEffect } from 'react';
import { HorizontalStack, SmartContainer, Stack } from '../../styles/Containers';
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { useNetworkOrderSummaries } from './redux/networkorder.actions';
import { convertCentsToPrice } from '../../services/Stripe';
import { DatePicker } from '../../components/Forms/DatePicker';
export const OrderSummaries = () => {
    const { summaryStats, summaryLoading, summaryFromDate, setSummaryFromDate } = useNetworkOrderSummaries();

    return (
        <Stack>
            <DatePicker label="From Date" value={summaryFromDate} onChange={(value) => setSummaryFromDate(value)} />
            {!summaryLoading && summaryStats && Object.keys(summaryStats).map(prodId => {
                return (
                    <HorizontalStack flex>
                        <StyledB>{summaryStats[prodId]?.name}</StyledB>
                        <StyledP>{summaryStats[prodId]?.totalPurchases}</StyledP>
                        <StyledP>${convertCentsToPrice(summaryStats[prodId]?.totalRevenue)}</StyledP>
                    </HorizontalStack>
                )
            })}
        </Stack>
    )
}