import React from 'react';
import { MediaImage, mediaPath } from './MediaUtils';
import { AudioPlayer } from '../../components/AudioPlayer/AudioPlayer';
import { FilePreview } from '../../components/FilePreview/FilePreview';
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer';
import { StyledImg, StyledP } from '../../styles/StyledHTML';
import { SmartContainer } from '../../styles/Containers';


export const MediaPreview = ({ item, network = false, crop = null }) => {
    const renderMedia = () => {
        if (item?.mediaType === 'image') return (<MediaImage radius network={network} image={item} crop={crop} />);
        if (item?.mediaType === 'audio') return (<SmartContainer width="100%" flex align="center"><AudioPlayer src={mediaPath(item.meta.path, item.meta.storage)} /><StyledP>{item?.name}</StyledP></SmartContainer>);
        if (item?.mediaType === 'doc') return (<FilePreview src={mediaPath(item.meta.path, item.meta.storage)} />);
        if (item?.mediaType === 'video') return (<VideoPlayer src={mediaPath(item.meta.path, item.meta.storage)} />);
        return (<div></div>)
    }
    return (
        <SmartContainer width="100%">{renderMedia()}</SmartContainer>
    )
}