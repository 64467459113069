import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import { getCurrentUser, hasVisitorToken } from '../redux/Auth/auth.actions'

import { useSelector } from 'react-redux'
import { Role, NetworkRole } from '../redux/Auth/auth.selectors'
import { FullAccount } from '../redux/Account/account.selectors';
import { getAccountInfo } from '../redux/Account/account.actions'

import { NoAccessPage } from '../pages/Account/NoAccessPage'
import { FullScreenLoader } from './Loaders/FullScreenLoader'
import { PlanRestrictionPage } from './Billing/PlanRestrictionPage'
import { PaymentRestrictionPage } from './Billing/PaymentRestrictionPage'
import { AcceptTerms } from './Auth/AcceptTerms'
import { LoginPage } from './Auth/LoginPage'

// Redux
import { FullNetwork } from '../redux/Network/network.selectors'
import { useDocumentTitle } from '../redux/Network/network.hooks';
import { useFirstPromoter } from '../modules/Integrations/FirstPromoter/FirstPromoterService';
import { useAnalytics } from '../modules/Analytics/AnalyticsService';
import { useHotJar } from '../modules/Integrations/HotJar/HotJarService';

// Loaders
import { NetworkLoader } from './Loaders/NetworkLoader'
import { config } from '../config';

export const PrivateRoute = ({ children, ...rest }) => {

  const dispatch = useDispatch();
  const isVisitor = hasVisitorToken();

  const auth = useSelector(state => state.auth)

  const currentUserRole = Role();

  const roleRestriction = rest.role?.split('|');
  const planRestriction = rest.plan?.split('|');
  const networkRestriction = rest.networkRole?.split('|');
  const bypassRestrictions = rest.bypassCheck?.split('|');
  const [hasAuthenticated, setHasAuthenticated] = useState('notyet');
  const [isSwitchingAccounts, setIsSwitchingAccounts] = useState(false);

  // Account Info
  const fullAccount = FullAccount();
  const fullNetwork = FullNetwork();
  const currentNetworkRole = NetworkRole(fullNetwork.network?.id);


  // Get The Current User
  useEffect(() => {

    // Wait until we have a network to load the user and account. 
    if (!fullNetwork.network) return;

    // Guard: Visitor Tokens
    if (isVisitor) return;

    // Load Auth if not Authenticated
    if (!auth.me && !auth.loading && !auth.error) {
      dispatch(getCurrentUser())
      return;
    }

    // Load Account as well
    if (!fullAccount.account && fullAccount?.accountLoading !== true) dispatch(getAccountInfo())

  }, [fullNetwork, auth.session, auth.me, auth.loading, auth.error, isVisitor, fullAccount.account, dispatch]);

  // Switching Accounts
  useEffect(() => { if (auth.switchAccountLoading) setIsSwitchingAccounts(true); }, [auth.switchAccountLoading]);

  // Show loader if we are switching accounts
  useEffect(() => {

    if (auth.success) {
      setHasAuthenticated('success')
    } else if (hasAuthenticated === 'success') {
      if (isSwitchingAccounts !== true) {
        setHasAuthenticated('loststatus')
      }

    }

  }, [auth.success, hasAuthenticated, isSwitchingAccounts]);

  // HTML Title
  useDocumentTitle(rest.title);
  useFirstPromoter(fullNetwork);
  useAnalytics(true);
  useHotJar(fullNetwork);



  // First Load This Network
  if (!fullNetwork.network) return (<NetworkLoader />);

  // Loader
  if (auth.loading === true || auth.switchAccountLoadding === true) { return (<FullScreenLoader />) }

  // Logout
  if (hasAuthenticated === 'loststatus') return (<Redirect to="/login" />);

  // If Error: Display Login or is this is a visitor token
  if (auth.error || isVisitor === true) return (<LoginPage redirect={window.location.pathname} />)

  // Redirect if not authenticated
  if (!auth.session) { return (<FullScreenLoader />) }

  // Loading Account
  if (fullAccount.accountLoading || !fullAccount.account) return (<FullScreenLoader />)

  // Terms & Condition Acceptance
  if (auth?.me.acceptedTermsVersion !== config.termsVersion) return (<AcceptTerms />)

  // Failed Payment Restrictions
  // if (bypassRestrictions && (bypassRestrictions.indexOf('paymentStatus') > -1)) {
  //   // do nothing
  // } else {
  //   if (fullAccount.account.paymentStatus === 'failed') return (<PaymentRestrictionPage account={fullAccount.account} />)
  // }

  // Subscription Status Restrictions
  // if (bypassRestrictions && (bypassRestrictions.indexOf('subscriptionStatus') > -1)) {
  //   // do nothing
  // } else {
  //   if (
  //     fullAccount.account.subscriptionStatus === 'canceled'
  //     || fullAccount.account.subscriptionStatus === 'incomplete'
  //     || fullAccount.account.subscriptionStatus === 'incomplete_expired'
  //   ) {
  //     return (<PaymentRestrictionPage account={fullAccount.account} />)
  //   }
  // }

  // Plan Restrictions
  // if (planRestriction && (planRestriction.indexOf(fullAccount.account?.plan) <= -1)) return (<PlanRestrictionPage upgradeMessage={rest.upgradeMessage} />)

  // Role Based Restrictions
  if (roleRestriction && (roleRestriction.indexOf(currentUserRole) <= -1)) return (<NoAccessPage />)

  // Network Access Restrictions
  if (auth.me.superAdmin !== true && networkRestriction && (networkRestriction.indexOf(currentNetworkRole) <= -1)) return (<NoAccessPage />)

  // Show If Authenticated
  return (<Route {...rest} />)

}