import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { getToken, APIBase } from '../../services/API';

import styled from 'styled-components';
import { SmartContainer, Stack,  StyledGrid, StyledGridItem, StyledImagePlaceholder } from '../../styles/Containers';
import { StyledB, StyledEllipsis, StyledImg, StyledHr } from '../../styles/StyledHTML';
import { setSpace, Theme } from '../../styles/Theme';
import { StyledCircularProgressWithLabel, StyledCircularProgress } from '../../styles/matieralui/Progress';

const getColor = (props) => {
    if (props.isDragAccept) {
        return 'green';
    }
    if (props.isDragReject) {
        return 'red';
    }
    if (props.isDragActive) {
        return 'purple';
    }
    return `${Theme.colors.base}`;
  }

const StyledDropTarget = styled(SmartContainer)`
    padding: ${setSpace(20)};
    border: 4px dashed ${Theme.colors.base};
    border-color: ${props => getColor(props)};
`

export const useFileUploader = ({callback, network = false}) => {

    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploaded, setUploaded] = useState([]);
    const [progress,setProgress] = useState([]);

    const runUpload = async (file) => {
        const token = getToken();
        const config = {
            onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                const progress = files.map(item => {
                    if(file.path === item.path) item.progress = percentCompleted;
                    return item;
                })

                // Set Progress
                setProgress(progress);

                // // Handle Complete
                // if(percentCompleted === 100){
                //     const newFiles = files.filter(item => {
                //         if(file.path !== item.path) return item;
                //         else {
                //             if(callback) callback(item);

                //             // TODO: Grab the Hosted URL
                //             // console.log

                //             setUploaded([...uploaded,item]);
                //         }
                //     })
                //     setFiles(newFiles);
                // }

            },
            headers: {
                Authorization: 'Bearer ' + token,
                // 'Access-Control-Allow-Origin': "true",
                "Content-Type": "multipart/form-data"
            }
            
        }
        const formData = new FormData();
        formData.append("file",file);
        const path = (network) ? `${APIBase}/v1/network/upload` : `${APIBase}/v1/upload`;
        const res = await axios.post(path,formData, config)
        
       
        return res;
    }

    const uploadFile = async (file) => {
        
        // TODO: Replace with Axios
        const res = await runUpload(file);

        // Update State
        if(res.data.status === 'success') {
            // const progress = files.map(item => {
            //     if(file.path === item.path) item.progress = 100;
            //     return item;
            // })

            // // Set Progress
            // setProgress(progress);

            const newFiles = files.filter(item => {
                if(file.path !== item.path) return item;
                else {
                    if(callback) callback(item);

                    item.data = res.data.data;

                    setUploaded([...uploaded,item]);
                    return false;
                }
            })
            setFiles(newFiles);
        }

    }

    // Run on files change
    useEffect(() => {
        if(files && files.length > 0) {
            setUploading(true);
            uploadFile(files[0])
            setProgress(files);
        } else if(progress && progress.length > 0 && files.length <= 0){
            setProgress([]);
            setUploading(false);
        } else {
            setUploading(false);
        }
    },[files])

    return {
        uploading,
        setFiles,
        progress,
        uploaded
    }

}

export const MediaUpload = ({accept, maxFiles, network = false}) => {
    
    const onUploadItemSuccess = (file) => {
       
        // TODO: On Upload Success of all uploads.
        
    }

    // File Uploader
    const { uploading, setFiles, progress, uploaded } = useFileUploader({callback: onUploadItemSuccess, network});

    
    // Dropzone Config
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({accept,maxFiles, onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, { 
            preview: URL.createObjectURL(file),
            progress: 0
        })));
    }});
    
    
    const renderPreviews = () => {
        if(!progress || progress.length <= 0) return (<div></div>)
        return progress.map(file => (
            <StyledGridItem>
                <MediaUploadPreview key={file.path} file={file} loading={uploading} />
            </StyledGridItem>
        ))
    }

    const renderUploaded = () => {
        if(!uploaded || uploaded.length <= 0) return (<div></div>)
        return uploaded.map(file => (
            <StyledGridItem>
                <MediaUploadPreview key={file.path} file={file} />
            </StyledGridItem>
        ))
    }
    
    return (

            <Stack>
                <StyledDropTarget 
                    thinking={uploading} 
                    clickable flex justify="center" align="center" 
                    {...getRootProps({className: 'dropzone'})}
                    >
                    <input {...getInputProps()} />
                    <StyledB>Drag 'n' drop some files here, or click to select files</StyledB>
                </StyledDropTarget>
                
              
                <StyledGrid>
                    {renderPreviews()}
                </StyledGrid>

                {uploaded && uploaded.length > 0 && (
                    <SmartContainer>
                        <StyledHr />

                        <StyledGrid columns={8}>
                            {renderUploaded()}
                        </StyledGrid>
                    </SmartContainer>
                )}

                
            </Stack>
    )
}

const MediaUploadPreview = ({file, loading}) => {

    const type = (file.type.indexOf('image') > -1) ? 'image' : 'other';
    return (
      <Stack width="100%" flex direction="column" justify="flex-end">
          {type === 'image' && (
              <SmartContainer position="relative">
                <SmartContainer thinking={loading}>
                    <StyledImg radius="1" shadow="one" src={file.preview} />
                </SmartContainer>
                {loading && file.progress !== 100 && (<SmartContainer absoluteCenter><StyledCircularProgressWithLabel  value={file.progress} /></SmartContainer>)}
                {loading && file.progress === 100 && (<SmartContainer absoluteCenter><StyledCircularProgress  /></SmartContainer>)}
            </SmartContainer>
          )}
          {type !== 'image' && (
              <SmartContainer position="relative">
                <SmartContainer thinking={loading} >
                    <StyledImagePlaceholder flex align="center" justify="center" minHeight="250px">{file.type}</StyledImagePlaceholder>
                </SmartContainer>
                {loading && file.progress !== 100 && (<SmartContainer absoluteCenter><StyledCircularProgressWithLabel  value={file.progress} /></SmartContainer>)}
                {loading && file.progress === 100 && (<SmartContainer absoluteCenter><StyledCircularProgress  /></SmartContainer>)}
            </SmartContainer>
          )}
          <StyledEllipsis>{file.path}</StyledEllipsis>
      </Stack>
    )
}