import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { push } from 'connected-react-router'
import { NoAccessPage } from '../../pages/Account/NoAccessPage'
import { FullScreenLoader } from '../Loaders/FullScreenLoader'
import { Redirect} from 'react-router-dom'
// Redux
import { Me, FullAuth } from '../../redux/Auth/auth.selectors'
import { acceptTerms } from '../../redux/Auth/auth.actions'

// Styles
import { SmartContainer, Surface, Stack } from '../../styles/Containers'
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { StyledButton } from '../../styles/matieralui/Button'

import { config } from '../../config'

export const AcceptTerms = () => {

    const dispatch = useDispatch();
    const me = Me();
    const { id } = useParams();
    const accountId = parseInt(id)
    const auth = FullAuth();

    const handleAccept = () => dispatch(acceptTerms(config.termsVersion))
    
    return (
        <SmartContainer flex align="center" justify="center" height="100vh" thinking={auth.acceptTermsLoading}>
            <Surface shadow="two" minWidth="300px">
                <Stack>
                    <StyledB>Terms &amp; Conditions Update</StyledB>
                    <StyledP>Review the <Link to="/terms">Terms &amp; Conditions</Link> to proceed.</StyledP>
                    <StyledButton onClick={handleAccept} variant="contained" color="primary">Accept &amp; Continue</StyledButton>
                </Stack>
            </Surface>
        </SmartContainer>
    )
}

