import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Styles
import { SmartContainer, Stack, HorizontalStack, Surface, StackOnMobile, LargeStack } from '../../../styles/Containers'
import { StyledH6, StyledP, StyledB } from '../../../styles/StyledHTML';
import { StyledTabs, StyledTab, StyledTabPanel } from '../../../styles/matieralui/Tabs';
import { StyledButton } from '../../../styles/matieralui/Button';

// Components
import { Repeater } from '../../../components/Forms/Repeater';
import { FormElement } from '../../components/Forms/FormElement';
import { FormInput } from '../../../components/Forms/FormInput';
import { PublishingForm } from '../../../components/Publishing/PublishingForm';
import { richTextToHTML, HTMLToRichText } from '../../../components/Forms/RichText';
import { TagEditor } from '../../../components/Taxonomy/TagEditor';
import { StyledInlineRichText } from '../../../styles/matieralui/Form';
import { MediaFormInput } from '../../../modules/Media/MediaFormInput';
import { Checkbox } from '../../../components/Forms/Checkbox';
import { StatusSelector } from '../../../components/Publishing/StatusSelector';
import { ShedulingSelector } from '../../../components/Publishing/ShedulingSelector';
import { MoveToTrash } from '../../../components/Publishing/MoveToTrash';
import { Duplicate } from '../../../components/Publishing/Duplicate';
import { MeditationsList } from '../Meditations/MeditationsList';
import { MeditationCard } from '../Meditations/MeditationCard';

// Redux
import { FullBreathing } from './redux/breathing.selectors';
import { updateBreathing, deleteBreathing, duplicateBreathing } from './redux/breathing.actions';
import { openToast } from '../../../redux/UI/ui.actions';
import { PostSelect } from '../../components/Content/PostSelect';
import { FormSelect } from '../../../components/Forms/FormSelect';

const FormMeditationOrder = ({ item, onChange }) => {

  return (
    <Surface width="100%" variant="inverse">
      <PostSelect buttonText="Select Meditation" ListComponent={MeditationsList} PreviewComponent={MeditationCard} value={item.meditationId} onChange={(value) => onChange(item, 'meditationId', value)} />
    </Surface>
  )
}

const FormBreathTextEditor = ({ item, onChange }) => {

  return (
    <Surface width="100%" variant="inverse">
      <Stack>
        <FormInput label="In Text" onChange={(value) => onChange(item, 'in', value)} value={item?.in} />
        <FormInput label="Hold Text" onChange={(value) => onChange(item, 'hold', value)} value={item?.hold} />
        <FormInput label="Out Text" onChange={(value) => onChange(item, 'out', value)} value={item?.out} />
      </Stack>
    </Surface>
  )
}
// ---------------------------
// Form Meditation
// ---------------------------

export const FormBreathing = ({ item, childrenMain, childrenSide }) => {

  const dispatch = useDispatch();
  const store = FullBreathing();
  const [activeTab, setActiveTab] = useState(0);
  const [status, setStatus] = useState('draft');
  const [scheduling, setScheduling] = useState('');

  // Name
  const [name, setName] = useState('');
  const [plan, setPlan] = useState('paid');

  // Content
  const [contentEditorReady, setContentEditorReady] = useState(false);
  const [contentEditorState, setContentEditorState] = useState(null);
  const [defaultContentEditorState, setDefaultContentEditorState] = useState(null);

  // Feature Image
  const [featureImage, setFeatureImage] = useState(null);

  // Breathe
  const [breathText, setBreathText] = useState([]);
  const [breathein, setBreatheIn] = useState(null);
  const [breatheout, setBreatheOut] = useState(null);

  // Meditations
  const [meditations, setMeditations] = useState([]);

  // Validate
  let disableSubmit = !name;

  // ---------------------------
  // Effects
  // ---------------------------

  // Sync Props
  useEffect(() => {

    // Publishing
    if (item?.status) setStatus(item.status);
    if (item?.publishedAt) setScheduling({ publishedAt: item.publishedAt, unpublishedAt: item.unpublishedAt });


    // Name
    if (item?.name) setName(item.name);
    else setName('');

    // Name
    if (item?.meta?.plan) setPlan(item?.meta?.plan);
    else setPlan('');

    // Content
    if (item?.meta?.description) {
      const defaultEditorState = HTMLToRichText(item.meta.description);
      setContentEditorState(defaultEditorState);
      setDefaultContentEditorState(defaultEditorState);
    } else {
      const defaultEditorState = HTMLToRichText('');
      setContentEditorState(defaultEditorState);
      setDefaultContentEditorState(defaultEditorState);
    }

    // Feature Image
    if (item?.meta?.featureImage) setFeatureImage(item.meta?.featureImage);
    else setFeatureImage(null);

    // Breathe In
    if (item?.meta?.breathein) setBreatheIn(item.meta?.breathein);
    else setBreatheIn(null);

    // Breathe Out
    if (item?.meta?.breatheout) setBreatheOut(item.meta?.breatheout);
    else setBreatheOut(null);

    if (item?.meta?.breathText) setBreathText(item.meta?.breathText);
    else setBreathText([]);


  }, [item])

  useEffect(() => {
    if (store.updateSuccess) dispatch(openToast(store.updateSuccess, 'success'))
    if (store.updateError) dispatch(openToast(store.updateError, 'error'))
  }, [store.updateLoading])


  // --------------------------
  // Interactions
  // --------------------------

  const handleSubmit = () => {


    // Payload
    const payload = {
      name,
      status: status,
      publishedAt: scheduling.publishedAt,
      unpublishedAt: scheduling.unpublishedAt,
      meta: {
        description: richTextToHTML(contentEditorState), // Convert Editor State to HTML
        breathText,
        plan
      },
    }
    dispatch(updateBreathing(item.id, payload));
  }

  const handleTabChange = (event, index) => setActiveTab(index);




  // --------------------------
  // Slots
  // --------------------------

  const renderMainContentChildren = () => {
    if (!childrenMain) return (<div></div>)
    return (<SmartContainer>{childrenMain}</SmartContainer>)
  }

  const renderSideContentChildren = () => {
    if (!childrenSide) return (<div></div>)
    return (<SmartContainer>{childrenSide}</SmartContainer>)
  }

  const repeaterOnChange = (updatedData) => {
    setBreathText([...updatedData]);
  }



  // --------------------------
  // Render Form
  // --------------------------

  return (
    <SmartContainer thinking={store?.updateLoading} minWidth="300px">
      <LargeStack>
        <FormInput
          label="Title"
          type="text"
          onChange={setName}
          value={name}
        />

        <Stack>
          <StackOnMobile flex align="center" justify="space-between">
            <StyledTabs
              indicatorColor="primary"
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Edit Form"
              variant="scrollable"
              scrollButtons="auto"
            >
              <StyledTab
                label="Details"
                id="screen-0"
                key="screen-0"
                aria-controls="screen-0"
              />
              <StyledTab
                label="Publishing"
                id="screen-1"
                key="screen-1"
                aria-controls="screen-1"
              />
            </StyledTabs>
            <StyledButton
              onClick={handleSubmit}
              color="primary"
              variant="contained"
            >
              Save
            </StyledButton>
          </StackOnMobile>

          <Surface>
            {/* EDIT DETAILS */}
            <StyledTabPanel value={activeTab} index={0}>
              <LargeStack>
                <FormElement label="Plan" description="Who has access?">
                  <FormSelect label="Plan" value={plan} onChange={setPlan}>
                    <option key="paid" value="paid">
                      Paid Users
                    </option>
                    <option key="free" value="free">
                      Free Users
                    </option>
                  </FormSelect>
                </FormElement>

                <FormElement
                  label="Description"
                  description="This will show up when people click into a Meditation."
                >
                  <StyledInlineRichText
                    value={contentEditorState}
                    defaultEditorState={defaultContentEditorState}
                    onChange={(value) => {
                      setContentEditorState(value);
                    }}
                  />
                </FormElement>

                <FormElement label="Breathe Text Loop" description="This will cycle while the meditation is running.">
                  <Repeater
                    id="breathtext"
                    datasource={breathText}
                    component={FormBreathTextEditor}
                    onChange={repeaterOnChange}
                    buttonText="New Text"
                  />
                </FormElement>

              </LargeStack>
            </StyledTabPanel>

            {/* SETTINGS */}
            <StyledTabPanel value={activeTab} index={1}>
              <LargeStack>
                <SmartContainer>
                  <StyledH6>Publishing Settings</StyledH6>
                </SmartContainer>
                <FormElement
                  label="Status"
                  description="Only published content will be visible on your site."
                >
                  <StatusSelector onChange={setStatus} value={status} />
                </FormElement>
                <FormElement
                  label="Sheduling"
                  description="Set when this item should go live and if it should be removed."
                >
                  <ShedulingSelector
                    onChange={setScheduling}
                    value={scheduling}
                  />
                </FormElement>
                <FormElement
                  label="Delete Item"
                  description="This will move this item to the trash. This can be undone."
                >
                  <StyledP>Are you sure?</StyledP>
                  <MoveToTrash
                    id={item.id}
                    action={deleteBreathing}
                    selector={FullBreathing}
                    route="/blogs"
                  />
                </FormElement>
                <FormElement
                  label="Duplicate Item"
                  description="This will create a copy of this item."
                >
                  <StyledP>Are you sure?</StyledP>
                  <Duplicate
                    id={item.id}
                    action={duplicateBreathing}
                    selector={FullBreathing}
                    route="/blogs"
                  />
                </FormElement>
                <FormElement label="Advanced" description="Advanced Settings">
                  {renderSideContentChildren()}
                </FormElement>
              </LargeStack>
            </StyledTabPanel>
          </Surface>
        </Stack>

        {renderMainContentChildren()}
      </LargeStack>
    </SmartContainer>
  );
}