import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../App';
import { EditMediaForm } from './EditMediaForm';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader';

// Styles
import { SmartContainer, Stack, Surface } from '../../styles/Containers'

// Redux
import { getMedia } from './redux/media.actions';
import { FullMedia } from './redux/media.selectors';
import { RouteBreadCrumbs } from '../../routes';
import { StyledH3 } from '../../styles/StyledHTML';


export const EditMediaPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullMedia();
    const { id } = useParams();
    const thisId = parseInt(id)

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Get This Item
        dispatch(getMedia(thisId))

    }, [])


    // --------------------------
    // Interactions
    // --------------------------

    // --------------------------
    // Renders
    // --------------------------


    // Render Page

    return (
        <App title="Media" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.media]}>

            {store?.activeItemLoading && (<FullScreenLoader />)}
            {!store?.activeItemLoading && (
                <SmartContainer thinking={store?.deleteLoading || store?.updateLoading}>
                    <EditMediaForm item={store?.activeItem} />
                </SmartContainer>
            )}
        </App>
    )
}