import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';


// Styles
import { SmartContainer, Stack, Surface, StyledStyledSideBarContainer } from '../../styles/Containers'

// Components
import { FormInput } from '../../components/Forms/FormInput';
import { PublishingForm } from '../../components/Publishing/PublishingForm';
import { TagEditor } from '../../components/Taxonomy/TagEditor';
import { MediaPreview } from './MediaPreview';

// Redux
import { FullMedia, FullMediaTags } from './redux/media.selectors';


import {
    updateMedia,
    deleteMedia,
    searchMediaTags,
    replaceMediaTags,
    updateNetworkMedia,
    deleteNetworkMedia,
    searchNetworkMediaTags,
    replaceNetworkMediaTags
} from './redux/media.actions';
import { FormInputAddressSearch } from '../../components/Forms/FormInputAddressSearch';
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { convertBytesToPrettySize, ImageCroppingPresets } from './MediaUtils';


// ---------------------------
// Edit Example Taxonomy Form
// ---------------------------

export const EditMediaForm = ({ item, network = false }) => {

    const dispatch = useDispatch();
    const store = FullMedia();

    // Name
    const [name, setName] = useState('');
    const nameError = false
    const nameHelperText = 'The title of the Product.'

    // File
    const [path, setPath] = useState('');
    const pathError = false;
    const pathHelperText = 'The url to the hosted file.'

    const [location, setLocation] = useState('');

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    // Sync Props
    useEffect(() => {

        if (item?.name) setName(item.name);
        else setName(null);

        if (item?.meta?.path) setPath(item.meta.path);
        else setPath(null);

        if (item?.meta?.location) setLocation(item.meta.location);
        else setLocation(null);
    }, [item])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (publishing) => {

        const meta = { ...item.meta };
        meta.path = path;
        meta.location = location;

        // Payload
        const payload = {
            name,
            meta
        }
        if (network === true) dispatch(updateNetworkMedia(item.id, payload));
        else dispatch(updateMedia(item.id, payload));
    }


    // --------------------------
    // Render Form
    // --------------------------

    return (
        <StyledStyledSideBarContainer thinking={store?.updateLoading} minWidth="300px">
            <SmartContainer>

                <Stack>
                    <Surface>
                        <FormInput label="Name" type="text" onChange={setName} value={name} helperText={nameHelperText} error={nameError} />
                        <FormInput disabled label="Path" type="text" onChange={setPath} value={path} helperText={pathHelperText} error={pathError} />
                        <br /><br />
                        {item?.meta?.size && (<StyledP><StyledB>File Size: </StyledB>{convertBytesToPrettySize(item?.meta?.size)}</StyledP>)}
                        <br />
                        <MediaPreview network={network} item={item} crop={ImageCroppingPresets.largepreview} />
                    </Surface>
                    <Surface>
                        <FormInputAddressSearch variant="outlined" label="Location" onChange={setLocation} value={location} />

                    </Surface>
                </Stack>

            </SmartContainer>
            <SmartContainer>
                <Stack>
                    <Surface>
                        <PublishingForm
                            item={item}
                            onSubmit={handleSubmit}
                            disable={disableSubmit}
                            deleteAction={(network === true) ? deleteNetworkMedia : deleteMedia}
                            selector={FullMedia}
                            route={(network === true) ? "/network/media" : "/media"}
                            withStatus={false}
                            withScheduling={false}
                        />
                    </Surface>
                    <Surface>
                        {item && (
                            <TagEditor
                                label="Media Tags"
                                model="Media"
                                modelId={item.id}
                                selector={FullMediaTags}
                                action={(network === true) ? searchNetworkMediaTags : searchMediaTags}
                                saveAction={(network === true) ? replaceNetworkMediaTags : replaceMediaTags}
                            />
                        )}
                    </Surface>


                </Stack>

            </SmartContainer>
        </StyledStyledSideBarContainer>
    )
}