import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

// Styled Components
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers';
import { StyledChip } from '../../styles/matieralui/Chip';
import { splitAndTrim } from '../../services/Utils';

// Components
import { Autocomplete } from '../Forms/Autocomplete';
import { StyledB, StyledP } from '../../styles/StyledHTML';

const StyledChipInline = styled(StyledChip)`
    margin-bottom: 1em !important;
`




export const TagEditor = ({ label, model, modelId, selector, action, saveAction }) => {


    // ------------------------------
    // State
    // ------------------------------

    const dispatch = useDispatch();
    const data = selector();
    const [activeTags, setActiveTags] = useState([])
    const newTag = ''
    const [tagsLoaded, setTagsLoaded] = useState(false);

    // ------------------------------
    // Effects
    // ------------------------------

    // Load Tags for This Item
    useEffect(() => { 
        
        // Load Assigned Tags
        const args = {}
        args[model] = modelId;
        args['prefix'] = 'tags';
        dispatch(action(0, null, null, 'all', args)) // Get Everything For this Item

        // Load All Available Tags
        dispatch(action(0, null, null, 'all'))


    },[modelId])

    // Set Local
    useEffect(() => {
        if(data?.tags?.rows) setActiveTags(data.tags.rows)
    },[data.tags])

    // Save Tags
    useEffect(() => { 
        if(!tagsLoaded){
            setTagsLoaded(true);
            return;
        }
        // Save Tag Changes
        if(activeTags !== data?.tags?.rows) saveTags(activeTags);
    },[activeTags,])

    // ------------------------------
    // Methods
    // ------------------------------

    const handleRemoveTag = (tag) => {
        if(!tag) return;
        const tags = activeTags.filter(theTag => tag.name !== theTag.name);
        setActiveTags([...tags])
    }

    const handleAddTag = (tag) => {
        if(!tag) {
            return;
        }
        const exists = activeTags.filter(theTag => tag.name === theTag.name).length;
        if(!exists) setActiveTagsFormat([...activeTags,tag])
    }
    const setActiveTagsFormat = (arr) => {

        // Make Sure There Are No Duplicates
        const storage = []
        const filtered = arr.filter(item => {
            if(!storage.includes(String(item.name).toLowerCase())){
                storage.push(String(item.name).toLowerCase())
                return item;
            }
            return false;
           
        })
        setActiveTags(filtered)
    }

    const tagDifference = () => {
        if(!data.items) return [];
        const tags = data.items.rows.filter(tag => {
            const exists = activeTags.filter(theTag => tag.name === theTag.name).length;
            return !exists;
        })
        return tags;
    }

    const saveTags = useMemo(() => throttle((tags) => { 
        dispatch(saveAction(tags, model, modelId))
    }, 2000),[])

    const handleNewTag = (name) => {
        if(name.indexOf(',') > -1){
            const formatted = splitAndTrim(name,',').map(item => { return {name: item}; })
            setActiveTagsFormat([...activeTags,...formatted]);
        } else {
            handleAddTag({name}) // Single Value
        }
        
    }


    // ------------------------------
    // Render
    // ------------------------------

    const renderTags = (tags) => {
        if(!tags) return (<div></div>)
        return tags.map(tag => (
            <StyledChipInline key={tag.id} label={tag.name} clickable onDelete={() => handleRemoveTag(tag)}/>
        ))
    }

    return (
        <SmartContainer thinking={data.tagsLoading || data.itemsLoading || data.replaceTagsLoading}>
            <Stack>
                <StyledP><StyledB>{label}</StyledB></StyledP>
                
                <HorizontalStack>{renderTags(activeTags)}</HorizontalStack>

                {data.items && (
                    <Autocomplete
                        label="Search" 
                        options={tagDifference()}
                        optionLabelKey="name"
                        onChange={handleAddTag}
                        value={newTag}
                        type="text"
                        noOptionsTextAction={handleNewTag}
                        noOptionsText="Add New"
                        />
                )}
            </Stack>
        </SmartContainer>        
    )
}