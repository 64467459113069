import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { SmartContainer, Surface, Stack, StyledFadeIn } from '../../styles/Containers';
import { StyledH6, StyledP } from '../../styles/StyledHTML';
import { Theme } from '../../styles/Theme';
import { StyledButton } from '../../styles/matieralui/Button';

import { FullNetwork } from '../../redux/Network/network.selectors';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { StyledIconButton } from '../../styles/matieralui/IconButton';
import { FullUI } from '../../redux/UI/ui.selectors';

const StyledWidgetWrap = styled(Stack)`
    position: fixed;
    z-index: 99999;
    /* bottom: 1em; */
    right: 3em;
    // SPACE OVERRIDE
    /* bottom: 90px; */
    top: 0;
`
const StyledTrigger = styled(Surface)`
    padding: 0.25em;
    border-radius: 999px;
    transform: scale3d(0.8,0.8,0.8) ;
`

const StyledHeader = styled(Surface)`
    background-color: ${Theme.colors.primary};
    h6,p {
        color: ${Theme.colors.primaryText} !important;
    }
`

const StyledQuickLink = styled.a`
    display: block;
    padding: 1em;
    border: 1px solid currentColor;
    margin-bottom: 1em;
    border-radius: ${Theme.radius};
    text-align: center;
`

export const AppStarterSupportWidget = () => {

    const fullNetwork = FullNetwork();
    const fullUI = FullUI()
    const widgetData = fullNetwork?.network?.meta?.appstartersupport;
    const { title, message, links } = widgetData;

    const [open, setOpen] = useState(false);

    const renderLinks = () => {
        if (!links?.length) return (<div></div>)
        return links.map(item => (
            <StyledQuickLink href={item.url} target={(item?.newTab === true ? '_blank' : '')}>{item.name}</StyledQuickLink>
        ))
    }

    return (
        <StyledWidgetWrap>
            <StyledFadeIn visible={!fullUI?.meditationPlaying}>
                <SmartContainer flex justify="flex-end">
                    <StyledTrigger clickable onClick={() => setOpen(!open)}>
                        <StyledIconButton>
                            <ChatBubbleOutlineIcon />
                        </StyledIconButton>
                    </StyledTrigger>
                </SmartContainer>
                {open && (
                    <Surface>
                        <Stack>
                            <StyledHeader>
                                <StyledH6>{title}</StyledH6>
                                <StyledP>{message}</StyledP>
                            </StyledHeader>
                            <SmartContainer>
                                {renderLinks()}
                            </SmartContainer>
                        </Stack>
                    </Surface>
                )}

            </StyledFadeIn>
        </StyledWidgetWrap>
    )
}