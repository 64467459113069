
export const toggleDrawer = (open) => dispatch => {

    // Update the UI
    dispatch({
        type: '[UI] Toggle Drawer',
        payload: {
            loading: false,
            drawer: {
                open: open
            }
        }
    });
}

// --------------------------------
// Alerts
// --------------------------------

export const openToast = (message, severity) => dispatch => {

    // Update the UI
    dispatch({
        type: '[UI] Toast',
        payload: {
            toast: {
                open: true,
                message,
                severity
            }
        }
    });

    setTimeout(() => {
        dispatch(closeToast())
    }, 5000)
}

export const closeToast = () => dispatch => {
    // Update the UI
    dispatch({
        type: '[UI] Close Toast',
        payload: {
            toast: {
                open: false,
            }
        }
    });
}

// --------------------------------
// UI Waiting
// --------------------------------

export const uiWaiting = (loading, message) => dispatch => {
    dispatch({
        type: '[UI] Waiting',
        payload: {
            loading,
            message,
        }
    });
}

// -------------------------
// SPACE 
// -------------------------

export const uiMeditationPlaying = (playing) => dispatch => {

    dispatch({
        type: '[UI] Meditation Playing',
        payload: {
            meditationPlaying: playing
        }
    })
}