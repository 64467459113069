import styled, {css} from 'styled-components';
import { Theme, setSpace } from './Theme';
import { SmartContainer } from './Containers';

export const HelperToDo = styled(SmartContainer)`

    background: black;
    color: white;
    padding: 1em;
    font-family: "Courier New", Courier, monospace;
    border-radius: ${Theme.radius};
    > * {
        display: block
        border: 1px solid white;
        &.done {
            text-decoration: line-through;
        }
    }
`
