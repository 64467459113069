import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import { SmartContainer, Stack, Surface, StyledStyledSideBarContainer } from '../../styles/Containers'

// Components
import { FormInput } from '../../components/Forms/FormInput';
import { RichTextEditor, richTextToHTML, HTMLToRichText } from '../../components/Forms/RichText';
import { PublishingForm } from '../../components/Publishing/PublishingForm';
import { StripePriceForm } from './StripePriceForm';
import { Repeater } from '../../components/Forms/Repeater';

// Redux
import { FullNetworkProducts } from './redux/networkproduct.selectors';
import { updateNetworkProduct, deleteNetworkProduct } from './redux/networkproduct.actions';

import { StyledH6 } from '../../styles/StyledHTML';

// ---------------------------
// New Example Content Form
// ---------------------------

export const EditNetworkProductForm = ({item}) => {

    const dispatch = useDispatch();
    const fullNetworkProducts = FullNetworkProducts();

    // Name
    const [name,setName] = useState('');
    const [nameError,setNameError] = useState(false);
    const [nameHelperText,setNameHelperText] = useState('The title of the Product.')

    // const [editorState, setEditorState] = useState('');
    const [editorReady, setEditorReady] = useState(false);
    const [editorState, setEditorState] = useState(null);
    const [defaultEditorState, setDefaultEditorState] = useState(null);

    // Prices
    const [prices,setPrices] = useState([])

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    const convertPriceToCents = (price) => {
        const number = parseFloat(price)*100;
        return number;
    }
    const convertCentsToPrice = (cents) => parseFloat(cents)/100;

    // Sync Props
    useEffect(() => {
        if(item?.name) setName(item.name);
        if(item?.prices) {
            const formattedPrices = item.prices.map(price => {
                const unit_amount = convertCentsToPrice(price.price);
                const format = {...price};
                format.price = unit_amount;
                return format;
            })
            setPrices(formattedPrices)
        }
        
        if(item?.meta?.description){
            const defaultEditorState = HTMLToRichText(item.meta.description);
            setEditorState(defaultEditorState);
            setDefaultEditorState(defaultEditorState);
        } else {
            const defaultEditorState = HTMLToRichText('');
            setEditorState(defaultEditorState);
            setDefaultEditorState(defaultEditorState);
        }
    },[item])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (publishing) => {

        // Convert Price
        const formattedPrices = prices.map(price => {
            const unit_amount = convertPriceToCents(price.price);
            const format = {...price};
            format.price = unit_amount;
            return format;
        })

        // Payload
        const payload = {
            name,
            status: publishing.status,
            prices: formattedPrices,
            publishedAt: publishing.publishedAt,
            unpublishedAt: publishing.unpublishedAt,
            meta: {
                description: richTextToHTML(editorState) // Convert Editor State to HTML
            }
        }
        dispatch(updateNetworkProduct(item.id, payload));
    }

    const onPricesChange = (updatedData) => {
        setPrices([...updatedData]);
    }
       
    // --------------------------
    // Render Form
    // --------------------------

    return (
        <StyledStyledSideBarContainer thinking={fullNetworkProducts?.createLoading} minWidth="300px">
            <SmartContainer>
                <Surface>
                    <Stack>
                        <FormInput label="Name" type="text" onChange={setName} value={name} helperText={nameHelperText} error={nameError} />
                        <FormInput disabled label="Stripe Product Id" type="text" value={item?.productId} helperText="The id in Stripe. This cannot be changed." />
                        
                        <StyledH6>Description</StyledH6>
                        <RichTextEditor 
                            label="Description" 
                            value={editorState} 
                            defaultEditorState={defaultEditorState}
                            onChange={(value) => { setEditorState(value)}} />
                        
                        <StyledH6>Product Prices</StyledH6>
                        <Repeater
                            id="product-prices"
                            datasource={prices}
                            component={StripePriceForm}
                            onChange={onPricesChange}
                            buttonText="Add New Price"
                            defaultArgs={{currency:'usd'}}
                            />
                    </Stack>
                </Surface>
            </SmartContainer>
            <SmartContainer>
                <Surface>
                    <Stack>                        
                        <PublishingForm 
                            item={item} 
                            onSubmit={handleSubmit} 
                            disable={disableSubmit}
                            deleteAction={deleteNetworkProduct}
                            selector={FullNetworkProducts}
                            route="/network/products"
                            />
                    </Stack>
                </Surface>
            </SmartContainer>
        </StyledStyledSideBarContainer>
    )
}