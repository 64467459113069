export const loadState = prefix => `${prefix}Loading`
export const errorState = prefix => `${prefix}Error`
export const successState = prefix => `${prefix}Success`
export const dataState = prefix => `${prefix}`

export const loadingActionType = action => `${action}`;
export const failedActionType = action => `${action} Failed`
export const successActionType = action => `${action} Success`
export const clearActionType = action => `${action} Clear`
export const wipeActionType = action => `${action} Wipe`

export const dispatchLoading = (action, prefix) => {
    return {
        type: loadingActionType(action),
        payload: {
            [loadState(prefix)]: true
        }
    }
}


export const dispatchError = (action, prefix, error) => {
    return {
        type: failedActionType(action),
        payload: {
            [loadState(prefix)]: false,
            [errorState(prefix)]: error,
            [successState(prefix)]: null
        }
    }
}

export const dispatchSuccess = (action, prefix, message, data) => {
    return {
        type: successActionType(action),
        payload: {
            [loadState(prefix)]: false,
            [errorState(prefix)]: null,
            [successState(prefix)]: message,
            [dataState(prefix)]: data
        }
    }
}

export const dispatchClearState = (action, prefix) => {
    return {
        type: clearActionType(action),
        payload: {
            [loadState(prefix)]: null,
            [errorState(prefix)]: null,
            [successState(prefix)]: null,
            [dataState(prefix)]: null
        }
    }
}

export const dispatchWipe = (action) => {
    return {
        type: wipeActionType(action)
    }
}
