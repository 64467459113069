import React from 'react';
// Icons
import SpeakerIcon from '@mui/icons-material/Speaker';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

// Containers
import { SmartContainer, Stack, StyledImagePlaceholder, Surface } from '../../styles/Containers'
import { StyledP } from '../../styles/StyledHTML';
import { MediaImage, mediaPath } from './MediaUtils';

export const MediaCard = ({ item, onClick, network = false, src, className, crop = false }) => {

    const handleSelect = (item) => {
        if (!onClick) return;
        onClick(item);
    }

    const renderMediaIcon = (type) => {
        if (type === 'audio') return (<SpeakerIcon fontSize="large" />);
        if (type === 'doc') return (<DescriptionOutlinedIcon fontSize="large" />)
        if (type === 'video') return (<PlayCircleOutlineOutlinedIcon fontSize="large" />)
        return (<div>{type}</div>)
    }

    const setMediaUrl = (item) => {
        if (src) return src;
        // return mediaPath(item.full.meta.path,{network})
        return mediaPath(item.full.meta.path, item.full.meta.storage)
    }

    // Guard
    if (!item.full) return (<Surface key={item.id} width="100%">Loading...</Surface>)

    const renderImage = (item) => {

        return (
            <MediaImage image={item} crop={crop} network={network} radius />
        )
        //return (<StyledImagePlaceholder background={`url('${setMediaUrl(item)}')`} minHeight="150px" flex align="center" justify="center" />)
    }

    // Render Preview
    const renderPreview = (item) => {
        if (item?.full?.mediaType === 'image') return renderImage(item);


        // Render Icon
        return (
            <SmartContainer position="relative" >
                <StyledImagePlaceholder minHeight="150px" flex align="center" justify="center">{renderMediaIcon(item?.full?.mediaType, { network })}</StyledImagePlaceholder>
                <SmartContainer position="absolute" bottom="0" left="0" width="100%" padding={2}>
                    <StyledP small>{item.title}</StyledP>
                </SmartContainer>
            </SmartContainer>
        )
    }

    return (
        <SmartContainer width="100%" clickable onClick={() => handleSelect(item)} className={className}>
            <Stack>
                {renderPreview(item)}
            </Stack>
        </SmartContainer>
    )
}