import styled, { css } from 'styled-components';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Theme } from '../Theme';

export const StyledBottomNavigation = styled(BottomNavigation)`
    /* position: fixed;
    bottom: 1.5rem;
    left: 2%;
    right: 2%;
    z-index: 999;
    width: 96%;
    background-color: ${Theme.colors.base} !important;
    height: 70px !important;
    border-radius: 1000px;
    box-shadow: ${Theme.shadow.three};
    border: 1px solid ${Theme.colors.baseAlternate} !important;
    transition: all 500ms;
    ${props => (props.hide === true) ? css`opacity: 0; pointer-events: none;` : ''} */

    // Absolute Bottom
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background-color: ${Theme.colors.base} !important;
    height: 70px !important;
    border-top: 1px solid ${Theme.colors.baseAlternate} !important;
    transition: all 500ms;
    ${props => (props.hide === true) ? css`opacity: 0; pointer-events: none;` : ''}
    ${props => (props.SafeArea && !props.isAndroid) ? css`padding-bottom: 2em; height: 80px !important;` : ''}

`
export const StyledBottomNavigationAction = styled(BottomNavigationAction)``