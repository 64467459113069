import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useUserNote } from './redux/usernotes.hooks';
import { HTMLToRichText, richTextToHTML } from '../../../components/Forms/RichText';
import { StyledInlineRichText } from '../../../styles/matieralui/Form';
import { Stack } from '../../../styles/Containers';
import { StyledButton } from '../../../styles/matieralui/Button';
import { FormInput } from '../../../components/Forms/FormInput';
import moment from 'moment';
import { StyledP } from '../../../styles/StyledHTML';

const StyledSpaceNotes = styled(StyledInlineRichText)`
    padding: 0;
    .DraftEditor-root {
        padding: 0;
    }
`


export const UserNote = ({ name, value, noteId = null, meditationId = null, seriesId = null, onChange, hideTitle = false }) => {

    const { saveUserNote, updateUserNote, fullNotes } = useUserNote({ meditationId, seriesId, noteId });

    // Content
    const [contentEditorReady, setContentEditorReady] = useState(false);
    const [contentEditorState, setContentEditorState] = useState(null);
    const [defaultContentEditorState, setDefaultContentEditorState] = useState(null);

    const [title, setTitle] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'))
    const [focus, setFocus] = useState(null);

    useEffect(() => {
        // Content
        if (value) {
            const defaultEditorState = HTMLToRichText(value);
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        } else {
            const defaultEditorState = HTMLToRichText('');
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        }
    }, [value, meditationId, seriesId])

    useEffect(() => {
        if (name) setTitle(name);
        else setTitle(moment().format('MMMM Do YYYY, h:mm:ss a'))
    }, [name])

    useEffect(() => {
        setFocus(true);
    }, [defaultContentEditorState])

    const handleSave = () => {
        const note = richTextToHTML(contentEditorState);
        saveUserNote({ name: title, content: note });
        if (!onChange) return;
        onChange(note);
    }


    return (
        <Stack thinking={fullNotes?.updateLoading} width="100%">
            {hideTitle != true && (<FormInput label="title" value={title} onChange={setTitle} />)}
            <StyledP light small>Touch below to start writing.</StyledP>
            <StyledSpaceNotes
                hideElements={true}
                hideLink={true}
                hideHTMLView={true}
                hideControls={true}
                value={contentEditorState}
                defaultEditorState={defaultContentEditorState}
                onChange={(value) => { setContentEditorState(value) }}
                focus={focus}
            />
            <StyledButton fullWidth variant='contained' onClick={handleSave}>Save</StyledButton>
            <StyledP>&nbsp;</StyledP>
            <StyledP>&nbsp;</StyledP>
        </Stack>
    )
}