import React from 'react'
import { useSelector } from 'react-redux';
import styled, {css} from 'styled-components';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SmartContainer, Surface, Stack } from '../Containers'
import { StyledH6 } from '../StyledHTML'
import { Theme, respondTo } from '../Theme'


const ModalStyles = styled(Modal)`

    // padding-left: 20%;
    // padding-right: 20%;
    
    margin: 0 auto;
    overflow: scroll;
    ${props => (props.fullWidth) ? css`
        max-width: 95%;
        padding-top: 5%;
    ` :'max-width: 50%; padding-top: 10%;'}

    > div:first-child {
        background-color: ${Theme.colors.base} !important;
        opacity: 0.85;
    }
   
    .modal-surface {
        outline: 0;
        border-radius: ${Theme.radius};
        box-shadow: ${Theme.shadow.three};
        background-color: ${Theme.colors.baseAlternate};
    }
    ${respondTo('tablet',css`
        padding-left: 5%; 
        padding-right:5%;
        max-width: 100%;
    `)}

    
   
`

export const StyledModal = ({open, onClose, label, description, children, fullWidth=false}) => {

    const ui = useSelector(state => state.ui);

    const handleClose = () => {
        
        // Check if we are saving. If we are return;
        if(ui.loading === true) return;

        // Close        
        onClose();
    }

    return (
        <ModalStyles
            open={open}
            onClose={handleClose}
            aria-labelledby={label}
            aria-describedby={description}
            fullWidth={fullWidth}
            >
                <Surface className="modal-surface">
                    <Stack saving={ui.loading}>
                        <SmartContainer flex justify="space-between" align="center">
                            <StyledH6>{label}</StyledH6>
                            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                        </SmartContainer>
                        <SmartContainer>{children}</SmartContainer>
                    </Stack>
                </Surface>   
        </ModalStyles>
    )
}
