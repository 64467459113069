import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga4';

const NETWORK_TRACKING_NAME = 'NetworkTracking';
const GLOBAL_PLATFORM_NAME = 'GlobalPlatformTracking';


export const googleAnalyticsInit = (fullNetwork) => {

  // Default Trackers
  let trackers = [{ trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, gaOptions: { name: GLOBAL_PLATFORM_NAME } }];

  // Enabled 2nd Network Tracking Code
  if (fullNetwork?.network?.meta?.googleanalytics && fullNetwork?.network?.meta?.googleanalytics?.enabled === true) {
    trackers.push(
      {
        trackingId: fullNetwork?.network?.meta?.googleanalytics?.trackingId,
        gaOptions: { name: NETWORK_TRACKING_NAME }
      }
    )
  }

  // Init Global Tracking
  ReactGA.initialize(trackers);

}

export const googleAnalyticsTrackPageView = ({ location, fullNetwork }) => {

  // let trackingArray = [GLOBAL_PLATFORM_NAME];

  // // Add Platform Tracking
  // if (fullNetwork?.network?.meta?.googleanalytics && fullNetwork?.network?.meta?.googleanalytics?.enabled === true) trackingArray.push(NETWORK_TRACKING_NAME);

  // GA 4
  ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  // const path = location.pathname + location.search;
  // ReactGA.pageview(path, trackingArray); // Deprecated

  // ReactGA.set({ page: path });
  // ReactGA.ga(`${NETWORK_TRACKING_NAME}.send`, 'pageview', {'page': path});
}

export const googleAnalyticsTrackEvent = ({ event, args, fullNetwork }) => {

  // let trackingArray = [GLOBAL_PLATFORM_NAME];

  // // Add Platform Tracking
  // if (fullNetwork?.network?.meta?.googleanalytics && fullNetwork?.network?.meta?.googleanalytics?.enabled === true) trackingArray.push(NETWORK_TRACKING_NAME);

  ReactGA.event(args);
}