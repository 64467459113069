import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import { SmartContainer, Stack } from '../../../styles/Containers'
import { StyledButton } from '../../../styles/matieralui/Button'

// Components
import { FormInput } from '../../../components/Forms/FormInput';

// Redux
import { openToast } from '../../../redux/UI/ui.actions';

// ---------------------------
// New Example Content Form
// ---------------------------

export const NewContentForm = ({selector,createAction,titleLabel="Title", helper="Enter a title"}) => {

    const dispatch = useDispatch();
    const store = selector();

    // Name
    const [name,setName] = useState('');

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    useEffect(() => {
        if(store.createSuccess) dispatch(openToast(store.createSuccess,'success'))
        if(store.createError) dispatch(openToast(store.createError,'error'))
    },[store.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (e) => dispatch(createAction({name}));
        
    // --------------------------
    // Render Form
    // --------------------------

    return (
        <SmartContainer thinking={store?.createLoading} minWidth="300px">
            <Stack>
                <FormInput label={titleLabel} type="text" onChange={setName} value={name} helperText={helper} />
                <SmartContainer flex align="center" justify="flex-end">
                    <StyledButton onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>Create</StyledButton>
                </SmartContainer>
            </Stack>
        </SmartContainer>
    )
}