import { searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, replaceTags, forceDeleteModel } from '../../../redux/DefaultModelActions';
import { APIGet, APIBase } from '../../../services/API';

const model = 'Media';
const route = '/v1/media';

export const searchMedia = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createMedia = (args) => createModel(model, route, args);
export const updateMedia = (id, args) => updateModel(model, route, id, args);
export const deleteMedia = (id) => deleteModel(model, route, id);
export const restoreMedia = (id) => restoreModel(model, route, id);
export const getMedia = (id) => getModel(model, route, id);
export const forceDeleteMedia = (id) => forceDeleteModel(model, route, id);

// Media Tags
const tagModel = 'MediaTag';
const tagRoute = '/v1/mediatag';

export const searchMediaTags = (page, limit, search, view, args) => searchModels(tagModel, tagRoute, page, limit, search, view, args);
export const createMediaTag = (args) => createModel(tagModel, tagRoute, args);
export const updateMediaTag = (id, args) => updateModel(tagModel, tagRoute, id, args);
export const deleteMediaTag = (id) => deleteModel(tagModel, tagRoute, id);
export const restoreMediaTag = (id) => restoreModel(tagModel, tagRoute, id);
export const getMediaTag = (id) => getModel(tagModel, tagRoute, id);
export const replaceMediaTags = (terms, contentType, contentId) => replaceTags(tagModel, tagRoute, terms, contentType, contentId);

// Network Media
const networkRoute = '/v1/network/media';

export const searchNetworkMedia = (page, limit, search, view, args) => searchModels(model, networkRoute, page, limit, search, view, args);
export const createNetworkMedia = (args) => createModel(model, networkRoute, args);
export const updateNetworkMedia = (id, args) => updateModel(model, networkRoute, id, args);
export const deleteNetworkMedia = (id) => deleteModel(model, networkRoute, id);
export const restoreNetworkMedia = (id) => restoreModel(model, networkRoute, id);
export const getNetworkMedia = (id) => getModel(model, networkRoute, id);
export const forceDeleteNetworkMedia = (id) => forceDeleteModel(model, networkRoute, id);

const networkTagRoute = '/v1/network/mediatag';

export const searchNetworkMediaTags = (page, limit, search, view, args) => searchModels(tagModel, networkTagRoute, page, limit, search, view, args);
export const createNetworkMediaTag = (args) => createModel(tagModel, networkTagRoute, args);
export const updateNetworkMediaTag = (id, args) => updateModel(tagModel, networkTagRoute, id, args);
export const deleteNetworkMediaTag = (id) => deleteModel(tagModel, networkTagRoute, id);
export const restoreNetworkMediaTag = (id) => restoreModel(tagModel, networkTagRoute, id);
export const getNetworkMediaTag = (id) => getModel(tagModel, networkTagRoute, id);
export const replaceNetworkMediaTags = (terms, contentType, contentId) => replaceTags(tagModel, networkTagRoute, terms, contentType, contentId);

export const loadMediaItem = async (id, network = false, args = {}) => {

    const path = (network) ? networkRoute : route;

    // Args: Are the Cropping and resize args.
    let qs = [];
    if (args) Object.keys(args).map(key => qs.push(`${key}=${args[key]}`))

    const queryString = qs.join('&');

    try {
        const res = await APIGet(`${APIBase}${path}/${id}?${queryString}`)
        const json = await res.json();
        return json;
    } catch (error) {
        return error;
    }

}
