import React from 'react';
import { SmartContainer } from '../../styles/Containers'
import { StyledP } from '../../styles/StyledHTML'

export const FormError = ({error}) => {
    return (
        <SmartContainer>
            <StyledP error={true}>{error}</StyledP>
        </SmartContainer>
    )
}