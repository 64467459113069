import React from 'react';
import styled, { css } from 'styled-components';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Tooltip from '@mui/material/Tooltip';

// Components
import { HorizontalStack } from '../../styles/Containers';
import { Theme, setSpace, respondTo } from '../../styles/Theme';

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { NetworkRole, Me } from '../../redux/Auth/auth.selectors'
import { ThisAccount } from '../../redux/Account/account.selectors'
import { StyledB, StyledP } from '../../styles/StyledHTML';

// Styled
const StyledAdminBar = styled(HorizontalStack)`
    background-color: ${Theme.colors.baseDark};
    border-radius: ${Theme.radius};
    color: ${Theme.colors.baseText};
    opacity: 0.35;
    padding: ${setSpace(4)};
    ${respondTo('tablet',css`display: block; b { margin-bottom: 0.5em; display: block;}`)}
`

// -------------------------
// The Component
// -------------------------

export const SuperAdminBar = () => {
    const fullNetwork = FullNetwork();
    const currentNetworkRole = NetworkRole(fullNetwork.network?.id);
    const account = ThisAccount();
    const me = Me();
    if(currentNetworkRole !== 'superadmin') return (<div data-superadminbard></div>)

    return (
        <StyledAdminBar data-superadminbard flex>
            <SuperAdminBarItem title={fullNetwork.network?.name} tip="Network" icon={<LanguageOutlinedIcon/>}/>
            <SuperAdminBarItem title={account?.name} tip="Account" icon={<HomeOutlinedIcon/>}/>
            <SuperAdminBarItem title={me?.email} tip="User" icon={<AccountCircleOutlinedIcon/>}/>
        </StyledAdminBar>
    )
}

const SuperAdminBarItem = ({icon, title, tip}) => {
    return (
    <Tooltip title={tip} placement="bottom">
        <HorizontalStack flex>
            <p>{icon}</p>
            <p>{title}</p>
        </HorizontalStack>
    </Tooltip>
    )
}