import { duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getModelBySlug, getRevisions } from '../../../../redux/DefaultModelActions';
import { APIGet, APIBase } from '../../../../services/API';

const model = 'Meditation';
const route = '/v1/meditation';

export const searchMeditations = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createMeditation = (args) => createModel(model, route, args);
export const updateMeditation = (id, args) => updateModel(model, route, id, args);
export const deleteMeditation = (id) => deleteModel(model, route, id);
export const restoreMeditation = (id) => restoreModel(model, route, id);
export const getMeditation = (id, args) => getModel(model, route, id, args);
export const getMeditationRevision = (id) => getRevisions(model, route, id);
export const duplicateMeditation = (id) => duplicateModel(model, route, id);

export const loadMeditation = async (id) => {

    try {
        const res = await APIGet(`${APIBase}${route}/${id}`)
        const json = await res.json();
        return json;
    } catch (error) {
        return error;
    }

}