import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { HorizontalStack, SmartContainer, Stack } from '../../../styles/Containers';
import { StyledB, StyledH3, StyledH6, StyledImg, StyledP } from '../../../styles/StyledHTML';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { FormInput } from '../../../components/Forms/FormInput';

import logo from '../assets/first-promoter-logo';
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledSwitch } from '../../../styles/matieralui/Form';

import { useSaveIntegration } from '../integrations.hooks';
import { IntegrationCard } from '../IntegrationCard';
import { IntegrationFormHeader } from '../IntegrationFormHeader';

export const FirstPromoterForm = ({meta, open = false, network = false}) => {
    
    const key = 'firstpromoter';
    const title = "First Promoter";
    const description = "Affiliate Tracking.";

    // Use Integration Save
    const { openForm, setOpenForm, saveIntegration, thinking } = useSaveIntegration({open, network});
    
    // Form Fields
    const [id,setId] = useState('');
    const [domain,setDomain] = useState('');
    const [enabled,setEnabled] = useState(false);

    // Set Default values
    useEffect(() => {
        if(!meta) return;
        if(meta[key] && meta[key].enabled) setEnabled(meta[key].enabled);
        if(meta[key] && meta[key].id) setId(meta[key].id);
        if(meta[key] && meta[key].domain) setDomain(meta[key].domain);
    },[meta])


    // Format Save Data
    const handleSave = () => {
        const m = {...meta};
        if(!m[key]) m[key] = {};
        m[key].enabled = enabled;
        m[key].id = id;
        m[key].domain = domain;
        saveIntegration(m);
    }

    return (
        <SmartContainer thinking={thinking}>
            <IntegrationCard clickable onClick={() => setOpenForm(true)} 
                title={title}
                description={description}
                logo={logo}
                />

            <StyledModal
                open={openForm}
                onClose={() => setOpenForm(false)}
                >
                    <Stack>
                        <IntegrationFormHeader title={title} logo={logo}>
                            <StyledSwitch color="primary" checked={enabled} name="enabled" onChange={(e) => setEnabled(e.target.checked)} />
                        </IntegrationFormHeader>
                       
                        <FormInput label="First Promoter Id" type="text" onChange={setId} value={id} helperText="Your First Promoter Account ID" />
                        <FormInput label="First Promoter Domain" type="text" onChange={setDomain} value={domain} helperText=".yourdomain.com" />
                        <StyledButton variant="contained" color="primary" onClick={handleSave}>Save</StyledButton>
                    </Stack>
                    
                </StyledModal>
        </SmartContainer>
    )
}