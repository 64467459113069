import React from 'react';
import styled, { css } from 'styled-components';
import { StyledSnackbar, StyledAlert } from '../../styles/matieralui/Alerts'
import { StyledButton } from '../../styles/matieralui/Button';

const StyledSpaceSnackBar = styled(StyledSnackbar)`
    bottom: 6em !important;
`

export const Toast = ({ message, severity, onClose, open }) => {

    if (!open) return (<div data-empty-toast></div>);

    return (
        <StyledSnackbar onClose={onClose} open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <StyledAlert onClose={onClose} severity={severity}>{message}</StyledAlert>
        </StyledSnackbar>
    )
}

export const PersistentToast = ({ message, severity, buttonText, onClick }) => {
    return (
        <StyledSpaceSnackBar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <StyledAlert severity={severity} action={
                <StyledButton onClick={onClick}>{buttonText}</StyledButton>
            }>{message}</StyledAlert>
        </StyledSpaceSnackBar>
    )
}