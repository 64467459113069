import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from './NetworkApp';
import { NetworkAccountsList } from './NetworkAccountsList';

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { switchAccount } from '../../redux/Auth/auth.actions'
import { RouteBreadCrumbs } from '../../routes';

export const NetworkAccountsPage = () => {

    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();

    // --------------------------
    // Effects
    // --------------------------

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (account) => {

        // Switch Account
        // dispatch(switchAccount(account.id))

        // Edit Account Page
        dispatch(push(`/network/accounts/${account.id}`));
        // alert(account.id);
    }

    // --------------------------
    // Renders
    // --------------------------

    return (
        <NetworkApp title="Accounts" breadcrumbs={[RouteBreadCrumbs.dashboard,RouteBreadCrumbs.network]}>
            
            <NetworkAccountsList onSelect={handleSelect} />

        </NetworkApp>
    )
}