import { searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getRevisions } from '../../../redux/DefaultModelActions';

const model = 'UserPreference';
const route = '/v1/userpreference';

// export const searchExampleContent = (page, limit, search, view) => searchModels(model, route, page, limit, search, view);
export const createUserPreference = (args) => createModel(model, route, args);
export const updateUserPreference = (id, args) => updateModel(model, route, id, args);
export const deleteUserPreference = (id) => deleteModel(model, route, id);
export const restoreUserPreference = (id) => restoreModel(model, route, id);
export const getUserPreference = (id) => getModel(model, route, id);