import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { NetworkApp } from './NetworkApp';
import { SurfaceMessage } from '../Alerts/SurfaceMessage';
import { FullScreenLoader } from '../Loaders/FullScreenLoader'
import { UserForm } from '../User/UserForm';

// Styled
import { SmartContainer, Stack, Surface } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledModal } from '../../styles/matieralui/Modal'
import { StyledB, StyledH6 } from '../../styles/StyledHTML';

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { getUser, updateUser } from '../../redux/Network/network.actions'
import { switchAccount } from '../../redux/Auth/auth.actions'
import { FullAuth, NetworkRole } from '../../redux/Auth/auth.selectors';
import { openToast } from '../../redux/UI/ui.actions';
import { RouteBreadCrumbs } from '../../routes';


export const NetworkEditUserPage = () => {

    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();
    const fullAuth = FullAuth();
    const { id } = useParams();
    const userId = parseInt(id)
    const currentUserNetworkRole = NetworkRole(fullNetwork?.network?.id);

    // --------------------------
    // Effects
    // --------------------------

    // Load User info
    useEffect(() => { dispatch(getUser(userId)) }, [])

    // Update Team Member
    useEffect(() => {
        if (fullNetwork.updateUserSuccess) {
            dispatch(openToast(fullNetwork.updateUserSuccess, 'success'))
        }
        if (fullNetwork.updateUserError) {
            dispatch(openToast(fullNetwork.updateUserError, 'error'))
        }
    }, [fullNetwork.updateUserLoading])

    // --------------------------
    // Permissions
    // --------------------------

    // Loading
    if (fullNetwork.userLoading) return (<FullScreenLoader />)

    // --------------------------
    // Interactions
    // --------------------------

    const handleUpdateUser = (user) => {

        const payload = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName
        }
        dispatch(updateUser(fullNetwork.user.id, payload))
    }

    const handleAccountClick = (account) => dispatch(switchAccount(account.id))

    // --------------------------
    // Renders
    // --------------------------

    const thisUser = (fullNetwork.user) ? fullNetwork.user : null;

    const renderAccounts = () => {

        return fullNetwork.user.accounts.map(account => {
            return (
                <Surface clickable onClick={() => handleAccountClick(account)}>{account.name}</Surface>
            )
        })

    }

    return (
        <NetworkApp title="Edit User" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network, RouteBreadCrumbs.networkUsers]}>

            {!thisUser && (<SurfaceMessage title="User not found" />)}

            {thisUser && (
                <Stack>
                    <Surface thinking={fullNetwork.getFullNetworkLoading}>

                        <UserForm user={thisUser} onSubmit={handleUpdateUser} userOnly={true} loading={fullNetwork.updateUserLoading} />

                    </Surface>

                    {/* <SmartContainer>
                        <StyledH6>User's Accounts</StyledH6>
                        <StyledB light>Select an Account to switch to that account.</StyledB>
                    </SmartContainer>

                    <Stack>{renderAccounts()}</Stack> */}

                </Stack>
            )}

        </NetworkApp>
    )
}