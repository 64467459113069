import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GraphicEqOutlinedIcon from '@mui/icons-material/GraphicEqOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";

// Solid
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import CachedIcon from '@mui/icons-material/Cached';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const SpaceIconX = CloseIcon;
export const SpaceIconClose = HighlightOffOutlinedIcon;
export const SpaceIconPlay = PlayArrowIcon;
export const SpaceIconPause = PauseIcon;
export const SpaceIconStop = StopIcon;
export const SpaceIconHome = HomeIcon;
export const SpaceIconNote = EditIcon;
export const SpaceIconCalendar = TodayOutlinedIcon;
export const SpaceIconAccount = MenuIcon;
export const SpaceIconSeries = HeadphonesIcon;
export const SpaceIconCheck = CheckIcon;
export const SpaceIconLocked = LockOutlinedIcon;
export const SpaceIconRefresh = CachedIcon;
export const SpaceIconStar = StarIcon;
export const SpaceIconArrowForward = ArrowForwardIosIcon;
export const SpaceIconArrowBack = ArrowBackIosIcon;
export const SpaceIconTarget = ModeStandbyIcon;