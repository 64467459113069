import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import { NetworkApp } from '../../components/Network/NetworkApp';
import { EditNetworkProductForm } from './EditNetworkProductForm';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader';

// Styles
import { SmartContainer } from '../../styles/Containers'

// Redux
import { getNetworkProduct, deleteNetworkProduct } from './redux/networkproduct.actions';
import { FullNetworkProducts } from './redux/networkproduct.selectors';
import { StyledA, StyledP } from '../../styles/StyledHTML';
import { openToast } from '../../redux/UI/ui.actions';
import { RouteBreadCrumbs } from '../../routes';

export const EditNetworkProductPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const [showAreYouSure, setShowAreYouSure] = useState(false);
    const fullNetworkProducts = FullNetworkProducts();
    const { id } = useParams();
    const thisId = parseInt(id)

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Get This Item
        dispatch(getNetworkProduct(thisId))

    },[])



    // --------------------------
    // Renders
    // --------------------------


    // Render Page

    return (
        <NetworkApp title="Edit Network Product" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network, RouteBreadCrumbs.networkProducts]}>
            
            {fullNetworkProducts?.activeItemLoading && (<FullScreenLoader />)}
            {!fullNetworkProducts?.activeItemLoading &&(
                <SmartContainer thinking={fullNetworkProducts?.deleteLoading || fullNetworkProducts?.updateLoading}>
                    <EditNetworkProductForm item={fullNetworkProducts?.activeItem} />                    
                </SmartContainer>
            )}
        </NetworkApp>
    )
}