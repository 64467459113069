import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { FullAuth } from '../../redux/Auth/auth.selectors';
import { newUserAccount } from '../../redux/Account/account.actions';
import { FullAccount } from '../../redux/Account/account.selectors';
import { FullNetwork } from '../../redux/Network/network.selectors';
import { openToast } from '../../redux/UI/ui.actions';

// Styles
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Components
import { FormInput } from '../Forms/FormInput'
import { FormError } from '../Forms/FormError'
import { UserRoleSelect } from './UserRoleSelect'
import { isEmail } from '../../services/Utils'


export const NewUserForm = () => {

    const dispatch = useDispatch();
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [emailError,setEmailError] = useState('');
    const [role,setRole] = useState('editor')
    const fullNetwork = FullNetwork();
    
    

    const auth = FullAuth();
    const fullAccount = FullAccount();

    const handleLogin = (e) => dispatch(newUserAccount(fullNetwork.network.id, fullAccount.account.id,name,email,role));
    const handleEmailChange = (value) => {
        if(isEmail(value)) setEmailError('')
        else setEmailError('Please enter a valid email')
        setEmail(value)
    }

    // Login Errors
    const hasError = (auth?.error) ? auth.error : false;

    useEffect(() => {
        if(fullAccount.addTeamMemberSuccess){
            dispatch(openToast(fullAccount.addTeamMemberSuccess,'success'))
        }
        if(fullAccount.addTeamMemberError) {
            dispatch(openToast(fullAccount.addTeamMemberError,'error'))
        }
    },[fullAccount.addTeamMemberLoading])


    // Validate
    const disableSubmit = (!email || !name || !role || !isEmail(email));

    return (
        <SmartContainer thinking={auth.loading} minWidth="300px">
            <Stack>
                {hasError && (<FormError error={hasError} />)}
                <FormInput label="Name" type="text" onChange={setName} value={name} />
                <FormInput label="Email" type="email" onChange={handleEmailChange} value={email} helperText={emailError} error={(emailError)} />
                <UserRoleSelect label="Account Role" value={role} onChange={setRole} />
                <StyledButton onClick={handleLogin} variant="contained" color="primary" disabled={disableSubmit}>Add User</StyledButton>
            </Stack>
        </SmartContainer>
    )
}