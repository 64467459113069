import styled, {css} from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Theme, setSpace } from '../Theme'


// List
export const StyledList = styled(List)`
`;

// List Item
export const StyledListItem = styled(ListItem)`
    .MuiListItemIcon-root {
       min-width: 0px;
       color: ${Theme.colors.baseText};
    }
    &.MuiListItem-root {
        &:hover {
            background-color: ${Theme.colors.base.inverse};
        }
        border-radius: ${Theme.radius};
        
    }
    ${props => (props.selected) ? css` background-color: ${Theme.colors.base.inverse};` : ''}
`;

// List Item Text
export const StyledListItemText= styled(ListItemText)`
    margin-left: ${setSpace(3)};
    margin-right: ${setSpace(3)};
    color: ${Theme.colors.baseText};
    &.MuiListItemText-root {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const StyledListItemIcon = styled(ListItemIcon)``