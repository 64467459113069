import React from 'react';
import styled, { css } from 'styled-components';
import { respondTo } from '../../styles/Theme';

// TCC Components
import { SmartContainer, Stack, StyledGrid, StyledGridItem, HorizontalStack } from '../../styles/Containers'
import { PaginatedListItem } from './PaginatedListItem'
import { PaginatedCardItem } from './PaginatedCardItem'
import { SearchInput } from '../Forms/SearchInput'

// Material UI
import { StyledPagination } from '../../styles/matieralui/Pagination'

const StyledPaginationControls = styled(SmartContainer)`
    ${respondTo('tablet', css`
        /* display: fle; */
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        [data-search-input],
        [data-pagination] {
            width: 100%;
            margin-bottom: 1em;
        }
        [data-horizontal-stack]{
            display: block;

        }

        > * {
            margin-top: 1em;
            width: 100%;
            
        }
        .MuiFormControl-root {
                width: 100% !important;
            }
    `)}
`


// Pagination
const Pagination = ({ count, onPaginationClick, currentPage }) => (
    <StyledPagination data-pagination
        defaultPage={currentPage}
        page={currentPage}
        count={count}
        showFirstButton
        showLastButton
        siblingCount={0}
        onChange={(event, value) => onPaginationClick(value)}
    />
)



// // Skeleton
// const SkeletonList = ({variant, total, customRenderer}) => {

//     const items = [];

//     if(variant === 'cards'){

//         for(let i=0; i<total; i++){
//             if(customRenderer) items.push(<StyledGridItem key={i}>{customRenderer({title:''})}</StyledGridItem>);
//             else items.push(<StyledGridItem key={i}><PaginatedListItem skeleton  item={{title:''}}/></StyledGridItem>)
//         }
//         return (
//             <StyledGrid>
//                 {items}
//             </StyledGrid>
//         )
//     }

//     for(let i=0; i<total; i++){
//         if(customRenderer) items.push(customRenderer({title:''}));
//         else items.push(<PaginatedListItem skeleton key={i} item={{title:'........', description:'...........................'}}/>)
//     }

//     return (
//         <Stack>
//             {items}
//         </Stack>
//     )
// }

// Pagination
const PaginationControls = (props) => {

    // const handleChange = (value) => {
    //     if(!props.onChange) return;
    //     props.onChange(value);
    // }

    // const handleSubmit = (value) => {
    //     if(!props.onSubmit) return;
    //     props.onSubmit(value)
    // }

    return (
        <StyledPaginationControls flex justify="space-between" align="center">
            <HorizontalStack data-horizontal-stack flex align="center">
                <SearchInput label="Search" onChange={props.onChange} value={props.searchText} onSubmit={props.onSubmit} />
                {props.extraControls && props.extraControls()}
            </HorizontalStack>
            <Pagination {...props} />
        </StyledPaginationControls>
    )
}

// Paginated List
export const PaginatedList = ({
    searchText,
    onSearchChange,
    onSearchSubmit,
    variant,
    loading,
    items,
    pages,
    total,
    onClickItem,
    onPaginationClick,
    currentPage,
    noPagination,
    customRenderer,
    noControls,
    paginationControls,
    columns,
}) => {

    const renderItem = (item) => {
        if (customRenderer) return customRenderer(item, onClickItem);
        return (<PaginatedListItem item={item} key={item.id} onClick={onClickItem} />)
    }
    const renderCardItem = (item) => {
        if (customRenderer) return customRenderer(item, onClickItem);
        return (<PaginatedCardItem item={item} key={item.id} onClick={onClickItem} />)
    }

    const renderItems = () => {

        if (loading) return;

        if (variant === 'cards') {
            return (
                <StyledGrid columns={columns}>
                    {items && items.map(item => {



                        return (
                            <StyledGridItem key={item.id}>
                                {renderCardItem(item)}
                            </StyledGridItem>
                        )
                    })}
                </StyledGrid>
            )
        }

        return (
            <Stack width="100%">
                {items && items.map(item => { return renderItem(item) })}
            </Stack>
        )
    }

    const renderControls = () => {
        if (noControls) return (<div></div>)

        return (
            <PaginationControls
                searchText={searchText}
                count={pages}
                currentPage={currentPage}
                onPaginationClick={onPaginationClick}
                onChange={onSearchChange}
                onSubmit={onSearchSubmit}
                extraControls={paginationControls}
            />
        )
    }

    return (

        <Stack thinking={loading}>

            {/* {renderControls()} */}

            {/* {loading && (<SkeletonList total={12} variant={variant} customRenderer={customRenderer} />)} */}

            {renderItems()}

            {renderControls()}

        </Stack>

    )
}