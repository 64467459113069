import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Services
import { uniqueId } from '../../services/Utils'

// Components
import { App } from '../../App';
import { Surface, Stack } from '../../styles/Containers'

import {  StyledH6, StyledP } from '../../styles/StyledHTML';
import { RadioGroup } from '../../components/Forms/RadioGroup';
import { CheckboxGroup } from '../../components/Forms/CheckboxGroup'; 
import { SwitchGroup } from '../../components/Forms/SwitchGroup';

export const RadioLab = () => {

    const [radioSelection, setRadioSelection] = useState('nitro');
    const [checkSelection, setCheckSelection] = useState([]);
    const [switchSelection, setSwitchSelection] = useState([]);

    const radioOptions = [
        {
            label: "Filter",
            value: "filter",
            key: 1
        },
        {
            label: "Espresso",
            value: "espresso",
            key: 2,
        },
        {
            label: "Latte",
            value: "latte",
            disabled: true,
            key: 3

        },
        {
            label: "Nitro",
            value: "nitro",
            key: 4,
            checked: true
        },
    ]

    return (
        <App title="Lab: Radio">
            <Stack>
                <Surface>
                    <Stack>
                        <StyledH6>Radio</StyledH6>
                        <RadioGroup label="Coffer Order" options={radioOptions} value={radioSelection} onChange={setRadioSelection} />
                        <StyledH6>Output from state</StyledH6>
                        <StyledP>{radioSelection}</StyledP>
                    </Stack>
                </Surface>
                <Surface>
                    <Stack>
                        <StyledH6>Checkboxes</StyledH6>
                        <CheckboxGroup label="Coffer Order" options={radioOptions} onChange={setCheckSelection} />
                        <StyledH6>Output from state</StyledH6>
                        {checkSelection.map(item => {
                            if(item.checked) return (<StyledP key={item.key}>{item.label}</StyledP>)
                            else return (<div key={item.key}></div>)
                        })}
                    </Stack>
                </Surface>
                <Surface>
                    <Stack>
                        <StyledH6>Switch Group</StyledH6>
                        <SwitchGroup label="Coffer Order" options={radioOptions} onChange={setSwitchSelection} />
                        <StyledH6>Output from state</StyledH6>
                        {switchSelection.map(item => {
                            if(item.checked) return (<StyledP key={item.key}>{item.label}</StyledP>)
                            else return (<div key={item.key}></div>)
                        })}
                    </Stack>
                </Surface>
            </Stack>
        </App>
    )
}