import { Divider } from "@mui/material";
import React from "react";
import { HorizontalStack, SmartContainer, Stack } from "../../../styles/Containers";
import { StyledB, StyledP } from "../../../styles/StyledHTML";

import { useSummary } from "../UserProgress/redux/userprogress.actions";
import { EmojiEmotions } from "../UserRatings/UserRating";

export const MeditationStats = ({ meditation }) => {

    const { summary } = useSummary(meditation.id);

    return (
        <Stack>
            {summary?.summary?.map(item => (
                <StyledP><StyledB>{item.type}</StyledB> {item.total}</StyledP>
            ))}
            <Divider />
            <HorizontalStack flex>
                {EmojiEmotions.map(option => (
                    <SmartContainer flex align="center" justify="center" direction="column">
                        <StyledP>{option.title}</StyledP>
                        <StyledP light>{option.value}</StyledP>
                    </SmartContainer>
                ))}
            </HorizontalStack>
            <StyledP><StyledB>Average Before Rating</StyledB> {summary?.ratings?.before?.average_rating}</StyledP>
            <StyledP><StyledB>Average After Rating</StyledB> {summary?.ratings?.after?.average_rating}</StyledP>
        </Stack>
    )
}