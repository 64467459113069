import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNetwork } from '../../redux/Network/network.actions';
import { FullNetwork } from '../../redux/Network/network.selectors'
import { openToast } from '../../redux/UI/ui.actions';

export const useSaveIntegration = ({
    open, 
    network = false
    }) => {

    // Dispatcher
    const dispatch = useDispatch();

    // Setup Store
    const store = (network === true) ? FullNetwork() : null; // TODO: Account Store

    // Thinking State
    const thinking = (store.updateNetworkLoading) ? store.updateNetworkLoading : false;

    // Control Form Modal
    const [openForm,setOpenForm] = useState(false);

    // Set Default Form State
    useEffect(() => { setOpenForm(open)},[open])

    // Handle Save Effects
    useEffect(() => {
        if(store.updateNetworkSuccess) {
            setOpenForm(false);
            dispatch(openToast(store.updateNetworkSuccess,'success'))
        }
        if(store.updateNetworkError) dispatch(openToast(store.updateNetworkError,'error'))
    },[store.updateNetworkLoading])

    // Handle Save 
    const saveIntegration = (meta) => {

        // Network Save
        if(network === true) {
            const networkId = store.getFullNetwork.id;
            if(!networkId) return;
            dispatch(updateNetwork(networkId, {meta:meta}));
        } else {
            // TODO: Account Save
        }
    }

    return {
        openForm,
        setOpenForm,
        saveIntegration,
        thinking
    }
}