import { createGlobalStyle, keyframes } from 'styled-components'
import { CSSReset } from './CSSReset'
import { Theme } from './Theme'
import { mimicButtonStyles } from './matieralui/Button'


export const GlobalStyleSheet = createGlobalStyle`
    ${CSSReset}
    body,
    .app-body {
        background-color: ${Theme.colors.base};
        color: ${Theme.colors.baseText};
        font-family: ${Theme.SpaceStyles.fonts.body} !important;
        font-weight: ${Theme.SpaceStyles.fonts.bodyWeight} !important;
        line-height: ${Theme.lineHeight};
        height: 100vh;

    }
    html {
        height: 100vh;
    }
    *[class*="MuiTypography"],
    *[class*="MuiInputBase"]{
        font-family: ${Theme.SpaceStyles.fonts.body} !important;
        font-weight: ${Theme.SpaceStyles.fonts.bodyWeight} !important;
        line-height: ${Theme.lineHeight};
    }


    a {
        color: ${Theme.colors.primary};
        text-decoration: none;
    }
    .button-primary {
        ${mimicButtonStyles}
        color: ${Theme.colors.primaryText};
        background-color: ${Theme.colors.primary};
    }
    .button-outlined {
        ${mimicButtonStyles}
        border: 1px solid;
        padding: 5px 15px;
    }
`

export const SkeletonAnimation = keyframes`
    0% {
        opacity: 0.15
    }
    50% {
        opacity: 0.90
    }
    100% {
        opacity: 0.15
    }
`;

export const SavingAnimation = keyframes`
    0% {
        opacity: 0.15
    }
    50% {
        opacity: 0.70
    }
    100% {
        opacity: 0.15
    }
`;