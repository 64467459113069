import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

// Redux
import { FullAuth } from '../../redux/Auth/auth.selectors';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { attemptSignup } from '../../redux/Auth/auth.actions';
import { useAnalytics } from '../../modules/Analytics/AnalyticsService';
// Styles
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers'
import { StyledH3 } from '../../styles/StyledHTML'
import { StyledButton } from '../../styles/matieralui/Button'

// Components
import { FormInput } from '../Forms/FormInput'
import { PasswordInput } from '../Forms/PasswordInput'
import { FormError } from '../Forms/FormError'
import { Checkbox } from '../Forms/Checkbox'
import { isEmail, isNativeApp } from '../../services/Utils'
import { Recaptcha } from '../Forms/Recaptcha'

export const SignupForm = () => {

    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    // const [companyName, setCompanyName] = useState('');
    const companyName = name;
    const [terms, setTerms] = useState(null);
    const [notRobot, setNotRobot] = useState(false)
    const [marketingOptIn, setMarketingOptIn] = useState(false);
    const currentNetwork = CurrentNetwork();
    const nativeApp = isNativeApp();
    const { trackEvent } = useAnalytics();

    useEffect(() => {
        if (nativeApp == true) setNotRobot(true);
    }, [nativeApp])

    // Validate Email
    const handleEmailChange = (value) => {

        if (isEmail(value)) setEmailError('')
        else setEmailError('Please enter a valid email')

        setEmail(value);
    }


    // Handle Signup
    const handleLogin = (e) => {

        // Track Login Event
        const trackArgs = {
            category: 'User',
            action: 'SIGNUP'
        }
        trackEvent('SIGNUP', trackArgs);

        dispatch(attemptSignup(currentNetwork.id, email, password, name, companyName, terms, marketingOptIn));
    }

    const auth = FullAuth();

    // Redirect logged in users
    if (auth.session) {

        // Track First Promoter
        // if (typeof window.$FPROM !== "undefined") window.$FPROM.trackSignup({ email: email, uid: email }, function () { });

        // Track First Promoter 2021
        if (typeof window.fpr !== "undefined") window.fpr("referral", { email: email });

        // Track Trial Starting.
        const trackArgs = {
            category: 'User',
            action: 'StartTrial',
            facebook: {
                value: '0.00',
                currency: 'USD', // TODO: replace with data form db
                predicted_ltv: '0.00'
            }
        }
        trackEvent('StartTrial', trackArgs);

        return (<Redirect to="/onboarding" />);

    }


    // Enable Submit
    let disableSubmit = (!notRobot || !email || !password || !name || !companyName || !isEmail(email) || !terms); // Required


    // Login Errors
    let hasError = (auth?.error) ? auth.error : false;
    if (hasError && hasError === 'Protected resource, please login.') hasError = false;

    const keyPress = (e) => {
        // On Enter
        if (e.keyCode == 13) handleLogin();
    }



    return (
        <SmartContainer thinking={auth.loading} minWidth="300px">
            <Stack>
                {/* <StyledH3>Sign up</StyledH3> */}
                {hasError && (<FormError error={hasError} />)}
                <FormInput onKeyDown={keyPress} label="Name" type="text" onChange={setName} value={name} />
                <FormInput onKeyDown={keyPress} label="Email" type="email" onChange={handleEmailChange} value={email} helperText={emailError} error={(emailError)} />
                <PasswordInput onKeyDown={keyPress} label="Password" type="password" onChange={setPassword} value={password} />
                {/* <FormInput onKeyDown={keyPress} label="Company Name" type="text" onChange={setCompanyName} value={companyName} /> */}
                <SmartContainer><Checkbox label="I'd like to receive updates from time to time. " value={marketingOptIn} onChange={setMarketingOptIn} color="primary" /></SmartContainer>
                <SmartContainer><Checkbox label="I agree to the terms and conditions" value={terms} onChange={setTerms} color="primary" /></SmartContainer>
                {!nativeApp && (<Recaptcha onSuccess={(token) => setNotRobot(token)} />)}
                <StyledButton width="100%" onClick={handleLogin} variant="contained" color="primary" disabled={disableSubmit}>Sign Up</StyledButton>
                <HorizontalStack textAlign="center">
                    <Link to="/login">Login</Link>
                    <Link to="/recover-password">Reset password</Link>
                </HorizontalStack>
            </Stack>
        </SmartContainer >
    )
}
