import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { PaginatedList } from '../PaginatedList/PaginatedList'

// Styled
import { SmartContainer } from '../../styles/Containers'


// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { getNetworkAccounts } from '../../redux/Network/network.actions';



export const NetworkAccountsList = ({onSelect}) => {

    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState('');

    // --------------------------
    // Effects
    // --------------------------

    // On Load: Load posts
    useEffect(() => { loadItems(page,searchText) },[])

    // Load Post With Pagination and Search
    const loadItems = (pageNumber, searchString) => dispatch(getNetworkAccounts(pageNumber,limit, searchText))

    // --------------------------
    // Interactions
    // --------------------------

    // Handle Network Click
    const handleNetworkClick = (network) => onSelect(network)

    // Handle Pagination Click
    const handlePaginationClick = (pageNumber) => {
        setPage(pageNumber)
        loadItems(pageNumber, searchText)
    }

    // Handle Search
    const handleSearch = (searchString) => {
        setPage(1)
        loadItems(1, searchText)
    }

    // --------------------------
    // Pagination Formating
    // --------------------------

    const formattedNetworks = (fullNetwork.networkAccounts) ? fullNetwork.networkAccounts.items.map(network => { return {id: network.id, title: network.name, description: network.plan }}) : []
    const total = (fullNetwork.networkAccounts) ? fullNetwork?.networkAccounts?.count : 0;
    const pageCount = Math.ceil(total/limit);   

    return (
        <SmartContainer>
            
            <PaginatedList 
                onClickItem={handleNetworkClick}
                loading={fullNetwork?.networkAccountsLoading} 
                items={formattedNetworks}
                total={total}
                pages={pageCount}
                currentPage={page}
                onPaginationClick={handlePaginationClick}
                searchText={searchText}
                onSearchChange={(value) => setSearchText(value)}
                onSearchSubmit={handleSearch}
                />


        </SmartContainer>
    )
}