import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { SmartContainer } from '../../styles/Containers'
import { StyledA, StyledP } from '../../styles/StyledHTML';
import { StyledAreYouSure } from '../../styles/matieralui/Dialog'
import { openToast } from '../../redux/UI/ui.actions';
export const Duplicate = ({id, action, selector, route}) => {

    const dispatch = useDispatch();
    const state = selector();

    const duplicate = () => {
        // alert(id)
        dispatch(action(id));
    }
    
    // Delete Item
    useEffect(() => {
        if(state.duplicateSuccess) {
            
            // Display Success
            dispatch(openToast(state.duplicateSuccess,'success'));

            // Redirect to List
            dispatch(push(`${route}/${state?.duplicate?.id}`))
        }
        if(state.deleteError) dispatch(openToast(state.deleteError,'error'))
    },[state.duplicateLoading, state.duplicateError,state.duplicateSuccess,dispatch,route])

    return (
        <SmartContainer>
            {id && (<StyledP><StyledA light small onClick={duplicate}>duplicate</StyledA></StyledP>)}
        </SmartContainer>
    )
}