import React, { useState } from 'react';
import { StyledTextField, StyledIconInput } from '../../styles/matieralui/Form'
import { StyledIconButton } from '../../styles/matieralui/IconButton'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export const PasswordInput = (props) => {

    const [show, setShow] = useState(false);
    const renderIcon = () => (!show) ? <VisibilityIcon /> : <VisibilityOffIcon />
    const newProps = {...props}
    newProps.type = (show) ? 'text' : 'password'

    return (
        <StyledIconInput>
            <StyledTextField {...newProps} onChange={(e) => newProps.onChange(e.target.value)} variant="filled" />
            <StyledIconButton onClick={() => setShow(!show)}>{renderIcon()}</StyledIconButton>
        </StyledIconInput>
    )
}