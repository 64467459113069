import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { NewContent } from '../../components/Content/NewContent';

// Styles
import { SmartContainer } from '../../../styles/Containers'
import { MeditationsList } from './MeditationsList';

// Redux
import { restoreMeditation, createMeditation } from './redux/meditations.actions';
import { FullMeditations } from './redux/meditations.selectors';
import { RouteBreadCrumbs } from '../../../routes';


export const PageNetworkMeditations = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullMeditations();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => { if (store.createSuccess) dispatch(push(`/network/meditations/${store.create.id}`)) }, [store?.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {

        if (item?.full?.deletedAt) dispatch(restoreMeditation(item.id))
        else dispatch(push(`/network/meditations/${item.id}`))
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <Link to="/network/tags">Tags</Link>
                <NewContent label="New Meditation" selector={FullMeditations} createAction={createMeditation} />
            </SmartContainer>
        )

    }

    // Render Page
    return (
        <App title="Meditations" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            <MeditationsList onSelect={handleSelect} />

        </App>
    )
}