import { APIGet, APIPut, APIPost, APIDelete, APIBase } from '../services/API';
import { dispatchLoading, dispatchError, dispatchSuccess, dispatchClearState } from './utils'
import { openToast } from './UI/ui.actions';

export const searchModels = (model, path, page, limit, search, view, args = {}) => dispatch => {

    // Prefix is what writes to Stat
    let prefix = 'items';
    if (args.prefix) {
        prefix = args.prefix;
        delete args.prefix;
    }

    const action = `[${model.toUpperCase()}] Search ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Build Querysting
    let qs = [];
    if (page) qs.push(`page=${page}`);
    if (limit) qs.push(`limit=${limit}`);
    if (view && view != 'all') qs.push(`view=${view}`)
    if (search) qs.push(`search=${search}`);
    if (args) Object.keys(args).map(key => qs.push(`${key}=${args[key]}`))

    const queryString = qs.join('&');

    // Make Request
    APIGet(`${APIBase}${path}?${queryString}`)
        .then(res => res.json())
        .then(json => {

            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else dispatch(dispatchSuccess(action, prefix, null, json.data));
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}

export const createModel = (model, path, args) => dispatch => {

    const prefix = 'create';
    const action = `[${model.toUpperCase()}] Create ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPost(`${APIBase}${path}`, args)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {

                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));

                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}

export const updateModel = (model, path, id, args) => dispatch => {

    const prefix = 'update';
    const action = `[${model.toUpperCase()}] Update ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPut(`${APIBase}${path}/${id}`, args)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}


export const deleteModel = (model, path, id) => dispatch => {

    const prefix = 'delete';
    const action = `[${model.toUpperCase()}] Delete ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIDelete(`${APIBase}${path}/${id}`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}

export const restoreModel = (model, path, id) => dispatch => {

    const prefix = 'restore';
    const action = `[${model.toUpperCase()}] Restore ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPost(`${APIBase}${path}/${id}/restore`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}

export const getModel = (model, path, id, args = {}) => dispatch => {

    let prefix = 'activeItem';
    if (args.prefix) {
        prefix = args.prefix;
        delete args.prefix;
    }

    const action = `[${model.toUpperCase()}] Get ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIGet(`${APIBase}${path}/${id}`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}

export const replaceTags = (model, path, terms, contentType, contentId) => dispatch => {

    const prefix = 'replaceTags';
    const action = `[${model.toUpperCase()}] Replace Tags ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    const args = {
        modelType: contentType,
        modelId: contentId,
        terms
    }

    // Make Request
    APIPost(`${APIBase}${path}/tag/replace`, args)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}

export const forceDeleteModel = (model, path, id) => dispatch => {

    const prefix = 'forceDelete';
    const action = `[${model.toUpperCase()}] Force Delete ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIDelete(`${APIBase}${path}/${id}/force`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}

export const getRevisions = (model, path, id, args = {}) => dispatch => {

    // Prefix is what writes to Stat
    let prefix = 'revisions';
    if (args.prefix) {
        prefix = args.prefix;
        delete args.prefix;
    }

    const action = `[${model.toUpperCase()}] Get Revisions ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIGet(`${APIBase}${path}/${id}/revisions`)
        .then(res => res.json())
        .then(json => {

            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else dispatch(dispatchSuccess(action, prefix, null, json.data));
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}


export const duplicateModel = (model, path, id, args) => dispatch => {

    const prefix = 'duplicate';
    const action = `[${model.toUpperCase()}] Duplicate ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPost(`${APIBase}${path}/${id}/duplicate`, args)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {

                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));

                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}