import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../../redux/UI/ui.actions';

// Redux
import { FullAccount } from '../../redux/Account/account.selectors';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { createAccount } from '../../redux/Account/account.actions';
import { switchAccount } from '../../redux/Auth/auth.actions'
import { Me } from '../../redux/Auth/auth.selectors';

// Styles
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'


// Components
import { FormInput } from '../Forms/FormInput'
import { FormError } from '../Forms/FormError'

export const NewAccountForm = () => {

    const dispatch = useDispatch();
    const [accountName,setAccountName] = useState('');
    const disableSubmit = (!accountName);
    const fullAccount = FullAccount();
    const me = Me();
    const currentNetwork = CurrentNetwork();

    // Toast
    useEffect(() => {
        if(fullAccount.createAccountSuccess){
            dispatch(openToast(fullAccount.createAccountSuccess,'success'));

            // Switch To The New Account
            dispatch(switchAccount(fullAccount.createAccount.userAccount.AccountId));
 
        }
        if(fullAccount.createAccountError) {
            dispatch(openToast(fullAccount.createAccountError,'error'))
        }
    },[fullAccount.createAccountLoading, fullAccount.createAccountSuccess, fullAccount.createAccountError, fullAccount.createAccount.userAccount.AccountId, dispatch])


    // Handle Save
    const handleSubmit = () => {
        if(!accountName) return;
        dispatch(createAccount(currentNetwork.id, me.id, {name: accountName})) 
    }   

    // Login Errors
    const hasError = (fullAccount?.accountError) ? fullAccount.accountError : false;


    return (
        <SmartContainer minWidth="300px" thinking={fullAccount.loading}>
            <Stack>
                {hasError && (<FormError error={hasError} />)}
                <FormInput label="Account Name" type="text" onChange={setAccountName} value={accountName} />
                <StyledButton onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>Create Account</StyledButton>
            </Stack>
        </SmartContainer>
    )
}