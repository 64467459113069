import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Components
import { FormInput } from '../../components/Forms/FormInput';

// Redux
import { FullNetworkProducts } from './redux/networkproduct.selectors';
import { createNetworkProduct } from './redux/networkproduct.actions';
import { openToast } from '../../redux/UI/ui.actions';

// ---------------------------
// New Example Content Form
// ---------------------------

export const NewNetworkProductForm = () => {

    const dispatch = useDispatch();
    const fullNetworkProducts = FullNetworkProducts();

    // Name
    const [name,setName] = useState('');
    const [nameError,setNameError] = useState(false);
    const [nameHelperText,setNameHelperText] = useState('The title of the new Example Content.')

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    useEffect(() => {
        if(fullNetworkProducts.createSuccess) dispatch(openToast(fullNetworkProducts.createSuccess,'success'))
        if(fullNetworkProducts.createError) dispatch(openToast(fullNetworkProducts.createError,'error'))
    },[fullNetworkProducts.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (e) => dispatch(createNetworkProduct({name}));
        
    // --------------------------
    // Render Form
    // --------------------------

    return (
        <SmartContainer thinking={fullNetworkProducts?.createLoading} minWidth="300px">
            <Stack>
                <FormInput label="Name" type="text" onChange={setName} value={name} helperText={nameHelperText} error={nameError} />
                <StyledButton onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>Save</StyledButton>
            </Stack>
        </SmartContainer>
    )
}