import React from 'react';
import styled, { css } from 'styled-components';
import { Stack } from '../../styles/Containers'
import { FullUI } from '../../redux/UI/ui.selectors';
import { setSpace, respondTo } from '../../styles/Theme'
import { useAnimatedPageMount } from '../../space/services/SpaceUtils';
import { animated } from 'react-spring';
const StyledMainBody = styled(Stack)`
    /* padding-left: ${setSpace(4)}; */
    /* ${props => (!props.open) ? css` padding-left:  ${setSpace(23)};` : ` padding-left: ${setSpace(60)};`}; */
    /* ${respondTo('mobile', css`
        padding-left: ${setSpace(4)} !important;
    `)}
    */
    // SPACE:
    padding-bottom: 8em;
    
    ${props => (props?.noNav === true) ? css` padding-top:  1em;` : ` padding-top: 4em;`};
    ${props => (props?.padding === 0) ? css` padding-top:  0em; ` : ``};
`


export const MainBody = ({ children, noNav, padding = 4 }) => {

    const ui = FullUI();
    const open = (!ui.drawer) ? false : ui.drawer.open
    const { animatedProps } = useAnimatedPageMount();

    return (

        <StyledMainBody noNav={noNav} width="100%" padding={padding} open={open}>
            <animated.div style={animatedProps}>{children}</animated.div>
        </StyledMainBody>

    )
}