import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";

// Styles
import { SmartContainer, Stack } from "../../../styles/Containers";

// Redux
import { StyledH1, StyledH3, StyledP } from "../../../styles/StyledHTML";
import { Screen } from "../../components/Display/Screen";
import { useInterval } from "../../services/SpaceUtils";
import { FullBreathing } from "./redux/breathing.selectors";
import { getBreathing } from "./redux/breathing.actions";
import { SpaceIconStop } from "../../styles/Icons";
import { AudioPlayer } from '../../../components/AudioPlayer/AudioPlayer';

const StyledOrbiter = styled(SmartContainer)`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
const StyledBreathTool = styled(SmartContainer)`
  position: relative;
`;
const StyledBreathText = styled(SmartContainer)`
  position: relative;
  z-index: 2;
`;


const StyledSolidCircle = styled(SmartContainer)`
  height: 180px;
  width: 180px;
  border: 5px solid #fbbf9b;
  border-radius: 120px;
  background-color: #fbbf9b;
  position: absolute;
  margin-left: 58px;
  margin-top: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBreatheText = styled(StyledP)`
  color: white;
  /* margin-top: 68px; */
  text-shadow: 0px 0px 20px rgba(0,0,0,0.5);
`;

const StyledPulsingCircle = styled(SmartContainer)`
  height: 240px;
  width: 240px;
  border: 5px solid #fbbf9b;
  border-radius: 120px;
  background-color: #fbbf9b;
  animation: pulse 20s ease-out infinite;
  opacity: 0;
  position: absolute;
  top: 25px;
  left: 28px;
`;

const StyledOuterOrbit = styled(SmartContainer)`
  position: absolute;
  // top: 50;
  // left: 50;
  width: 294px;
  height: 294px;
  border-radius: 100%;
  -webkit-animation: spin-right 20s linear infinite;
  animation: spin-right 20s linear infinite;
`;

const StyledOuterOrbitCircles = styled(SmartContainer)`
  position: absolute;
  top: -13px;
  left: 125px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #f2e0c0;
  opacity: 0.5;
`;

const StyledOuterOrbitCircle1 = styled(SmartContainer)`
  width: 294px;
  height: 294px;
  border: 2px #f2e0c0 solid;
  border-radius: 100%;
  position: relative;
  opacity: 0.5;
  /* &:before {
    display: block;
    content: " ";
    width: 30px;
    height: 30px;
    background-color: #f2e0c0;
    border-radius: 50%;
    border: 1px solid #f2e0c0;
    position: relative;
    left: 130px;
    top: -15px;
  }
  &:after {
    display: block;
    content: " ";
    width: 30px;
    height: 30px;
    background-color: #f2e0c0;
    border-radius: 50%;
    border: 1px solid #f2e0c0;
    position: relative;
    right: -260px;
    bottom: -155px;
  } */
`;

const StyledDot = styled(SmartContainer)`
    width: 30px;
    height: 30px;
    background-color: #f2e0c0;
    border-radius: 50%;
    border: 1px solid #f2e0c0;
    position: relative;
    ${props => (props) ? css`
      top: ${props.top};
      left: ${props.left};
    `: ''}
`


const StyledStopButton = styled(SmartContainer)`
  position: relative;
  z-index: 2;
  background-color: #f2e0c0;
  width: 50px;
  height: 50px;
  margin: auto;
  border: 5px solid #fbbf9b;
  border-radius: 120px;
  display: flex;
  align-items: center;
`;

const StyledAudioPlayer = styled(AudioPlayer)`
  
.controls {
  display: none;
}
`


export const PageBreathingStart = (props) => {
  const screenAlign = "flex-start";
  const store = FullBreathing();
  const dispatch = useDispatch();
  const id = props.exercise;
  const [breatheAction, setBreatheAction] = useState(0);
  const [totalTime, setTotalTime] = useState(props?.time ? props.time : 2);
  const [timeRemaining, setTimeRemaining] = useState(totalTime * 60000);
  const [breathein, setBreatheIn] = useState("Breathe In");
  const [breatheout, setBreatheOut] = useState("Breathe Out");
  const [activeItem, setActiveItem] = useState(null);
  const [textIndex, setTextIndex] = useState(0);
  const [sec, setSec] = useState(0);
  const [controlAudio, setControlAudio] = useState(null);
  const audio = "https://assets.spaceculture.co/networks/1/Be%20Still%20the%20Earth%20-%20In%20the%20Clouds.mp3";

  const [currentAction, setCurrentAction] = useState('ready');
  const [displayText, setDisplayText] = useState('ready');

  const handleTick = (reset = false) => {
    if (reset) setSec(0);
    else setSec(sec + 1);

    // Reduce Time
    setTimeRemaining(timeRemaining - 1000)
  }

  useEffect(() => {

    if (sec === 0) {
      if (activeItem?.meta?.breathText?.length > (textIndex + 1)) setTextIndex(textIndex + 1);
      else setTextIndex(0);

      setCurrentAction('in');

    }
    else if (sec === 5) setCurrentAction('hold');
    else if (sec === 12) setCurrentAction('out');
    else if (sec === 20) handleTick(true);
  }, [sec])


  useEffect(() => {
    const text = (activeItem?.meta?.breathText[textIndex][currentAction]) ? activeItem?.meta?.breathText[textIndex][currentAction] : 'hold';
    setDisplayText(text)
  }, [currentAction, textIndex])

  // Every Seconds
  useInterval(() => { handleTick(); }, 1000);

  // Set Active Iteam
  useEffect(() => {
    if (!id) return;
    if (!store?.items) return;
    const i = store?.items?.rows?.filter(x => x?.id === id);
    if (!i) return;
    setActiveItem(i[0])
  }, [id]);

  useEffect(() => {
    if (!activeItem) return;
    handleTick(true)
    setControlAudio('play')
  }, [activeItem])



  useEffect(() => {
    if (timeRemaining < 0) props.screen("complete");
    // initialStart();
    // alternateAction();
  }, [timeRemaining, totalTime]);

  // Get This Item
  // useEffect(() => {
  //   if (props.exercise) dispatch(getBreathing(id));
  // }, []);

  // Set Active Item
  useEffect(() => {
    if (store?.activeItem) setActiveItem(store.activeItem);
  }, [store?.activeItem, activeItem]);

  // Stop Button
  const stopBreathing = () => {
    props.screen("select");
  };

  // Render Page
  return (
    <SmartContainer flex align="center" justify="space-between" direction="column" height="100vh">
      <p>&nbsp;</p>
      <StyledBreathTool width="100%">
        <StyledOrbiter>
          <StyledPulsingCircle />
          <StyledSolidCircle>
            <StyledBreatheText>{displayText}</StyledBreatheText>
          </StyledSolidCircle>
          <StyledOuterOrbit>
            <StyledOuterOrbitCircles />
          </StyledOuterOrbit>
          <StyledOuterOrbitCircle1>
            <StyledDot left="130px" top="-15px" />
            <StyledDot left="275px" top="96px" />
            <StyledDot left="41px" top="185px" />
          </StyledOuterOrbitCircle1>
        </StyledOrbiter>
      </StyledBreathTool>
      <SmartContainer>
        <Stack>
          <StyledBreathText>
            {/* <StyledH1>{activeItem?.name}</StyledH1>
            <StyledP>{activeItem?.content}</StyledP> */}

            <StyledStopButton onClick={() => stopBreathing()}>
              <SpaceIconStop
                style={{
                  color: "white",
                  marginLeft: "5px",
                  fontSize: "30px",
                }}
              />
            </StyledStopButton>
          </StyledBreathText>
          <StyledAudioPlayer src={audio} controlEvent={controlAudio} />
        </Stack>
      </SmartContainer>
      <SmartContainer>&nbsp;</SmartContainer>
    </SmartContainer>
  );
};
