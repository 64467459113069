import React, { useState } from 'react';
import { SmartContainer, Stack } from '../../styles/Containers';
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { StyledIconButton } from '../../styles/matieralui/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const Collapse = ({open = true, title, children}) => {
    const [openState, setOpenState] = useState(open);
    return (
        <SmartContainer width="100%" >
            <SmartContainer flex justify="space-between" align="center" clickable onClick={() => setOpenState(!openState)}>
                <StyledP><StyledB>{title}</StyledB></StyledP>
                <StyledIconButton onClick={() => setOpenState(!openState)}>
                    {openState && (<ExpandLessIcon />)}
                    {!openState && (<ExpandMoreIcon />)}
                </StyledIconButton>
            </SmartContainer>
            {openState && (<SmartContainer width="100%">{children}</SmartContainer>)}
        </SmartContainer>
    )
    
}