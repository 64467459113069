import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from '../../components/Network/NetworkApp';
import { ModelList } from '../../components/PaginatedList/ModelList';
import { NewTagForm } from '../../components/Taxonomy/NewTagForm';

// Styles
import { StyledModal } from '../../styles/matieralui/Modal'
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Redux
import { searchNetworkMediaTags, restoreNetworkMediaTag, createNetworkMediaTag } from './redux/media.actions';
import { FullMediaTags } from './redux/media.selectors';
import { RouteBreadCrumbs } from '../../routes';

export const NetworkMediaTagsPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [view,setView] = useState('all');
    const store = FullMediaTags();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {
        if(store.createSuccess) {
            // Close The Modal
            setOpenNewModal(false);

            // Redirect to edit page
            dispatch(push(`/network/media/tags/${store.create.id}`))
        }
    },[store.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {
        dispatch(push(`/network/media/tags/${item.id}`))
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <StyledButton variant="contained" color="primary" onClick={() => setOpenNewModal(true)}>New Tag</StyledButton>
            </SmartContainer>
        )
        
    }

    // Render Page
    return (
        <NetworkApp title="Media Tag" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network, RouteBreadCrumbs.networkMedia]}>
            
            <ModelList 
                model="MediaTag" 
                action={searchNetworkMediaTags} 
                restore={restoreNetworkMediaTag}
                selector={FullMediaTags} 
                onSelect={handleSelect} />
            
            <StyledModal
                open={openNewModal}
                onClose={() => setOpenNewModal(false)}
                label="New Tag"
                description="Create new tag"
                >
                    <Stack>
                        <NewTagForm selector={FullMediaTags} createAction={createNetworkMediaTag} />
                    </Stack>
                    
            </StyledModal>
        </NetworkApp>
    )
}