import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from '../../../components/Network/NetworkApp';
import { NoAccessPage } from '../../../pages/Account/NoAccessPage'
import { SurfaceMessage } from '../../../components/Alerts/SurfaceMessage';


// Styled
import { LargeStack, SmartContainer, Stack, StyledGrid, StyledGridItem, Surface } from '../../../styles/Containers'
import { StyledB, StyledP } from '../../../styles/StyledHTML';

// Redux
import { FullNetwork } from '../../../redux/Network/network.selectors';
import { getFullNetwork, updateNetwork } from '../../../redux/Network/network.actions'
import { hasNetworkAccess } from '../../../redux/Auth/auth.actions'
import { FullAuth } from '../../../redux/Auth/auth.selectors';
import { APIBase } from '../../../services/API';
import { RouteBreadCrumbs } from '../../../routes';
import { StyledButton } from '../../../styles/matieralui/Button';
import { FormElement } from '../../components/Forms/FormElement';
import { FormInput } from '../../../components/Forms/FormInput';
import { UniversalVideoPlayer } from '../../../components/VideoPlayer/UniversalVideoPlayer';
import { Checkbox } from '../../../components/Forms/Checkbox';

export const SpaceNetworkPage = () => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [meta, setMeta] = useState({});
    const fullNetwork = FullNetwork();
    const fullAuth = FullAuth();
    const { id, app } = useParams();
    const networkId = parseInt(id);

    const goTo = (path) => dispatch(push(path))


    // Onboarding Video
    const [onboardingVideo, setOnboardingVideo] = useState(null);
    const [onboardingVideoPortrait, setOnboardingVideoPortrait] = useState(false);

    const [onboardingTitle, setOnboardingTitle] = useState('Welcome To Space');
    const [onboardingText, setOnboardingText] = useState('Making Space for God is the most important thing we can do.');

    // --------------------------
    // Effects
    // --------------------------

    // Close Modal on Success
    useEffect(() => { dispatch(getFullNetwork(networkId)) }, [])

    // On Network Success
    useEffect(() => {

        // Set Full Meta
        setMeta(fullNetwork.getFullNetwork?.meta);

        // Onboarding Video
        if (fullNetwork.getFullNetwork?.meta?.onboardingVideo) setOnboardingVideo(fullNetwork.getFullNetwork?.meta.onboardingVideo);
        else setOnboardingVideo('');
        if (fullNetwork.getFullNetwork?.meta?.onboardingVideoPortrait) setOnboardingVideoPortrait(fullNetwork.getFullNetwork?.meta.onboardingVideoPortrait);
        else setOnboardingVideoPortrait(false);

        if (fullNetwork.getFullNetwork?.meta?.onboardingTitle) setOnboardingTitle(fullNetwork.getFullNetwork?.meta.onboardingTitle);
        else setOnboardingTitle('Welcome To Space');

        if (fullNetwork.getFullNetwork?.meta?.onboardingText) setOnboardingText(fullNetwork.getFullNetwork?.meta.onboardingText);
        else setOnboardingText('Making Space for God is the most important thing we can do.');


    }, [fullNetwork.getFullNetwork])

    const handleSave = () => {

        if (!networkId) return;

        // Save Full Meta
        const metaCopy = { ...meta };

        // Override These Values
        metaCopy.onboardingVideo = onboardingVideo;
        metaCopy.onboardingVideoPortrait = onboardingVideoPortrait;
        metaCopy.onboardingTitle = onboardingTitle;
        metaCopy.onboardingText = onboardingText;

        dispatch(updateNetwork(networkId, { meta: metaCopy }));
    }

    // --------------------------
    // Permissions
    // --------------------------

    const hasAccess = hasNetworkAccess(fullAuth.me, networkId)
    if (!hasAccess) return (<NoAccessPage />)

    // --------------------------
    // Interactions
    // --------------------------

    const thisNetwork = (fullNetwork.getFullNetwork) ? fullNetwork.getFullNetwork : null;

    // --------------------------
    // Renders
    // --------------------------

    return (
        <NetworkApp title="Network Integrations" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            {!thisNetwork && (<SurfaceMessage title="Network Does Not Exist" />)}

            {thisNetwork && (
                <Stack>
                    <SmartContainer flex justify="flex-end">
                        <StyledButton onClick={handleSave} color="primary" variant="contained">Save</StyledButton>
                    </SmartContainer>


                    <Surface>
                        <LargeStack>
                            <FormElement label="Onboarding Title" description="The first words they will see.">
                                <Stack>
                                    <FormInput label="Onboarding Title" onChange={setOnboardingTitle} value={onboardingTitle} />
                                </Stack>
                            </FormElement>
                            <FormElement label="Onboarding Text" description="The first intro text.">
                                <Stack>
                                    <FormInput label="Onboarding Text" multiline onChange={setOnboardingText} value={onboardingText} />
                                </Stack>
                            </FormElement>
                            <FormElement label="Onboarding Video" description="This is the video people see right when they sign up.">
                                <Stack>
                                    <FormInput label="Onboarding Video" onChange={setOnboardingVideo} value={onboardingVideo} />
                                    <Checkbox label="Portrait Video" value={onboardingVideoPortrait} onChange={(value) => setOnboardingVideoPortrait(value)} color="primary" />
                                    {onboardingVideo && (<UniversalVideoPlayer portrait={onboardingVideoPortrait} radius={true} url={onboardingVideo} controls={true} />)}
                                </Stack>
                            </FormElement>
                        </LargeStack>
                    </Surface>
                </Stack>
            )}

        </NetworkApp>
    )
}