import { duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getModelBySlug, getRevisions } from '../../../../redux/DefaultModelActions';

const model = 'UserNote';
const route = '/v1/note';

export const searchUserNotes = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createUserNote = (args) => createModel(model, route, args);
export const updateUserNote = (id, args) => updateModel(model, route, id, args);
export const deleteUserNote = (id) => deleteModel(model, route, id);
export const restoreUserNote = (id) => restoreModel(model, route, id);
export const getUserNote = (id) => getModel(model, route, id);
export const getUserNoteRevision = (id) => getRevisions(model, route, id);
// export const duplicateSeries = (id) => duplicateModel(model, route, id);