import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components 
import { Autocomplete } from '../Forms/Autocomplete';
import { NetworkRoleSelect } from './NetworkRoleSelect';
import { SurfaceMessage } from '../Alerts/SurfaceMessage'

// Styled
import { Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { getNetworkUsers, newNetworkAdmin } from '../../redux/Network/network.actions'
import { NetworkRole } from '../../redux/Auth/auth.selectors'
import { StyledP } from '../../styles/StyledHTML';

export const NewNetworkAdminForm = ({networkId}) => {

    const dispatch = useDispatch();
    const [userEmail, setUserEmail] = useState(null);
    const [role, setRole] = useState('networkadmin');
    const [users, setUsers] = useState([]);
    const fullNetwork = FullNetwork();
    const currentUserNetworkRole = NetworkRole(networkId);

    // --------------------------------
    // Effects
    // --------------------------------

    useEffect(() => {
        if(fullNetwork?.networkUsers?.items){

            const formatNetworkUsers = fullNetwork?.networkUsers?.items.map(u => {
                const formattedUser = {...u};
                console.log(formattedUser,"formattedUser")
                formattedUser.readableString = u.email + ' | Network: ' + u.networkId;
                return formattedUser;
            })


            setUsers(formatNetworkUsers)
        }
    },[fullNetwork.networkUsers])

    // --------------------------------
    // Interactions
    // --------------------------------

    const onSearch = (searchString) => dispatch(getNetworkUsers(1,1000, searchString))

    const onSubmit = () => dispatch(newNetworkAdmin(fullNetwork.network.id, userEmail.id, role));

    const disableSubmit = (!userEmail || !role) ? true : false;
    
    // Must be part of a network
    if(!networkId) return (<SurfaceMessage title="Access Denied" />)

    return (
        <Stack thinking={fullNetwork.newAdminLoading}>

            <StyledP>If we aren't adding a superadmin, then this should be a user with the same Network Id as you see in the URL. If you don't see one in the url, add the user first then add them here.</StyledP>
            
            <Autocomplete
                label="Search email" 
                onSearch={onSearch}
                options={users}
                onChange={setUserEmail}
                value={userEmail}
                optionLabelKey="readableString"
                type="email" />

            <NetworkRoleSelect networkId={networkId} value={role} onChange={(value) => setRole(value)} />

            <StyledButton disabled={disableSubmit} onClick={onSubmit} variant="contained" color="primary">Save New Admin</StyledButton>
            
        </Stack>
    )
}