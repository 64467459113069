import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { StyledBottomNavigation, StyledBottomNavigationAction } from '../../../styles/matieralui/BottomNavigation';
import { SpaceIconHome, SpaceIconNote, SpaceIconSeries, SpaceIconAccount, SpaceIconArrowBack } from '../../styles/Icons';
import { useHistory, useLocation } from 'react-router-dom'
import { SmartContainer } from '../../../styles/Containers';
import { Theme } from '../../../styles/Theme';
import logo from '../../assets/logo-black-short.png';
import { StyledImg, StyledP } from '../../../styles/StyledHTML';
import { StyledIconButton } from '../../../styles/matieralui/IconButton';
import { InstallApp } from './InstallApp';
import { isAndroid, isNativeApp } from '../../../services/Utils';
import { StyledButton } from '../../../styles/matieralui/Button';
import { useAnimatedBackButton } from '../../services/SpaceUtils';
import { animated } from 'react-spring';

const StyledTopNavigation = styled(SmartContainer)`
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    position: fixed;
    z-index: 999;
    background-color: ${Theme.colors.base} !important;
    /* box-shadow: ${Theme.shadow.three}; */
    border-bottom: 1px solid ${Theme.colors.baseAlternate} !important;
    transition: all 500ms;
    padding: 1em;
    
    padding-left: 1em;
    
    ${props => (props.hide === true) ? css`opacity: 0; pointer-events: none;` : ''}
    // Native App SafeArea
    ${props => (props.SafeArea) ? css`padding-top: 1.5em;` : ''}
    ${props => (props?.isAndroid) ? css`padding-top: 0.5em;` : ''}
`

const StyledStackNavButton = styled(StyledButton)`

    color: ${Theme.colors.iosBlue} !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: -4px !important;
`

export const SpaceTopNav = ({ hide = false, stackNav, back, backLabel }) => {

    const dispatch = useDispatch();
    const goTo = (path) => dispatch(push(path));
    const nativeApp = isNativeApp();
    const android = isAndroid();

    const history = useHistory();
    const { animatedProps } = useAnimatedBackButton();

    const goBack = (path) => {
        // if (path !== 'back') goTo(path);
        // else {
        // Use the react router to go back one level
        history.goBack();
        // }
    }

    return (
        <StyledTopNavigation hide={hide} flex align="center" justify="space-between" SafeArea={nativeApp} isAndroid={android}>
            <SmartContainer flex align="center" justify="center" clickable>
                {back && (

                    <StyledStackNavButton onClick={() => goBack(back)} startIcon={<SpaceIconArrowBack />}>
                        <animated.div style={animatedProps}>{backLabel}</animated.div>
                    </StyledStackNavButton>
                )}
                {!back && (<SmartContainer flex align="center" maxWidth="120px" clickable onClick={() => goTo('/')}><StyledImg alt="Space Logo" src={logo} /></SmartContainer>)}



                {/* <InstallApp /> */}
            </SmartContainer>
        </StyledTopNavigation >
    )
}