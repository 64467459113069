import React, { useState, useEffect } from 'react';
import { CompositeDecorator, RichUtils, EditorState } from 'draft-js';
import { HorizontalStack, SmartContainer, Stack } from '../../../styles/Containers';
import { FormInput } from '../FormInput';
import { StyledIconButton } from '../../../styles/matieralui/IconButton'
import LinkIcon from '@mui/icons-material/Link';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { StyledButton } from '../../../styles/matieralui/Button';
import { FormSelect } from '../FormSelect';

// --------------------
// Draft Link
// --------------------

export const DraftLink = ({entityKey, contentState, children}) => {
    const data = contentState.getEntity(entityKey).getData();
    const c = (data?.className) ? data.className : '';
    return (
        <a href={data.url} target={data.target} className={c}>{children}</a>
    )
}

// --------------------
// Find Link
// --------------------

const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK"
        );
    }, callback);
};

// --------------------
// Decorator
// --------------------

export const createLinkDecorator = () => new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: DraftLink,
    },
]);


// --------------------
// Link Tigger
// --------------------

export const DraftLinkTrigger = ({open=false, selected = false, toggleEditor, editorState, onChange}) => {
    const [value,setValue] = useState({});

    // Open Editor
    const openLinkEditor = () => {
        
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            let target = '';
            let className = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
                target = linkInstance.getData().target;
                className = linkInstance.getData().className
            }

            setValue({url, target, className});
            toggleEditor(true)

            
        }
    }

    // Confirm Link
    const confirmLink = (v) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('LINK','MUTABLE',{
            url: v?.url,
            target: v?.target,
            className: v?.className
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        
        // Apply entity
        let nextEditorState = EditorState.set(editorState, {currentContent: contentStateWithEntity });

        // Apply selection
        nextEditorState = RichUtils.toggleLink(nextEditorState, nextEditorState.getSelection(), entityKey);

        // Apply Change
        onChange(nextEditorState);

        // Close Editor
        toggleEditor(false);
        setValue({})

    }

    const removeLink = () => {
        const selection = editorState.getSelection();
        if(!selection.isCollapsed()) onChange(RichUtils.toggleLink(editorState, selection, null));
        toggleEditor(false)
    }


    const handleButtonClick = () => {

        // Add New Link
        openLinkEditor();

    }

    const handleChange = (v) => {
        confirmLink(v);
    }
    const handleRemove = () => {
        removeLink();
    }
    return (
        <SmartContainer flex align="center">
            <StyledIconButton onClick={handleButtonClick}>
                <LinkIcon />
            </StyledIconButton>
            
            <StyledModal label="Link Editor"
                open={selected}
                onClose={() => toggleEditor(false)}>
                <DraftLinkEditor value={value} onChange={handleChange} onRemove={handleRemove} />
            </StyledModal>
        </SmartContainer>
    );
}

// --------------------
// Link Editor
// --------------------

export const DraftLinkEditor = ({value={}, onChange, onRemove}) => {
    const [localHref,setLocalHref] = useState(value?.url);
    const [localTarget,setLocalTarget] = useState(value?.target);
    const [localStyle,setLocalStyle] = useState(value?.style);

    useEffect(() => {

        // URL
        if(value?.url) setLocalHref(value.url)
        else setLocalHref(null)

        // TARGET
        if(value?.target) setLocalTarget(value.target)
        else setLocalTarget(null)

        // STYLE
        if(value?.styled) setLocalStyle(value.styled)
        else setLocalStyle(null)

    },[value])

    const onSave = () => {
        const args = {
            url: localHref,
            target: localTarget,
            className: localStyle
        }
        onChange(args);
    }

    return (
        <Stack>
            <FormInput name="href" type="text" label="URL" value={localHref} onChange={setLocalHref} />
            <HorizontalStack flex justify="flex-end">
                <FormSelect label="Target" value={localTarget} onChange={setLocalTarget}>
                    <option key="normal" value="">Normal</option>
                    <option key="_blank" value="_blank">New Tab</option>
                </FormSelect>
                <FormSelect label="Style" value={localStyle} onChange={setLocalStyle}>
                    <option key="none" value="">Link</option>
                    <option key="button-outlined" value="button-outlined">Outlined Button</option>
                    <option key="button-primary" value="button-primary">Action Button</option>
                </FormSelect>
            </HorizontalStack>
            <HorizontalStack flex justify="flex-end">
                <StyledButton onClick={onRemove} variant="outlined">Remove Link</StyledButton>
                <StyledButton onClick={onSave} variant="contained" color="primary">Save</StyledButton>
            </HorizontalStack>
        </Stack>
    )
}