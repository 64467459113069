import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { globalReducer } from './reducer'

export const history = createBrowserHistory()


const middlewares = [
  routerMiddleware(history),
  thunk
]

const storeMiddleware = (process.env.NODE_ENV === 'development')
  ? composeWithDevTools(applyMiddleware(...middlewares))
  : applyMiddleware(...middlewares)

  
const store = createStore(
  globalReducer(history),
  storeMiddleware,
)

export { store }
