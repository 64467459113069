import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { StyledBottomNavigation, StyledBottomNavigationAction } from '../../../styles/matieralui/BottomNavigation';
import { SpaceIconHome, SpaceIconNote, SpaceIconSeries, SpaceIconAccount, SpaceIconTarget } from '../../styles/Icons';
import { useLocation } from 'react-router-dom'
import { isAndroid, isNativeApp } from '../../../services/Utils';

export const SpaceBottomNav = ({ hide = false }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const nativeApp = isNativeApp();
    const android = isAndroid();
    const goTo = (path) => dispatch(push(path));

    const [value, setValue] = useState(null);
    const handleChange = (e, v) => {
        if (v === value) return
        goTo(v);

    }
    useEffect(() => {
        setValue(location?.pathname);
    }, [location?.pathname])
    return (
        <StyledBottomNavigation hide={hide} showLabels value={value} onChange={handleChange} SafeArea={nativeApp} isAndroid={android}>
            <StyledBottomNavigationAction value="/" icon={<SpaceIconHome />} />
            <StyledBottomNavigationAction value="/breathing" icon={<SpaceIconTarget />} />
            <StyledBottomNavigationAction value="/journal" icon={<SpaceIconNote />} />
            <StyledBottomNavigationAction value="/account" icon={<SpaceIconAccount />} />
        </StyledBottomNavigation>
    )
}