import React, { useState } from 'react';
import styled, {css} from 'styled-components';
import { config } from '../../config';

// Assets
import logo from '../../assets/logo.svg'

// Redux
import { attemptLogout } from '../../redux/Auth/auth.actions'
import { FullUI } from '../../redux/UI/ui.selectors'
import { FullNetwork } from '../../redux/Network/network.selectors'
import { toggleDrawer } from '../../redux/UI/ui.actions'
import { Role, NetworkRole } from '../../redux/Auth/auth.selectors'

import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Material UI

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';


// Material Icons
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

// Styled Material UI
import { StyledList, StyledListItem, StyledListItemText, StyledListItemIcon } from '../../styles/matieralui/List';
import { StyledMenu } from '../../styles/matieralui/Menus';
import { SmartContainer, Stack } from '../../styles/Containers'

// TCC Components
import { Theme, setSpace, respondTo } from '../../styles/Theme'
import { Logo } from './Logo';


// Styled Components
const StyledMainNav = styled.div`
    [data-main-nav-wrap]{
        height: 100vh;
        // width: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: ${setSpace(2)} ${setSpace(2)};
        padding-top: ${setSpace(6)};
        background-color: ${Theme.colors.baseAlternate};
        box-shadow: ${Theme.shadow.two};
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
    }
    

    ${respondTo('mobile',css`
        
        [data-main-nav-wrap]{
            ${props => (!props.open) ? css` display: none;` : ' display: flex;'}
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            overflow: scroll;
            z-index: 100;
        }
        [data-toggle-trigger] { display: none; }
    `)}

`

const MobileMenuButton = styled(IconButton)`
    position: fixed !important;
    top: ${setSpace(2)};
    right: ${setSpace(2)};
    background-color: blue;
    display: none !important;
    z-index: 101;
    ${respondTo('mobile',css`
        display:inline-flex !important;
    `)}
`

/**
 * Nav Item
 */

const NavItem = ({text,open,icon, to, onClick, tooltip}) => {
    
    return (
        <Tooltip title={tooltip} placement="right">
            <StyledListItem button onClick={(e) => onClick(to,e)}>
                <StyledListItemIcon>{icon}</StyledListItemIcon>
                {open && (<StyledListItemText primary={text} />)}
            </StyledListItem>
        </Tooltip>
)}

/**
 * The Main Navigation
 */

 export const MainNav = () => {

    // Read From State
    const dispatch = useDispatch();
    const currentUserRole = Role();
    const fullNetwork = FullNetwork();
    const currentNetworkRole = NetworkRole(fullNetwork.network?.id);
    const ui = FullUI();
    const open = (!ui.drawer) ? false : ui.drawer.open
    const [openMobile,setOpenMobile] = useState(false)

    const handleSelect = (to,e) => dispatch(push(to));
    const handleLogout = () => dispatch(attemptLogout());
    const toggleOpen = () => dispatch(toggleDrawer(!open))

    // My Account Menu 
    const [anchorEl, setAnchorEl] = useState(null);
    const handleAccountClick = (event) => setAnchorEl(event.currentTarget);
    
    const handleAccountClose = (action) => {

        if(action == 'logout') dispatch(attemptLogout());
        if(action == 'account') dispatch(push('/account'));
        setAnchorEl(null)
    }

    const handleMobileOpen = (e) => {

        if(openMobile === true) {
            setOpenMobile(false);
            dispatch(toggleDrawer(false))
        } else {
            setOpenMobile(true);
            dispatch(toggleDrawer(true))
        }
    }
    

    return (
        <StyledMainNav open={openMobile}>

            <SmartContainer data-main-nav-wrap>

                <Stack data-logo-container position="relative" width="100%">
                    <SmartContainer><Logo src={logo} /></SmartContainer>
                </Stack>           
                
                <StyledList>
                    <NavItem onClick={handleSelect} tooltip="Home" open={open} text="Home" to="/" icon={<HomeOutlinedIcon/>} />
                    <NavItem onClick={handleSelect} tooltip="Example Content" open={open} text="Example Content" to="/examplecontent" icon={<HomeOutlinedIcon/>} />
                    <NavItem onClick={handleSelect} tooltip="Placeholder" open={open} text="Placeholder" to="/" icon={<HomeOutlinedIcon/>} />
                    {currentUserRole === 'administrator' && (<NavItem onClick={handleSelect} tooltip="Add Ons" open={open} text="Add Ons" to="/dashboard/addons" icon={<StorefrontOutlinedIcon/>} />)}
                </StyledList>
                
                <StyledList>
                    <Tooltip title="Manage Your Account" placement="right">
                        <StyledListItem button onClick={handleAccountClick}>
                            <StyledListItemIcon><AccountCircleOutlinedIcon/></StyledListItemIcon>
                            {open && (<StyledListItemText primary="Account" />)}
                        </StyledListItem>
                    </Tooltip>
                    <Tooltip title="Open Nav" placement="right" data-toggle-trigger>
                        <StyledListItem button onClick={toggleOpen}>
                            <StyledListItemIcon>
                                {!open && (<KeyboardArrowRightOutlinedIcon/>)}
                                {open && (<CloseOutlinedIcon/>)}
                            </StyledListItemIcon>
                            {open && (<StyledListItemText primary="Close" />)}
                        </StyledListItem>
                    </Tooltip>
                </StyledList>

                <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleAccountClose}
                >
                    <StyledList>
                        <NavItem onClick={handleSelect} tooltip="Manage Your Profile" open={true} text="My Profile" to="/profile" icon={<AccountCircleOutlinedIcon/>} />
                        {currentUserRole === 'owner' && (<NavItem onClick={handleSelect} tooltip="Manage Your Subscription" open={true} text="Billing" to="/billing" icon={<CreditCardIcon/>} />)}
                        {config.allowTeammates === true && (currentUserRole === 'owner' ||  currentUserRole === 'admin')&& (<NavItem onClick={handleSelect} tooltip="Manage Your Team" open={true} text="Team" to="/team" icon={<PeopleOutlinedIcon/>} />)}
                        {config.allowMultipleAccounts === true && (<NavItem onClick={handleSelect} tooltip="Switch Accounts" open={true} text="Switch Accounts" to="/accounts" icon={<SwapCallsIcon/>} />)}
                        {currentUserRole === 'owner' && (<NavItem onClick={handleSelect} tooltip="Account Settings" open={true} text="Settings" to="/settings" icon={<SettingsOutlinedIcon/>} />)}
                        {currentNetworkRole && currentNetworkRole !== 'norole' && (<NavItem onClick={handleSelect} tooltip="Network Settings" open={true} text="Network" to="/network" icon={<LanguageOutlinedIcon/>} />)}
                        <NavItem onClick={handleLogout} tooltip="Signout of Your Account" open={true} text="Log Out" to="/logout" icon={<ExitToAppOutlinedIcon/>} />
                    </StyledList>

                </StyledMenu>

            </SmartContainer>

            <MobileMenuButton onClick={handleMobileOpen}>
                {!openMobile && (<MenuIcon />)}
                {openMobile && (<CloseOutlinedIcon />)}
            </MobileMenuButton>

        </StyledMainNav>
    )
 }