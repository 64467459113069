export default (state = {}, action) => {

    switch (action.type) {
        case '[AUTH] Wipe':
            return {};
        case '[AUTH] Attempt Login':
        case '[AUTH] Attempt Login Failed':
        case '[AUTH] Attempt Login Success':
        case '[AUTH] Attempt Visitor Token':
        case '[AUTH] Attempt Visitor Token Failed':
        case '[AUTH] Attempt Visitor Token Success':
        case '[AUTH] Attempt Signup':
        case '[AUTH] Attempt Signup Failed':
        case '[AUTH] Attempt Signup Success':
        case '[AUTH] Recover Password':
        case '[AUTH] Recover Password Failed':
        case '[AUTH] Recover Password Success':
        case '[AUTH] Reset Password':
        case '[AUTH] Reset Password Failed':
        case '[AUTH] Reset Password Success':
        case '[AUTH] Load Current User':
        case '[AUTH] Load Current User Failed':
        case '[AUTH] Load Current User Success':
        case '[AUTH] Update Current User':
        case '[AUTH] Update Current User Failed':
        case '[AUTH] Update Current User Success':
        case '[AUTH] Switch Account':
        case '[AUTH] Switch Account Failed':
        case '[AUTH] Switch Account Success':
        case '[AUTH] Accept Terms':
        case '[AUTH] Accept Terms Failed':
        case '[AUTH] Accept Terms Success':
        
            const newState = Object.assign({},state,{...action.payload})
            return newState;
    
        
        case '[AUTH] Attempt Logout':
        case '[AUTH] Attempt Logout Failed':
        case '[AUTH] Attempt Logout Success':
            return Object.assign({},state,{...action.payload})
            
        default:
            return state
    }
}
