import React, { useState } from 'react';
import { HorizontalStack, SmartContainer, Stack } from '../../styles/Containers';
import { StyledButton } from '../../styles/matieralui/Button';
import { StyledModal } from '../../styles/matieralui/Modal';
export const FrameModalButton = ({ text, url }) => {

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    return (
        <SmartContainer >
            <StyledButton variant='outlined' onClick={() => setOpenModal(true)}>{text}</StyledButton>
            <StyledModal open={openModal} onClose={handleCloseModal}>
                <iframe height="500px" width="100%" src={url} />
            </StyledModal>
        </SmartContainer>
    )
}