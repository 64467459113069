import React from 'react';
import { SmartContainer, HorizontalStack } from '../../styles/Containers';
import { StyledImg, StyledH6 } from '../../styles/StyledHTML';

export const IntegrationFormHeader = ({title,logo,children}) => {
    return (
        <SmartContainer flex align="center" justify="space-between">
            <HorizontalStack flex align="center">
                <StyledImg src={logo} maxWidth="30px" />
                <StyledH6>{title}</StyledH6>
            </HorizontalStack>
            {children}
        </SmartContainer>
    )
}