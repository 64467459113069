import React, { useState, useRef } from 'react';

// Theming
import { Stack, SmartContainer } from '../../styles/Containers'
import { StyledVideo } from '../../styles/StyledHTML';
import { StyledIconButton } from '../../styles/matieralui/IconButton';


// Material UI
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';



export const VideoPlayer = ({src}) => {

    // Playing State
    const [playing,setPlaying] = useState(false);

    // HTML Ref
    const el = useRef(null);

    const togglePlay = (e) => {
        e.preventDefault();
        setPlaying(!playing)
        if(playing !== true) el.current.play();
        else el.current.pause();
    }

    return (
        <Stack data-styled-video-player>
            <StyledVideo radius ref={el}>
                <source src={src} />
                <span>Your browser does not support the video element.</span>
            </StyledVideo>
            <SmartContainer flex align="center">
                <StyledIconButton onClick={togglePlay}>
                    {playing && (<PauseCircleOutlineOutlinedIcon />)}
                    {!playing && (<PlayCircleOutlineOutlinedIcon />)}
                </StyledIconButton>
            </SmartContainer>
        </Stack>
    )
}