import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

// Redux
import { FullAuth } from '../../redux/Auth/auth.selectors';
import { attemptLogin } from '../../redux/Auth/auth.actions';
import { CurrentNetwork } from '../../redux/Network/network.selectors';

// Styles
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledH3, StyledImg } from '../../styles/StyledHTML'

// Components
import { FormInput } from '../Forms/FormInput'
import { PasswordInput } from '../Forms/PasswordInput'
import { FormError } from '../Forms/FormError'
import { isEmail } from '../../services/Utils'

import { useAnalytics } from '../../modules/Analytics/AnalyticsService';


export const LoginForm = ({ redirect }) => {

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const currentNetwork = CurrentNetwork();
    const { trackEvent } = useAnalytics();

    const handleLogin = (e) => dispatch(attemptLogin(email, password, currentNetwork.id));

    const handleEmailChange = (value) => {
        if (isEmail(value)) setEmailError('')
        else setEmailError('Please enter a valid email')
        setEmail(value)
    }
    const auth = FullAuth();

    // Redirect logged in users
    if (auth.session && auth.session.token) {

        // Track Login Event
        const trackArgs = {
            category: 'User',
            action: 'LOGIN'
        }

        trackEvent('LOGIN', trackArgs);


        // Reload if we are on the page currently
        if (window.location.pathname === redirect) {
            window.location.reload();
            return;
        }

        // Redirect if we aren't on that page already
        return (<Redirect to={redirect} />);

    }

    // Login Errors
    let hasError = (auth?.error) ? auth.error : false;
    if (hasError && hasError === 'Protected resource, please login.') hasError = false;
    const disableSubmit = (!email || !password || !isEmail(email));

    const keyPress = (e) => {
        // On Enter
        if (e.keyCode == 13) handleLogin();
    }

    return (
        <SmartContainer thinking={auth.loading} minWidth="300px">
            <Stack>
                {/* <StyledH3>Login</StyledH3> */}
                {hasError && (<FormError error={hasError} />)}
                <FormInput onKeyDown={keyPress} label="Email" type="email" onChange={handleEmailChange} value={email} helperText={emailError} error={(emailError)} />
                <PasswordInput onKeyDown={keyPress} label="Password" type="password" onChange={setPassword} value={password} />
                <StyledButton width="100%" onClick={handleLogin} variant="contained" color="primary" disabled={disableSubmit}>Login</StyledButton>
                <HorizontalStack textAlign="center">
                    <Link to="/signup">Signup</Link>
                    <Link to="/recover-password">Reset password</Link>
                </HorizontalStack>
            </Stack>
        </SmartContainer>
    )
}