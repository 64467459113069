import React, { useState, useEffect } from 'react';

export const useFirstPromoter = (fullNetwork) => {

    // First Promoter
    const [firstPromoter, setFirstPromoter] = useState(null);
    const [isReady, setIsReady] = useState(false);

    // Mount
    useEffect(() => {
        console.log()
        // Load First Promoter

    }, [])

    // Load Network Id
    useEffect(() => {
        if (fullNetwork?.network?.meta?.firstpromoter?.enabled === true) setFirstPromoter(fullNetwork?.network?.meta?.firstpromoter)
    }, [fullNetwork]);

    // Load Code
    useEffect(() => {


        setIsReady(true)
        if (!firstPromoter || firstPromoter.enabled !== true) return;

        // Original First Promoter Code
        // (function(){
        //     var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||"complete"==t||"loaded"==t)try{window.$FPROM.init(firstPromoter.id,firstPromoter.domain)}catch(t){}};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)
        // })();

        // // Callback
        // function onLoad(e,t,firstPromoter){

        //     if(!t || !firstPromoter) return;

        //     try {
        //         window.$FPROM.init(firstPromoter.id,firstPromoter.domain)
        //     }
        //     catch(t){

        //     }

        // }

        // // Create Script
        // var t=document.createElement("script");
        // t.type="text/javascript";
        // t.async=!0;
        // t.src='https://cdn.firstpromoter.com/fprom.js';
        // t.addEventListener('load', (e) => { onLoad(e,t,firstPromoter)});
        // // t.onload=t.onreadystatechange=function(){
        // //     var t=this.readyState;
        // //     if(!t||"complete"==t||"loaded"==t)try{window.$FPROM.init(firstPromoter.id,firstPromoter.domain)}catch(t){}
        // // };
        // var e=document.getElementsByTagName("script")[0];
        // e.parentNode.insertBefore(t,e)


        // First Promoter 2021: Include is in Public HTML
        window.fpr("init", { cid: firstPromoter.id });
        window.fpr("click");


    }, [firstPromoter])

    return {
        firstPromoter
    }

}