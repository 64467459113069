import React, { useState } from 'react';

// Components
import { App } from '../../App';
import { Surface, Stack, SmartContainer, StyledGridByTwo, StyledGridItem } from '../../styles/Containers'
import { StyledH1, StyledP } from '../../styles/StyledHTML';
import { FormInput } from '../../components/Forms/FormInput';
import { UniversalVideoPlayer } from '../../components/VideoPlayer/UniversalVideoPlayer';
import { OEmbedPlayer } from '../../components/VideoPlayer/OEmbedPlayer'



export const VideoLab = () => {

    const videos = [
        {
            name: "YouTube",
            url: "https://www.youtube.com/watch?v=oUFJJNQGwhk"
        },
        {
            name: "Youtube Shortlink",
            url: "https://youtu.be/oUFJJNQGwhk"
        },
        {
            name: "Youtube Playlist",
            url: "https://www.youtube.com/playlist?list=PLogRWNZ498ETeQNYrOlqikEML3bKJcdcx"
        },
        {
            name: "File",
            url: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
        },
        {
            name: "Wistia",
            url: "https://home.wistia.com/medias/e4a27b971d"
        },
        {
            name: "Wistia Sharelink",
            url: "https://pathway.wistia.com/medias/y0aw3tsmrb"
        },
        {
            name: "Facebook",
            url: "https://www.facebook.com/facebook/videos/10153231379946729"
        },
        {
            name: "Vimeo",
            url: "https://vimeo.com/90509568"
        },
        {
            name: "Vimeo Alt",
            url: "https://vimeo.com/kevinmargo/constructteaser"
        },
        {
            name: "Vimeo player",
            url: "https://player.vimeo.com/video/90509568"
        },
        {
            name: "Vimeo Player",
            url: "https://player.vimeo.com/video/540540189"
        }
    ]

    const onPlay = (video) => {
        alert(video.name);
    }

    const renderVideo = (video) => {
        return (
            <StyledGridItem>
                <Surface width="100%">
                    <Stack>
                        <StyledP>{video.name}</StyledP>
                        <StyledP light>{video.url}</StyledP>
                        <UniversalVideoPlayer
                            radius={true} 
                            url={video.url}
                            controls={true} 
                            onPlay={() => onPlay(video)}
                            />
                    </Stack>
                </Surface>
            </StyledGridItem>
        )
    }

    return (
        <App title="Lab: Design System">
            <StyledGridByTwo>
                
                {videos.map(video => renderVideo(video))}           
                
                
            </StyledGridByTwo>
        </App>
    )
}