import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from '../../components/Network/NetworkApp';
import { ModelList } from '../../components/PaginatedList/ModelList';
import { NewNetworkProductForm } from './NewNetworkProductForm';

// Styles
import { StyledModal } from '../../styles/matieralui/Modal'
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Redux
import { searchNetworkProducts, restoreNetworkProduct } from './redux/networkproduct.actions';
import { FullNetworkProducts } from './redux/networkproduct.selectors';
import { RouteBreadCrumbs } from '../../routes';

export const NetworkProductPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [view,setView] = useState('all');
    const fullNetworkProducts = FullNetworkProducts();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {
        if(fullNetworkProducts.createSuccess) {
            // Close The Modal
            setOpenNewModal(false);

            // Redirect to edit page
            dispatch(push(`/network/products/${fullNetworkProducts.create.id}`))
        }
    },[fullNetworkProducts.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {
        dispatch(push(`/network/products/${item.id}`))
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <StyledButton variant="contained" color="primary" onClick={() => setOpenNewModal(true)}>New Product</StyledButton>
            </SmartContainer>
        )
        
    }

    // Render Page
    return (
        <NetworkApp title="Network Products" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>
            
            <ModelList 
                model="NetworkProducts" 
                action={searchNetworkProducts} 
                restore={restoreNetworkProduct}
                selector={FullNetworkProducts} 
                onSelect={handleSelect} />
            
            <StyledModal
                open={openNewModal}
                onClose={() => setOpenNewModal(false)}
                label="New Product"
                description="Create a new Product"
                >
                    <Stack>
                        <NewNetworkProductForm />
                    </Stack>
                    
            </StyledModal>

            

        </NetworkApp>
    )
}