import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { HorizontalStack, SmartContainer, Surface } from '../../../styles/Containers';
import { StyledIconButton } from '../../../styles/matieralui/IconButton';
import { StyledP } from '../../../styles/StyledHTML';
import { SpaceIconPlay, SpaceIconCheck, SpaceIconLocked } from '../../styles/Icons';
import { findUserProgress } from '../UserProgress/redux/userprogress.actions';
import { PROGRESS_COMPLETED } from '../../services/SpaceUtils';
import { StyledMediaImage } from '../../components/Display/MediaAsset';
import { StyledTag } from '../Series/SeriesCard';
const StyledMed = styled(HorizontalStack)`
    /* ${props => (props.status == PROGRESS_COMPLETED) ? css`
        color: green;
    ` : css`opacity: 1;`} */
    > * {
        margin-right: 0.5em !important; 
    }
`
const StyledMedSurface = styled(Surface)`

`
export const MeditationListItem = ({ meditation, free }) => {

    const [progress, setProgress] = useState(null);

    useEffect(() => {
        const loadProgress = async () => {
            const res = await findUserProgress({ meditationId: meditation.id });
            if (res?.progress?.id) {
                setProgress(res?.progress)
            }
        }
        loadProgress();
    }, [])
    return (
        <StyledMedSurface width="100%">
            <StyledMed width="100%" flex align="center" justify="space-between" status={progress?.type}>
                <HorizontalStack flex align="center">

                    {meditation?.meta?.socialSquare && (

                        <SmartContainer maxWidth="70px">
                            <StyledMediaImage shadow="one" radius id={meditation?.meta?.socialSquare} network={true} />
                        </SmartContainer>

                    )}
                    <StyledP>{meditation?.name}</StyledP>

                </HorizontalStack>


                <HorizontalStack flex align="center">
                    {free && (<SmartContainer><StyledTag>Free</StyledTag></SmartContainer>)}
                    <StyledIconButton spacify={true} noShadow={true}>
                        {progress?.type === PROGRESS_COMPLETED && (<SpaceIconCheck />)}
                        {progress?.type !== PROGRESS_COMPLETED && (<SpaceIconPlay />)}
                    </StyledIconButton>
                </HorizontalStack>
            </StyledMed>
        </StyledMedSurface>

    )
}