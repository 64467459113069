import React from 'react';
import styled, { css, keyframes } from "styled-components";
import { SmartContainer } from "../../styles/Containers";

export const BreathKeyframes = keyframes`
    0%   { transform: translate3d(0%,65%,0) scale3d(0,0,0); opacity: 0; }
    50% { transform: translate3d(0%,65%,0) scale3d(2.25,2.25,2.25); opacity: 1; }
    98% { transform: translate3d(0%,65%,0) scale3d(0,0,0); opacity: 0; }
    100% { transform: translate3d(0%,65%,0) scale3d(0,0,0); opacity: 0; }
`

export const BreathKeyframesTwo = keyframes`
    0%   { opacity: 0.25;  }
    50% {  opacity: 1;}
    100% {  opacity: 0.25; }
`

export const BreathGradiant = styled(SmartContainer)`
    position: fixed;
   
    transition: all 2000ms cubic-bezier(0.33, 0, 0.19, 1);
    transition-delay: 0ms;
    transform: translate3d(0%,50%,0) scale3d(1,1,1);
    pointer-events: none;
    animation: ${BreathKeyframes} 10s cubic-bezier(0.25, 0, 0.75, 1) infinite;
    ${props => (props.color) ? css`background: radial-gradient(circle, ${props.color} 0%, rgba(252,78,39,0) 40%);` : ''}
    ${props => (props.delay) ? css`animation-delay: ${props.delay};` : ''}

    /** Round Two */
    /* left: -30px;
    bottom: -30px;
     */
    width: 120%;
    left: -10%;
    height: 100vh;
`

const BreathContainer = styled(SmartContainer)`
 pointer-events: none;
`




export const BreathAnimation = () => {
    return (
        <BreathContainer width="100%" height="100vh" overflow="hidden" position="fixed" bottom="0" left="0">
            {/* <BreathGradiant overflow="hidden" top="50%" left="50%" width="100vw" height="70vh" color="rgba(241,0,255,0.5)" /> */}
            {/* <BreathGradiant overflow="hidden" delay="0s" width="100vw" height="100vh" color="rgba(75,255,255,1)" /> */}
            <BreathGradiant overflow="hidden" delay="0s" color="rgba(252,78,39,0.75)" />
        </BreathContainer>
    )
}