
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import { routes } from './routes'
import { ThemeProvider } from '@mui/material/styles';
import { MaterialTheme } from './styles/Theme'

// New Theme
import { GlobalStyleSheet } from './styles/GlobalStyles'
import { UpdateApp } from './components/UpdateApp/UpdateApp'

/**
 * Turns on hot reloading. This is if hot reloading is available. We turn this off for Cypress testing used by SEIT.
 */
const { hot } = module

if (hot) hot.accept();

// App Starter
const AppStarter = () => {

  return (
    <AppContainer>
      <Provider store={store}>
        {/* <UpdateApp /> */}
        <ThemeProvider theme={MaterialTheme}>
          <>
            <GlobalStyleSheet />
            {routes}
          </>
        </ThemeProvider>
      </Provider>
    </AppContainer>
  )
}

// Ready. Set. Go
ReactDOM.render(<AppStarter />, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
// serviceWorker.register({ onUpdate: (r) => console.log(r, "SW R") });