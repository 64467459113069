import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { ModelList } from '../../../components/PaginatedList/ModelList';

// Styles
import { SmartContainer, Surface } from '../../../styles/Containers'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TagSelect } from '../../../components/Taxonomy/TagSelect';

// Redux
import { searchMeditations, restoreMeditation } from './redux/meditations.actions';
import { FullMeditations } from './redux/meditations.selectors';
import { FullTags } from '../Tags/redux/tags.selectors';
import { searchTags } from '../Tags/redux/tags.actions';

export const MeditationsList = ({ onSelect }) => {

    // New Modal
    const dispatch = useDispatch();
    const [view, setView] = useState('all');
    const [extraFilters, setExtraFilters] = useState(null);
    const [tag, setTag] = useState('all');
    const store = FullMeditations();

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => onSelect(item);

    // Filter
    const handleCategoryChange = (catId) => {

        setTag(catId);
        const filters = { ...extraFilters }

        if (catId !== 'all') filters.Tag = `[${catId}]`;
        else delete filters.Tag;
        setExtraFilters(filters)
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderCustomControls = () => {

        return (
            <SmartContainer>
                <TagSelect
                    label="Category"
                    selector={FullTags}
                    searchAction={searchTags}
                    value={tag}
                    onChange={handleCategoryChange}
                />
            </SmartContainer>
        )
    }

    // Render Page
    return (
        <ModelList
            model="Meditation"
            action={searchMeditations}
            restore={restoreMeditation}
            selector={FullMeditations}
            onSelect={handleSelect}
            filters={extraFilters}
            paginationControls={renderCustomControls}
            variant="cards"
            defaultLimit={20}
        />
    )
}