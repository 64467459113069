import { searchModels, createModel, updateModel, deleteModel, restoreModel, getModel } from '../../../redux/DefaultModelActions';
import { APIBase, APIGet } from '../../../services/API';
const model = 'NetworkProduct';
const route = '/v1/networkproduct';

export const searchNetworkProducts = (page, limit, search, view) => searchModels(model, route, page, limit, search, view);
export const createNetworkProduct = (args) => createModel(model, route, args);
export const updateNetworkProduct = (id, args) => updateModel(model, route, id, args);
export const deleteNetworkProduct = (id) => deleteModel(model, route, id);
export const restoreNetworkProduct = (id) => restoreModel(model, route, id);
export const getNetworkProduct = (id) => getModel(model, route, id);

export const loadProduct = async (id) => {

    try {
        const res = await APIGet(`${APIBase}${route}/${id}`)
        const json = await res.json();
        return json;
    } catch (error) {
        return error;
    }

}