import React from 'react';

// TCC Components
import { App } from '../../App'
import { Surface } from '../../styles/Containers'
import { StyledH1, StyledP } from '../../styles/StyledHTML'

export const NoAccessPage = () => {

    return (
        <App title="No Access">
            <Surface>
                <StyledH1>Permission needed</StyledH1>
                <StyledP>You don't have the permissions needed to see this page.</StyledP>
            </Surface>
        </App>
    );
}