/**
 * This form follows the Stripe Pricing Form
 * See https://stripe.com/docs/api/prices/object
 * -----------------------------------------------
 * Current Support for Once off Only
 */
import React, { useEffect, useState } from 'react'
import { config } from '../../config'
import { Stack, Surface } from '../../styles/Containers'
import { FormInput } from '../../components/Forms/FormInput'
import { FormSelect } from '../../components/Forms/FormSelect'

export const StripePriceForm = ({item, onChange}) => {

    const [editable, setEditable] = useState(false);

    useEffect(() => {
    
        if(!item.priceId) setEditable(true) // Stripe Prices cannot be edited after creation.
        else setEditable(false);
    },[item])


    return (
        <Surface width="100%">
            <Stack>
                <FormInput label="Nickname" value={item.nickname} onChange={(value) => onChange(item,'nickname',value)} />
                <FormInput disabled={!editable} label="Price" type="number" InputProps={{ inputProps: { min: 1 } }} value={item.price} onChange={(value) => onChange(item,'price',value)} />
                <FormSelect disabled={!editable} label="Currency" value={item.currency} onChange={(value) => onChange(item,'currency',value)}>
                    {config.currencies.map(currency => (<option key={currency} value={currency}>{currency}</option>))}
                </FormSelect>
            </Stack>
        </Surface>
    )
}


// {
//     "id": "price_1HvM8FAhuroZsecgFbPFXvZy",
//     "object": "price",
//     "active": true,
//     "billing_scheme": "per_unit",
//     "created": 1607256867,
//     "currency": "usd",
//     "livemode": false,
//     "lookup_key": null,
//     "metadata": {},
//     "nickname": null,
//     "product": "prod_GiEt411DnWvtzn",
//     "recurring": {
//       "aggregate_usage": null,
//       "interval": "month",
//       "interval_count": 1,
//       "usage_type": "licensed"
//     },
//     "tiers_mode": null,
//     "transform_quantity": null,
//     "type": "recurring",
//     "unit_amount": 2000,
//     "unit_amount_decimal": "2000"
//   }