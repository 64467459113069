import styled from 'styled-components';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export const StyledTimeline = styled(Timeline)``;
export const StyledTimelineItem = styled(TimelineItem)``;
export const StyledTimelineSeparator = styled(TimelineSeparator)``;
export const StyledTimelineConnector = styled(TimelineConnector)``;
export const StyledTimelineContent = styled(TimelineContent)``;
export const StyledTimelineDot = styled(TimelineDot)``;