import IconButton from '@mui/material/IconButton';
import styled, { css } from 'styled-components';
import { Theme } from '../Theme'
export const StyledIconButton = styled(IconButton)`
    ${props => (props.selected) ? css`color: ${Theme.colors.primary} !important;` : ''}

    // SPACE CULTURE
    .MuiSvgIcon-root {
        font-size: 1.75rem;
    }
    ${props => (props.spacify) ? css`
        &.MuiButtonBase-root {
            /* background-color: ${Theme.colors.primary} !important; */
            /* background: radial-gradient(circle, rgba(75,255,255,1) 0%, rgba(0,0,0,0) 90%) !important; */
            /* color: ${Theme.colors.primaryText} !important; */
            box-shadow: ${Theme.shadow.one} !important;
            background-color: ${Theme.colors.base} !important ;
        }
    
    
    
    ` : ''}

    ${props => (props.noShadow) ? css`
        &.MuiButtonBase-root {

            box-shadow: none !important;
   
        }
    
    
    
    ` : ''}
`;