import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactPixel from 'react-facebook-pixel';
 //https://www.npmjs.com/package/react-facebook-pixel

export const facebookPixelInit = (fullNetwork) => {
    
    // Guard: No Pixel
    if(!fullNetwork?.network?.meta?.facebookpixel) return;
    
    // Guard: Not Enabled
    if(fullNetwork?.network?.meta?.facebookpixel?.enabled !== true) return;

    // Initialize
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init(fullNetwork?.network?.meta?.facebookpixel?.trackingId, advancedMatching, options);

}

export const facebookPixelTrackPageView = ({location,fullNetwork}) => {

    // Guard: No Pixel
    if(!fullNetwork?.network?.meta?.facebookpixel) return;
    
    // Guard: Not Enabled
    if(fullNetwork?.network?.meta?.facebookpixel?.enabled !== true) return;
    
    ReactPixel.pageView();


}

export const facebookPixelTrackEvent = ({event, args, fullNetwork}) => {

    // Guard: No Pixel
    if(!fullNetwork?.network?.meta?.facebookpixel) return;
    
    // Guard: Not Enabled
    if(fullNetwork?.network?.meta?.facebookpixel?.enabled !== true) return;

    // Custom Event
    ReactPixel.trackCustom('trackCustom', event, args);
 }