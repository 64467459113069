import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { getUserPreference, createUserPreference, updateUserPreference } from '../../modules/UserPreferences/redux/userpreferences.actions';
import { FullUserPreference } from '../../modules/UserPreferences/redux/userpreferences.selectors';
import { isNativeApp, postMessage } from '../../services/Utils';
// Styles
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledH6 } from '../../styles/StyledHTML'

// Components
import { Checkbox } from '../Forms/Checkbox'


export const UserPreferencesForm = ({ me }) => {

    const dispatch = useDispatch();
    const [marketingOptIn, setMarketingOptIn] = useState(false);
    const store = FullUserPreference();
    const nativeApp = isNativeApp();


    // Load
    useEffect(() => {

        if (me?.userPreferences?.id) dispatch(getUserPreference(me?.userPreferences?.id));

    }, [me])

    // Set
    useEffect(() => {
        if (store?.activeItem?.meta?.marketingOptIn) setMarketingOptIn(store?.activeItem?.meta?.marketingOptIn);
    }, [store?.activeItem])

    // On Create
    useEffect(() => {
        if (store.createSuccess) {
            dispatch(getUserPreference(store.create.id)); // Load this.
        }
    }, [store.createSuccess])

    const savePreferences = () => {
        const meta = { ...store?.activeItem?.meta };
        meta.marketingOptIn = marketingOptIn;
        const payload = {
            meta
        }
        if (store?.activeItem) dispatch(updateUserPreference(store?.activeItem?.id, payload));
        else dispatch(createUserPreference(payload));
    }
    //
    const handleReminders = () => {
        postMessage('LOCAL_REMINDERS', { title: "Reminders", message: "There's no better time than now.", back: document?.referrer });
    }


    return (
        <SmartContainer minWidth="300px" thinking={store?.activeItemLoading || store?.updateLoading || store?.createLoading}>
            <Stack>
                <StyledH6>Preferences</StyledH6>
                <SmartContainer><Checkbox label="I'd like to receive updates from time to time. " value={marketingOptIn} onChange={setMarketingOptIn} color="primary" /></SmartContainer>
                {nativeApp && (<SmartContainer><StyledButton onClick={handleReminders} variant='outlined'>Manage Reminders</StyledButton></SmartContainer>)}
                <StyledButton onClick={savePreferences} variant="contained" color="primary">Save Preferences</StyledButton>
            </Stack>
        </SmartContainer>
    )
}