import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../../redux/UI/ui.actions';

// Redux
import { updateNetworkAccount } from '../../redux/Network/network.actions';
import { FullNetwork } from '../../redux/Network/network.selectors';
// Styles
import { SmartContainer, Stack, Surface } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Components
import { FormInput } from '../Forms/FormInput'


export const NetworkEditAccountForm = ({account}) => {

    const dispatch = useDispatch();
    const store = FullNetwork();
    const [name,setName] = useState('');
    const [subscriptionStatus,setSubscriptionStatus] = useState('');
    const [paymentStatus,setPaymentStatus] = useState('');
    const [failedPaymentCount,setFailedPaymentCount] = useState('');
    const [plan,setPlan] = useState('');
    const [customerId,setCustomerId] = useState('');
    const [subscriptionId,setSubscriptionId] = useState('');

    // Clone to user for updating
    useEffect(() => {
        if(!account) return;

        // Name
        if(account?.name) setName(account?.name);
        else setName('');

        // Subscription Status
        if(account?.subscriptionStatus) setSubscriptionStatus(account?.subscriptionStatus);
        else setSubscriptionStatus('');

        // Payment Status
        if(account?.paymentStatus) setPaymentStatus(account?.paymentStatus);
        else setPaymentStatus('');

        // Failed Payment Count
        if(account?.failedPaymentCount) setFailedPaymentCount(account?.failedPaymentCount);
        else setFailedPaymentCount('');

        // Plan
        if(account?.plan) setPlan(account?.plan);
        else setPlan('');

        // Customer Id
        if(account?.customerId) setCustomerId(account?.customerId);
        else setCustomerId('');

        // Subscription Id
        if(account?.subscriptionId) setSubscriptionId(account?.subscriptionId);
        else setSubscriptionId('');

    },[account])

    // Handle Save
    const handleSubmit = () => {
        const args = {
            name,
            subscriptionStatus,
            paymentStatus,
            failedPaymentCount,
            plan,
            subscriptionId,
            customerId
        }
        

        dispatch(updateNetworkAccount(account.id, args))    
    }

    // Login Errors
    // const hasError = (fullAccount?.accountError) ? fullAccount.accountError : false;

    const disableSubmit = (!name) ? true : false;

    return (
        <SmartContainer minWidth="300px" thinking={store?.updateNetworkAccountLoading}>

            {!account && (<SmartContainer>Loading</SmartContainer>)}
            {account && (
                <Surface>
                    <Stack>
                        <FormInput label="Account Name" type="text" onChange={setName} value={name} />
                        <FormInput label="Subscription Status" type="text" onChange={setSubscriptionStatus} value={subscriptionStatus} />
                        <FormInput label="Payment Status" type="text" onChange={setPaymentStatus} value={paymentStatus} />
                        <FormInput label="Failed Payment Count" type="number" onChange={setFailedPaymentCount} value={failedPaymentCount} />
                        <FormInput label="Plan" type="text" onChange={setPlan} value={plan} />
                        <FormInput label="Stripe Customer Id" type="text" onChange={setCustomerId} value={customerId} />
                        <FormInput label="Stripe Subscription Id" type="text" onChange={setSubscriptionId} value={subscriptionId} />
                        <StyledButton onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>Update Account</StyledButton>
                    </Stack>
                </Surface>
            )}
        </SmartContainer>
    )
}