import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { StyledButton } from '../../styles/matieralui/Button'
import { StatusSelector } from '../../components/Publishing/StatusSelector';
import { ShedulingSelector } from '../../components/Publishing/ShedulingSelector';
import { MoveToTrash } from '../../components/Publishing/MoveToTrash';
import { Duplicate } from './Duplicate';
import { openToast } from '../../redux/UI/ui.actions';

// Styles
import { HorizontalStack, Stack, Surface } from '../../styles/Containers'



export const PublishingForm = ({
        item, 
        disable, 
        onSubmit, 
        route, 
        deleteAction,
        duplicateAction,
        selector, 
        withStatus = true, 
        withScheduling = true,
        withDuplicate = true
    }) => {

    // ----------------------------
    // Local State
    // ----------------------------
    const disableSubmit = true;
    const dispatch = useDispatch();
    const [id,setId] = useState(null)
    const [status,setStatus] = useState('draft');
    const [scheduling, setScheduling] = useState('');
    const [activeItem,setActiveItem] = useState(null);
    const state = selector();

    // ----------------------------
    // Effects
    // ----------------------------

    // On Mount
    useEffect(() => {
        // Guard: Reset
        if(!item?.id) return;
        if(item?.id === activeItem?.id) return; 
        setActiveItem(item);
        if(item?.id) setId(item.id);
        if(item?.status) setStatus(item.status);
        if(item?.publishedAt) setScheduling({publishedAt: item.publishedAt, unpublishedAt: item.unpublishedAt});
    },[item])

    // Update Watcher
    useEffect(() => {
        if(state.updateSuccess) dispatch(openToast(state.updateSuccess,'success'))
        if(state.updateError) dispatch(openToast(state.updateError,'error'))
    },[state.updateLoading])

    // ----------------------------
    // Methods
    // ----------------------------

    const handleSubmit = () => {

        // Call On Submit and pass up data.
        const data = {}
        if(withScheduling){
            data.publishedAt = scheduling.publishedAt;
            data.unpublishedAt = scheduling.unpublishedAt;
        }
        if(withStatus) {
            data.status = status;
        }
        onSubmit(data);
    }
   
    // ----------------------------
    // Render
    // ----------------------------

    return (
        <Stack>
            {withStatus && (<StatusSelector onChange={setStatus} value={status} />)}
            {withScheduling && (<ShedulingSelector onChange={setScheduling} value={scheduling} />)}
            <StyledButton width="100%" onClick={handleSubmit} variant="contained" color="primary" disabled={disable}>Save</StyledButton>
            <HorizontalStack flex>
                <MoveToTrash id={id} action={deleteAction} selector={selector} route={route} />
                {withDuplicate && (<Duplicate id={id} action={duplicateAction} selector={selector} route={route} />)}
            </HorizontalStack>
        </Stack>
    )
}