import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../App';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader';

// Styles
import { Stack, Surface, StyledRichTextContent, StyledImagePlaceholder, SmartContainer } from '../../styles/Containers'

// Redux
import { getNetworkProduct } from './redux/networkproduct.actions';
import { FullNetworkProducts } from './redux/networkproduct.selectors';
import { StyledButton } from '../../styles/matieralui/Button';
import { StyledH3 } from '../../styles/StyledHTML';
import { RouteBreadCrumbs } from '../../routes';

export const ViewNetworkProductSuccessPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const fullNetworkProducts = FullNetworkProducts();
    const { id } = useParams();
    const thisId = parseInt(id)
    const [selectedPrice, setSelectedPrice] = useState(null)

    const goTo = (path) => dispatch(push(path))

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => { dispatch(getNetworkProduct(thisId)) },[])

    // --------------------------
    // Renders
    // --------------------------


    // Render Page
    return (
        <App title={fullNetworkProducts?.activeItem?.name} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.billing]}>
            
            {fullNetworkProducts?.activeItemLoading && (<FullScreenLoader />)}
            {!fullNetworkProducts?.activeItemLoading &&(
                <SmartContainer>
                    <Surface>
                        <Stack>

                            <StyledH3>Success! Thank you for purchasing.</StyledH3>
                            
                            <StyledButton onClick={() => goTo('/') } variant="contained" color="primary">Return to your dashboard</StyledButton>

                            <StyledImagePlaceholder minHeight="150px" />

                            <StyledRichTextContent dangerouslySetInnerHTML={{__html:fullNetworkProducts?.activeItem?.meta?.description}}/>
                                        
                        </Stack>
                    </Surface>
                </SmartContainer>
            )}
        </App>
    )
}