import React, { useState, useEffect } from 'react';
import { DateTimePicker } from '../Forms/DatePicker';
import { Stack } from '../../styles/Containers';

export const ShedulingSelector = (props) => {

    const [publishedAt,setPublishedAt] = useState(null);
    const [unpublishedAt,setUnpublishedAt] = useState(null);

    const handlePublishChange = (value) => {
        const data = {
            publishedAt: value,
            unpublishedAt: props.unpublishedAt
        }
        props.onChange(data);
    }
    const handleUnpublishChange = (value) => {
        const data = {
            publishedAt: props.publishedAt,
            unpublishedAt: (!value || value == "") ? null : value
        }
        props.onChange(data);
    }
   
    return (
        <Stack>
             <DateTimePicker label="Publish On" value={props?.value?.publishedAt} onChange={(value) => handlePublishChange(value)} />
             <DateTimePicker label="Unpublish On" value={props?.value?.unpublishedAt} onChange={(value) => handleUnpublishChange(value)} />
        </Stack>
    )

}