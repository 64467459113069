export default (state = {}, action) => {

    switch (action.type) {
        case '[ACCOUNT] Wipe':
            return {};
        case '[ACCOUNT] Get Account':
        case '[ACCOUNT] Get Account Failed':
        case '[ACCOUNT] Get Account Success':
        case '[ACCOUNT] Get Account Clear':
        case '[ACCOUNT] Update Account':
        case '[ACCOUNT] Update Account Failed':
        case '[ACCOUNT] Update Account Success':
        case '[ACCOUNT] Update Account Clear':
        case '[ACCOUNT] Get Team':
        case '[ACCOUNT] Get Team Failed':
        case '[ACCOUNT] Get Team Success':
        case '[ACCOUNT] Get Team Clear':
        case '[ACCOUNT] Update Team Member':
        case '[ACCOUNT] Update Team Member Failed':
        case '[ACCOUNT] Update Team Member Success':
        case '[ACCOUNT] Update Team Member Clear':
        case '[ACCOUNT] Add Team Member':
        case '[ACCOUNT] Add Team Member Failed':
        case '[ACCOUNT] Add Team Member Clear':
        case '[ACCOUNT] Remove Team Member':
        case '[ACCOUNT] Remove Team Member Failed':
        case '[ACCOUNT] Remove Team Member Clear':
        case '[ACCOUNT] Remove Me':
        case '[ACCOUNT] Remove Me Failed':
        case '[ACCOUNT] Remove Me Clear':
        case '[ACCOUNT] Remove Success':
        case '[ACCOUNT] Create Account':
        case '[ACCOUNT] Create Account Failed':
        case '[ACCOUNT] Create Account Success':
        case '[ACCOUNT] Create Account Clear':
            const newState = Object.assign({}, state, { ...action.payload })
            return newState;

        case '[ACCOUNT] Add Team Member Success':
            // Add the new Member to the state.
            const newTeamMemberState = Object.assign({}, state, { ...action.payload })
            newTeamMemberState.team.push(action.payload.addTeamMember)
            return newTeamMemberState;
        case '[ACCOUNT] Remove Team Member Success':
            // Add the new Member to the state.
            const removeTeamMemberState = Object.assign({}, state, { ...action.payload })
            removeTeamMemberState.team.filter(person => person.id !== action.payload.removeTeamMember)
            return removeTeamMemberState;
        default:
            return state
    }
}
