import React from 'react';
import styled from 'styled-components';
import { SmartContainer } from '../../styles/Containers';
import { StyledIframe } from '../../styles/StyledHTML';

const ResponsiveEmbed = styled(SmartContainer)`
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`


export const OEmbedPlayer = ({src,title, radius}) => {

    // Parse Vimeo Embed
    const formatVimeoEmbed = (url) => {
        const vimeoParts = url.split('/');
        const vimeoId = vimeoParts[vimeoParts.length-1];
        return `https://player.vimeo.com/video/${vimeoId}`;
    }

    // Formate Youtube Embed
    const formatYoutubeEmbed = (url) => {
        let youtubeId;
        if((url.indexOf('youtu.be') > -1) || (url.indexOf('/embed/') > -1)){
            const parts = url.split('/');
            youtubeId = parts[parts.length-1];
        } else if(url.indexOf('v=') > -1){
            const qs = url.split('v=');
            youtubeId = qs[qs.length-1];
        }
        return `https://www.youtube.com/embed/${youtubeId}`
    }

    // Gaurd: No Src
    if(!src) return (<SmartContainer>No Video</SmartContainer>)

    // Run Parse
    let formattedUrl = src;
    if(src.indexOf('vimeo.com') > -1) formattedUrl = formatVimeoEmbed(src);
    else if((src.indexOf('youtube.com') > -1) || (src.indexOf('youtu.be') > -1)) formattedUrl = formatYoutubeEmbed(src);

    return (
        <ResponsiveEmbed>
             <StyledIframe radius width="784" height="441" src={formattedUrl} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></StyledIframe>
        </ResponsiveEmbed>
    )
}