import React from 'react'
import styled, { css } from 'styled-components';
import { respondTo } from '../../styles/Theme';
import { SmartContainer, Stack } from '../../styles/Containers';
import { StyledB, StyledH6 } from '../../styles/StyledHTML';
import { StyledBreadcrumbs } from '../../styles/matieralui/Breadcrumbs';


const StyledTopBar = styled(SmartContainer)`
${respondTo('tablet', css`
        /* display: fle; */
        display: block;
    `)}

`

export const TopBar = ({ subtitle, title, children, breadcrumbs }) => {
    return (
        <StyledTopBar flex align="center" justify="space-between" width="100%">
            <SmartContainer>
                <StyledB light small>{subtitle}</StyledB>
                <StyledH6>{title}</StyledH6>
                {breadcrumbs && (<StyledBreadcrumbs links={breadcrumbs} />)}
            </SmartContainer>
            {children}
        </StyledTopBar>
    )
}