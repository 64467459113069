

// Generic Model Reducers
import { DefaultModelReducers } from '../../../redux/DefaultModelReducers';

// Generic Model Reducers
const defaults = DefaultModelReducers('NetworkProduct');
export default defaults;

// If we want to add more... make a new reducers and use combind
// const commentReducers = combineReducers({
//     defaults,
//     otherReducers
//   });