import { Drawer } from "@mui/material";
import styled from "styled-components";
export const StyledDrawer = styled(Drawer)`

`
export const StyledBottomDrawer = styled(Drawer)`
    .MuiPaper-root {
        left: 1em;
        right: 1em;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding-bottom: 4em;
    }
`