import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Services
import { uniqueId } from '../../services/Utils'

// Components
import { App } from '../../App';
import { SmartContainer, Surface, Stack, StyledRichTextContent } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { FormInput } from '../../components/Forms/FormInput';
import { RichTextEditor, RichTextDisplay, HTMLToRichText, richTextToHTML} from '../../components/Forms/RichText';
import { EditorState } from 'draft-js';

import { StyledH1, StyledH6, StyledP } from '../../styles/StyledHTML';



export const TextInputsLab = () => {

    const [text, setText] = useState('');
    const [editorState, setEditorState] = useState(null);
    const [defaultEditorState, setDefaultEditorState] = useState(HTMLToRichText(''));
    const [html,setHTML] = useState('');

    const getHTML = () => {
        setHTML(richTextToHTML(editorState));
    }

    return (
        <App title="Lab: Text Inputs">
            <Stack>
                <Surface>
                    <Stack>
                        <StyledH6>FormInput as Text</StyledH6>
                        <FormInput label="Text" value={text} onChange={(value) => setText(value)} />
                        
                        <StyledH6>FormInput as Email</StyledH6>
                        <FormInput label="Email" type="email" value={text} onChange={(value) => setText(value)} />
                        
                        <StyledH6>FormInput as Password</StyledH6>
                        <FormInput label="Password" type="password" value={text} onChange={(value) => setText(value)} />
                        
                        <StyledH6>FormInput as Textarea</StyledH6>
                        <FormInput label="Textarea" rowsMax={4} multiline value={text} onChange={(value) => setText(value)} />
                        
                        <StyledH6>Output from state</StyledH6>
                        <StyledP>{text}</StyledP>
                    </Stack>
                </Surface>
                <Surface>
                    <Stack>
                        <StyledH6>Rich Text Editor</StyledH6>
                        <RichTextEditor label="Rich Text" 
                            value={editorState} 
                            defaultEditorState={defaultEditorState}
                            onChange={(value) => setEditorState(value)} />
                        <StyledButton variant="contained" color="primary" onClick={() => getHTML()}>GET HTML</StyledButton>
                        <StyledRichTextContent>{html}</StyledRichTextContent>
                    </Stack>
                </Surface>
            </Stack>
        </App>
    )
}