import { duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, replaceTags, getRevisions } from '../../../../redux/DefaultModelActions';
import { APIGet, APIBase } from '../../../../services/API';

const model = 'Guide';
const route = '/v1/guide';

export const searchGuides = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createGuide = (args) => createModel(model, route, args);
export const updateGuide = (id, args) => updateModel(model, route, id, args);
export const deleteGuide = (id) => deleteModel(model, route, id);
export const restoreGuide = (id) => restoreModel(model, route, id);
export const getGuide = (id) => getModel(model, route, id);
export const getGuideRevision = (id) => getRevisions(model, route, id);
export const duplicateGuide = (id) => duplicateModel(model, route, id);
export const replaceGuides = (terms, contentType, contentId) => replaceTags(model, route, terms, contentType, contentId);


export const loadGuide = async (id) => {

    try {
        const res = await APIGet(`${APIBase}${route}/${id}`)
        const json = await res.json();
        return json;
    } catch (error) {
        return error;
    }

}