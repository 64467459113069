import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components 
import { Autocomplete } from '../Forms/Autocomplete';
import { NetworkRoleSelect } from './NetworkRoleSelect';
import { SurfaceMessage } from '../Alerts/SurfaceMessage'

// Styled
import { Stack, HorizontalStack } from '../../styles/Containers'
import { StyledButton, StyledDangerButton } from '../../styles/matieralui/Button'
import { StyledB, StyledP } from '../../styles/StyledHTML'
import { StyledAreYouSure } from '../../styles/matieralui/Dialog'

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { updateNetworkAdmin, removeNetworkAdmin } from '../../redux/Network/network.actions'
import { NetworkRole } from '../../redux/Auth/auth.selectors'

export const EditNetworkAdminForm = ({networkId, admin}) => {

    const dispatch = useDispatch();
    const [role, setRole] = useState(admin.role);
    const [areYouSure, setAreYouSure] = useState(false);
    const fullNetwork = FullNetwork();
    const currentUserNetworkRole = NetworkRole(networkId);


    // --------------------------------
    // Interactions
    // --------------------------------

    const onSubmit = () => dispatch(updateNetworkAdmin(fullNetwork.network.id, admin.id, role));

    const onRemove = () => setAreYouSure(true)

    const onAreYouSure = (e) => {
        if(!e) setAreYouSure(false)
        else {
            setAreYouSure(false)
            dispatch(removeNetworkAdmin(fullNetwork.network.id, admin.id));
        }
    }

    const disableSubmit = (!role) ? true : false;
    
    // Must be part of a network
    if(!networkId) return (<SurfaceMessage title="Access Denied" />)
    if(currentUserNetworkRole !== 'superadmin' && admin.role === 'superadmin') return (<SurfaceMessage title="Access Denied" />)

    return (
        <Stack thinking={fullNetwork.updateAdminLoading || fullNetwork.removeAdminLoading}>

            <StyledB>{admin?.firstName}</StyledB>
            <StyledP light>{admin?.email}</StyledP>
            
            <NetworkRoleSelect networkId={networkId} value={role} onChange={(value) => setRole(value)} />

            <HorizontalStack>
                <StyledButton disabled={disableSubmit} onClick={onSubmit} variant="contained" color="primary">Update Admin</StyledButton>
                <StyledDangerButton variant="outlined" onClick={onRemove}>Remove Admin From Network</StyledDangerButton>
            </HorizontalStack>

            <StyledAreYouSure loading={false} open={areYouSure} onClose={onAreYouSure} label="Are you sure?" description="This will remove this user permanently from your account." agreeText="Yes, remove this user"/>
            
        </Stack>
    )
}