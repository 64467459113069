import { duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getModelBySlug, getRevisions } from '../../../../redux/DefaultModelActions';

const model = 'Series';
const route = '/v1/series';

export const searchSeries = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createSeries = (args) => createModel(model, route, args);
export const updateSeries = (id, args) => updateModel(model, route, id, args);
export const deleteSeries = (id) => deleteModel(model, route, id);
export const restoreSeries = (id) => restoreModel(model, route, id);
export const getSeries = (id, args) => getModel(model, route, id, args);
export const getSeriesRevision = (id) => getRevisions(model, route, id);
export const duplicateSeries = (id) => duplicateModel(model, route, id);