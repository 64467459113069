import React, { useState } from 'react';
import styled from 'styled-components'

// Drag & Drop
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid';

// Material UI
import CloseIcon from '@mui/icons-material/Close'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

// Styles
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers'
import { StyledButton, StyledDangerButton } from '../../styles/matieralui/Button'
import { StyledIconButton } from '../../styles/matieralui/IconButton'
import { StyledPopover } from '../../styles/matieralui/Popover'
import { StyledB, StyledP } from '../../styles/StyledHTML'

export const Repeater = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [removeCheck, setRemoveCheck] = useState(false);
    const [itemToRemove,setItemToRemove] = useState(null);

    // ---------------------------
    // Interactions
    // ---------------------------

    const handleAdd = () => {

        let defaultArgs;
        if(props.defaultArgs) {
            defaultArgs = {...props.defaultArgs,id:uuidv4()}
        } else {
            defaultArgs = {
                id: uuidv4()
            }
        }

        if(props.onChange) props.onChange([...props.datasource,defaultArgs]);
    }

    const handleRemove = (e, item) => { 
        
        // Show the Popover
        setRemoveCheck(true);

        // Anchor it to the currently item
        setAnchorEl(e.currentTarget)
        
        // Set Active Item
        setItemToRemove(item); 

    }

    const removeRepeatedItem = () => {
        if(!itemToRemove) return;
        setRemoveCheck(false);
        setAnchorEl(null)
        const remainingItems = props.datasource.filter(i => i.id !== itemToRemove.id)
        if(props.onChange) props.onChange(remainingItems);
        setItemToRemove(null);
    }

    const handleItemChange = (item,field,value) => {
        const updatedItems = props.datasource.map(i => {
            if(i.id !== item.id) return i;
            item[field] = value;
            return item;
        })
        if(props.onChange) props.onChange([...updatedItems]);
    }

    const handleDragEnd = (result) => { 
        
        const { destination, source, draggableId } = result;
        if(!destination) return;

        // Dropped in same place
        if(destination.droppableId === source.droppableId && destination.index === source.index) return;

        // Get Item
        const newItems = [...props.datasource]
        const movedItem = newItems.splice(source.index,1)[0];
        newItems.splice(destination.index,0,movedItem)
        
        if(props.onChange) props.onChange(newItems);

    }

    // ---------------------------
    // Renderers
    // ---------------------------

    const disableAdd = (props.limit && (props.datasource.length >= props.limit))

    const handlePopover = (e) => {
        setRemoveCheck(true);
        setAnchorEl(e.currentTarget)
    }
    const closePopover = () =>{
        setAnchorEl(null)
        setRemoveCheck(false);
    }
    // Main
    return (
        <Stack>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={props.id}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <Stack>
                                {props.datasource.map((item, index) => (
                                    <RepeatedItem 
                                        withHandle={props.withHandle} 
                                        index={index} 
                                        key={item.id} 
                                        component={props.component} 
                                        item={item} 
                                        onRemove={handleRemove} 
                                        onItemChange={handleItemChange}
                                        
                                        />
                                ))}
                                {provided.placeholder}
                            </Stack>
                        </div>
                    )}  
                </Droppable>
            </DragDropContext>
            <SmartContainer flex justify="flex-end">
                <StyledButton disabled={disableAdd} variant="outlined" onClick={() => handleAdd()}>{props.buttonText || 'Add'}</StyledButton>
            </SmartContainer>
           
            <StyledPopover open={removeCheck} anchorEl={anchorEl} onClose={() => closePopover()}>
                <Stack>
                    <StyledP>Are you sure you want to remove this item?</StyledP>
                    <HorizontalStack flex justify="flex-end">
                        <StyledButton onClick={closePopover}>Cancel</StyledButton>
                        <StyledDangerButton variant="contained" onClick={() => removeRepeatedItem()}>Remove</StyledDangerButton>
                    </HorizontalStack>
                </Stack>
            </StyledPopover>

        </Stack>
    )
}

const StyledRepeatedItemControls = styled(SmartContainer)``

export const RepeatedItemControls = (props) => {
    return (
        <StyledRepeatedItemControls flex>
            <StyledIconButton size="small" onClick={(e) => props.onRemove(e,props.item)}><CloseIcon size="small" /></StyledIconButton>
            {props.withHandle && (<StyledIconButton {...props.provided.dragHandleProps} size="small"><DragIndicatorOutlinedIcon size="small" /></StyledIconButton>)}
        </StyledRepeatedItemControls>
    )
}


export const RepeatedItem = (props) => {
    const SubComponent = props.component
    return (
        <Draggable draggableId={props.item.id} index={props.index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                    <HorizontalStack flex justify="space-between" align="center" {...provided.dragHandleProps}>
                        <SubComponent width="100%" item={props.item} onChange={props.onItemChange} />
                        <RepeatedItemControls withHandle={props.withHandle} item={props.item} provided={provided} onRemove={(e,item) => props.onRemove(e,item)} />
                    </HorizontalStack>
                </div>
            )}
        </Draggable>
    )
}