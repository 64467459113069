import React, { useState } from 'react';

// Components
import { LoginForm } from './LoginForm'

// Styles
import { SmartContainer, Stack, Surface } from '../../styles/Containers'

// SPACE CULTURE
import logo from '../../space/assets/logo-black-short.png';
import logoWhite from '../../space/assets/logo-white-short.png';
import { StyledH1, StyledH6, StyledImg, StyledP } from '../../styles/StyledHTML';
import { StyledButton } from '../../styles/matieralui/Button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { BreathAnimation } from '../../space/styles/AnimatedGradient';
import { Divider } from '@mui/material';



export const LoginPage = ({ redirect }) => {
    let path = '/'
    if (redirect) path = redirect
    const dispatch = useDispatch();
    const goTo = (r) => dispatch(push(r))

    return (
        <SmartContainer padding={10} >

            <Stack flex direction="column" align="center" justify="center" minHeight="70vh" zIndex="10" position="relative">
                <SmartContainer maxWidth="200px">
                    <StyledImg alt="Space Maker" src={logo} />
                </SmartContainer>

                <Surface variant="inverse">
                    <Stack maxWidth="400px" textAlign="center">
                        <SmartContainer><StyledH6 style={{ marginBottom: "0 !important" }}>Find God in the Space</StyledH6></SmartContainer>
                        <StyledP>Pray. Reflect. Grow.</StyledP>
                        <LoginForm redirect={path} />
                    </Stack>




                    <Stack maxWidth="400px" textAlign="center">
                        <SmartContainer></SmartContainer>
                        <Divider />
                        <StyledButton variant='contained' fullWidth onClick={() => goTo('/signup')}>Sign Up For Free</StyledButton>
                    </Stack>

                </Surface>


            </Stack>

            <SmartContainer position="fixed" left="0" bottom="2em" zIndex="5" align="center" flex justify="Center" width="100%">
                <SmartContainer maxWidth="100px"><StyledImg alt="Space Maker" src={logoWhite} /></SmartContainer>
            </SmartContainer>
            <BreathAnimation />
        </SmartContainer>
    )
}