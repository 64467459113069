import React, { useState, useEffect, useMemo } from 'react';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

// Styled
import { StyledAutocomplete, StyledTextField } from '../../styles/matieralui/Form';
import { StyledCircularProgress } from '../../styles/matieralui/Progress';
import { StyledButton } from '../../styles/matieralui/Button';

const StyledNoOptionsButton = styled(StyledButton)`
    width: 100%;
`

export const Autocomplete = ({label, onSearch, onClose, options, onChange, optionLabelKey, value, noOptionsText, noOptionsTextAction}) => {
    
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    let loading = false;
    if(onSearch) loading = open && options.length === 0;


    const search = useMemo(() => throttle((searchString) => { onSearch(searchString)}, 1000),[])


    // --------------------------------
    // Effects
    // --------------------------------

    useEffect(() => { 
        if(!onSearch) return;
        search(inputValue)
    },[inputValue])

    // On Selection
    const handleChange = (e,newValue) => onChange(newValue)
    
    // On Searching
    const handleInputChange = (e,newValue) => setInputValue(newValue);


    // --------------------------------
    // Renderers
    // --------------------------------

    const renderInput = (params) => {
        return (
            <StyledTextField {...params} 
                label={label}
                variant="filled"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <StyledCircularProgress /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
            />
        )
    }

    const getOptionLabel = (option) => option[optionLabelKey]

    const handleOnClose = () => {
        if(onClose){
            setOpen(false);
            onClose();
        } else {
            setOpen(false)
        }

    }

    const renderNoOptions = () => {
        //noOptionsTextAction
        if(noOptionsTextAction) return (<StyledNoOptionsButton onMouseDown={() => noOptionsTextAction(inputValue)}>{noOptionsText}</StyledNoOptionsButton>);
        if(noOptionsText) return noOptionsText;
        return 'No Options';
    }


    return (
        <StyledAutocomplete 
            open={open}
            onOpen={() => setOpen(true)}
            onClose={handleOnClose}
            getOptionLabel={getOptionLabel}
            options={options}
            loading={loading}
            renderInput={renderInput}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={handleChange}
            onInputChange={handleInputChange}
            noOptionsText={renderNoOptions()}
        
        />
    )
}