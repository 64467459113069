import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';


// TCC Redux
import { FullUI } from '../../redux/UI/ui.selectors'
import { closeToast } from '../../redux/UI/ui.actions'
// Components
import { ResetForm } from './ResetForm'
import { Toast } from '../Toast/Toast'
// Styles
import { SmartContainer, Surface } from '../../styles/Containers'





export const ResetPage = () => {
    // Read State
    const dispatch = useDispatch()
    const ui = FullUI();

    const { token } = useParams();

    return (
        <SmartContainer flex align="center" justify="center" padding={10}>

            <ResetForm token={token} />

            {ui.toast?.open && (<Toast onClose={() => dispatch(closeToast())} open={ui.toast?.open} severity={ui.toast?.severity} message={ui.toast?.message} />)}
        </SmartContainer>
    )
}