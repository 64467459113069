import React from 'react';
import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player'
import { SmartContainer } from '../../styles/Containers';
import { Theme } from '../../styles/Theme';

// https://github.com/CookPete/react-player

const ResponsiveWrap = styled(SmartContainer)`
    position: relative;
    padding-top: 56.25%;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
    ${props => (props.portrait == true) ? css`
        padding-top: 178%;
    ` : ''}

`


const StyledReactPlayer = styled(ReactPlayer)`

    ${props => (props.radius) ? css`
        iframe,
        video,
        .w-chrome {
            border-radius: ${Theme.radius};
        }
    ` : ''}
`;



export const UniversalVideoPlayer = (props) => {
    return (
        <ResponsiveWrap portrait={props?.portrait}>
            <StyledReactPlayer className="react-player" radius={props.radius} width="100%" height="100%" {...props} />
        </ResponsiveWrap>

    )
}