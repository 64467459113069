import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { App } from '../../App';
import { FullScreenLoader } from '../Loaders/FullScreenLoader'
import { FullAccount } from '../../redux/Account/account.selectors';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { RouteBreadCrumbs } from '../../routes';

import { PlanSelector } from './PlanSelector'
import { CustomerPortal } from './CustomerPortal'
import { NetworkProductList } from '../../modules/NetworkProducts/NetworkProductList';
import { Stack, Surface, SmartContainer } from '../../styles/Containers';
import { StyledH3, StyledP } from '../../styles/StyledHTML';
import { StyledButton } from '../../styles/matieralui/Button'
import { Card } from '../Layout/Card'
import { StyledCloseButton } from '../../space/components/Display/StyledCloseButton';
import { postMessage } from '../../services/Utils';
import { BuyMeACoffeePanel } from '../../space/components/BuyMeACoffee/BuyMeACoffeePanel';


export const BillingComponent = () => {
    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const currentNetwork = CurrentNetwork();
    const { billing } = currentNetwork;
    const goTo = (path) => dispatch(push(path))


    // Loader
    if (fullAccount.accountLoading) return (<FullScreenLoader />)


    const renderBody = () => {

        // Select A Plan
        if (!fullAccount?.account?.plan) return (<PlanSelector />)

        // Update Subscription
        if (fullAccount?.account?.subscriptionStatus !== 'canceled') return (<CustomerPortal />)

        // Reactivate
        if (fullAccount?.account?.subscriptionStatus === 'canceled') return (<PlanSelector />)

    }


    return (
        <Stack>
            {renderBody()}
        </Stack>
    )
}


export const BillingPage = () => {

    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const currentNetwork = CurrentNetwork();
    const { billing } = currentNetwork;
    const goTo = (path) => dispatch(push(path));


    useEffect(() => {

        ///// OLD REV CAT AND STRIPE
        // No Active or Canceled Subscription
        // if (fullAccount?.account?.subscriptionStatus === null) postMessage('OPEN_PLAN_SELECTOR', { account: fullAccount?.account });
        // else if (fullAccount?.account?.subscriptionStatus === 'paused' || fullAccount?.account?.subscriptionStatus === 'canceled') {
        //     postMessage('OPEN_PLAN_MANAGER', { account: fullAccount?.account, back: document?.referrer });
        // }
        /// NO MORE NATIVE PAYMENT SCREEN

    }, []);

    // Loader
    if (fullAccount.accountLoading) return (<FullScreenLoader />)


    const renderBody = () => {

        // Select A Plan
        // if (!fullAccount?.account?.plan) return (<PlanSelector />)

        // Update Subscription
        if (fullAccount?.account?.subscriptionStatus && fullAccount?.account?.subscriptionStatus !== 'canceled') return (<CustomerPortal />)

        // Reactivate
        // if (fullAccount?.account?.subscriptionStatus === 'canceled') return (<PlanSelector />)

        // Buy Me A Coffee
        return (<BuyMeACoffeePanel />);

    }

    const renderNetworkProducts = () => {
        if (!billing.hasNetworkProducts) return (<div></div>)
        return (
            <Stack>
                <Surface>
                    <Card title="View Orders" text="See past purchases and orders" buttonText="View Orders" onClick={() => goTo('/billing/orders')} />
                </Surface>
                <NetworkProductList />
            </Stack>
        )
    }

    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <StyledCloseButton onClick={() => goTo('/')} />
            </SmartContainer>
        )

    }

    return (
        <App noNav={true} topBarChildren={renderTopBarChildren()}>

            <Stack>

                {renderBody()}

            </Stack>

        </App>
    )
}