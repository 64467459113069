import React from 'react';
import { config } from '../../config'
import { FormSelect } from '../../components/Forms/FormSelect'

export const OrderStatusSelector = (props) => {
   
    return (
        <FormSelect label="Status" value={props.value} onChange={props.onChange}>
            {props.withAll && (<option key="all" value="all">All</option>)}
            {config.orderStatuses.map(status => (<option key={status} value={status}>{status}</option>))}
        </FormSelect>
    )

}