import React, { useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { visitorToken, getCurrentUser, hasVisitorToken, getSession } from '../../redux/Auth/auth.actions'

// Components
import { FullScreenLoader } from './FullScreenLoader';

export const ScopedVisitorLoader = ({accountId, networkId}) => {

    // Get Session
    const isVisitor = hasVisitorToken();
    const session = getSession();
    
    const dispatch = useDispatch();

    // Get Token
    useEffect(() => { 
        if(!session) dispatch(visitorToken(accountId, networkId));
        else if(!isVisitor) dispatch(getCurrentUser());
        else dispatch(visitorToken(accountId, networkId));
    }, []);

    return (<FullScreenLoader />)

}