import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { NoAccessPage } from '../../pages/Account/NoAccessPage'
import { FullScreenLoader } from '../Loaders/FullScreenLoader'

// Redux
import { Me, FullAuth } from '../../redux/Auth/auth.selectors'
import { switchAccount } from '../../redux/Auth/auth.actions'



export const SwitchAccount = () => {

    const dispatch = useDispatch();
    const me = Me();
    const { id } = useParams();
    const accountId = parseInt(id)
    const auth = FullAuth();
    const [switchAccountId, setSwitchAccountId] = useState(null);

    // On Load
    useEffect(() => {
        
        const newAccount = me.accounts.filter(account => accountId === account.id)[0];
        if(!newAccount) setSwitchAccountId('NOACCESS');
        else setSwitchAccountId(newAccount.id);

    
    },[]);

    useEffect(() => {
        if(switchAccountId === 'NOACCESS') return;
        if(!switchAccountId) return;
        dispatch(switchAccount(switchAccountId));
    
    },[switchAccountId])

    if(switchAccountId === 'NOACCESS') return (<NoAccessPage />)

    return (<FullScreenLoader />)    
}