import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'


// Components
import { App } from '../../App';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader'
import { ModelList } from '../../components/PaginatedList/ModelList';

// Styles
import { Stack, Surface, SmartContainer } from '../../styles/Containers';

// Redux
import { searchNetworkOrdersAccountLevel } from './redux/networkorder.actions';
import { FullNetworkOrders } from './redux/networkorder.selectors';
import { FullAccount } from '../../redux/Account/account.selectors';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { NetworkOrderCard } from './NetworkOrderCard';
import { RouteBreadCrumbs } from '../../routes';


export const AccountNetworkOrdersPage = () => {

    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const currentNetwork = CurrentNetwork();
    const { billing } = currentNetwork;
    const goTo = (path) => dispatch(push(path))
    const handleSelect = (item) => dispatch(push('/billing/orders/'+item.id))

    // Loader
    if(fullAccount.accountLoading) return (<FullScreenLoader />)

    const renderCards = (item) => {
    
        const handleSelect = (item) => {
            // Redirect to edit page
            dispatch(push(`/billing/orders/${item.id}`))
        }
        
        // Guard
        if(!item.full) return (<Surface key={item.id} width="100%">Loading...</Surface>)
    
        // Product
        return (<NetworkOrderCard onClick={(item) => handleSelect(item)} key={item.id} order={item.full} />)
    
    }

    
    return (
        <App title="Orders" breadcrumbs={[RouteBreadCrumbs.dashboard,RouteBreadCrumbs.billing]}>

            <Stack>

                <ModelList 
                    model="NetworkOrders" 
                    action={searchNetworkOrdersAccountLevel} 
                    selector={FullNetworkOrders} 
                    onSelect={handleSelect}
                    defaultView="published"
                    variant="cards"
                    defaultLimit="12"
                    customRenderer={renderCards}
                    />

            </Stack>
            
        </App>
    )    
}

