import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { NewContent } from '../../components/Content/NewContent';

// Styles
import { SmartContainer } from '../../../styles/Containers'
import { GuidesList } from './GuidesList';

// Redux
import { restoreGuide, createGuide } from './redux/guides.actions';
import { FullGuides } from './redux/guides.selectors';
import { RouteBreadCrumbs } from '../../../routes';


export const PageNetworkGuides = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullGuides();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => { if (store.createSuccess) dispatch(push(`/network/guides/${store.create.id}`)) }, [store?.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {

        if (item?.full?.deletedAt) dispatch(restoreGuide(item.id))
        else dispatch(push(`/network/guides/${item.id}`))
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <Link to="/network/tags">Tags</Link>
                <NewContent label="New Guide" selector={FullGuides} createAction={createGuide} />
            </SmartContainer>
        )

    }

    // Render Page
    return (
        <App title="Guides" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            <GuidesList onSelect={handleSelect} />

        </App>
    )
}