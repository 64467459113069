import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { NetworkApp } from '../../components/Network/NetworkApp';
import { EditMediaForm } from './EditMediaForm';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader';

// Styles
import { SmartContainer } from '../../styles/Containers'

// Redux
import { getMedia, getNetworkMedia } from './redux/media.actions';
import { FullMedia } from './redux/media.selectors';
import { RouteBreadCrumbs } from '../../routes';


export const NetworkEditMediaPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullMedia();
    const { id } = useParams();
    const thisId = parseInt(id)

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Get This Item
        dispatch(getNetworkMedia(thisId))

    },[])


    // --------------------------
    // Interactions
    // --------------------------

    // --------------------------
    // Renders
    // --------------------------


    // Render Page

    return (
        <NetworkApp title="Media" breadcrumbs={[RouteBreadCrumbs.dashboard,RouteBreadCrumbs.networkMedia]}>
            
            {store?.activeItemLoading && (<FullScreenLoader />)}
            {!store?.activeItemLoading && (
                 <SmartContainer thinking={store?.deleteLoading || store?.updateLoading}>
                    <EditMediaForm network={true} item={store?.activeItem} />
                </SmartContainer>
            )}
        </NetworkApp>
    )
}