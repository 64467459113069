import React, {useState,useEffect} from 'react';
import { FullNetwork } from './network.selectors';

export const useDocumentTitle = (pageTitle) => {

    const fullNetwork = FullNetwork();

    const [title,setTitle] = useState('Loading');

    const setHTMLTitle = (title) => {

        if(!fullNetwork?.network?.name) return;
        document.title = `${title} | ${fullNetwork.network.name}`;
        
    }


    useEffect(() => { setTitle(pageTitle); }, [pageTitle]);
    useEffect(() => { setHTMLTitle(title);},[title,fullNetwork.network])

    return [title,setTitle];
}