import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom'

const StyledLogo = styled.img`
    width: 100%;
    height: auto;
    display: block;
    max-width: 50px;
    margin: 0 auto;
`;

export const Logo = ({src}) => (
    <Link to="/">
        <StyledLogo src={src} alt="Home Logo"/>
    </Link>
)