import React from 'react';
import styled from 'styled-components';
import { SmartContainer } from '../../../styles/Containers';
import { animated } from 'react-spring';
import { useAnimatedMeditationScreen } from '../../services/SpaceUtils';


const StyledScreen = styled(SmartContainer)`
    flex: 1;
    width: 100%;
    position: relative;
    z-index: 2;
    /* height: 100vh; */
    /* overflow: scroll; */
    padding-bottom: 6em;
`

export const Screen = ({ children, ...rest }) => {
    const { animatedProps } = useAnimatedMeditationScreen();

    return (

        <StyledScreen {...rest}>
            <animated.div style={animatedProps}>
                {children}
            </animated.div>
        </StyledScreen>
    )
}