import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { facebookPixelInit, facebookPixelTrackEvent, facebookPixelTrackPageView } from '../Integrations/Facebook/FacebookService';
import { googleAnalyticsInit, googleAnalyticsTrackPageView, googleAnalyticsTrackEvent } from '../Integrations/GoogleAnalytics/GoogleAnalyticsService';
import { segmentInit, segmentTrackEvent, segmentIdentify, segmentTrackPageView } from "../Integrations/Segment/SegmentService";
import { platformTrackEvent } from "../PlatformEvents/redux/platformevent.actions";

// Store
import { FullNetwork } from "../../redux/Network/network.selectors";
import { Me } from '../../redux/Auth/auth.selectors';



// Use Analytics Page View
export const useAnalytics = (init) => {

    const [ready, setReady] = useState(false);

    // Load Network
    const fullNetwork = FullNetwork();
    const me = Me();

    // Grab The Location
    const location = useLocation();

    // Mount: Initialize
    useEffect(() => {

        // Guard: Not Loaded
        if (!fullNetwork?.network) return;
        if (init != true) return;

        // Google Analytics
        googleAnalyticsInit(fullNetwork);

        // Facebook Pixel
        facebookPixelInit(fullNetwork);

        // Segment
        segmentInit(fullNetwork);

        // Ready
        setReady(true);


    }, [fullNetwork]);

    // PageView: On Page Change
    useEffect(() => {

        if (!ready) return;

        trackPageView(location);

    }, [location, ready])

    // Identify
    useEffect(() => {

        if (!me) return;
        if (!ready) return;

        identify(me)

    }, [me, ready])

    // Track PageView
    const trackPageView = (location) => {

        // Send to Google
        googleAnalyticsTrackPageView({ location, fullNetwork });

        // Send to Facebook
        facebookPixelTrackPageView({ location, fullNetwork });

        // Segment
        segmentTrackPageView({ location, fullNetwork });
    }

    // Track Events to Destinations
    const trackEvent = (event, args) => {

        // Send To Google
        const googleArgs = (args?.google) ? args.google : args;
        googleAnalyticsTrackEvent({ event, args: googleArgs, fullNetwork });

        // Send To Facebook
        const facebookArgs = (args?.facebook) ? args.facebook : args;
        facebookPixelTrackEvent({ event, args: facebookArgs, fullNetwork });

        // Segment
        const segmentArgs = (args?.segment) ? args.segment : args;
        segmentTrackEvent({ event, args: segmentArgs, fullNetwork });

        // Platform Events (Native)
        platformTrackEvent(event, args);

    }

    // Run Identify on Destinations
    const identify = (user) => {

        // Current Account
        const account = user?.accounts?.filter(account => account.loggedIn === true)
        const currentAccount = (account?.length > 0) ? account[0] : null;
        const MyAccount = {
            me: user,
            account: currentAccount
        }
        if (!currentAccount?.id) return;
        if (!user?.id) return;
        const ID = `a:${currentAccount.id}u:${user.id}`;

        // Segment
        segmentIdentify(ID, MyAccount);

    }

    return {
        trackEvent,
        identify
    }

}
