import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { PaginatedList } from '../PaginatedList/PaginatedList'
import { StyledModal } from '../../styles/matieralui/Modal'
import { NewNetworkAdminForm } from './NewNetworkAdminForm';
import { EditNetworkAdminForm } from './EditNetworkAdminForm';

// Styled
import { Stack} from '../../styles/Containers'
import { StyledB, StyledP } from '../../styles/StyledHTML'
import { StyledButton } from '../../styles/matieralui/Button'

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors'
import { getNetworkTeam } from '../../redux/Network/network.actions'
import { NetworkRole } from '../../redux/Auth/auth.selectors'

export const NetworkTeamList = ({networkId}) => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [activeUser, setActiveUser] = useState(null);
    const [activeUserRole, setActiveUserRole] = useState(null);
    const [openNewAdminModal, setOpenNewAdminModal] = useState(false);
    const fullNetwork = FullNetwork(); 
    const currentUserNetworkRole = NetworkRole(networkId);

    // ---------------------------
    // Effects
    // ---------------------------

    // Get Team List
    useEffect(() => { if(fullNetwork.network?.id) dispatch(getNetworkTeam(networkId)) },[fullNetwork.network])

    // Close new admin modal
    useEffect(() => { if(fullNetwork.newAdminSuccess) setOpenNewAdminModal(false); },[fullNetwork.newAdminLoading])

    // Close update admin modal
    useEffect(() => { if(fullNetwork.updateAdminSuccess) setOpenModal(false); },[fullNetwork.updateAdminSuccess])
    useEffect(() => { if(fullNetwork.removeAdminSuccess) setOpenModal(false); },[fullNetwork.removeAdminSuccess])

    // ---------------------------
    // Interactions
    // ---------------------------

    const handleUserClick = (user) => {

        const thisUser = fullNetwork.team.filter(u => u.id === user.id)[0]
        setActiveUser(thisUser);
        setOpenModal(true)
    }

    const handleCloseModal = () => setOpenModal(false)


    const handleNewNetworkAdmin = () => setOpenNewAdminModal(true)

    const handleCloseNewAdminModal = () => setOpenNewAdminModal(false)

    // ---------------------------
    // Render
    // ---------------------------


    const formattedTeam = (fullNetwork.team) ? fullNetwork.team.map(user => { return {id: user.id, title: `${user.firstName} ${user.lastName}`, description: user.role}}) : []


    return (
        <Stack>
            <StyledB>Network Team</StyledB>
            <StyledP>List of network admins on this network</StyledP>
            <StyledButton onClick={handleNewNetworkAdmin} variant="outlined" color="primary">New Network Admin</StyledButton>
            <PaginatedList 
                onClickItem={handleUserClick}
                loading={fullNetwork?.teamLoading} 
                items={formattedTeam}
                noControls={true}
                variant="cards"
                />

            <StyledModal
                open={openModal}
                onClose={handleCloseModal}
                label="Edit Network Admin"
                description="Edit the network administrator"
                >
                    <EditNetworkAdminForm networkId={networkId} admin={activeUser} />
                    
                    
            </StyledModal>

            <StyledModal
                open={openNewAdminModal}
                onClose={handleCloseNewAdminModal}
                label="New Network Admin"
                description="Create a new network administrator"
                >
                    <NewNetworkAdminForm networkId={networkId} />
                    
            </StyledModal>
        </Stack>
    )
}