import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createUserProgress, hasUserCompletedMeditation, updateUserProgress } from './userprogress.actions';
import { FullUserProgress } from './userprogress.selectors';

export const useHasCompletedMeditation = (meditationId) => {
    const [loading, setLoading] = useState(null);
    const [result, setResult] = useState(null);
    const [complete, setComplete] = useState(false);

    const checkMeditationStatus = async () => {
        setLoading(true);
        const res = await hasUserCompletedMeditation({ meditationId });
        if (res?.length > 0) {
            setComplete(true);
        } else {
            setComplete(false);
        }
        setLoading(false);

    }

    useEffect(() => {
        if (!loading) {
            setResult(null);
            setComplete(null);
            checkMeditationStatus();
        }
    }, [meditationId])
    return {
        hasCompletedMeditationLoading: loading,
        hasCompletedMeditation: complete
    }
}

// export const useUserProgress = ({ progressId = null, meditationId = null, seriesId = null }) => {

//     const [pId, setProgressId] = useState(null);
//     const [mId, setMeditationId] = useState(null);
//     const [sId, setSeriesId] = useState(null);


//     const dispatch = useDispatch();
//     const store = FullUserProgress();

//     // New User Rating
//     const saveUserProgress = (type, progress) => {
//         // const args = {
//         //     MeditationId: mId,
//         //     SeriesId: sId,
//         //     type,
//         //     meta: {
//         //         progress
//         //     }
//         // }

//         // dispatch(createUserProgress(args))
//         // // else dispatch(updateUserProgress(pId, args))

//     }

//     // useEffect(() => {
//     //     console.log("INIT")
//     //     console.log(pId, "-- pid")
//     //     if (progressId) setProgressId(progressId);
//     //     else setProgressId(null);

//     //     if (seriesId) setSeriesId(seriesId);
//     //     else setSeriesId(null);

//     //     if (meditationId) setMeditationId(meditationId)
//     //     else setMeditationId(null);

//     // }, [seriesId, meditationId, progressId]);

//     // useEffect(() => {
//     //     if (store.createSuccess) {
//     //         if (!pId) setProgressId(store?.create?.id)

//     //     }
//     // }, [store?.createLoading])

//     return {
//         saveUserProgress,
//         fullUserProgress: store,
//         progressId: pId
//     }

// }


