import styled from 'styled-components';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Theme } from '../Theme'


export const StyledSnackbar = styled(Snackbar)`

    box-shadow: none;
    .MuiPaper-root {
        box-shadow: ${Theme.shadow.two};
        border-radius: ${Theme.radius};
        display: flex;
        align-items: center;
    }
`;



export const StyledAlert = styled(MuiAlert)`


`;
