import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SmartContainer, Stack } from '../../../styles/Containers';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { FormInput } from '../../../components/Forms/FormInput';

import logo from '../assets/segment-logo.png';
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledSwitch } from '../../../styles/matieralui/Form';

import { useSaveIntegration } from '../integrations.hooks';
import { IntegrationCard } from '../IntegrationCard';
import { IntegrationFormHeader } from '../IntegrationFormHeader';
/*
<script>
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="jCL5nD5dtfQIg0FVPo542Nh8mFDW65vD";analytics.SNIPPET_VERSION="4.13.2";
  analytics.load("jCL5nD5dtfQIg0FVPo542Nh8mFDW65vD");
  analytics.page();
  }}();
</script>
*/

export const SegmentForm = ({meta, open = false, network = false}) => {
    const key = 'segment';
    const title = "Segment";
    const description = "Adds Segment tracking.";

    // Use Integration Save
    const { openForm, setOpenForm, saveIntegration, thinking } = useSaveIntegration({open, network});
    
    // Form Fields
    const [trackingID,setTrackingID] = useState('');
    const [enabled,setEnabled] = useState(false);

    // Set Default values
    useEffect(() => {
        if(!meta) return;
        if(meta[key] && meta[key].enabled) setEnabled(meta[key].enabled);
        if(meta[key] && meta[key].trackingId) setTrackingID(meta[key].trackingId);
    },[meta])


    // Format Save Data
    const handleSave = () => {
        const m = {...meta};
        if(!m[key]) m[key] = {};
        m[key].enabled = enabled;
        m[key].trackingId = trackingID;
        saveIntegration(m);
    }

    return (
        <SmartContainer thinking={thinking}>
            <IntegrationCard clickable onClick={() => setOpenForm(true)} 
                title={title}
                description={description}
                logo={logo}
                />

            <StyledModal
                open={openForm}
                onClose={() => setOpenForm(false)}
                >
                    <Stack>
                        <IntegrationFormHeader title={title} logo={logo}>
                            <StyledSwitch color="primary" checked={enabled} name="enabled" onChange={(e) => setEnabled(e.target.checked)} />
                        </IntegrationFormHeader>
                                              
                        <FormInput label="Tracking ID" type="text" onChange={setTrackingID} value={trackingID} helperText="Your Google Analytics Tracing ID" />
                        <StyledButton variant="contained" color="primary" onClick={handleSave}>Save</StyledButton>
                    </Stack>
                    
                </StyledModal>
        </SmartContainer>
    )
}