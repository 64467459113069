import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export const MapMarker = ({ position, onClick }) => {
    const handleClick = (e) => {
        if(onClick) onClick();
    }
    return (
        <Marker position={position} eventHandlers={{
            click: (e) => {
                handleClick(e)
            },
          }}>
        </Marker>
    )
}



export const MapContext = ({ center, zoom, markers, children, onMarkerClick }) => {
    const map = useMap();
    const handleClick = (m) => {
        if(onMarkerClick) onMarkerClick(m)
    }
    useEffect(() => {
        map.setView(center, zoom);
    }, [center]);
    return (
        <>
            <TileLayer url='https://{s}.tile.osm.org/{z}/{x}/{y}.png' />
            {markers.map(m => {
                return (
                    <MapMarker position={m.coord} onClick={() => handleClick(m)} />
                )
            })}
            {children}
        </>
    )
}

export const MapView = ({ center = [49.1951, 16.6068], zoom = 13, markers = [], children, onMarkerClick }) => {

    return (
        <MapContainer style={{ height: "450px", width: "100%" }} attributionControl={true} animate={true} center={center} zoom={zoom}>
            <MapContext center={center} zoom={zoom} markers={markers} onMarkerClick={onMarkerClick}>{children}</MapContext>
        </MapContainer>
    )
}
