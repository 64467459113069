import { searchModels, createModel, updateModel, deleteModel, restoreModel, getModel } from '../../../redux/DefaultModelActions';
import { APIBase, APIGet } from '../../../services/API';
import { useState, useEffect } from 'react';
const model = 'NetworkOrder';
const route = '/v1/networkorder';


// Defaults
export const searchNetworkOrders = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createNetworkOrder = (args) => createModel(model, route, args);
export const updateNetworkOrder = (id, args) => updateModel(model, route, id, args);
export const deleteNetworkOrder = (id) => deleteModel(model, route, id);
export const restoreNetworkOrder = (id) => restoreModel(model, route, id);
export const getNetworkOrder = (id) => getModel(model, route, id);

// Account Restriction
export const searchNetworkOrdersAccountLevel = (page, limit, search, view, args) => searchModels(model, route + '/account', page, limit, search, view, args);
export const getNetworkOrderAccountLevel = (id) => getModel(model, route + '/account', id);


export const useNetworkOrderSummaries = () => {

    const [summaryStats, setSummaryStats] = useState(null);
    const [summaryLoading, setLoading] = useState(false);
    const [summaryFromDate, setSummaryFromDate] = useState(null);

    const getSummary = async (from) => {

        setLoading(true);

        let path = `${APIBase}/v1/networkorder/product/stats/`;
        if (from) path += `?from=${from}`

        try {
            const res = await APIGet(path)
            const json = await res.json();

            setSummaryStats(json.data.summary);
            setLoading(false);
            return json;
        } catch (error) {
            setLoading(false);
            return error;
        }

    }

    useEffect(() => {
        getSummary(summaryFromDate);
    }, [summaryFromDate])


    return {
        summaryStats,
        summaryLoading,
        setSummaryFromDate,
        summaryFromDate
    }
}