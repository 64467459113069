import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../../App';
import { FullScreenLoader } from '../../../components/Loaders/FullScreenLoader';
import { respondTo, Theme } from '../../../styles/Theme';

// Styles
import { SmartContainer, Stack, StyledBottomContainer, StyledGrid, StyledGridItem, Surface } from '../../../styles/Containers'
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledB, StyledH5, StyledP } from '../../../styles/StyledHTML';
import { StyledList, StyledListItem } from '../../../styles/matieralui/List';

// Redux
import { getSeries, searchSeries } from '../Series/redux/series.actions';
import { FullSeries, findSeries } from '../Series/redux/series.selectors';
import { StyledH1, StyledImg } from '../../../styles/StyledHTML';

import { StyledMediaImage } from '../../components/Display/MediaAsset';

import { useAnimatedFadeIn } from '../../services/SpaceUtils';

import { FullMeditations } from './redux/meditations.selectors';

import { CurrentNetwork } from '../../../redux/Network/network.selectors';


import logoWhite from '../../assets/logo-white.png';
import gradient from '../../assets/gradient.jpg';
import { animated } from 'react-spring';
import { SpaceIconStar } from '../../styles/Icons';
import { isNativeApp, postMessage } from '../../../services/Utils';

const SpaceLogo = styled(SmartContainer)`
    width: 100%;
    /* height: 75vh; */
    background: url(${gradient}) no-repeat;
    background-size: cover;
    background-position: 100% 100%;
    /* position: fixed; */
    top: 0;
    left: 0%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
    /* border-radius: 1000px; */
    /* transform: translateY(-20vh); */
    img {
        max-width: 250px;
    }
`
const StyledArch = styled.div`
    background-color: ${Theme.colors.base};
    width: 100%;
    height: 10vh;
    border-radius: ${Theme.radiusLarge};
    margin-top: -5vh;
`


const StyledContent = styled(SmartContainer)`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 4em;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
`
const StyledDots = styled(SmartContainer)`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    max-width: 200px;
`;
const StyledDot = styled(SmartContainer)`
    width: 10px;
    height: 10px;
    border-radius: 1000px;
    background-color: ${Theme.colors.pink};
    ${props => (props?.active) ? css`opacity:1;` : css`opacity:0.5;`}
`

const OnboardingScreen = ({ children }) => {
    const { animatedProps } = useAnimatedFadeIn();
    return (
        <animated.div style={animatedProps}>
            {children}
        </animated.div>
    )
}


export const PageOnboarding = () => {

    /**
     * TODO:
     * [ ] Get A free Series
     */

    // New Modal
    const dispatch = useDispatch();
    const nativeApp = isNativeApp();


    const [screen, setScreen] = useState(1);

    const goTo = (path) => dispatch(push(path))
    const handleNext = () => {
        if (screen !== 3) {
            setScreen(screen + 1)
        } else {
            if (nativeApp) {
                postMessage('LOCAL_REMINDERS', { title: "Reminders", message: "There's no better time than now.", back: document?.referrer });
                goTo('/')
            } else {
                goTo('/')
            }
        }
    }
    const handlePrev = () => {
        setScreen(screen - 1)
    }


    // --------------------------
    // Renders
    // --------------------------


    // Render Page
    return (
        <App noNav={true} padding={0}>
            <SmartContainer flex direction="column" justify="space-between" height="100vh">
                <SpaceLogo>
                    <StyledImg src={logoWhite} />
                </SpaceLogo>

                <StyledContent>
                    <SmartContainer>
                        {screen === 1 && (
                            <OnboardingScreen>
                                <Stack>
                                    <StyledP textAlign="center"><StyledB>HEIGHTEN YOUR AWARENESS OF GOD IN YOUR LIFE</StyledB></StyledP>
                                    <SmartContainer flex align="center" justify="center">
                                        <SpaceIconStar />
                                        <SpaceIconStar />
                                        <SpaceIconStar />
                                        <SpaceIconStar />
                                        <SpaceIconStar />
                                    </SmartContainer>
                                    <StyledP light textAlign="center">"I've been searching for something that would help me make more space for God in my everyday life. This app is a game changer!"</StyledP>
                                </Stack>
                            </OnboardingScreen>
                        )}
                        {screen === 2 && (
                            <OnboardingScreen>
                                <Stack>
                                    <StyledP textAlign="center"><StyledB>GOD IS IN THE SPACE YOU GIVE</StyledB></StyledP>
                                    <StyledP light textAlign="center">Your best self is connected to your relationship with God. We create a simple and intentional space to experience God in your day to day.</StyledP>
                                </Stack>
                            </OnboardingScreen>
                        )}
                        {screen === 3 && (
                            <OnboardingScreen>
                                {nativeApp && (
                                    <Stack>
                                        <StyledP textAlign="center"><StyledB>SET TIME ASIDE FOR GOD</StyledB></StyledP>
                                        <StyledP light textAlign="center">A daily notification that doesn't distract you but focuses you on your relationship with God.</StyledP>
                                    </Stack>
                                )}
                                {!nativeApp && (
                                    <Stack>
                                        <StyledP textAlign="center"><StyledB>SET TIME ASIDE FOR GOD</StyledB></StyledP>
                                        <StyledP light textAlign="center">A daily notification that doesn't distract you but focuses you on your relationship with God. Download the native app from the Apple and Google Play stores.</StyledP>
                                    </Stack>
                                )}


                            </OnboardingScreen>
                        )}
                    </SmartContainer>
                    <SmartContainer flex align="center" justify="space-between" width="70%">
                        <StyledButton disabled={(screen === 1)} onClick={handlePrev}>Prev</StyledButton>
                        <StyledDots>
                            <StyledDot active={(screen === 1)} />
                            <StyledDot active={(screen === 2)} />
                            <StyledDot active={(screen === 3)} />
                        </StyledDots>
                        <StyledButton onClick={handleNext}>Next</StyledButton>
                    </SmartContainer>
                </StyledContent>
            </SmartContainer >


        </App >
    )
}