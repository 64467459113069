export const segmentInit = (fullNetwork) => {

    // Guard: No Pixel
    if (!fullNetwork?.network?.meta?.segment) return;

    // Guard: Not Enabled
    if (fullNetwork?.network?.meta?.segment?.enabled !== true) return;

    const trackingId = fullNetwork?.network?.meta?.segment?.trackingId;

    // Include Source & Initialize
    var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
        analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) { var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = e }; analytics._writeKey = trackingId; analytics.SNIPPET_VERSION = "4.13.2";
        analytics.load(trackingId);

    }

}

export const segmentTrackPageView = ({ location, fullNetwork }) => {

    // Guard: No Pixel
    if (!fullNetwork?.network?.meta?.segment) return;

    // Guard: Not Enabled
    if (fullNetwork?.network?.meta?.segment?.enabled !== true) return;

    // Track
    if (window.analytics) window.analytics.page();

}

export const segmentTrackEvent = ({ event, args, fullNetwork }) => {

    // Guard: No Pixel
    if (!fullNetwork?.network?.meta?.segment) return;

    // Guard: Not Enabled
    if (fullNetwork?.network?.meta?.segment?.enabled !== true) return;

    // Custom Event
    if (window.analytics) window.analytics.track(event, args);
}

export const segmentIdentify = (id, user) => {

    const me = user.me;
    const account = user.account;
    const activeCampaignMasterList = {
        id: 1,
        status: 'active'
    }
    const activeCampaignMarketingList = {
        id: 2,
        status: (me?.userPreferences?.meta?.marketingOptIn) ? 'active' : 'unsubscribed'
    }

    const traits = {
        name: me?.firstName + ' ' + me?.lastName,
        email: me.email,
        created: me?.createdAt,
        company: {
            id: account?.id,
            name: account?.name,
            plan: account?.plan
        },
        lists: [activeCampaignMasterList, activeCampaignMarketingList]
    };
    if (account?.plan) traits.plan = account.plan;
    if (account?.subscriptionStatus) traits.subscriptionStatus = account.subscriptionStatus;
    if (me?.userPreferences?.meta?.marketingOptIn) traits.marketingOptIn = me?.userPreferences?.meta?.marketingOptIn;
    else traits.marketingOptIn = false;

    // Group
    if (account?.id && window.analytics) {
        window.analytics.group(account.id, {
            name: account?.name,
            plan: account.plan,
        });
    }


    // Individual
    if (window.analytics) window.analytics.identify(id, traits);

}