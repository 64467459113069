import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Services
import { uniqueId } from '../../services/Utils'

// Components
import { App } from '../../App';
import { Surface, Stack } from '../../styles/Containers'
import { DatePicker, DateTimePicker, TimePicker } from '../../components/Forms/DatePicker';




import { StyledH1, StyledH6, StyledP } from '../../styles/StyledHTML';



export const DateInputsLab = () => {

    const [theDate, setTheDate] = useState('2017-05-30T10:30');
    const [theTime, setTheTime] = useState('23:00');

    return (
        <App title="Lab: Date Inputs">
            <Stack>
                <Surface>
                    <Stack>
                        <StyledH6>Date Only</StyledH6>
                        <DatePicker label="Date Only" value={theDate} onChange={(value) => setTheDate(value)} />
                        
                        <StyledH6>Date &amp; Time</StyledH6>
                        <DateTimePicker label="Date and Time" value={theDate} onChange={(value) => setTheDate(value)} />
                        
                        <StyledH6>Time Only</StyledH6>
                        <TimePicker label="Time Only" value={theTime} onChange={(value) => setTheTime(value)} />
                        <hr />

                        <StyledH6>Output from state</StyledH6>
                        <StyledP>Date: {theDate}</StyledP>
                        <StyledP>Time: {theTime}</StyledP>
                    </Stack>
                </Surface>
            </Stack>
        </App>
    )
}