import React, { useState } from 'react';
import styled from 'styled-components';
import { SmartContainer } from '../../styles/Containers'
import { StyledTextField, StyledIconInput } from '../../styles/matieralui/Form'
import { StyledIconButton } from '../../styles/matieralui/IconButton'
import { setSpace } from '../../styles/Theme'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const StyledPasswordContainer = styled(SmartContainer)`
    position: relative;
    .MuiIconButton-root {
        position: absolute;
        right: ${setSpace(1)};
        top: 50%;
        transform: translateY(-50%);
    }
`

export const SearchInput = (props) => {

    const handleChange = (value) => {
        if(!props.onChange) return;
        props.onChange(value);
    }

    const handleSubmit = (value) => {
        if(!props.onSubmit) return;
        props.onSubmit(value)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') handleSubmit(props.value)
    }

    return (
        <StyledIconInput data-search-input>
            <StyledTextField {...props} onKeyPress={handleKeyPress} onChange={(e) => handleChange(e.target.value)} variant="filled" />
            <StyledIconButton onClick={() => handleSubmit(props.value)}><SearchOutlinedIcon /></StyledIconButton>
        </StyledIconInput>
    )
}