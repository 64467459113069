import { replaceTags, duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getModelBySlug, getRevisions } from '../../../../redux/DefaultModelActions';
import { APIGet, APIBase } from '../../../../services/API';

const model = 'Tag';
const route = '/v1/tag';

export const searchTags = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createTag = (args) => createModel(model, route, args);
export const updateTag = (id, args) => updateModel(model, route, id, args);
export const deleteTag = (id) => deleteModel(model, route, id);
export const restoreTag = (id) => restoreModel(model, route, id);
export const getTag = (id) => getModel(model, route, id);
export const getTagRevision = (id) => getRevisions(model, route, id);
export const duplicateTag = (id) => duplicateModel(model, route, id);
export const replaceNetworkTags = (terms, contentType, contentId) => replaceTags(model, route, terms, contentType, contentId);
