import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from './NetworkApp';
import { NetworkList } from './NetworkList';
import { NetworkForm } from './NetworkForm'

// Styled
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledModal } from '../../styles/matieralui/Modal'
import { StyledP } from '../../styles/StyledHTML';

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { RouteBreadCrumbs } from '../../routes';


export const NetworkNetworksPage = () => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const fullNetwork = FullNetwork();

    // --------------------------
    // Effects
    // --------------------------

    // Close Modal on Success
    useEffect(() => {if(fullNetwork.createNetworkSuccess){setOpenModal(false)}},[fullNetwork.createNetworkLoading,fullNetwork.createNetworkSuccess])
   
    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => dispatch(push('/network/edit/'+item.id));

    // Close Modal
    const handleCloseModal = () => setOpenModal(false)

    // --------------------------
    // Renders
    // --------------------------

    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <StyledButton variant="contained" color="primary" onClick={() => setOpenModal(true)}>New Network</StyledButton>
            </SmartContainer>
        )
        
    }

    

    return (
        <NetworkApp title="Networks List" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard,RouteBreadCrumbs.network]}>
            
            <NetworkList onSelect={handleSelect} />

            <StyledModal
                open={openModal}
                onClose={handleCloseModal}
                label="New Network"
                description="Create a new user on your account"
                >
                    <Stack>
                        <StyledP light>A Network is a new Whitelabeled version of this platform.</StyledP>
                        <NetworkForm />
                    </Stack>
                    
            </StyledModal>


        </NetworkApp>
    )
}