import React, { useState, useEffect } from 'react';

// Redux
import { Role, NetworkRole } from '../../redux/Auth/auth.selectors';

// Styles
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers'
import { StyledButton, StyledDangerButton } from '../../styles/matieralui/Button'
import { StyledH6, StyledP } from '../../styles/StyledHTML'

// Components
import { FormInput } from '../Forms/FormInput'
import { UserRoleSelect } from './UserRoleSelect'
import { isEmail } from '../../services/Utils'
import { FullNetwork } from '../../redux/Network/network.selectors';
import { Link } from 'react-router-dom';

export const UserForm = ({ loading, user, onSubmit, isMe, onRemove, userOnly }) => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [role, setRole] = useState('');

    const currentUserRole = Role();
    const fullNetwork = FullNetwork();
    const networkRole = NetworkRole(fullNetwork.network.id);

    // Clone to user for updating
    useEffect(() => {
        if (!user) return;
        setEmail(user.email);
        setFirst(user.firstName);
        setLast(user.lastName)
        setRole(user.role)
    }, [user])


    const handleLogin = (e) => onSubmit({ email, firstName: first, lastName: last, role });
    const handleRemove = (e) => onRemove(user);
    const handleEmailChange = (value) => {
        if (isEmail(value)) setEmailError('')
        else setEmailError('Please enter a valid email')
        setEmail(value)
    }

    // Login Errors

    let disableEmail = (isMe) ? false : true
    let disabledFirstName = true;
    if (isMe) disabledFirstName = false;

    let disabledLastName = true;
    if (isMe) disabledLastName = false;

    // Role Restrictions
    let disableRole = true;
    if (currentUserRole === 'owner' || currentUserRole === 'admin') disableRole = false;
    if (currentUserRole !== 'owner' && role === 'owner') disableRole = true;
    if (isMe) disableRole = true;

    // let availableRoles = [...config.roles]
    // if(currentUserRole !== 'owner') availableRoles = availableRoles.filter(role => role !== 'owner')

    // Show Remove User Option
    let showRemoveUser = false;
    if (currentUserRole === 'owner') showRemoveUser = true;
    if (isMe) showRemoveUser = false;
    if (userOnly) showRemoveUser = false;

    // Enable things for network users
    if (networkRole === 'superadmin' || networkRole === 'networkadmin') {
        disableRole = false;
        disabledFirstName = false;
        disabledLastName = false;
        disableEmail = false;
    }

    // Allow Submit
    const disableSubmit = (!email || !isEmail(email));

    return (
        <SmartContainer minWidth="300px" thinking={loading}>
            <Stack>
                <StyledH6>Update User Info</StyledH6>
                <FormInput disabled={disabledFirstName} label="First Name" type="text" onChange={setFirst} value={first} />
                <FormInput disabled={disabledLastName} label="Last Name" type="text" onChange={setLast} value={last} />
                <FormInput disabled={disableEmail} label="Email" type="email" onChange={handleEmailChange} value={email} helperText={emailError} error={(emailError)} />
                {!userOnly && !disableRole && (<UserRoleSelect label="Account Role" value={role} onChange={setRole} isMe={isMe} />)}
                <HorizontalStack>
                    <StyledButton onClick={handleLogin} variant="contained" color="primary" disabled={disableSubmit}>Update</StyledButton>
                    <StyledP>&nbsp;</StyledP>
                    {/* <StyledP small light><Link to='#' onClick={(e) => { window.location.href = `mailto:hello@spaceculture.co?subject=Account Deletion`; e.preventDefault(); }}>Delete Account</Link></StyledP> */}
                    {showRemoveUser && (<StyledDangerButton onClick={handleRemove} variant="outlined" disabled={disableSubmit}>Remove From Account</StyledDangerButton>)}
                </HorizontalStack>
            </Stack>
        </SmartContainer>
    )
}