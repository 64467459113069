import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SmartContainer, Stack } from '../../../styles/Containers';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { FormInput } from '../../../components/Forms/FormInput';

import logo from '../assets/hotjar-logo.png';
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledSwitch } from '../../../styles/matieralui/Form';

import { useSaveIntegration } from '../integrations.hooks';
import { IntegrationCard } from '../IntegrationCard';
import { IntegrationFormHeader } from '../IntegrationFormHeader';

export const HotJarForm = ({meta, open = false, network = false}) => {
    const key = 'hotjar';
    const title = "HotJar";
    const description = "Adds Video Session Playback.";

    // Use Integration Save
    const { openForm, setOpenForm, saveIntegration, thinking } = useSaveIntegration({open, network});
    
    // Form Fields
    const [siteId,setSiteId] = useState('');
    const [enabled,setEnabled] = useState(false);

    // Set Default values
    useEffect(() => {
        if(!meta) return;
        if(meta[key] && meta[key].enabled) setEnabled(meta[key].enabled);
        if(meta[key] && meta[key].siteId) setSiteId(meta[key].siteId);
    },[meta])


    // Format Save Data
    const handleSave = () => {
        const m = {...meta};
        if(!m[key]) m[key] = {};
        m[key].enabled = enabled;
        m[key].siteId = siteId;
        saveIntegration(m);
    }

    return (
        <SmartContainer thinking={thinking}>
            <IntegrationCard clickable onClick={() => setOpenForm(true)} 
                title={title}
                description={description}
                logo={logo}
                />

            <StyledModal
                open={openForm}
                onClose={() => setOpenForm(false)}
                >
                    <Stack>
                        <IntegrationFormHeader title={title} logo={logo}>
                            <StyledSwitch color="primary" checked={enabled} name="enabled" onChange={(e) => setEnabled(e.target.checked)} />
                        </IntegrationFormHeader>
                                              
                        <FormInput label="Site Id" type="text" onChange={setSiteId} value={siteId} helperText="Your HotJar Site Id" />
                        <StyledButton variant="contained" color="primary" onClick={handleSave}>Save</StyledButton>
                    </Stack>
                    
                </StyledModal>
        </SmartContainer>
    )
}