import React from 'react'
import { useSelector } from 'react-redux';
import styled, {css} from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SmartContainer, Surface, Stack } from '../Containers'
import { StyledH6 } from '../StyledHTML'
import { Theme, respondTo } from '../Theme'
import { StyledButton, StyledDangerButton } from './Button'


const DialogStyles = styled(Dialog)`

    padding: 20%;
    
    > div:first-child {
        background-color: ${Theme.colors.base} !important;
        opacity: 0.85 !important;
    }
   
    .MuiDialog-paper {
        outline: 0;
        border-radius: ${Theme.radius};
        box-shadow: ${Theme.shadow.three};
        background-color: ${Theme.colors.baseAlternate};
        height: auto !important;
    }

    .MuiDialog-container {
        height: auto !important;
    }

   
    ${respondTo('mobile',css`padding-left: 5%; padding-right:5%;`)}

    
   
`



export const StyledAreYouSure = ({loading, open, onClose, label, description, agreeText, children}) => {


    const handleClose = (value) => {
        
        // Check if we are saving. If we are return;
        if(loading === true) return;

        // Close        
        onClose(value)
    }
    return (
        <DialogStyles
            data-dialog-styles
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby={label}
            aria-describedby={description}
            >
                <Surface className="modal-surface" thinking={loading}>
                <SmartContainer flex justify="space-between" align="center">
                    <StyledH6>{label}</StyledH6>
                </SmartContainer>
                <DialogContentText>{description}</DialogContentText>
            
            <DialogActions>
                <Stack>
                    <SmartContainer>
                        <StyledButton onClick={() => handleClose(false)} color="primary">
                            Cancel
                        </StyledButton>
                        <StyledDangerButton onClick={() => handleClose(true)} variant="contained">
                            {agreeText}
                        </StyledDangerButton>
                    </SmartContainer>
                    <SmartContainer>{children}</SmartContainer>
                </Stack>
                
            </DialogActions>
            </Surface>
        </DialogStyles>
    )
}
/*
<Surface className="modal-surface">
    <Stack saving={ui.loading}>
        <SmartContainer flex justify="space-between" align="center">
            <StyledH6>{label}</StyledH6>
            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
        </SmartContainer>
        <SmartContainer>{children}</SmartContainer>
    </Stack>
</Surface> 
*/

export const StyledDialog = ({loading, open, onClose, label, description, agreeText, children}) => {


    const handleClose = (value) => {
        
        // Check if we are saving. If we are return;
        if(loading === true) return;

        // Close        
        onClose(value)
    }
    return (
        <DialogStyles
            data-dialog-styles
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby={label}
            aria-describedby={description}
            >
                <Surface className="modal-surface" thinking={loading}>
                    <SmartContainer flex justify="space-between" align="center">
                        <StyledH6>{label}</StyledH6>
                    </SmartContainer>
                    <DialogContentText>{description}</DialogContentText>
            
            <DialogActions>
                <Stack>
                    <SmartContainer>
                        <StyledButton onClick={() => handleClose(false)} color="primary">
                            Cancel
                        </StyledButton>
                        <StyledDangerButton onClick={() => handleClose(true)} variant="contained">
                            {agreeText}
                        </StyledDangerButton>
                    </SmartContainer>
                    <SmartContainer>{children}</SmartContainer>
                </Stack>
                
            </DialogActions>
            </Surface>
        </DialogStyles>
    )
}