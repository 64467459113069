import React, { useState, useRef, useEffect, useMemo } from 'react';

// Theming
import { SmartContainer, Stack, StyledFadeIn } from '../../styles/Containers'
import { StyledIconButton } from '../../styles/matieralui/IconButton';

// Icons
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import { SpaceIconPause, SpaceIconPlay } from '../../space/styles/Icons';
import NoSleep from 'nosleep.js';
import { StyledP } from '../../styles/StyledHTML';
import { StyledCircularProgress } from '../../styles/matieralui/Progress';


export const AudioPlayer = ({ src, onChange, onPlay, onPause, onProgress, onComplete, onLoad, withEvents = false, className, controlEvent = null }) => {

    // Playing State
    const [playing, setPlaying] = useState(false);
    const [setUp, setSetUp] = useState(false);
    const [canPlay, setCanPlay] = useState(false);

    // HTML Ref
    const audioElement = useRef(null);

    const play = () => {
        if (!audioElement?.current) return;
        audioElement.current.play();
    }
    const pause = () => {
        if (!audioElement?.current) return;
        audioElement.current.pause();
    }

    const handlePlay = () => {
        setPlaying(true)
        if (onPlay) onPlay(audioElement);
    }

    const handlePause = () => {
        setPlaying(false)
        if (onPause) onPause(audioElement);
    }
    const handleEnded = () => {
        setPlaying(false)
        if (onComplete) onComplete(audioElement);
    }
    const handleProgress = () => {
        if (onProgress) onProgress(audioElement);
    }

    const handleCanPlay = () => {
        setCanPlay(true);
    }

    const handleLoad = () => {
        if (onLoad) onLoad(audioElement)
    }

    const passStateUp = (s) => {
        if (onChange) onChange(s);
    }

    useEffect(() => {

        if (!setUp && withEvents === true) {
            audioElement.current.addEventListener('play', handlePlay)
            audioElement.current.addEventListener('pause', handlePause)
            audioElement.current.addEventListener('ended', handleEnded);
            audioElement.current.addEventListener('canplay', handleLoad);
            // audioElement.current.addEventListener('timeupdate', handleProgress);
            setSetUp(true);

        }
    }, [src])

    useEffect(() => {
        if (!controlEvent) return;
        if (controlEvent === 'play') play();
        if (controlEvent === 'pause') pause();
    }, [controlEvent])



    return (
        <Stack data-styled-audio-player className={className}>
            <audio ref={audioElement}>
                <source src={src} />
                <span>Your browser does not support the audio element.</span>
            </audio>
            <StyledFadeIn visible="true" flex align="center" className="controls">
                {playing && (
                    <StyledIconButton onClick={() => pause()} spacify={true}>
                        <SpaceIconPause />
                    </StyledIconButton>
                )}
                {!playing && (
                    <StyledIconButton onClick={() => play()} spacify={true}>
                        <SpaceIconPlay />
                    </StyledIconButton>
                )}
            </StyledFadeIn>
            {/* {!canPlay && (<StyledCircularProgress />)} */}
        </Stack>
    )
}
