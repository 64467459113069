import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import { HorizontalStack, SmartContainer } from '../Containers';
import { StyledP } from '../StyledHTML';
export const StyledCircularProgress = styled(CircularProgress)`

`;

export const StyledCircularProgressWithLabel = (props) => {
    return (
        <SmartContainer {...props}>
            <StyledCircularProgress variant="determinate" value={props.value} />
        </SmartContainer>
    )
}

export const StyledLinearProgress = styled(LinearProgress)`
    opacity: 0.5;
`
export const StyledLinearProgressWithLabel = (props) => {
    return (
        <HorizontalStack className={props?.className} width="100%" flex align="center" justify="space-between">
            {props?.beforeLabel && (<StyledP light small>{props?.beforeLabel}</StyledP>)}
            <SmartContainer width="70%" minWidth="200px"><StyledLinearProgress variant="determinate" value={props.value} /></SmartContainer>
            {props?.afterLabel && (<StyledP light small>{props?.afterLabel}</StyledP>)}
        </HorizontalStack>
    )
}