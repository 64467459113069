import { useSelector } from 'react-redux';

export const FullAuth = () => useSelector(state => state.auth);
export const Session = () => useSelector(state => state.auth?.session)
export const Me = () => useSelector(state => state.auth.me)
export const Role = () => useSelector(state => {
    // First Check Network
    if(state.network?.network?.id){
        const networkRole = getNetworkRole(state.network?.network?.id,state);

        // If you are a network user then act like the owner of this account
        if(networkRole !== 'norole'){
            return 'owner';
        }
    }

    


    // Account Role
    const account = state.auth.me?.accounts?.filter(account => account.loggedIn === true)
    if(!account) return 'norole';
    return account[0].role;
})

export const NetworkRole = (networkId) => useSelector(state => {

    if(!networkId) return null;

    return getNetworkRole(networkId, state);
})

const getNetworkRole = (networkId, state) => {

    if(!networkId) return null;

    // If you are a super admin for one you are for all.
    if(state.auth?.me?.superAdmin === true) return 'superadmin';

    // Otherwise get this network admin status
    const network = state.auth.me?.networks?.filter(network => network.id === networkId)
    
    if(!network) return 'norole';
    return network[0].role;
}

export const MeWithAccount = () => useSelector(state => {
    if(!state.auth?.me) return null;
    const me = state.auth.me;
    const account = state.auth.me?.accounts?.filter(account => account.loggedIn === true)
    const myAccount = (account.length > 0) ? account[0] : null;
    return {
        me,
        account: myAccount
    }
});