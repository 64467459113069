import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Theme, setSpace } from '../Theme'

export const StyledMenu = styled(Menu)`
    .MuiMenu-paper {
        background-color: ${Theme.colors.base.alternate};
        padding: 0 ${setSpace(2)};
        box-shadow: ${Theme.shadow.three};
        border-radius: ${Theme.radius};
    }

    .MuiListItem-root {
        &:hover {
            background-color: ${Theme.colors.base.default};
        }
    }
`;


export const StyledMenuItem = styled(MenuItem)``;