import React from 'react';
import styled from 'styled-components';
import { Surface } from '../../styles/Containers'
import { StyledIframe } from '../../styles/StyledHTML';
import { StyledIconButton } from '../../styles/matieralui/IconButton';

// Material UI
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const StyledPreview = styled(StyledIframe)`
    min-height: 500px;
    width: 100%;
`;


export const FilePreview = ({src}) => {

    if(src.indexOf('pdf') > -1) return (<StyledPreview radius src={src} />)

    return (
        <Surface flex align="center">
            <StyledIconButton>
                <DescriptionOutlinedIcon />
            </StyledIconButton>
        </Surface>
    )

}