import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { NewContent } from '../../components/Content/NewContent';

// Styles
import { SmartContainer } from '../../../styles/Containers'
import { NetworkBreathingList } from './NetworkBreathingList';

// Redux
import { restoreBreathing, createBreathing } from './redux/breathing.actions';
import { FullBreathing } from './redux/breathing.selectors';
import { RouteBreadCrumbs } from '../../../routes';


export const PageNetworkBreathing = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullBreathing();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => { if (store.createSuccess) dispatch(push(`/network/breathing/${store.create.id}`)) }, [store?.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {

        if (item?.full?.deletedAt) dispatch(restoreBreathing(item.id))
        else dispatch(push(`/network/breathing/${item.id}`))
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <NewContent label="New Breathing Exercise" selector={FullBreathing} createAction={createBreathing} />
            </SmartContainer>
        )

    }

    // Render Page
    return (
        <App title="Breathing Exercises" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            <NetworkBreathingList onSelect={handleSelect} />

        </App>
    )
}