export default (state = {}, action) => {

    switch (action.type) {
        case '[BILLING] Wipe':
            return {};
        case '[BILLING] Create Checkout Session':
        case '[BILLING] Create Checkout Session Failed':
        case '[BILLING] Create Checkout Session Success':
        case '[BILLING] Create Checkout Session Clear':
        case '[BILLING] Create Checkout Session Purchase':
        case '[BILLING] Create Checkout Session Purchase Failed':
        case '[BILLING] Create Checkout Session Purchase Success':
        case '[BILLING] Create Checkout Session Purchase Clear':
        case '[BILLING] Create Portal Session':
        case '[BILLING] Create Portal Session Failed':
        case '[BILLING] Create Portal Session Success':
        case '[BILLING] Create Portal Session Clear':
            const newState = Object.assign({},state,{...action.payload})
            return newState;
        default:
            return state
    }
}
