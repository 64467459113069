
import React, { useState, useEffect } from 'react';
import { StyledFormControl, StyledFormControlLabel, StyledFormLabel, StyledSwitch, StyledFormGroup } from '../../styles/matieralui/Form';

export const SwitchGroup = (props) => {
    
    const [options, setOptions] = useState(props.options);

    const handleChange = (e) => {
        const newOptions = options.map(option => {
            if(option.value === e.target.value) option.checked = e.target.checked;
            return option;
        })
        setOptions(newOptions);
        props.onChange(newOptions)
    }

    return (
        <StyledFormControl>
            <StyledFormLabel>{props.label}</StyledFormLabel>
            <StyledFormGroup value={props.value}>
                {options && options.map(option => { 
                    return (<StyledFormControlLabel key={option.key} label={option.label} control={<StyledSwitch color="primary" onChange={handleChange} {...option} />} />)
                })}
            </StyledFormGroup>
        </StyledFormControl>
    )
}