import React, { useState } from 'react';

// Components
import { App } from '../../App';
import { Surface, Stack, SmartContainer, StyledGridByTwo, StyledGridItem } from '../../styles/Containers'
import { ColorPicker, ColorSelector } from '../../components/Forms/ColorPicker';
import { StyledP } from '../../styles/StyledHTML';

//http://casesandberg.github.io/react-color/
export const ColorPickerLab = () => {

    const brandColors = ['#d9e3f0','#f47373','#697689','#37d67a','#2ccce4'];
    const [pickedColor,setPickedColor] = useState('');
    const handleChange = (color) => {
        setPickedColor(color);
    }
    return (
        <App title="Lab: Design System">
            <StyledGridByTwo>
                <StyledGridItem>
                    <Surface width="100%">
                        <StyledP>Color Picker: {pickedColor}</StyledP>
                        <StyledP><ColorPicker value={pickedColor} onChange={handleChange} /></StyledP>
                    </Surface>
                </StyledGridItem>

                <StyledGridItem>
                    <Surface width="100%">
                        <StyledP>Color Selector: {pickedColor}</StyledP>
                        <StyledP><ColorSelector value={pickedColor} onChange={handleChange} options={brandColors} /></StyledP>
                    </Surface>
                </StyledGridItem>
            </StyledGridByTwo>
        </App>
    )
}