import React from 'react';

// TCC Components
import { Surface } from '../../styles/Containers'
import { StyledB, StyledP } from '../../styles/StyledHTML';

export const PaginatedCardItem = ({item, onClick}) => {
    return (
        <Surface width="100%" onClick={() =>onClick(item)} cursor="pointer">
            <StyledB>{item.title}</StyledB>
            <StyledP light>{item.description}</StyledP>
        </Surface>
    )

}