import { useEffect, useState } from 'react';
import { duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getModelBySlug, getRevisions } from '../../../../redux/DefaultModelActions';
import { APIPost, APIPut, APIBase, APIGet } from '../../../../services/API';
import { PROGRESS_COMPLETED, PROGRESS_IN_PROGRESS, PROGRESS_PAUSED, STATS_MEDITATION_COMPLETED } from '../../../services/SpaceUtils';
const model = 'UserProgress';
const route = '/v1/progress';

export const searchUserProgress = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createUserProgress = (args) => createModel(model, route, args);
export const updateUserProgress = (id, args) => updateModel(model, route, id, args);
export const deleteUserProgress = (id) => deleteModel(model, route, id);
export const restoreUserProgress = (id) => restoreModel(model, route, id);
export const getUserProgress = (id) => getModel(model, route, id);
// export const getUserNoteRevision = (id) => getRevisions(model, route, id);
// export const duplicateSeries = (id) => duplicateModel(model, route, id);

export const findUserProgress = async ({ meditationId = null, seriesId = null, all = false }) => {
    let qs = `page=1&limit=1&type=["${PROGRESS_COMPLETED}","${PROGRESS_IN_PROGRESS}","${PROGRESS_PAUSED}"]`;
    if (seriesId) qs += `&SeriesId=["${seriesId}"]`
    if (meditationId) qs += `&MeditationId=["${meditationId}"]`

    try {

        const res = await APIGet(`${APIBase}${route}?${qs}`);
        const json = await res.json();
        if (json.status === "success") {
            if (all !== true) return { progress: json?.data?.rows[0] };
            else return json?.data?.rows;
        }
        return null;
    } catch (error) {
        return error;
    }
}

export const findUserProgressSeriesCompletedMeditations = async ({ seriesId = null }) => {
    let qs = `page=1&type=["${STATS_MEDITATION_COMPLETED}"]`;
    if (seriesId) qs += `&SeriesId=["${seriesId}"]`

    try {

        const res = await APIGet(`${APIBase}${route}?${qs}`);
        const json = await res.json();

        if (json.status === "success" && json?.data?.count > 0) {
            return json?.data?.rows;
        }
        return null;
    } catch (error) {
        return error;
    }
}

export const hasUserCompletedMeditation = async ({ meditationId = null }) => {
    let qs = `page=1&type=["${STATS_MEDITATION_COMPLETED}"]`;
    if (meditationId) qs += `&MeditationId=["${meditationId}"]`

    try {

        const res = await APIGet(`${APIBase}${route}?${qs}`);
        const json = await res.json();

        if (json.status === "success" && json?.data?.count > 0) {
            return json?.data?.rows;
        }
        return null;
    } catch (error) {
        return error;
    }
}

export const saveUserProgressEvent = async ({ progressId = null, meditationId = null, seriesId = null, type, progress }) => {
    const args = {
        MeditationId: meditationId,
        SeriesId: seriesId,
        type,
        meta: {
            progress
        }
    }
    try {

        // Check For Existing
        let progressRes;
        if (progressId === null && (type === PROGRESS_COMPLETED || type === PROGRESS_IN_PROGRESS || type === PROGRESS_PAUSED)) {
            progressRes = await findUserProgress({ meditationId, seriesId });
            if (progressRes?.progress) {
                progressId = progressRes.progress.id;
            }
        }

        let res;
        if (progressId !== null) res = await APIPut(`${APIBase}${route}/${progressId}`, args);
        else res = await APIPost(`${APIBase}${route}`, args);
        const json = await res.json();
        return json;
    } catch (error) {
        return error;
    }
}



export const getUpNextContent = async (fullSeries) => {

    try {
        const latestProgress = await findUserProgress({});

        if (!latestProgress?.progress) {

            const defaults = {

                seriesId: fullSeries?.rows[0]?.id,
                meditationId: fullSeries?.rows[0]?.meditations[0]?.meditationId
            }
            return defaults;
        }

        // Paused or Inprogress
        if (latestProgress?.progress?.type === PROGRESS_PAUSED || latestProgress?.progress?.type === PROGRESS_IN_PROGRESS) {
            return {
                meditationId: latestProgress?.progress?.MeditationId,
                seriesId: latestProgress?.progress?.SeriesId
            }
        }

        // Completed: Get Next In Series
        if (latestProgress?.progress?.type === PROGRESS_COMPLETED) {

            // Next In Series
            let thisSeries = fullSeries?.rows?.filter(series => {
                if (latestProgress?.progress?.SeriesId === series.id) return true;
                return false;
            })
            if (thisSeries) thisSeries = thisSeries[0]

            // Get Next Series
            const lastMedInSeries = thisSeries?.meditations[thisSeries?.meditations.length - 1];
            if (lastMedInSeries?.meditationId === latestProgress?.progress?.MeditationId) {
                // TODO.... Get Next Series.
                // Temp Solution. Restart.
                return {
                    seriesId: fullSeries?.rows[0]?.id,
                    meditationId: fullSeries?.rows[0]?.meditations[0]?.meditationId
                }
            } else {
                // Get Next In Series
                let getNext = 'looking';
                let nextInSeries = thisSeries?.meditations.filter(med => {

                    if (med.meditationId === latestProgress?.progress?.MeditationId) {
                        getNext = 'next';
                        return false;
                    }
                    if (getNext === 'next') {
                        getNext = 'found';
                        return med;
                    }
                    if (getNext === 'looking' || getNext === 'found') return false;
                })
                if (nextInSeries) nextInSeries = nextInSeries[0];

                return {
                    seriesId: thisSeries.id,
                    meditationId: nextInSeries.meditationId
                }

            }

        }
    } catch (error) {

    }
}

// export const getMeditationSummary = (meditationId) => {
//     try {

//         const res = await APIGet(`${APIBase}${route}/summary/meditation/${meditationId}`);
//         console.log(res, "MED SUMM")
//         const json = await res.json();
//         if (json.status === "success") {
//             return json?.data?.rows;
//         }
//         return null;
//     } catch (error) {
//         return error;
//     }
// }

export const useSummary = (meditationId) => {
    const [data, setData] = useState(null);

    const getData = async () => {

        let path = `${APIBase}${route}/summary/meditation`;
        if (meditationId) path = `${path}/${meditationId}`


        const res = await APIGet(`${path}`);
        const json = await res.json();
        if (json.status === 'success') {
            setData(json.data)
        } else {
            setData([])
        }
    }

    useEffect(() => {

        getData();

    }, [])

    return {
        summary: data
    }
}