import { config } from '../config';
export const getToken = () => {

  const session = JSON.parse(window.localStorage.getItem(config.sessionId));
  if(!session) return null;
  return session.token;

}

export const getOptions = () => {
  const token = getToken();

  let options = {
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  };

  if(token) options.headers.append('Authorization','Bearer '+ token)
  return options;

}

export async function APIPost(path,body){


  const token = getToken();

  let options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    cache: "no-store",
  };

  if(token) options.headers.append('Authorization','Bearer '+ token)

  return fetch(path,options)
}

export async function APIGet(path,body){

    const token = getToken();
    
    let options = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      cache: "no-store",
    };

    
    
 
    if(token) options.headers.append('Authorization','Bearer '+ token)

    return fetch(path,options)
}


export async function APIPut(path,body){


  const token = getToken();

  let options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    cache: "no-store",
  };

  if(token) options.headers.append('Authorization','Bearer '+ token)


  return fetch(path,options)
}

export async function APIDelete(path){

  const token = getToken();

  let options = {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    cache: "no-store",
  };

  if(token) options.headers.append('Authorization','Bearer '+ token)

  return fetch(path,options)
}

export const APIBase = process.env.REACT_APP_API;

