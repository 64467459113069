import React, { useState } from 'react';
import { Button } from '@mui/material';
import styled, { css } from 'styled-components';
import { Theme } from '../Theme'
import { HorizontalStack } from '../Containers';

export const SPACE_BUTTON_STYLED = css`
    &.MuiButtonBase-root {
        border-radius: ${Theme.radius};
        text-transform: none !important;
        font-size: 1rem;
        box-shadow: none;
    }
`



export const StyledButton = styled(Button)`
    display: inline-block;
    ${props => (props.width) ? css` width: ${props.width};` : ''}
    &.MuiButton-contained {
        /* box-shadow: ${Theme.shadow.one}; */
        &.Mui-disabled {
            ${(Theme?.elements?.button?.disabled?.text) ? css`color:${Theme.elements.button.disabled.text}` : ''}
        }
    }
    ${SPACE_BUTTON_STYLED}
`;

export const StyledDangerButton = styled(Button)`
    display: inline-block;

    &.MuiButton-outlined {
        color: ${Theme.colors.error};
        border-color: ${Theme.colors.error}; 
    }
    &.MuiButton-contained {
        color: white;
        background-color: ${Theme.colors.error};
        box-shadow: ${Theme.shadow.one};
        &:hover {
            background-color: ${Theme.colors.error}
        }
    }
`;

export const StyledAreYouSureButton = ({ onClick, confirmText = 'Are you sure?', children, ...other }) => {
    const [sure, setSure] = useState(false);
    const handleClick = () => {
        if (sure !== true) {
            setSure(true);
            return;
        } else {
            onClick();
        }
    }
    const text = (sure !== true) ? children : confirmText;
    const color = (sure !== true) ? '' : 'primary';
    const variant = (sure !== true) ? 'outlined' : 'contained';
    return (
        <HorizontalStack flex>
            {sure === true && (
                <StyledButton {...other} onClick={() => setSure(false)}>cancel</StyledButton>
            )}
            <StyledDangerButton {...other} variant={variant} onClick={handleClick}>{text}</StyledDangerButton>
        </HorizontalStack>

    )
}

export const mimicButtonStyles = css`
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    display: inline-block;
`