import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SmartContainer, Stack, StyledBottomContainer, StyledFadeIn } from '../../../styles/Containers';
import { AudioPlayer } from '../../../components/AudioPlayer/AudioPlayer';
import { mediaPath } from '../../../modules/Media/MediaUtils';
import { useMediaAsset } from '../../components/Display/MediaAsset';
import { StyledP } from '../../../styles/StyledHTML';
import { formatProgress, PROGRESS_COMPLETED, PROGRESS_IN_PROGRESS, PROGRESS_PAUSED, STATS_MEDITATION_COMPLETED, STATS_SERIES_COMPLETED } from '../../services/SpaceUtils';
import { saveUserProgressEvent } from '../UserProgress/redux/userprogress.actions';
import { Theme } from '../../../styles/Theme';
import { StyledCircularProgress, StyledLinearProgressWithLabel } from '../../../styles/matieralui/Progress';
import { StyledButton } from '../../../styles/matieralui/Button';
import { FullUI } from '../../../redux/UI/ui.selectors';
import { uiMeditationPlaying } from '../../../redux/UI/ui.actions';
import { useDispatch } from 'react-redux';


const StyledAudioPlay = styled(AudioPlayer)`
    .MuiSvgIcon-root {
       
        font-size: 2.25rem;
        
    }
    .MuiButtonBase-root {
        width: 3em;
        height: 3em;
        /* background-color: ${Theme.colors.primary} !important; */
        /* color: ${Theme.colors.primaryText} !important; */
    }
`
const StyledContinueButton = styled(StyledButton)`
    font-size: 0.75em !important;
    margin-top: 1em;
    opacity: 0;
    transition: all 1000ms !important;
    ${props => (props.progress > 95 && props.free != true) ? css`opacity:0.5;` : ''}
    ${props => (props.progress > 5 && props.free == true) ? css`opacity:0.5;` : ''}
`

const MediationProgress = styled(StyledCircularProgress)`
    position: absolute;
    top: 0;
    left:0;
    width: 100% !important;
    height: 100% !important;
    pointer-events: none;
    opacity: 0.5;
`

export const MeditationPlayer = ({ audioId, onComplete, seriesId, meditationId, lastInSeries = false, onPlay = null, onPause = null, onStop = null, free = false, onLoad = false }) => {

    const { assetLoading, assetPath } = useMediaAsset({ id: audioId, network: true });
    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentPlayerState, setCurrentPlayerState] = useState('');
    const ui = FullUI();
    const dispatch = useDispatch();

    let localProgressId; // Old School no state. Can't hold stat set via Async


    const handlePlay = async (ref) => {
        const args = formatProgress(ref);
        args.type = PROGRESS_IN_PROGRESS;
        args.audio = audioId;
        let res = await saveUserProgressEvent({ progressId: localProgressId, seriesId, meditationId, type: PROGRESS_IN_PROGRESS, progress: args })
        if (res?.status === 'success') {
            localProgressId = res?.data?.id;
        }
        setPlaying(true)
        setCurrentPlayerState('playing')
        if (onPlay) onPlay(args);
        dispatch(uiMeditationPlaying(true));
    }

    const handlePause = async (ref) => {
        if (!ref?.current) return;
        const args = formatProgress(ref);
        args.type = PROGRESS_PAUSED;
        args.audio = audioId;
        let res = await saveUserProgressEvent({ progressId: localProgressId, seriesId, meditationId, type: PROGRESS_PAUSED, progress: args })
        setPlaying(false)
        setCurrentPlayerState('paused')
        setProgress(args)
        if (onPause) onPause(args);
        dispatch(uiMeditationPlaying(false));
    }

    const handleCompleted = async (ref) => {
        const args = formatProgress(ref);
        args.type = PROGRESS_COMPLETED;
        args.percent = 100;
        args.audio = audioId;

        runCompleted(args)

    }

    const runCompleted = async (args) => {
        // Update Current Progress
        await saveUserProgressEvent({ progressId: localProgressId, seriesId, meditationId, type: PROGRESS_COMPLETED, progress: args })

        // Update Stats
        await saveUserProgressEvent({ progressId: null, seriesId, meditationId, type: STATS_MEDITATION_COMPLETED, progress: {} })

        // Update Series Stats
        if (lastInSeries) await saveUserProgressEvent({ progressId: null, seriesId, meditationId, type: STATS_SERIES_COMPLETED, progress: {} })

        setPlaying(false)
        setCurrentPlayerState('complete')
        onComplete();
        dispatch(uiMeditationPlaying(false));
    }

    const finishEarly = async () => {
        const args = {}
        args.type = PROGRESS_COMPLETED;
        args.percent = 100;
        args.audio = audioId;
        runCompleted(args)
    }

    const handleProgress = async (ref) => {

        if (!ref?.current) return;
        const args = formatProgress(ref);
        setProgress(args)
    }

    const handleLoad = async (ref) => {
        if (!ref?.current) return;
        const args = formatProgress(ref);
        setProgress(args)
        if (onLoad) onLoad(args);
    }


    return (
        <Stack flex align="center" justify="center" direction="column">
            {assetLoading && (<StyledP light>Breath...</StyledP>)}
            <SmartContainer position="relative">
                {!assetLoading && (<StyledAudioPlay onChange={(value) => setCurrentPlayerState(value)} withEvents={true} onPlay={handlePlay} onPause={handlePause} onProgress={handleProgress} onComplete={handleCompleted} onLoad={handleLoad} src={mediaPath(assetPath)} />)}
            </SmartContainer>
            {/* <StyledContinueButton free={free} progress={progress?.percent} fullWidth size="small" onClick={finishEarly}>skip</StyledContinueButton> */}
            <StyledFadeIn visible={(ui?.meditationPlaying === false && progress?.percent >= 0)}>
                <StyledButton fullWidth size="small" onClick={finishEarly}>skip</StyledButton>
            </StyledFadeIn>
            <StyledBottomContainer flex align="center" justify="center">
                <StyledFadeIn visible={(ui?.meditationPlaying !== true && progress?.percent >= 0)}>
                    <StyledLinearProgressWithLabel variant="determinate" value={(progress?.percent) ? progress?.percent : 0} beforeLabel={progress?.formattedCurrentTime} afterLabel={progress?.formattedDuration} />
                </StyledFadeIn>
            </StyledBottomContainer>
        </Stack>
    )
}

