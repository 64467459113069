import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { NetworkApp } from '../../components/Network/NetworkApp';
import { EditMediaTagForm } from './EditMediaTagForm';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader';

// Styles
import { SmartContainer } from '../../styles/Containers'

// Redux
import { getNetworkMediaTag } from './redux/media.actions';
import { FullMediaTags } from './redux/media.selectors';
import { RouteBreadCrumbs } from '../../routes';


export const NetworkEditMediaTagPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullMediaTags();
    const { id } = useParams();
    const thisId = parseInt(id)

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Get This Item
        dispatch(getNetworkMediaTag(thisId))

    },[])


    // --------------------------
    // Interactions
    // --------------------------

    // --------------------------
    // Renders
    // --------------------------


    // Render Page

    return (
        <NetworkApp title="Media Tag Editor"  breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network, RouteBreadCrumbs.networkMedia, RouteBreadCrumbs.networkMediaTags]}>
            
            {store?.activeItemLoading && (<FullScreenLoader />)}
            {!store?.activeItemLoading &&(
                 <SmartContainer thinking={store?.deleteLoading || store?.updateLoading}>
                    <EditMediaTagForm network={true} item={store?.activeItem} />                    
                </SmartContainer>
            )}
        </NetworkApp>
    )
}