import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { StyledSnackbar, StyledAlert } from '../../styles/matieralui/Alerts';
import { StyledButton } from '../../styles/matieralui/Button';
import { Theme } from '../../styles/Theme';

import * as serviceWorker from '../../serviceWorker';


const StyledUpdate = styled(StyledSnackbar)`
    box-shadow: none;
    .MuiPaper-root {
        box-shadow: ${Theme.shadow.two};
        border-radius: ${Theme.radius};
        display: flex;
        align-items: center;
    }
`

export const UpdateApp = () => {

    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);

    const onSWUpdate = (registration) => {
        console.log("onSWUPDATE");
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        console.log("APP UPDATED HAS MOUNTED")
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        if (waitingWorker?.postMessage) {
            console.log("-- Update App")
            waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        }
        setShowReload(false);
        window.location.reload(true);
    };

    return (
        <StyledUpdate open={showReload} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <StyledAlert severity='info' action={
                <StyledButton onClick={reloadPage}>Install</StyledButton>
            }>A newer version of the app is available.</StyledAlert>
        </StyledUpdate>
    )
}