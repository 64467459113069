import { APIGet, APIPut, APIPost, APIDelete, APIBase } from '../../services/API';
import { dispatchLoading, dispatchError, dispatchSuccess, dispatchClearState } from '../utils'
import { openToast } from '../UI/ui.actions'
import { attemptLogout } from '../Auth/auth.actions';
export const getAccountInfo = () => dispatch => {

    const action = '[ACCOUNT] Get Account';
    const prefix = 'account';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIGet(`${APIBase}/v1/me/account`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') dispatch(dispatchError(action, prefix, json.message))
            else dispatch(dispatchSuccess(action, prefix, null, json.data.account));
        })
        .catch(e => { dispatch(dispatchError(action, prefix, e.message)) })

}

export const updateAccount = (accountInfo) => dispatch => {

    const action = '[ACCOUNT] Update Account';
    const prefix = 'account';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPut(`${APIBase}/v1/account/${accountInfo.id}`, accountInfo)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') dispatch(dispatchError(action, prefix, json.message))
            else dispatch(dispatchSuccess(action, prefix, json.message, json.data.account));
        })
        .catch(e => { dispatch(dispatchError(action, prefix, e.message)) })
}

export const createAccount = (networkId, userId, args) => dispatch => {

    const action = '[ACCOUNT] Create Account';
    const prefix = 'createAccount';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    const payload = { ...args };
    payload.userId = userId;
    payload.networkId = networkId;

    // Make Request
    APIPost(`${APIBase}/v1/account`, payload)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {


                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })
}

export const getTeamMembers = (accountId) => dispatch => {

    const action = '[ACCOUNT] Get Team';
    const prefix = 'team';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIGet(`${APIBase}/v1/account/${accountId}/users`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else dispatch(dispatchSuccess(action, prefix, json.message, json.data.team));
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })
}

export const updateUserAccountRole = (accountId, userId, role) => dispatch => {

    const action = '[ACCOUNT] Update Team Member';
    const prefix = 'activeTeamMember';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPut(`${APIBase}/v1/account/${accountId}/users/${userId}`, { role })
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else dispatch(dispatchSuccess(action, prefix, json.message, json.data.team));
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })
}

export const newUserAccount = (networkId, accountId, name, email, role) => dispatch => {

    const action = '[ACCOUNT] Add Team Member';
    const prefix = 'addTeamMember';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPost(`${APIBase}/v1/account/${accountId}/users`, { name, email, role, networkId })
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else dispatch(dispatchSuccess(action, prefix, json.message, json.data.user));
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })
}

export const removeUserAccount = (accountId, userId) => dispatch => {

    const action = '[ACCOUNT] Remove Team Member';
    const prefix = 'removeTeamMember';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIDelete(`${APIBase}/v1/account/${accountId}/users/${userId}`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {

                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data.user));

                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })
}

export const removeMe = (accountId, userId) => dispatch => {

    const action = '[ACCOUNT] Remove Me';
    const prefix = 'removeMe';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIDelete(`${APIBase}/v1/account/me`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {

                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data.user));
                dispatch(openToast("You account has been removed.", "success"));
                // Dispatch Clear State
                setTimeout(() => {
                    dispatch(dispatchClearState(action, prefix));

                }, 1500)

                // Log out
                setTimeout(() => {
                    dispatch(attemptLogout());
                }, 3000)

            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })
}