import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { SmartContainer } from '../../../styles/Containers';
import { StyledP, StyledHr } from '../../../styles/StyledHTML';
import { useSearchUnsplash } from './UnsplashService';
import { PaginatedList } from '../../../components/PaginatedList/PaginatedList';
import { MediaCard } from '../MediaCard';
import { StyledGrid, StyledGridItem } from '../../../styles/Containers';
import { StyledCircularProgress } from '../../../styles/matieralui/Progress';
import CheckIcon from '@mui/icons-material/Check';
import { Theme } from '../../../styles/Theme';

const StyledUnsplashItem = styled(SmartContainer)`

    ${props => (props.status == 'importing' || props.status == 'success') ? css`
        .media-card { opacity: 0.5; }
    ` : ''}

`
const StyledCheck = styled(CheckIcon)`
    color: ${Theme.colors.baseText};
    font-size: 3em !important;
`

const UpsplashItem = ({item, onClick}) => {
    const unsplashSrc = item?.full?.urls?.small;
    item.full.mediaType = 'image';
    item.full.importStatus = (item?.full?.importStatus) ? item.full.importStatus : 'preview';
    const st = (item.full.importStatus === 'preview') ? '' : item.full.importStatus;
    return (
        <StyledUnsplashItem width="100%" status={item.full.importStatus} position="relative">
            <MediaCard className="media-card" item={item} src={unsplashSrc} onClick={onClick} />
            <StyledP>{st}</StyledP>
            {item.full.importStatus === 'importing' && (<SmartContainer absoluteCenter><StyledCircularProgress /></SmartContainer>)}
            {item.full.importStatus === 'success' && (<SmartContainer absoluteCenter><StyledCheck /></SmartContainer>)}
           
        </StyledUnsplashItem>
    )
}



export const UnsplashSearch = ({network = false}) => {

    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(40);
    const [searchText, setSearchText] = useState();
    const [uploaded, setUploaded] = useState([]);

    const { 
        searchUnsplashLoading, 
        searchUnsplashResult, 
        runSearchUnsplash, 
        setSearchUnsplashResult,
        importUnsplashImage
    } = useSearchUnsplash({network});

     // Apply External Filters
     useEffect(() => {
         if(!searchText) return;
        runSearchUnsplash({page,limit,search:searchText})
    },[page, limit])

    const handleItemClick = (item) => {
        importUnsplashImage(item?.full);

    }
     // Handle Pagination Click
     const handlePaginationClick = (pageNumber) => {
        setPage(pageNumber)
        runSearchUnsplash({page:pageNumber,limit,search:searchText})
    }

    // Handle Search
    const handleSearch = (searchString) => {
        setPage(1)
        runSearchUnsplash({page:1,limit,search:searchText})
    }
    
    // const formatted = searchUnsplashResult?.
    const formatted = (searchUnsplashResult?.data?.count > 0) ? searchUnsplashResult?.data?.rows.map(item => { return {id: item.id, title: 'Image', description: item.alt_description, full:item }}) : []
    const total = (searchUnsplashResult?.data?.count > 0) ? searchUnsplashResult?.data?.count : 0;
    const pageCount = Math.ceil(total/limit);

    // Render Card
    const renderMediaCard = (item, onClickItem) => {
        const unsplashSrc = item?.full?.urls?.small;
        item.full.mediaType = 'image';
        return (<UpsplashItem item={item} onClick={onClickItem} />)
        // return (<MediaCard item={item} onClick={onClickItem} src={unsplashSrc} />)
    }

    const renderUploaded = () => {
        if(!uploaded || uploaded.length <= 0) return (<div></div>)
        return uploaded.map(file => {
            const item = file.item;
            const unsplashSrc = item?.full?.urls?.small;
            item.full.mediaType = 'image';
            return (
                <StyledGridItem>
                    <MediaCard item={item} src={unsplashSrc} />
                    <StyledP>{item.status}</StyledP>
                </StyledGridItem>
            )
        })
    }

    return (
        <SmartContainer>

            <SmartContainer>
                <PaginatedList 
                    onClickItem={handleItemClick}
                    loading={searchUnsplashLoading} 
                    items={formatted}
                    total={total}
                    pages={pageCount}
                    currentPage={page}
                    onPaginationClick={handlePaginationClick}
                    onSearchChange={(value) => setSearchText(value)}
                    onSearchSubmit={handleSearch}
                    columns={5}
                    variant="cards" 
                    customRenderer={renderMediaCard}
                    />
                    {total <= 0 && (
                        <SmartContainer textAlign="center">Search Photos</SmartContainer>
                    )}
            </SmartContainer>

            
        </SmartContainer>
    )
}