import React, { useState, useEffect, useRef, useMemo } from 'react';
import { StyledTextField } from '../../styles/matieralui/Form';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { loadScript } from '../../services/Utils';
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { Stack, HorizontalStack, SmartContainer } from '../../styles/Containers';

import { MapView } from '../Maps/MapView';
import 'leaflet/dist/leaflet.css';

// Store As Constants
const autocompleteService = { current: null };
const geocoder = { current: null };

// Format Google Return into an Address
export const formatGoogleAddress = (obj) => {
    return {
        address: obj?.formatted_address,
        place_id: obj?.place_id,
        coord: {
            lat: obj?.geometry?.location.lat(),
            lng: obj?.geometry?.location.lng()
        }
    }
}

// New Search Field
export const FormInputAddressSearch = ({label, value, onChange, withMap = true}) => {
    
    const [localValue, setLocalValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const loaded = useRef(false);

    // Load Google and Set to Window Object
    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&libraries=geometry,places,location`,document.querySelector('head'),'google-maps');
        loaded.current = true;
    }

    // Throttle the search of the google API
    const fetch = useMemo(() => throttle((request, callback) => { autocompleteService.current.getPlacePredictions(request, callback);}, 200),[]);

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
            geocoder.current = new window.google.maps.Geocoder();
        }
        if (!autocompleteService.current) return undefined;

        if (inputValue === '') {
            setOptions(localValue ? [localValue] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (localValue) newOptions = [localValue];

                if (results) newOptions = [...newOptions, ...results];

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [localValue,inputValue, fetch]);

    useEffect(() => {
        setLocalValue(value?.address)
    },[value])

    const handleChange = async (event, newValue) => {
        
        setOptions(newValue ? [newValue, ...options] : options);
        const data = {};
        data.address = newValue?.description;
        data.place_id = newValue?.place_id;

        // Get the location from google
        geocoder.current.geocode({'placeId': data.place_id}, function(results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                if(results[0]){
                    const formatted = formatGoogleAddress(results[0])
                    onChange(formatted);
                }
                // Send to parent
            } else {
                // TODO
            }
        });

    }
    
    return (
        <Stack>
        <Autocomplete
            id="locationArea"
            style={{ "width": "100%" }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={handleChange}
            value={localValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <StyledTextField {...params} label={label} variant="outlined" fullWidth />
            )}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <HorizontalStack flex align="center" {...props}>
                        <LocationOnIcon />
                        <SmartContainer>
                            {parts.map((part,index) => (<StyledB key={index}>{part.text}</StyledB>))}
                            <StyledP light>{option.structured_formatting.secondary_text}</StyledP>
                        </SmartContainer>
                    </HorizontalStack>
                );
            }}
        />
        {withMap && value && (<MapView center={value?.coord} markers={[value]} />)}
        </Stack>
    );
}
