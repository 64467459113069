import { searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getRevisions, duplicateModel } from '../../../redux/DefaultModelActions';
import { APIBase, APIGet, APIPut, APIPost } from '../../../services/API';

const model = 'PlatformEvent';
const route = '/v1/platformevent';

export const searchPlatformEvents = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createPlatformEvent = (args) => createModel(model, route, args);
export const updatePlatformEvent = (id, args) => updateModel(model, route, id, args);
export const deletePlatformEvent = (id) => deleteModel(model, route, id);
export const restorePlatformEvent = (id) => restoreModel(model, route, id);
export const getPlatformEvent = (id) => getModel(model, route, id);

const networkRoute = '/v1/network/platformevent/account';

export const searchNetworkPlatformEvents = (page, limit, search, view, args) => searchModels(model, networkRoute, page, limit, search, view, args);

export const platformTrackEvent = async (name, args) => {

    const payload = {
        name,
        meta: args
    }
    try {
        const res = await APIPost(`${APIBase}${route}`, payload)
        const json = await res.json();
        return json;
    } catch (error) {
        return error;
    }

}