import React from 'react';
import { config } from '../../config'
import { FormSelect } from '../Forms/FormSelect'

export const StatusSelector = (props) => {
   
    return (
        <FormSelect label="Status" value={props.value} onChange={props.onChange}>
            {config.modelStatuses.map(status => (<option key={status} value={status}>{status}</option>))}
        </FormSelect>
    )

}