import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Styles
import { SmartContainer, Stack, HorizontalStack, Surface, StackOnMobile, LargeStack } from '../../../styles/Containers'
import { StyledH6, StyledP, StyledB } from '../../../styles/StyledHTML';
import { StyledTabs, StyledTab, StyledTabPanel } from '../../../styles/matieralui/Tabs';
import { StyledButton } from '../../../styles/matieralui/Button';

// Components
import { Repeater } from '../../../components/Forms/Repeater';
import { FormElement } from '../../components/Forms/FormElement';
import { FormInput } from '../../../components/Forms/FormInput';
import { PublishingForm } from '../../../components/Publishing/PublishingForm';
import { richTextToHTML, HTMLToRichText } from '../../../components/Forms/RichText';
import { TagEditor } from '../../../components/Taxonomy/TagEditor';
import { StyledInlineRichText } from '../../../styles/matieralui/Form';
import { MediaFormInput } from '../../../modules/Media/MediaFormInput';
import { Checkbox } from '../../../components/Forms/Checkbox';
import { StatusSelector } from '../../../components/Publishing/StatusSelector';
import { ShedulingSelector } from '../../../components/Publishing/ShedulingSelector';
import { MoveToTrash } from '../../../components/Publishing/MoveToTrash';
import { Duplicate } from '../../../components/Publishing/Duplicate';

// Redux
import { FullGuides } from './redux/guides.selectors';
import { updateGuide, deleteGuide } from './redux/guides.actions';
import { openToast } from '../../../redux/UI/ui.actions';


// ---------------------------
// Form Guide
// ---------------------------

export const FormGuide = ({ item, childrenMain, childrenSide }) => {

    const dispatch = useDispatch();
    const store = FullGuides();
    const [activeTab, setActiveTab] = useState(0);
    const [status, setStatus] = useState('draft');
    const [scheduling, setScheduling] = useState('');

    // Name
    const [name, setName] = useState('');

    // Content
    const [contentEditorReady, setContentEditorReady] = useState(false);
    const [contentEditorState, setContentEditorState] = useState(null);
    const [defaultContentEditorState, setDefaultContentEditorState] = useState(null);

    // Feature Image
    const [profileImage, setProfileImage] = useState(null);

    // Excerpt
    const [excerpt, setExcerpt] = useState(null);

    // Audio
    const [audio, setAudio] = useState(null);

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    // Sync Props
    useEffect(() => {

        // Publishing
        if (item?.status) setStatus(item.status);
        if (item?.publishedAt) setScheduling({ publishedAt: item.publishedAt, unpublishedAt: item.unpublishedAt });

        // Name
        if (item?.name) setName(item.name);
        else setName('');

        // Content
        if (item?.meta?.description) {
            const defaultEditorState = HTMLToRichText(item.meta.description);
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        } else {
            const defaultEditorState = HTMLToRichText('');
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        }

        // Feature Image
        if (item?.meta?.profileImage) setProfileImage(item.meta?.profileImage);
        else setProfileImage(null);

        // Excerpt
        if (item?.meta?.excerpt) setExcerpt(item.meta?.excerpt);
        else setExcerpt(null);

        // Excerpt
        if (item?.meta?.audio) setAudio(item.meta?.audio);
        else setAudio(null);


    }, [item])

    useEffect(() => {
        if (store.updateSuccess) dispatch(openToast(store.updateSuccess, 'success'))
        if (store.updateError) dispatch(openToast(store.updateError, 'error'))
    }, [store.updateLoading])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = () => {


        // Payload
        const payload = {
            name,
            status: status,
            publishedAt: scheduling.publishedAt,
            unpublishedAt: scheduling.unpublishedAt,
            meta: {
                profileImage,
                description: richTextToHTML(contentEditorState), // Convert Editor State to HTML
                excerpt,
                audio
            }
        }
        dispatch(updateGuide(item.id, payload));
    }

    const handleTabChange = (event, index) => setActiveTab(index);

    // --------------------------
    // Slots
    // --------------------------

    const renderMainContentChildren = () => {
        if (!childrenMain) return (<div></div>)
        return (<SmartContainer>{childrenMain}</SmartContainer>)
    }

    const renderSideContentChildren = () => {
        if (!childrenSide) return (<div></div>)
        return (<SmartContainer>{childrenSide}</SmartContainer>)
    }




    // --------------------------
    // Render Form
    // --------------------------

    return (
        <SmartContainer thinking={store?.updateLoading} minWidth="300px">
            <LargeStack>

                <FormInput label="Name" type="text" onChange={setName} value={name} />


                <Stack>
                    <StackOnMobile flex align="center" justify="space-between">
                        <StyledTabs indicatorColor="primary" value={activeTab} onChange={handleTabChange} aria-label="Edit Form" variant="scrollable" scrollButtons="auto">
                            <StyledTab label="Details" id="screen-0" key="screen-0" aria-controls="screen-0" />
                            <StyledTab label="Assets" id="screen-1" key="screen-1" aria-controls="screen-1" />
                            <StyledTab label="Publishing" id="screen-3" key="screen-3" aria-controls="screen-3" />
                        </StyledTabs>
                        <StyledButton onClick={handleSubmit} color="primary" variant="contained">Save</StyledButton>
                    </StackOnMobile>

                    <Surface>

                        {/* EDIT DETAILS */}
                        <StyledTabPanel value={activeTab} index={0}>
                            <LargeStack>

                                <FormElement label="Description" description="This will show up when people click into a Meditation.">
                                    <StyledInlineRichText
                                        value={contentEditorState}
                                        defaultEditorState={defaultContentEditorState}
                                        onChange={(value) => { setContentEditorState(value) }} />
                                </FormElement>

                                <FormElement label="Short Description" description="This will show up on cards and in lists.">
                                    <FormInput label="Excerpt" rowsMax={4} multiline onChange={setExcerpt} value={excerpt} />
                                </FormElement>

                            </LargeStack>
                        </StyledTabPanel>

                        {/* Media Assets */}
                        <StyledTabPanel value={activeTab} index={1}>
                            <LargeStack>
                                <SmartContainer><StyledH6>Media Assets</StyledH6></SmartContainer>
                                <FormElement label="Profile Image" description="Avatar for this person.">
                                    <MediaFormInput network={true} type="image" label="" value={profileImage} onChange={(value) => setProfileImage(value)} />
                                </FormElement>
                                <FormElement label="Sample Voice Audio" description="This is the audio that a user will use to choose their Guide.">
                                    <MediaFormInput network={true} type="audio" value={audio} onChange={(value) => setAudio(value)} />
                                </FormElement>
                            </LargeStack>
                        </StyledTabPanel>


                        {/* SETTINGS */}
                        <StyledTabPanel value={activeTab} index={2}>
                            <LargeStack>
                                <SmartContainer><StyledH6>Publishing Settings</StyledH6></SmartContainer>
                                <FormElement label="Status" description="Only published content will be visible on your site.">
                                    <StatusSelector onChange={setStatus} value={status} />
                                </FormElement>
                                <FormElement label="Sheduling" description="Set when this item should go live and if it should be removed.">
                                    <ShedulingSelector onChange={setScheduling} value={scheduling} />
                                </FormElement>
                                <FormElement label="Delete Item" description="This will move this item to the trash. This can be undone.">
                                    <StyledP>Are you sure?</StyledP>
                                    <MoveToTrash id={item.id} action={deleteGuide} selector={FullGuides} route="/network/guides" />
                                </FormElement>
                                <FormElement label="Advanced" description="Advanced Settings">
                                    {renderSideContentChildren()}
                                </FormElement>
                            </LargeStack>
                        </StyledTabPanel>

                    </Surface>
                </Stack>

                {renderMainContentChildren()}
            </LargeStack>

        </SmartContainer>
    )
}