import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { NewContent } from '../../components/Content/NewContent';

// Styles
import { SmartContainer } from '../../../styles/Containers'
import { GuidesList, NotePromptsList } from './NotePromptsList';

// Redux
import { restoreGuide, createGuide, createNotePrompt, restoreNotePrompt } from './redux/noteprompts.actions';
import { FullNotePrompts } from './redux/noteprompts.selectors';
import { RouteBreadCrumbs } from '../../../routes';


export const PageNetworkNotePrompts = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullNotePrompts();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => { if (store.createSuccess) dispatch(push(`/network/noteprompts/${store.create.id}`)) }, [store?.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {

        if (item?.full?.deletedAt) dispatch(restoreNotePrompt(item.id))
        else dispatch(push(`/network/noteprompts/${item.id}`))
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <NewContent label="New Note Prompt" selector={FullNotePrompts} createAction={createNotePrompt} />
            </SmartContainer>
        )

    }

    // Render Page
    return (
        <App title="Note Prompts" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            {/* <GuidesList onSelect={handleSelect} /> */}
            <NotePromptsList onSelect={handleSelect} />

        </App>
    )
}