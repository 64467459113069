export default (state = {}, action) => {

    switch (action.type) {
        case '[UI] Wipe':
            return {};
        case '[UI] Toggle Drawer':
        case '[UI] Toast':
        case '[UI] Close Toast':
        case '[UI] Waiting':
        case '[UI] Meditation Playing':
            return Object.assign({}, state, { ...action.payload })

        default:
            return state
    }
}
