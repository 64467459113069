export default (state = {}, action) => {

    switch (action.type) {
        case '[USERS] Wipe':
            return {};
        case '[USERS] Get Users':
        case '[USERS] Get Users Success':
        case '[USERS] Get Users Failed':
        case '[USERS] Update User':
        case '[USERS] Update User Failed':
        case '[USERS] Create User':
        case '[USERS] Create User Failed':
            return Object.assign({},state,{...action.payload});
        case '[USERS] Update User Success':

            // Find the User with the action is.
            const newUserItems = state.users.items.map(item => {

                // Update Data
                if(item.id === action.payload.user.id) {
                    return action.payload.user;
                }

                // Return Original
                return item;
            })

            // New State
            const updatedUserState = {...state};
            updatedUserState.users.items = newUserItems;
            return Object.assign({},updatedUserState,{...action.payload})
        case '[USERS] Create User Success':

            // New State
            const createUserStates = {...state};
            createUserStates.users.items.push(action.payload.user);
            return Object.assign({},createUserStates,{...action.payload})

        default:
            return state
    }
}
