import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createUserNote, updateUserNote } from './usernotes.actions';
import { FullUserNotes } from './usernotes.selectors';



export const useUserNote = ({ noteId = null, meditationId = null, seriesId = null }) => {
    const [nId, setNoteId] = useState(null);
    const [mId, setMeditationId] = useState(null);
    const [sId, setSeriesId] = useState(null);

    const dispatch = useDispatch();
    const store = FullUserNotes();

    // New User Rating
    const saveUserNote = ({ name, content }) => {
        const args = {
            MeditationId: mId,
            SeriesId: sId,
            name,
            content
        }

        if (nId === null) dispatch(createUserNote(args))
        else dispatch(updateUserNote(nId, args))

    }

    useEffect(() => {
        if (noteId) setNoteId(noteId);
        else setNoteId(null);

        if (seriesId) setSeriesId(seriesId);
        else setSeriesId(null);

        if (meditationId) setMeditationId(meditationId)
        else setMeditationId(null);

    }, [noteId, seriesId, meditationId]);

    useEffect(() => {
        if (store.createSuccess) {
            setNoteId(store?.create?.id)
        }
    }, [store?.createLoading, store?.updateLoading])

    return {
        saveUserNote,
        fullNotes: store
    }

}


