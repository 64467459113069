import React from 'react';

// Material UI
import { StyledCircularProgress } from '../../styles/matieralui/Progress';

// TCC Styles
import { SmartContainer, Stack } from '../../styles/Containers'
import logo from '../../space/assets/logo-black-short.png';
import logoWhite from '../../space/assets/logo-white-short.png';
import { StyledH3, StyledImg, StyledP } from '../../styles/StyledHTML';
import { BreathAnimation } from '../../space/styles/AnimatedGradient';

export const FullScreenLoader = () => {
    return (
        <Stack direction="column" height="60vh" flex align="center" justify="center">
            <SmartContainer maxWidth="150px">
                <StyledImg alt="Space Maker" src={logo} />
            </SmartContainer>
            <SmartContainer opacity={0.25}>
                <StyledCircularProgress />
            </SmartContainer>
            {/* <SmartContainer maxWidth="200px">
                <StyledImg alt="Space Maker" src={logo} />
            </SmartContainer> */}
            {/* <SmartContainer>
                <StyledH3>Take A Deep Breath</StyledH3>
            </SmartContainer>
            <SmartContainer opacity={0.25}>
                <StyledCircularProgress />
            </SmartContainer>
            <StyledP>&nbsp;</StyledP>
            <SmartContainer maxWidth="100px" position="fixed" bottom="2em" zIndex="5">
                <StyledImg alt="Space Maker" src={logo} />
            </SmartContainer> */}
            {/* <BreathAnimation /> */}
        </Stack>
    )
}