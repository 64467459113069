import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { App } from '../../../App';
import { createUserNote, getUserNote } from './redux/usernotes.actions';
import { FullUserNotes } from './redux/usernotes.selectors';
import { useParams } from 'react-router-dom';

// Space
import { UserNotesList } from './UserNotesList';
import { StyledH6 } from '../../../styles/StyledHTML';
import { UserNote } from './UserNote';
import { openToast } from '../../../redux/UI/ui.actions';
import { SmartContainer } from '../../../styles/Containers';

export const PageJournalEntry = () => {


    // New Modal
    const dispatch = useDispatch();
    const store = FullUserNotes();
    const { id } = useParams();
    const [activeItem, setActiveItem] = useState(null);

    // --------------------------
    // Effects
    // --------------------------

    // Get This Item
    useEffect(() => { dispatch(getUserNote(id)) }, []);

    // Set Active Item
    useEffect(() => {
        if (store?.activeItem) setActiveItem(store.activeItem);
    }, [store?.activeItem])

    useEffect(() => {
        if (store.updateSuccess) {
            dispatch(openToast('Saved', 'success'))
        }
    }, [store?.updateLoading])


    return (
        <App title='' stackNav="Notes" back="/journal" backLabel="Back">
            <SmartContainer thinking={store?.updateLoading}>
                {activeItem && (<UserNote noteId={activeItem.id} name={activeItem.name} value={activeItem.content} />)}
            </SmartContainer>
        </App>
    )
}