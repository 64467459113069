import React from 'react';
import styled from 'styled-components';
import { SmartContainer } from '../../../styles/Containers';

export const StyledSeriesScroller = styled(SmartContainer)`
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: scroll;
    display: block;
    [data-series-card]{
        width: 300px;
        margin-right: 2em;
        h5 {
            font-size: 1em;
        }
    }
`

export const StyledScrollWrap = styled(SmartContainer)`
    min-width: 100%;
    display: flex;
`
export const HorizontalScroller = ({ children, width }) => {
    return (
        <StyledSeriesScroller data-series-scroller>
            <StyledScrollWrap data-series-scroll-wrap width={width}>
                {children}
            </StyledScrollWrap>
        </StyledSeriesScroller>
    )
}