import React from 'react';
import { StyledFormControlLabel, StyledCheckedbox } from '../../styles/matieralui/Form'

export const Checkbox = (props) => {

    const handleChange = (e) => {
        props.onChange(e.target.checked)
    }

    return (
        <StyledFormControlLabel label={props.label} control={
            <StyledCheckedbox color={props.color} name={props.name} checked={props.value} onChange={handleChange} />
        } />
    );
}