import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeMe } from '../../redux/Account/account.actions';
import { FullAccount } from '../../redux/Account/account.selectors';
import { openToast } from '../../redux/UI/ui.actions';
import { isPayingCustomer } from '../../services/Utils';
import { validPlans } from '../../space/services/SpaceUtils';
import { SmartContainer, Stack } from '../../styles/Containers';
import { StyledAreYouSureButton, StyledButton } from '../../styles/matieralui/Button';
import { StyledA, StyledP } from '../../styles/StyledHTML';
export const AccountDeletion = ({ user, account }) => {
    const [view, setView] = useState('default');
    const customerIsPaying = isPayingCustomer(account, validPlans);
    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const handleAccountDelete = () => {

        dispatch(removeMe());
    }

    useEffect(() => {

        if (fullAccount?.removeMeSuccess) {
            dispatch(openToast("You account has been removed", "success"));
        }
    }, [fullAccount?.removeMeLoading])

    return (
        <Stack thinking={fullAccount?.removeMeLoading}>
            <SmartContainer>
                <StyledP>Delete Account</StyledP>
                <StyledP light>Follow these steps to ensure your account is deleted.</StyledP>
            </SmartContainer>
            {view === 'default' && (
                <StyledButton variant='outlined' onClick={() => setView('delete')}>Delete Account</StyledButton>
            )}


            {view === 'delete' && (
                <SmartContainer>
                    {customerIsPaying && (
                        <SmartContainer>
                            <StyledP light>You still have an active subscription.</StyledP>
                            <StyledA href="/billing">First, Cancel your subscription.</StyledA>
                        </SmartContainer>
                    )}
                    {!customerIsPaying && (
                        <Stack>
                            <StyledP light>Once deleted you won't be able to access this account again and the information associated with it.</StyledP>
                            <StyledAreYouSureButton confirmText='Yes, delete my account' variant="outlined" onClick={handleAccountDelete}>Delete Permenantly</StyledAreYouSureButton>
                        </Stack>
                    )}

                </SmartContainer>
            )}
        </Stack>
    )
}