import React, { useState } from 'react';
import { useMediaAsset, mediaPath } from '../Media/MediaUtils';

// Components
import { App } from '../../App';
import { StyledGrid, StyledGridItem, Surface } from '../../styles/Containers';
import { StyledH1, StyledH6, StyledImg } from '../../styles/StyledHTML';
import { MediaPreview } from '../Media/MediaPreview';
import { MediaFormInput } from '../Media/MediaFormInput';

export const MediaProcessingLab = () => {

    const [id, setId] = useState();
    const network = false;
    const args = {
        process: 'testtwo',
        w: 1000, // Width
        h: 1000, // Height
        q: 100, // Quality
        f: 'webp', // Format
        u: false, // withoutEnlargement
        c: false // Crop
    }
    const { assetLoading, assetPath, assetSrc } = useMediaAsset({ id, network, args });

    const handleMediaSelection = (v) => setId(v);

    return (
        <App title="Lab: Media Processing">
            <StyledH1>Get Image {id}</StyledH1>
            <MediaFormInput label="Media Selection" value={id} onChange={(value) => handleMediaSelection(value)} />

            <StyledH6>{assetSrc}</StyledH6>
            <StyledGrid>
                <StyledGridItem>
                    <Surface>
                        <StyledImg src={mediaPath(assetPath)} />
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface>
                        <StyledImg src={mediaPath(assetPath)} />
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface>
                        <StyledImg src={mediaPath(assetPath)} />
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface>
                        <StyledImg src={mediaPath(assetPath)} />
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface>
                        <StyledImg src={mediaPath(assetPath)} />
                    </Surface>
                </StyledGridItem>
            </StyledGrid>

        </App>
    )
}