import React from 'react';
import moment from 'moment-timezone';
import { FormSelect } from '../Forms/FormSelect'

export const TimeZoneSelect = (props) => {
    
    const zones = moment.tz.names();

    return (
        <FormSelect label="Timezone" value={props.value} onChange={props.onChange}>
            {zones.map(zone => (<option key={zone} value={zone}>{zone}</option>))}
        </FormSelect>
    )

}