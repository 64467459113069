import React, { useState, useEffect } from 'react';
import { Stack, Surface, SmartContainer, HorizontalStack } from '../../../styles/Containers';
import { StyledP } from '../../../styles/StyledHTML';
import { StyledFormLabel } from '../../../styles/matieralui/Form';
import { StyledButton, StyledDangerButton } from '../../../styles/matieralui/Button';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { SpaceIconClose } from '../../styles/Icons';
import { StyledIconButton } from '../../../styles/matieralui/IconButton';

export const PostSelect = ({
    buttonText = 'Select',
    onChange,
    label,
    value,
    withClear = true,
    ListComponent,
    PreviewComponent
}) => {

    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(null);

    const handleSelect = (item) => {
        setOpenModal(false);
        setActive(item.full.id);
        onChange(item.full.id);
    }

    // Effects
    useEffect(() => {
        if (active && active.id === value) {
            // Do Nothing
        } else {
            setActive(value);
        }
    }, [value]);

    return (
        <Stack thinking={loading} width="100%" position="relative">
            {label && (<StyledP><StyledFormLabel>{label}</StyledFormLabel></StyledP>)}
            {!active && (<StyledButton variant="outlined" onClick={() => setOpenModal(!openModal)}>{buttonText}</StyledButton>)}
            <HorizontalStack flex align="center" width="100%">
                {active && (<PreviewComponent id={value} width="100%" />)}
                {active && withClear === true && (
                    <SmartContainer><StyledIconButton onClick={() => onChange(null)}><SpaceIconClose /></StyledIconButton></SmartContainer>
                )}
            </HorizontalStack>


            <StyledModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                label="Select An Item"
                fullWidth={true}
            >
                <Surface variant="inverse">
                    <ListComponent onSelect={handleSelect} />
                </Surface>


            </StyledModal>
        </Stack >
    )
}