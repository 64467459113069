import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { convertCentsToPrice } from '../../services/Stripe';

// Components
import { App } from '../../App';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader'

// Styles
import { Stack, Surface, SmartContainer, HorizontalStack } from '../../styles/Containers';

// Redux
import { getNetworkOrderAccountLevel } from './redux/networkorder.actions';
import { FullNetworkOrders } from './redux/networkorder.selectors';
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { RouteBreadCrumbs } from '../../routes';


export const AccountViewNetworkOrderPage = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const thisId = parseInt(id)
    const fullNetworkOrder = FullNetworkOrders();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => { dispatch(getNetworkOrderAccountLevel(thisId)) },[dispatch,thisId])
    

    // Loader
    if(fullNetworkOrder.activeItemLoading) return (<FullScreenLoader />)
    
    // Set Order
    const order = (fullNetworkOrder.activeItem?.createdAt) ? fullNetworkOrder.activeItem : {}

    return (
        <App title="Order Details" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.billing, RouteBreadCrumbs.billingOrders]}>

            <Stack>
                <SmartContainer flex align="flex-end" justify="space-between">
                    <SmartContainer>
                        <StyledB>Order Id: #{order?.id}</StyledB>
                        <StyledP light>{moment(order?.createdAt).format('YYYY-MM-DD')}</StyledP>
                    </SmartContainer>
                    <SmartContainer>
                        <StyledP light>{order?.orderStatus}</StyledP>
                    </SmartContainer>
                </SmartContainer>
                
                {order?.meta?.lineItems.map(item => (
                    <Surface>
                        <Stack>
                            <StyledB>{item?.description}</StyledB>
                            <StyledP light>${convertCentsToPrice(item?.amount_total)} {item?.currency}</StyledP>
                        </Stack>
                    </Surface>
                ))}

                <SmartContainer flex justify="flex-end">
                    <HorizontalStack flex>
                        <StyledB>Total:</StyledB>
                        <StyledP>${convertCentsToPrice(order?.amount)}</StyledP>
                    </HorizontalStack>
                </SmartContainer>

            </Stack>
            
        </App>
    )    
}