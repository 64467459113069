import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Styles
import { SmartContainer, Stack, HorizontalStack, Surface, StackOnMobile, LargeStack } from '../../../styles/Containers'
import { StyledH6, StyledP, StyledB } from '../../../styles/StyledHTML';
import { StyledTabs, StyledTab, StyledTabPanel } from '../../../styles/matieralui/Tabs';
import { StyledButton } from '../../../styles/matieralui/Button';

// Components
import { Repeater } from '../../../components/Forms/Repeater';
import { FormElement } from '../../components/Forms/FormElement';
import { FormInput } from '../../../components/Forms/FormInput';
import { PublishingForm } from '../../../components/Publishing/PublishingForm';
import { richTextToHTML, HTMLToRichText } from '../../../components/Forms/RichText';
import { TagEditor } from '../../../components/Taxonomy/TagEditor';
import { StyledInlineRichText } from '../../../styles/matieralui/Form';
import { MediaFormInput } from '../../../modules/Media/MediaFormInput';
import { Checkbox } from '../../../components/Forms/Checkbox';
import { StatusSelector } from '../../../components/Publishing/StatusSelector';
import { ShedulingSelector } from '../../../components/Publishing/ShedulingSelector';
import { MoveToTrash } from '../../../components/Publishing/MoveToTrash';
import { Duplicate } from '../../../components/Publishing/Duplicate';
import { MeditationsList } from '../Meditations/MeditationsList';
import { MeditationCard } from '../Meditations/MeditationCard';

// Redux
import { FullSeries } from './redux/series.selectors';
import { updateSeries, deleteSeries, duplicateSeries } from './redux/series.actions';
import { openToast } from '../../../redux/UI/ui.actions';
import { PostSelect } from '../../components/Content/PostSelect';
import { FormSelect } from '../../../components/Forms/FormSelect';

const FormMeditationOrder = ({ item, onChange }) => {

    return (
        <Surface width="100%" variant="inverse">
            <PostSelect buttonText="Select Meditation" ListComponent={MeditationsList} PreviewComponent={MeditationCard} value={item.meditationId} onChange={(value) => onChange(item, 'meditationId', value)} />
        </Surface>
    )
}

// ---------------------------
// Form Meditation
// ---------------------------

export const FormSeries = ({ item, childrenMain, childrenSide }) => {

    const dispatch = useDispatch();
    const store = FullSeries();
    const [activeTab, setActiveTab] = useState(0);
    const [status, setStatus] = useState('draft');
    const [scheduling, setScheduling] = useState('');

    // Name
    const [name, setName] = useState('');
    const [plan, setPlan] = useState('paid');

    // Content
    const [contentEditorReady, setContentEditorReady] = useState(false);
    const [contentEditorState, setContentEditorState] = useState(null);
    const [defaultContentEditorState, setDefaultContentEditorState] = useState(null);

    // Feature Image
    const [featureImage, setFeatureImage] = useState(null);

    // Excerpt
    const [excerpt, setExcerpt] = useState(null);

    // Meditations
    const [meditations, setMeditations] = useState([]);

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    // Sync Props
    useEffect(() => {

        // Publishing
        if (item?.status) setStatus(item.status);
        if (item?.publishedAt) setScheduling({ publishedAt: item.publishedAt, unpublishedAt: item.unpublishedAt });


        // Name
        if (item?.name) setName(item.name);
        else setName('');

        // Name
        if (item?.meta?.plan) setPlan(item?.meta?.plan);
        else setPlan('');

        // Content
        if (item?.meta?.description) {
            const defaultEditorState = HTMLToRichText(item.meta.description);
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        } else {
            const defaultEditorState = HTMLToRichText('');
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        }

        // Feature Image
        if (item?.meta?.featureImage) setFeatureImage(item.meta?.featureImage);
        else setFeatureImage(null);

        // Excerpt
        if (item?.meta?.excerpt) setExcerpt(item.meta?.excerpt);
        else setExcerpt(null);

        // Meditations
        if (item?.meditations) setMeditations(item.meditations);
        else setMeditations([]);

    }, [item])

    useEffect(() => {
        if (store.updateSuccess) dispatch(openToast(store.updateSuccess, 'success'))
        if (store.updateError) dispatch(openToast(store.updateError, 'error'))
    }, [store.updateLoading])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = () => {


        // Payload
        const payload = {
            name,
            status: status,
            publishedAt: scheduling.publishedAt,
            unpublishedAt: scheduling.unpublishedAt,
            meta: {
                featureImage,
                description: richTextToHTML(contentEditorState), // Convert Editor State to HTML
                excerpt,
                plan
            },
            meditations
        }
        dispatch(updateSeries(item.id, payload));
    }

    const handleTabChange = (event, index) => setActiveTab(index);


    const repeaterOnChange = (updatedData) => {
        setMeditations([...updatedData]);
    }

    // --------------------------
    // Slots
    // --------------------------

    const renderMainContentChildren = () => {
        if (!childrenMain) return (<div></div>)
        return (<SmartContainer>{childrenMain}</SmartContainer>)
    }

    const renderSideContentChildren = () => {
        if (!childrenSide) return (<div></div>)
        return (<SmartContainer>{childrenSide}</SmartContainer>)
    }




    // --------------------------
    // Render Form
    // --------------------------

    return (
        <SmartContainer thinking={store?.updateLoading} minWidth="300px">
            <LargeStack>

                <FormInput label="Title" type="text" onChange={setName} value={name} />


                <Stack>
                    <StackOnMobile flex align="center" justify="space-between">
                        <StyledTabs indicatorColor="primary" value={activeTab} onChange={handleTabChange} aria-label="Edit Form" variant="scrollable" scrollButtons="auto">
                            <StyledTab label="Details" id="screen-0" key="screen-0" aria-controls="screen-0" />
                            <StyledTab label="Assets" id="screen-1" key="screen-1" aria-controls="screen-1" />
                            <StyledTab label="Publishing" id="screen-3" key="screen-3" aria-controls="screen-3" />
                        </StyledTabs>
                        <StyledButton onClick={handleSubmit} color="primary" variant="contained">Save</StyledButton>
                    </StackOnMobile>

                    <Surface>

                        {/* EDIT DETAILS */}
                        <StyledTabPanel value={activeTab} index={0}>
                            <LargeStack>

                                <FormElement label="Plan" description="Who has access?">
                                    <FormSelect label="Plan" value={plan} onChange={setPlan}>
                                        <option key="paid" value="paid">Paid Users</option>
                                        <option key="free" value="free">Free Users</option>
                                    </FormSelect>

                                </FormElement>

                                <FormElement label="Description" description="This will show up when people click into a Meditation.">
                                    <StyledInlineRichText
                                        value={contentEditorState}
                                        defaultEditorState={defaultContentEditorState}
                                        onChange={(value) => { setContentEditorState(value) }} />
                                </FormElement>

                                <FormElement label="Short Description" description="This will show up on cards and in lists.">
                                    <FormInput label="Excerpt" rowsMax={4} multiline onChange={setExcerpt} value={excerpt} />
                                </FormElement>

                                <FormElement label="Meditations" description="TThese are the meditations that are part of this Series">
                                    <Repeater
                                        id="seriesmeditations"
                                        datasource={meditations}
                                        component={FormMeditationOrder}
                                        onChange={repeaterOnChange}
                                        buttonText="Add Meditation"
                                    />
                                </FormElement>

                            </LargeStack>
                        </StyledTabPanel>

                        {/* Media Assets */}
                        <StyledTabPanel value={activeTab} index={1}>
                            <LargeStack>
                                <SmartContainer><StyledH6>Media Assets</StyledH6></SmartContainer>
                                <FormElement label="Feature Image" description="This is the main image that will display in the feed.">
                                    <MediaFormInput network={true} type="image" label="" value={featureImage} onChange={(value) => setFeatureImage(value)} />
                                </FormElement>


                            </LargeStack>
                        </StyledTabPanel>


                        {/* SETTINGS */}
                        <StyledTabPanel value={activeTab} index={2}>
                            <LargeStack>
                                <SmartContainer><StyledH6>Publishing Settings</StyledH6></SmartContainer>
                                <FormElement label="Status" description="Only published content will be visible on your site.">
                                    <StatusSelector onChange={setStatus} value={status} />
                                </FormElement>
                                <FormElement label="Sheduling" description="Set when this item should go live and if it should be removed.">
                                    <ShedulingSelector onChange={setScheduling} value={scheduling} />
                                </FormElement>
                                <FormElement label="Delete Item" description="This will move this item to the trash. This can be undone.">
                                    <StyledP>Are you sure?</StyledP>
                                    <MoveToTrash id={item.id} action={deleteSeries} selector={FullSeries} route="/blogs" />
                                </FormElement>
                                <FormElement label="Duplicate Item" description="This will create a copy of this item.">
                                    <StyledP>Are you sure?</StyledP>
                                    <Duplicate id={item.id} action={duplicateSeries} selector={FullSeries} route="/blogs" />
                                </FormElement>
                                <FormElement label="Advanced" description="Advanced Settings">
                                    {renderSideContentChildren()}
                                </FormElement>
                            </LargeStack>
                        </StyledTabPanel>

                    </Surface>
                </Stack>

                {renderMainContentChildren()}
            </LargeStack>

        </SmartContainer>
    )
}