import React, { useState } from 'react';

// Components
import { App } from '../../App';
import { Surface, Stack, SmartContainer, StyledGridByTwo, StyledGridItem } from '../../styles/Containers'
import { StyledP } from '../../styles/StyledHTML';
import { FormInput } from '../../components/Forms/FormInput';

export const DesignSystem = () => {

    const [text,setText] = useState(null);

    return (
        <App title="Lab: Design System">
            <Stack>
                <StyledGridByTwo>
                    <StyledGridItem>
                        <Surface width="100%">
                            <Stack>
                                <StyledP>Form Input</StyledP>
                                <FormInput label="Text" value={text} onChange={(value) => setText(value)} />
                            </Stack>
                            
                        </Surface>
                    </StyledGridItem>
                    <StyledGridItem>
                        <SmartContainer width="100%">
                            <StyledP>Form Input</StyledP>
                            <FormInput inverse label="Text" value={text} onChange={(value) => setText(value)} />
                        </SmartContainer>
                    </StyledGridItem>
                    
                    
                </StyledGridByTwo>
                
            </Stack>
        </App>
    )
}