import styled, { css } from 'styled-components';
import { SmartContainer } from './Containers';
import { Theme } from './Theme'

// --------------------------------------
// Typography
// --------------------------------------

const textStyles = css`
    line-height: ${Theme.lineHeight};
    color: ${Theme.colors.baseText};

    // Error
    ${props => (props.error) ? css`color: ${Theme.colors.error};` : ''}

    // Success
    ${props => (props.success) ? css`color: ${Theme.colors.success};` : ''}

    // Light
    ${props => (props.light) ? css`opacity: 0.5;` : ''}

    // Small
    ${props => (props.small) ? css`font-size: 0.75em;` : ''}

    // margin
    ${props => (props.marginTop) ? css`margin-top: ${props.marginTop};` : ''}
    ${props => (props.marginBottom) ? css`margin-bottom: ${props.marginBottom};` : ''}

    // Text Align
    ${props => (props.textAlign) ? css` text-align:${props.textAlign}` : ''}
`

export const StyledH1 = styled.h1`
    font-family: ${Theme.SpaceStyles.fonts.heading};
    font-weight: ${Theme.SpaceStyles.fonts.headingWeight};
    font-size: 3em;
    margin: 0;
    ${textStyles}
`;

export const StyledH2 = styled.h2`
    font-family: ${Theme.SpaceStyles.fonts.heading};
    font-weight: ${Theme.SpaceStyles.fonts.headingWeight};
    font-size: 2.5em;
    margin: 0;
    ${textStyles}
`;

export const StyledH3 = styled.h3`
    font-family: ${Theme.SpaceStyles.fonts.heading};
    font-weight: ${Theme.SpaceStyles.fonts.headingWeight};
    font-size: 2em; 
    margin: 0;
    ${textStyles}
`;

export const StyledH4 = styled.h4`
    font-family: ${Theme.SpaceStyles.fonts.heading};
    font-weight: ${Theme.SpaceStyles.fonts.headingWeight};
    font-size: 2.5em;
    margin: 0;
    ${textStyles}
`;

export const StyledH5 = styled.h5`
    font-family: ${Theme.SpaceStyles.fonts.heading};
    font-weight: ${Theme.SpaceStyles.fonts.headingWeight};
    font-size: 2em;
    margin: 0;
    ${textStyles}
`;

export const StyledH6 = styled.h6`
    font-family: ${Theme.SpaceStyles.fonts.heading};
    font-weight: ${Theme.SpaceStyles.fonts.headingWeight};
    font-size: 1.5em;
    margin: 0;
    ${textStyles}
`;

export const StyledP = styled.p`
    font-size: 1em;
    margin: 0;
    ${textStyles}
`;
export const StyledB = styled.b`
    font-weight: 600;
    margin: 0;
    ${textStyles}
`;

export const StyledA = styled.a`
    ${textStyles}
    cursor: pointer;
`;


// --------------------------------------
// HTML
// --------------------------------------

export const StyledImg = styled.img`
    width: 100%;
    height: auto;
    ${props => (props.radius) ? css`border-radius: ${Theme.radius};` : ''}
    ${props => (props.shadow) ? css` box-shadow: ${Theme.shadow[props.shadow]};` : ``}
    ${props => (props.maxWidth) ? css` max-width: ${props.maxWidth};` : ``}
`;

export const StyledEllipsis = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    ${props => (props.width) ? css`width: ${props.width};` : 'width: 100%;'}
    ${props => (props.maxWidth) ? css`max-width: ${props.maxWidth};` : ''}
`

export const StyledIframe = styled.iframe`
    width: 100%;
    height: auto;
    ${props => (props.radius) ? css`border-radius: ${Theme.radius};` : ''}
`;

export const StyledVideo = styled.video`
    width: 100%;
    height: auto;
    ${props => (props.radius) ? css`border-radius: ${Theme.radius};` : ''}
`;

export const StyledHr = styled.hr`
    border: 1px solid ${Theme.colors.base};
`

export const StyledCode = styled.textarea`
    background-color: ${Theme.colors.base};
    width: 100%;
    display: block;
    padding: 1em;
    font-family: Courier;
`;