import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../../styles/Theme';
import { SkeletonAnimation } from '../../../styles/GlobalStyles';
import { StyledImg } from '../../../styles/StyledHTML';
import { mediaPath } from '../../../modules/Media/MediaUtils';
import { loadMediaItem } from '../../../modules/Media/redux/media.actions';
import { storeWithExpiry, loadWithExpiry } from '../../services/SpaceUtils';
import { SmartContainer } from '../../../styles/Containers';
import { useSpring, animated } from 'react-spring';

const StyledImageLoader = styled(StyledImg)`
    background: ${Theme.colors.baseDarkened};
    background-size: 200% 200%;
    /* min-height: 150px; */
    width: 100%;
    display: inline-block;
    ${props => (props.loading) ? css`
        animation: ${SkeletonAnimation} 5000ms linear infinite;
    ` : ''}
`

export const useMediaAsset = ({ id, network }) => {

    const [activeMedia, setActiveMedia] = useState(null);
    const [path, setPath] = useState(null);
    const [loading, setLoading] = useState(false);

    const localStorageKey = 'mediaassets';

    const loadMedia = async (id) => {
        if (!id) return;
        setLoading(true);

        // Load From Storage First
        let localLibrary = await loadWithExpiry(localStorageKey);
        if (!localLibrary) localLibrary = {}
        else if (localLibrary[id]) {
            setPath(localLibrary[id]);
            setLoading(false);
            return;
        }

        // Load From Server
        const res = await loadMediaItem(id, network);
        if (res.status === 'success') {
            setPath(res.data?.meta?.path);
            setActiveMedia(res.data);
            setLoading(false);

            // Regrab storage. things probably came in during that load
            localLibrary = await loadWithExpiry(localStorageKey);
            if (!localLibrary) localLibrary = {}

            // Save to Storage
            localLibrary[res.data.id] = res.data?.meta?.path;
            const l = storeWithExpiry(localStorageKey, localLibrary, 5000);



        } else {
            // TODO: Error
            setLoading(false);
        }
    }


    // Effects
    useEffect(() => {

        // Load Media from Server
        if (activeMedia && activeMedia.id === id) {
        } else {
            loadMedia(id)
        }
    }, [id]);

    const truePath = (loading) ? null : path;
    return {
        assetLoading: loading,
        // activeMedia,
        assetPath: truePath,
        assetSrc: (truePath) ? mediaPath(truePath) : null
    }
}


export const MediaImage = ({ id, network = false, className, radius = false, download = false }) => {

    const { assetLoading, assetPath } = useMediaAsset({ id, network });
    const styles = useSpring({ opacity: assetLoading ? 0 : 1 })
    return (
        <animated.div style={styles}>
            {!assetLoading && (<StyledImageLoader radius={radius} className={className} loading={assetLoading} src={mediaPath(assetPath)} />)}
        </animated.div>

    )

}

export const StyledMediaImage = styled(MediaImage)`
${props => (props.shadow) ? css` box-shadow: ${Theme.shadow[props.shadow]};` : ``}
`