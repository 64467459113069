import React, { useState, useEffect } from 'react';
import { loadWithExpiry, storeWithExpiry } from '../../services/Utils';
import { loadMediaItem } from './redux/media.actions';
import { APIBase, APIGet, APIPost } from '../../services/API';
import { StyledImg } from '../../styles/StyledHTML';


export const ImageCroppingPresets = {
    thumbnail: {
        process: 'thumbnail',
        w: 400, // Width
        h: 400, // Height
        q: 90, // Quality
        f: 'original', // Format
        u: false, // withoutEnlargement
        c: false // Crop
    },
    largepreview: {
        process: 'largepreview',
        w: 2200, // Width
        q: 90, // Quality
        f: 'original', // Format
        u: true, // withoutEnlargement
        // c: false // Crop
    },
    // TODO: HighRes (but not full res)

}



export const mediaPath = (file, storage = 's3') => {
    if (!file) return;
    if (file.indexOf('http://') > -1) return file;
    if (file.indexOf('https://') > -1) return file;

    let domain = process.env.REACT_APP_MEDIA_DOMAIN;
    if (storage === 'wasabi') domain = process.env.REACT_APP_WASABI_DOMAIN;

    return `${domain}/${file}`;
}

export const importHostedMedia = async ({ name, type, url, network = false }) => {

    try {

        // Path
        const route = (network === true) ? 'network/media' : 'media';
        const path = `${APIBase}/v1/${route}/import`;

        // Search
        const res = await APIPost(path, { url, name, type });
        const json = await res.json();
        return json;

    } catch (error) {
        return error;
    }
}

export const convertBytesToPrettySize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const useMediaAsset = ({ id, network, args = {} }) => {

    const [activeMedia, setActiveMedia] = useState(null);
    const [path, setPath] = useState(null);
    const [loading, setLoading] = useState(false);

    const localStorageKey = 'mediaassets';

    const loadMedia = async (id) => {
        if (!id) return;

        let processed_id = `${id}`;
        if (args?.process) processed_id = `${id}_${args.process}`;
        setLoading(true);

        // Load From Storage First
        let localLibrary = await loadWithExpiry(localStorageKey);

        if (!localLibrary) localLibrary = {}
        else if (localLibrary[processed_id]) {

            setPath(localLibrary[processed_id]);
            setLoading(false);
            return;
        }

        // Load From Server
        const res = await loadMediaItem(id, network, args);
        if (res.status === 'success') {

            let process_path = res.data?.meta?.path;
            if (args?.process && res.data?.meta?.crops && res.data?.meta?.crops[args.process]) process_path = mediaPath(res.data?.meta?.crops[args.process]?.path, res.data?.meta?.storage);
            setPath(process_path);
            setActiveMedia(res.data);
            setLoading(false);

            // Regrab storage. things probably came in during that load
            localLibrary = await loadWithExpiry(localStorageKey);
            if (!localLibrary) localLibrary = {}

            // Save to Storage
            localLibrary[processed_id] = mediaPath(process_path, res.data?.meta?.storage);
            const l = storeWithExpiry(localStorageKey, localLibrary, 5000);

        } else {
            // TODO: Error
            setLoading(false);
        }
    }


    // Effects
    useEffect(() => {

        // Load Media from Server
        if (activeMedia && activeMedia.id === id) {
        } else {
            loadMedia(id)
        }
    }, [id]);

    const truePath = (loading) ? null : path;

    return {
        assetLoading: loading,
        assetPath: truePath,
        assetSrc: (truePath) ? mediaPath(truePath) : null,
        activeMedia
    }
}

export const MediaImage = ({ image, id, crop = {}, network = false, ...rest }) => {

    const { assetLoading, assetPath, assetSrc } = useMediaAsset({ id: image.id, network, args: crop });
    if (assetLoading) return null;
    return (<StyledImg {...rest} src={assetPath} />)
}