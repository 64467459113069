import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../../App';
import { FullScreenLoader } from '../../../components/Loaders/FullScreenLoader';
import { respondTo } from '../../../styles/Theme';

// Styles
import { SmartContainer, Stack, StyledGrid, StyledGridItem, Surface } from '../../../styles/Containers'
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledH5, StyledP } from '../../../styles/StyledHTML';
import { StyledList, StyledListItem } from '../../../styles/matieralui/List';

// Redux
import { getSeries, searchSeries } from './redux/series.actions';
import { FullSeries, findSeries } from './redux/series.selectors';
import { StyledH1, StyledImg } from '../../../styles/StyledHTML';

import { StyledMediaImage } from '../../components/Display/MediaAsset';

import { useAnimatedPageMount } from '../../services/SpaceUtils';
import { animated } from 'react-spring';
import { StackOnMobile } from '../../../styles/Containers';
import { MeditationListItem } from '../Meditations/MeditationListItem';
import { Favorite } from '../UserFavorites/Favorite';
import { Theme } from '../../../styles/Theme';
import { findUserProgressSeriesCompletedMeditations } from '../UserProgress/redux/userprogress.actions';
import { SpaceIconStar } from '../../styles/Icons';
import { StyledTag } from './SeriesCard';


export const StyledMeditation = styled(SmartContainer)`
    /* padding-top: 1em !important; */
    padding-bottom: 1em !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    transition: ${Theme.transition.one} !important;
    cursor: pointer !important;
    ${props => (props.locked === true) ? css`
        opacity:0.25;
        pointer-events: none;
    ` : css`opacity: 1;`}
    /* &:hover {
        padding-left: 1em !important;
        padding-right: 1em !important;
    } */
`

const StyledSeriesImage = styled(SmartContainer)`
    // STAR
    .MuiSvgIcon-root {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        color: white;
    }
    [data-tag]{
        position: absolute;
        top: 0.5em;
        right: 0.5em; 
    }
`

export const PageSeriesLanding = () => {

    // New Modal
    const dispatch = useDispatch();
    const fullSeries = FullSeries();
    const { id } = useParams();
    const [activeSeries, setActiveSeries] = useState(null);
    const [seriesProgress, setSeriesProgress] = useState(null);
    const { animatedProps } = useAnimatedPageMount();


    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Load Everything
        if (!fullSeries?.items) dispatch(searchSeries(1, 999, null, 'published'))

    }, [])

    useEffect(() => {
        if (!fullSeries?.items?.count) return;
        const a = findSeries(id, fullSeries?.items?.rows);
        setActiveSeries(a);

        // Get Series Progress
        const loadProgress = async () => {
            const res = await findUserProgressSeriesCompletedMeditations({ seriesId: a.id });
            if (res) {
                setSeriesProgress(res)
            } else {
                // No Progress. Unlocked item one.
            }
        }
        loadProgress();
    }, [fullSeries])

    const handleMeditationClick = (meditation) => {
        dispatch(push(`/series/${id}/play/${meditation.id}`))
    }

    const goTo = (path) => dispatch(push(path));

    const findProgressForMeditation = (medId) => {
        if (!seriesProgress) return;
        const found = seriesProgress?.filter(item => item.MeditationId === medId);
        // TODO: Only returns one currently.
        if (found) return found[0];
    }

    // --------------------------
    // Renders
    // --------------------------


    const renderMeditations = () => {
        // Guard
        if (!activeSeries?.fullMeditations) return (<div></div>);

        let number = 0;
        let lastMedCompleted = false;
        return activeSeries?.fullMeditations.map((meditation, index) => {

            if (!meditation?.id) return (<div></div>)
            number = number + 1;


            // Locked
            // TODO: Removing Locked option for now. User can now listen in any order
            let locked = false;
            // let locked = true;
            // if (!seriesProgress && index === 0) locked = false;

            // const completed = findProgressForMeditation(meditation.id);
            // if (lastMedCompleted === true) {
            //     locked = false;
            //     lastMedCompleted = false;
            // }

            // if (completed) {
            //     locked = false;
            //     lastMedCompleted = true;
            // }





            return (
                <StyledMeditation locked={locked} button key={meditation.id} onClick={() => handleMeditationClick(meditation)}>
                    <MeditationListItem meditation={meditation} />
                </StyledMeditation>
            )
        })
    }

    const free = (activeSeries?.meta?.plan === 'free') ? true : false;

    // Render Page
    return (
        <App stackNav="Series" back="/series" backLabel="Back">
            {fullSeries?.itemsLoading && (<FullScreenLoader />)}
            {activeSeries && (
                <SmartContainer>
                    <animated.div style={animatedProps}>
                        <Stack>
                            {activeSeries?.meta?.featureImage && (
                                <StyledSeriesImage width="100%" position="relative">
                                    <StyledMediaImage shadow="one" radius id={activeSeries?.meta?.featureImage} network={true} />
                                    {free && <StyledTag data-tag>Free</StyledTag>}
                                </StyledSeriesImage>
                            )}
                            <SmartContainer>
                                <StyledH5>{activeSeries?.name}</StyledH5>
                            </SmartContainer>
                            <SmartContainer>
                                <Favorite modelId={activeSeries.id} modelType="Series" withText={true} />
                            </SmartContainer>


                            <StyledList>
                                {renderMeditations()}
                            </StyledList>
                        </Stack>

                    </animated.div>

                </SmartContainer>
            )}
        </App>
    )
}