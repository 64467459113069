import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { HorizontalStack, SmartContainer, Stack, Surface } from '../../styles/Containers';
import { StyledB, StyledH3, StyledH6, StyledImg, StyledP } from '../../styles/StyledHTML';
import { StyledModal } from '../../styles/matieralui/Modal';
import { FormInput } from '../../components/Forms/FormInput';
import { Checkbox } from '../../components/Forms/Checkbox'
import { Repeater } from '../../components/Forms/Repeater';


import logo from '../Integrations/assets/appstartersupport-logo.png';
import { StyledButton } from '../../styles/matieralui/Button';
import { StyledSwitch } from '../../styles/matieralui/Form';

import { useSaveIntegration } from '../Integrations/integrations.hooks';
import { IntegrationCard } from '../Integrations/IntegrationCard';
import { IntegrationFormHeader } from '../Integrations/IntegrationFormHeader';


// Repeated Component
const AppStarterSupportLinkForm = ({item,onChange}) => {
    return (
        <Surface width="100%">
            <HorizontalStack flex>
                <FormInput label="Name" value={item.name} onChange={(value) => onChange(item,'name',value)} />
                <FormInput label="URL" value={item.url} onChange={(value) => onChange(item,'url',value)} />
                <Checkbox label="New Tab?" value={item.newTab} onChange={(value) => onChange(item,'newTab',value)} color="primary" />
            </HorizontalStack>
        </Surface>
    )
}

// Form
export const AppStarterSupportForm = ({meta, open = false, network = false}) => {
    const key = 'appstartersupport';
    const title = "App Starter Support";
    const description = "Adds a simple support widget.";

    // Use Integration Save
    const { openForm, setOpenForm, saveIntegration, thinking } = useSaveIntegration({open, network});
    
    // Form Fields
    const [widgetTitle,setWidgetTitle] = useState('');
    const [message,setMessage] = useState('');
    const [links,setLinks] = useState([])
    const [enabled,setEnabled] = useState(false);

    // Set Default values
    useEffect(() => {
        if(!meta) return;
        if(meta[key] && meta[key].enabled) setEnabled(meta[key].enabled);
        if(meta[key] && meta[key].title) setWidgetTitle(meta[key].title);
        if(meta[key] && meta[key].message) setMessage(meta[key].message);
        if(meta[key] && meta[key].links) setLinks(meta[key].links);
    },[meta])

    const repeaterOnChange = (updatedData) => {
        setLinks([...updatedData]);
    }


    // Format Save Data
    const handleSave = () => {
        const m = {...meta};
        if(!m[key]) m[key] = {};
        m[key].enabled = enabled;
        m[key].title = widgetTitle;
        m[key].message = message;
        m[key].links = links;
        saveIntegration(m);
    }

    return (
        <SmartContainer thinking={thinking}>
            <IntegrationCard clickable onClick={() => setOpenForm(true)} 
                title={title}
                description={description}
                logo={logo}
                />

            <StyledModal
                open={openForm}
                onClose={() => setOpenForm(false)}
                >
                    <Stack>
                        <IntegrationFormHeader title={title} logo={logo}>
                            <StyledSwitch color="primary" checked={enabled} name="enabled" onChange={(e) => setEnabled(e.target.checked)} />
                        </IntegrationFormHeader>
                                              
                        <FormInput label="Widget Title" type="text" onChange={setWidgetTitle} value={widgetTitle} helperText="Welcome!" />
                        <FormInput label="Widget Message" type="text" onChange={setMessage} value={message} helperText="We respond to every message as soon as possible." />
                        <StyledP>Quick Links</StyledP>
                        <Repeater
                            id="appstartersupportlinks"
                            datasource={links}
                            component={AppStarterSupportLinkForm}
                            onChange={repeaterOnChange}
                            />
                        
                        <StyledButton variant="contained" color="primary" onClick={handleSave}>Save</StyledButton>
                    </Stack>
                    
                </StyledModal>
        </SmartContainer>
    )
}