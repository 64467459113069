import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { ModelList } from '../../../components/PaginatedList/ModelList';

// Styles
import { SmartContainer, Surface } from '../../../styles/Containers'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// Redux
import { searchTags, restoreTag } from './redux/tags.actions';
import { FullTags } from './redux/tags.selectors';

export const TagsList = ({ onSelect }) => {

    // New Modal
    const dispatch = useDispatch();
    const [view, setView] = useState('all');
    const store = FullTags();

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => onSelect(item);


    // --------------------------
    // Renders
    // --------------------------


    // Render Page
    return (
        <ModelList
            model="Tags"
            action={searchTags}
            restore={restoreTag}
            selector={FullTags}
            onSelect={handleSelect}
            variant="cards"
        />
    )
}