import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Styled
import { SmartContainer, Surface, Stack } from '../../styles/Containers'
import { StyledH3, StyledP } from '../../styles/StyledHTML';
import { StyledButton } from '../../styles/matieralui/Button'

// Redux
import { createCustomerPortalSession } from '../../redux/Billing/billing.actions'
import { FullBilling } from '../../redux/Billing/billing.selectors'
import { FullAccount } from '../../redux/Account/account.selectors';
import { openToast } from '../../redux/UI/ui.actions';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { isStripeSubscription, postMessage, isNativeApp } from '../../services/Utils';

export const CustomerPortal = ({ account }) => {

    const dispatch = useDispatch();
    const fullBilling = FullBilling();
    const fullAccount = FullAccount();
    const currentNetwork = CurrentNetwork();
    const { billing } = currentNetwork;

    const nativeBilling = (fullAccount?.account?.subscriptionId && !isStripeSubscription(fullAccount?.account?.subscriptionId));
    const nativeApp = isNativeApp();

    const loadPortal = () => {

        // Native
        if (nativeBilling) {
            postMessage('OPEN_PLAN_MANAGER', { account: fullAccount?.account, back: document?.referrer });
            if (!nativeApp) alert("You purchased this subscription using the app. Please login via the app to manage your subscription.")
        } else {
            // Stripe
            dispatch(createCustomerPortalSession(currentNetwork.id))
        }

    }

    // Listen For Session Changes
    useEffect(() => {

        // Redirect to Stripe Checkout
        if (fullBilling.portalSessionSuccess) {

            // Redirect To Portal
            window.location.href = fullBilling.portalSession.url;

        }

        // Display Error
        if (fullBilling.portalSessionError) {
            dispatch(openToast(fullBilling.portalSessionError, 'error'))
        }
    }, [fullBilling.portalSessionLoading, fullBilling.portalSession, fullBilling.portalSessionSuccess, fullBilling.portalSessionError, dispatch])

    const getCurrentPlan = () => {
        let currentPlan = null;
        currentPlan = billing.plans.filter(plan => {
            if (plan?.monthly?.nickname === fullAccount.account.plan) return plan;
            else if (plan?.yearly?.nickname === fullAccount.account.plan) return plan;
            return false;
        })
        return currentPlan[0]
    }

    const plan = getCurrentPlan();

    const renderButton = () => {
        if (nativeBilling && !nativeApp) return null;
        return (<StyledButton variant="contained" color="primary" onClick={() => loadPortal()}>Manage Your Subscription</StyledButton>)
    }

    return (
        <Surface>

            <Stack>
                <SmartContainer>
                    <StyledH3>Manage your {plan.name} subscription</StyledH3>
                </SmartContainer>
                {!nativeBilling && (<StyledP light>Update your card, Change your plan and more.</StyledP>)}
                {nativeBilling && !nativeApp && (<StyledP light>You purchased this subscription via the native app. Please login through the app to manage your subscription.</StyledP>)}
                {nativeBilling && nativeApp && (<StyledP light>Update your card, Change your plan and more.</StyledP>)}
                {renderButton()}
            </Stack>


        </Surface>
    )
}