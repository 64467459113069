import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import { Theme, setSpace } from '../../styles/Theme'
import { Editor, EditorState, convertFromHTML, ContentState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html'
import 'draft-js/dist/Draft.css';

// Components
import { FormSelect } from '../Forms/FormSelect';


// Styles
import { SmartContainer, Stack } from '../../styles/Containers';
import { StyledB, StyledP, StyledCode } from '../../styles/StyledHTML';
import { FormInput } from './FormInput';

// Icons
import { StyledIconButton } from '../../styles/matieralui/IconButton'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CodeIcon from '@mui/icons-material/Code';

// Draft Link
import { createLinkDecorator, DraftLinkTrigger } from './DraftJs/DraftLink';
// import { onAddDraftLink } from './DraftJs/DraftLink';

// import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
// import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
// import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
// import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
// import LinkIcon from '@material-ui/icons/Link';
// import LinkOffIcon from '@material-ui/icons/LinkOff';

const StyledEditor = styled.div`
    width: 100%;
    
`;

const StyledEditorControls = styled(SmartContainer)`
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: ${Theme.radius};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: ${setSpace(1)};
`;

const StyledEditorInput = styled(SmartContainer)`
   
    .DraftEditor-root {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: ${Theme.radius};
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 10px 12px 10px;
    }
`;

const StyledFormSelect = styled(FormSelect)`
    max-width: 200px;
`

const textTypes = [
    {
        value: 'unstyled',
        name: 'Unstyled'
    },
    {
        value: 'p',
        name: 'Paragraph'
    },
    {
        value: 'header-one',
        name: 'Heading One',
    },
    {
        value: 'header-two',
        name: 'Heading Two',
    },
    {
        value: 'header-three',
        name: 'Heading Three',
    },
    {
        value: 'header-four',
        name: 'Heading Four',
    },
    {
        value: 'header-five',
        name: 'Heading Five',
    },
    {
        value: 'header-six',
        name: 'Heading Six',
    },
    {
        value: 'blockquote',
        name: 'Blockquote',
    },
    {
        value: 'code-block',
        name: 'Code',
    }
];

/**
 * Converts EditorState to HTML
 * @param {editorState} state 
 */

export const richTextToHTML = (state) => {
    if (!state) return;
    return stateToHTML(state.getCurrentContent());
}

/**
 * Converts HTML to an editorState
 * @param {string} html 
 */

export const HTMLToRichText = (html) => {
    // Decorators
    const decorator = createLinkDecorator()
    // THML
    const contentHTML = convertFromHTML(html)
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    // const content = JSON.stringify(convertToRaw(state))
    // return content;
    const es = EditorState.createWithContent(state, decorator)
    return es;
}
/*
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const blocksFromHtml = htmlToDraft(this.props.content);
const { contentBlocks, entityMap } = blocksFromHtml;
const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
const editorState = EditorState.createWithContent(contentState);
*/

/**
 * RichTextEditor
 * @param {obj} props 
 */
export const RichTextEditor = (props) => {

    // Decorators
    const decorator = createLinkDecorator()

    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
    const [htmlView, setHtmlView] = useState(false);
    const [html, setHTML] = useState(null);
    const [toggleLink, setToggleLink] = useState(false);
    const [hideControls, setHideControls] = useState(props?.hideControls)
    const [ready, setReady] = useState(false);
    const editorRef = useRef();
    // On Editor Change: Pass Full State to Parent
    useEffect(() => {
        props.onChange(editorState); // Pass editor straight up
    }, [editorState])

    // On Initialize: Replace Entire State
    useEffect(() => {
        setEditorState(props.defaultEditorState);
        setTimeout(() => { setReady(true) }, 1000);
    }, [props.defaultEditorState]);

    useEffect(() => {
        if (ready !== true) return;
        if (props?.focus !== true) return;
        if (!editorRef) return;
        if (!editorRef?.current) return;

        editorRef.current.focus();
    }, [ready, editorRef])



    // Update Editor State
    const updateEditorState = (es) => setEditorState(es)

    // Handle Keyboard Commands
    const handleKeyCommand = (command, es) => {
        const newState = RichUtils.handleKeyCommand(es, command);

        if (newState) {
            updateEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    // Check For Inline Styles
    const hasInlineStyle = (style) => {
        if (!editorState) return false;
        return editorState.getCurrentInlineStyle().has(style)
    }
    const isBlockType = (type) => {
        const currentContentBlockType = getCurrentBlockType();
        return (currentContentBlockType === type) ? true : false;
    }
    const getCurrentBlockType = () => {
        if (!editorState) return;
        const selection = editorState.getSelection();
        const anchorKey = selection.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey)
        return currentContentBlock.type;
    }

    const toggleHtml = () => {
        if (htmlView === true) {
            // richTextToHTML(editorState)
            const v = HTMLToRichText(html);
            // Convert to editor state.
            updateEditorState(v);
            setHtmlView(false);
        }
        if (htmlView !== true) {
            // Convert to HTML
            const h = richTextToHTML(editorState);
            setHtmlView(true);
            setHTML(h);

        }
    }
    const handleHTMLChange = (value) => {
        setHTML(value);
        const v = HTMLToRichText(value);
        updateEditorState(v);
    }
    const cn = (props?.className) ? props.className : '';
    return (
        <Stack className={cn}>
            <StyledP>
                <StyledB>{props.label}</StyledB>
            </StyledP>

            <StyledEditor>
                {hideControls !== true && (
                    <StyledEditorControls data-rich-text-editor-controls flex width="100%" align="center">
                        {htmlView !== true && (
                            <SmartContainer flex width="100%" wrap>
                                <StyledIconButton selected={hasInlineStyle('BOLD')} onClick={() => updateEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))}><FormatBoldIcon /></StyledIconButton>
                                <StyledIconButton selected={hasInlineStyle('ITALIC')} onClick={() => updateEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))}><FormatItalicIcon /></StyledIconButton>
                                <StyledIconButton selected={hasInlineStyle('UNDERLINE')} onClick={() => updateEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))}><FormatUnderlinedIcon /></StyledIconButton>
                                <StyledIconButton selected={hasInlineStyle('STRIKETHROUGH')} onClick={() => updateEditorState(RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH'))}><FormatStrikethroughIcon /></StyledIconButton>
                                <StyledIconButton selected={isBlockType('unordered-list-item')} onClick={() => updateEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))}><FormatListBulletedIcon /></StyledIconButton>
                                <StyledIconButton selected={isBlockType('ordered-list-item')} onClick={() => updateEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'))}><FormatListNumberedIcon /></StyledIconButton>
                                {props?.hideLink !== true && (<DraftLinkTrigger selected={toggleLink} toggleEditor={setToggleLink} editorState={editorState} onChange={updateEditorState} />)}
                                {/* <StyledIconButton onClick={() => alert("LINK")}><LinkIcon /></StyledIconButton> */}
                                {/* <StyledIconButton><FormatAlignLeftIcon /></StyledIconButton>
                    <StyledIconButton><FormatAlignCenterIcon /></StyledIconButton>
                    <StyledIconButton><FormatAlignRightIcon /></StyledIconButton> */}
                                {/* <StyledIconButton><LinkIcon /></StyledIconButton> */}
                                {props?.hideElements !== true && (<StyledFormSelect value={getCurrentBlockType()} label="Element" onChange={(value) => updateEditorState(RichUtils.toggleBlockType(editorState, value))}>{textTypes.map(element => (<option key={element.value} value={element.value}>{element.name}</option>))}</StyledFormSelect>)}
                            </SmartContainer>
                        )}
                        {htmlView === true && (<SmartContainer flex width="100%">HTML VIew</SmartContainer>)}
                        {props?.hideHTMLView !== true && (<StyledIconButton selected={htmlView} onClick={() => toggleHtml()}><CodeIcon /></StyledIconButton>)}
                    </StyledEditorControls>
                )}

                {htmlView !== true && (
                    <StyledEditorInput>
                        {editorState && (
                            <Editor
                                editorState={editorState}
                                onChange={updateEditorState}
                                handleKeyCommand={handleKeyCommand}
                                focus={props?.focus}
                                ref={editorRef}
                            />
                        )}
                    </StyledEditorInput>
                )}
                {htmlView === true && (
                    <FormInput multiline onChange={handleHTMLChange} value={html} />
                )}

            </StyledEditor>
        </Stack>
    )

}

export const getBlockClassName = (name) => `richText-block richText-${name}-block`;

export const BlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    const blockAlignment = contentBlock.getData() && contentBlock.getData().get('text-align');
    if (blockAlignment) return `${getBlockClassName(blockAlignment)} richText-textAlignment-block`;
    if (type === 'unstyled') return getBlockClassName('unstyled');
    return null;
}