import { APIGet, APIPut, APIPost, APIBase } from '../../services/API';
import { config } from '../../config';
import { dispatchLoading, dispatchError, dispatchSuccess, dispatchWipe } from '../utils'
import { openToast } from '../UI/ui.actions'

export const attemptLogin = (email, password, networkId) => dispatch => {

    // Update the UI
    dispatch({
        type: '[AUTH] Attempt Login',
        payload: {
            loading: true
        }
    });

    // Make An API Request To Login
    APIPost(`${APIBase}/v1/login`, { email, password, networkId })
        .then(res => res.json())
        .then(json => {

            if (json.status == "error") {
                let e = json?.message;
                if (!e && json?.error) e = json?.error;
                else if (!e) e = "Something went wrong.";

                dispatch({
                    type: '[AUTH] Attempt Login Failed',
                    payload: {
                        loading: false,
                        error: e
                    }
                });
                return;
            }

            const session = {
                token: json.data.token,
                firstName: json.data.firstName,
                role: json.data.userAccount.Role.name
            }

            // Write to local storage
            window.localStorage.setItem(config.sessionId, JSON.stringify(session));

            // Success
            dispatch({
                type: '[AUTH] Attempt Login Success',
                payload: {
                    loading: false,
                    success: true,
                    session: session
                }
            });

        })
        .catch(e => {
            dispatch({
                type: '[AUTH] Attempt Login Failed',
                payload: {
                    loading: false,
                    error: e
                }
            });
        })

}

export const attemptLogout = () => dispatch => {

    // Update the UI
    dispatch({
        type: '[AUTH] Attempt Logout',
        payload: {
            loading: true
        }
    });

    // Destory Session
    window.localStorage.removeItem(config.sessionId);

    dispatch({
        type: '[AUTH] Attempt Logout Success',
        payload: {
            loading: false,
            success: true,
            session: null,
            me: null
        }
    });

    dispatch(clearEverything())

}

export const loadUserFromStorage = () => dispatch => {

    const session = window.localStorage.getItem(config.sessionId);
    if (!session) {
        dispatch({
            type: '[AUTH] Attempt Login Failed',
            payload: {
                loading: false,
                error: 'User not found'
            }
        });
    } else {
        dispatch({
            type: '[AUTH] Attempt Login Success',
            payload: {
                loading: false,
                success: true,
                session: JSON.parse(session)
            }
        });
    }

}


export const getCurrentUser = () => dispatch => {

    const session = window.localStorage.getItem(config.sessionId);

    // Update the UI
    dispatch({
        type: '[AUTH] Load Current User',
        payload: {
            loading: true
        }
    });

    // Make An API Request To Login
    APIGet(`${APIBase}/v1/me`)
        .then(res => res.json())
        .then(json => {
            if (json.status == "error") {
                dispatch({
                    type: '[AUTH] Load Current User Failed',
                    payload: {
                        loading: false,
                        error: json.message
                    }
                });
                return;
            }

            // Success
            dispatch({
                type: '[AUTH] Load Current User Success',
                payload: {
                    loading: false,
                    success: true,
                    me: json.data.me,
                    session: JSON.parse(session)
                }
            });

        })
        .catch(e => {
            dispatch({
                type: '[AUTH] Load Current User Failed',
                payload: {
                    loading: false,
                    error: e
                }
            });
        })
}

export const updateMe = (newMe) => dispatch => {



    // User Cannot Update their own role
    delete newMe.role;

    // Get The Current User
    const session = JSON.parse(window.localStorage.getItem(config.sessionId));

    // Leave if we aren't editing ourselves
    if (session.id !== newMe.id) return;


    // Update the UI
    dispatch({
        type: '[AUTH] Update Current User',
        payload: {
            loading: true,
            updateMeLoader: true
        }
    });

    // Make An API Request To Login
    APIPut(`${APIBase}/v1/me`, newMe)
        .then(res => res.json())
        .then(json => {
            if (json.status == "error") {
                dispatch({
                    type: '[AUTH] Update Current User Failed',
                    payload: {
                        loading: false,
                        updateMeLoader: false,
                        updateError: json.message,
                        success: false,
                        updateSuccess: false
                    }
                });
                return;
            }

            // Success
            session.firstName = json.data.me.firstName
            dispatch({
                type: '[AUTH] Update Current User Success',
                payload: {
                    loading: false,
                    updateMeLoader: false,
                    success: true,
                    updateSuccess: true,
                    me: json.data.me,
                    session: session,
                    message: json.message,
                    updateError: null
                }
            });
            return;

        })
        .catch(e => {
            dispatch({
                type: '[AUTH] Update Current User Failed',
                payload: {
                    loading: false,
                    updateMeLoader: false,
                    updateError: e,
                    success: false,
                    updateSuccess: false
                }
            });
        })

}


/**
 * Sign Up To The App
 * @param {string} email 
 * @param {string} password 
 * @param {sting} fullName 
 * @param {string} accountName 
 */
export const attemptSignup = (networkId, email, password, fullName, accountName, terms, marketingOptIn) => dispatch => {

    // Update the UI
    dispatch({
        type: '[AUTH] Attempt Signup',
        payload: {
            loading: true
        }
    });

    // Format Payload
    const payload = {
        name: accountName,
        networkId,
        user: {
            name: fullName,
            email: email,
            password: password
        }
    }
    if (terms) payload.user.acceptedTermsVersion = config.termsVersion;
    if (marketingOptIn) payload.user.marketingOptIn = marketingOptIn;

    APIPost(`${APIBase}/v1/account/create`, payload)
        .then(res => res.json())
        .then(json => {


            if (json.status == "error") {

                let e = json?.message;
                if (!e && json?.error) e = json?.error;
                else if (!e) e = "Something went wrong.";

                dispatch({
                    type: '[AUTH] Attempt Signup Failed',
                    payload: {
                        loading: false,
                        error: e
                    }
                });
                return;
            }

            const session = {
                token: json.data.token,
                firstName: json.data.firstName,
                role: json.data.userAccount.Role.name
            }

            // Write to local storage
            window.localStorage.setItem(config.sessionId, JSON.stringify(session));

            // Success
            dispatch({
                type: '[AUTH] Attempt Signup Success',
                payload: {
                    loading: false,
                    success: true,
                    session
                }
            });

        })
        .catch(e => {
            dispatch({
                type: '[AUTH] Attempt Signup Failed',
                payload: {
                    loading: false,
                    error: e
                }
            });
        })

}


/**
 * Recover Password
 * @param {string} email 
 */
export const recoverPassword = (networkId, email) => dispatch => {

    // Update the UI
    dispatch({
        type: '[AUTH] Recover Password',
        payload: {
            loading: true,
            recoverLoader: true
        }
    });

    // Make An API Request To Login
    APIPost(`${APIBase}/v1/recover`, { networkId, email })
        .then(res => res.json())
        .then(json => {

            if (json.status == "error") {
                dispatch({
                    type: '[AUTH] Recover Password Failed',
                    payload: {
                        loading: false,
                        recoverLoader: false,
                        error: json.message,
                        recoverError: json.message
                    }
                });
                return;
            }

            // Success
            dispatch({
                type: '[AUTH] Recover Password Success',
                payload: {
                    loading: false,
                    recoverLoader: false,
                    success: true,
                    message: json.message,
                    recoverError: null,
                    error: null
                }
            });

        })
        .catch(e => {
            dispatch({
                type: '[AUTH] Recover Password Failed',
                payload: {
                    loading: false,
                    recoverLoader: false,
                    error: e,
                    recoverError: null
                }
            });
        })

}


/**
 * Recover Password
 * @param {string} email 
 */
export const resetPassword = (password, token) => dispatch => {

    // Update the UI
    dispatch({
        type: '[AUTH] Reset Password',
        payload: {
            loading: true,
            resetLoader: true
        }
    });

    // Make An API Request To Login
    APIPost(`${APIBase}/v1/reset`, { password, token })
        .then(res => res.json())
        .then(json => {

            if (json.status == "error") {
                dispatch({
                    type: '[AUTH] Reset Password Failed',
                    payload: {
                        loading: false,
                        resetLoader: false,
                        error: json.message,
                        resetError: json.message
                    }
                });
                return;
            }

            // Success
            dispatch({
                type: '[AUTH] Reset Password Success',
                payload: {
                    loading: false,
                    resetLoader: false,
                    success: true,
                    message: json.message,
                    resetError: null,
                    error: null
                }
            });

        })
        .catch(e => {
            dispatch({
                type: '[AUTH] Reset Password Failed',
                payload: {
                    loading: false,
                    resetLoader: false,
                    error: e,
                    resetError: e.message
                }
            });
        })

}

export const switchAccount = (accountId) => dispatch => {

    const action = '[AUTH] Switch Account';
    const prefix = 'switchAccount';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIGet(`${APIBase}/v1/switch/${accountId}`)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') dispatch(dispatchError(action, prefix, json.message))
            else {

                // Destory Current Token
                window.localStorage.removeItem(config.sessionId);

                // Update Session
                const session = {
                    token: json.data.token,
                    role: json.data?.userAccount?.Role.name
                }

                // Write to local storage
                window.localStorage.setItem(config.sessionId, JSON.stringify(session));

                dispatch(dispatchSuccess(action, prefix, json.message, json.data));

                // Clear State
                dispatch(clearEverything())

                window.location.href = '/';
            }
        })
        .catch(e => { dispatch(dispatchError(action, prefix, e.message)) })
}

export const hasNetworkAccess = (me, networkId) => {

    if (!me.networks) return false;
    if (me.superAdmin === true) return true;
    let access = false;
    me.networks.map(network => {
        if (network.id === networkId) access = true;
    })
    return access;
}

export const acceptTerms = (version) => dispatch => {

    const action = '[AUTH] Accept Terms';
    const prefix = 'acceptTerms';

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPut(`${APIBase}/v1/me/terms`, { version })
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {
                dispatch(openToast(json.message, 'error'))
                dispatch(dispatchError(action, prefix, json.message))
            }
            else {
                dispatch(openToast(json.message, 'success'))
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));

                // Clear State
                dispatch(clearEverything())

                // Redirect
                window.location.href = '/';
            }
        })
        .catch(e => {
            dispatch(openToast(e.message, 'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}


export const visitorToken = (accountId = null, networkId = null) => dispatch => {

    // Update the UI
    dispatch({
        type: '[AUTH] Attempt Visitor Token',
        payload: {
            loading: true
        }
    });

    const args = {}
    if (accountId) args.accountId = accountId;
    if (networkId) args.networkId = networkId;

    // Make An API Request To Login
    APIPost(`${APIBase}/v1/visitor/token`, args)
        .then(res => res.json())
        .then(json => {

            if (json.status == "error") {
                dispatch({
                    type: '[AUTH] Attempt Visitor Token Failed',
                    payload: {
                        loading: false,
                        error: json.message
                    }
                });
                return;
            }

            const session = {
                token: json.data.token,
                visitor: true
            }

            // Write to local storage
            window.localStorage.setItem(config.sessionId, JSON.stringify(session));

            // Success
            dispatch({
                type: '[AUTH] Attempt Visitor Token Success',
                payload: {
                    loading: false,
                    success: true,
                    session: session
                }
            });

        })
        .catch(e => {
            dispatch({
                type: '[AUTH] Attempt Visitor Token Failed',
                payload: {
                    loading: false,
                    error: e
                }
            });
        })

}

export const clearEverything = () => dispatch => {
    dispatch(dispatchWipe('[ACCOUNT]'))
    dispatch(dispatchWipe('[UI]'))
    dispatch(dispatchWipe('[USERS]'))
    dispatch(dispatchWipe('[AUTH]'))
    dispatch(dispatchWipe('[BILLING]'))
    dispatch(dispatchWipe('[NETWORK]'))
}

export const hasVisitorToken = () => {

    const session = JSON.parse(window.localStorage.getItem(config.sessionId));
    if (session && session.visitor === true) return true;
    return false;

}

export const getSession = () => {
    return JSON.parse(window.localStorage.getItem(config.sessionId));
}
