
import React from 'react';
import { StyledFormControl, StyledFormControlLabel, StyledFormLabel, StyledRadioGroup, StyledRadio } from '../../styles/matieralui/Form';



export const RadioGroup = (props) => {
    const handleChange = (e) => props.onChange(e.target.value)
    return (
        <StyledFormControl>
            <StyledFormLabel>{props.label}</StyledFormLabel>
            <StyledRadioGroup aria-label={props.label} name={props.name} value={props.value} onChange={handleChange}>
                {props.options && props.options.map(option => { 
                    return (<StyledFormControlLabel key={option.key} control={<StyledRadio color="primary"/>} {...option} />)
                })}
            </StyledRadioGroup>
        </StyledFormControl>
    )
}