import React, { useState, useEffect, useCallback } from 'react';

// Components
import { MediaLibrary } from './MediaLibrary';
import { MediaPreview } from './MediaPreview';
import { StyledModal } from '../../styles/matieralui/Modal'

// Styles
import { Stack, SmartContainer } from '../../styles/Containers';
import { StyledButton, StyledDangerButton } from '../../styles/matieralui/Button';
import { StyledP } from '../../styles/StyledHTML';
import { StyledFormLabel } from '../../styles/matieralui/Form';

// Redux
import { loadMediaItem } from './redux/media.actions';
import { mediaPath } from './MediaUtils';

// ---------------------------
// The Component
// ---------------------------

export const MediaFormInput = ({
    buttonText = 'Select Media',
    clearButtonText = 'Clear Media',
    type = 'all',
    onChange,
    label = 'Select Media',
    value,
    network = false,
    showDownload = false
}) => {

    // Local State
    const [openModal, setOpenModal] = useState(false);
    const [activeMedia, setActiveMedia] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadMedia = useCallback((async (id) => {
        if (!id) return;
        setLoading(true);
        const res = await loadMediaItem(id, network);
        if (res.status === 'success') {
            setActiveMedia(res.data);
            setLoading(false);
        } else {
            // TODO: Error
            setLoading(false);
        }
    }), [network]);


    // // Effects
    // useEffect(() => {
    //     // Load Media from Server
    //     if(activeMedia && activeMedia.id !== value) loadMedia(value)
    // },[value,activeMedia,loadMedia]);

    // Effects: Value Has Changed
    useEffect(() => {

        loadMedia(value)

    }, [value])


    // Interactions
    const handleSelection = (item) => {
        setOpenModal(false);
        setActiveMedia(item.full);
        onChange(item.full.id);
    }

    const handleClear = () => {
        setActiveMedia(null);
        onChange(null);
    }

    return (
        <Stack thinking={loading}>
            <StyledP><StyledFormLabel>{label}</StyledFormLabel></StyledP>
            {!activeMedia && (<StyledButton variant="outlined" onClick={() => setOpenModal(!openModal)}>{buttonText}</StyledButton>)}
            {activeMedia && (<SmartContainer flex justify="flex-end"><StyledDangerButton variant="outlined" onClick={() => handleClear()}>{clearButtonText}</StyledDangerButton></SmartContainer>)}
            <MediaPreview item={activeMedia} network={network} />
            {showDownload && activeMedia?.meta?.path && (
                <a href={mediaPath(activeMedia?.meta?.path)} target="_blank" download>Download File</a>

            )}

            <StyledModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                label="Select or Upload Media"
                fullWidth={true}
            >
                <MediaLibrary network={network} type={type} onChange={(item) => handleSelection(item)} />

            </StyledModal>
        </Stack>
    )
}