import React from 'react';

// TCC Components
import { Surface } from '../../styles/Containers'
import { StyledH1, StyledP } from '../../styles/StyledHTML'

export const SurfaceMessage = ({title,description}) => {

    return (
            <Surface>
                <StyledH1>{title}</StyledH1>
                <StyledP>{description}</StyledP>
            </Surface>
    );
}