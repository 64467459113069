import { useState, useEffect } from 'react';
import { APIBase, APIGet, APIPost } from '../../../services/API';
import { importHostedMedia } from '../MediaUtils';

// Search Unsplash
export const searchUnsplash = async ({search = null,limit = 10,page = 1}) => {

    let qs = [];
    if(search) qs.push('search='+search);
    if(limit) qs.push('limit='+limit);
    if(page) qs.push('page='+page);
    let qString = qs.join('&');
    const path = `${APIBase}/v1/media/unsplash/search/?${qString}`;

    try {

        // Search
        const res = await APIGet(path);
        const json = await res.json();
        return json;

    } catch (error) {
        return error;
    }
}

// Search Unsplash
// export const importImageFromUnsplash = async (image) => {
//     const maxWidth = 2800;
//     const path = `${APIBase}/v1/media/unsplash/import/`;
//     const url = `${image?.urls?.full}&w=${maxWidth}`
//     // https://images.unsplash.com/photo-1576271758698-e64b83251f4a?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDUxMzV8MHwxfHNlYXJjaHwxOHx8YmVlcnxlbnwwfHx8fDE2MjY2NzEwODE&ixlib=rb-1.2.1&q=85&w=20

//     try {

//         // Search
//         const res = await APIPost(path,{url});
//         const json = await res.json();
//         return json;

//     } catch (error) {
//         return error;
//     }
// }


export const useSearchUnsplash = ({network=false}) => {
    const [loading,setLoading] = useState(false);
    const [result,setResults] = useState({});
    const [toImport,setToImport] = useState([]);
    const [imported,setImported] = useState([]);


    // Search Unsplash
    const runSearchUnsplash = async ({search,limit, page}) => {
        setLoading(true);
        const res = await searchUnsplash({search,limit, page});
        setResults(res);
        setLoading(false);
    }

    // Update Image Status
    const updateImageStatus = async (image, status) => {
        
        // Clone Data
        const data = {...result};

        // Change the status of the selected value.
        data.data.rows = result?.data?.rows.map(row => {
            
            if(image.id === row.id) {
                row.importStatus = status;
            }
            return row;
        })

        // Update
        setResults(data);
    }

    // Add Image to the Import Queue
    const importImage = async (image) => {

        if(image?.importStatus && image?.importStatus != 'preview') return;

        // Set Status
        await updateImageStatus(image,'importing');

        // Import Image
        const maxWidth = 2800;
        const args = {
            name: image.id,
            type: "image",
            url: `${image?.urls?.full}&w=${maxWidth}`,
            network
        }

        // Set Status
        const res = await importHostedMedia(args);
        if(res.status === 'success') await updateImageStatus(image,'success');
        else await updateImageStatus(image,'preview');

    }

    return {
        searchUnsplashLoading: loading,
        searchUnsplashResult: result,
        runSearchUnsplash,
        setSearchUnsplashResult: setResults,
        setToImportUnsplash: setToImport,
        unsplashToImport: toImport,
        unsplashImportedImages: imported,
        importUnsplashImage: importImage
    }

}

export const useImportUnsplashImage = () => {
    
    const [images,setImages] = useState([]);
    const [imported,setImported] = useState([]);

    return {
        setUnsplashImportImages: setImages,
        unsplashImportImages: images,
        unsplashImportedImages: imported
    }
}