import React from 'react';

import { App } from '../../App'
import { FullNetwork } from '../../redux/Network/network.selectors';
import { NetworkRole } from '../../redux/Auth/auth.selectors'

// TCC Redux

/**
 * App
 * This is the main wrapper for the entire application.
 * It hands auth and setup.
 */

export const NetworkApp = ({title, breadcrumbs, children, topBarChildren}) => {

    // Read State
    const fullNetwork = FullNetwork();
    const currentNetworkRole = NetworkRole(fullNetwork.network?.id);
    const defaultTitle = (currentNetworkRole === 'superadmin') ? 'Platform ' : fullNetwork.network?.name + ' Network';

    return (
        <App title={defaultTitle + ' ' + title} subtitle={`Network: ${fullNetwork.network.name}`} topBarChildren={topBarChildren} breadcrumbs={breadcrumbs}>
            {children}
        </App>
    )
}