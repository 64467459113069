import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Styles
import { SmartContainer, Stack, HorizontalStack, Surface, StackOnMobile, LargeStack } from '../../../styles/Containers'
import { StyledH6, StyledP, StyledB } from '../../../styles/StyledHTML';
import { StyledTabs, StyledTab, StyledTabPanel } from '../../../styles/matieralui/Tabs';
import { StyledButton } from '../../../styles/matieralui/Button';

// Components
import { Repeater } from '../../../components/Forms/Repeater';
import { FormElement } from '../../components/Forms/FormElement';
import { FormInput } from '../../../components/Forms/FormInput';
import { PublishingForm } from '../../../components/Publishing/PublishingForm';
import { richTextToHTML, HTMLToRichText } from '../../../components/Forms/RichText';
import { TagEditor } from '../../../components/Taxonomy/TagEditor';
import { StyledInlineRichText } from '../../../styles/matieralui/Form';
import { MediaFormInput } from '../../../modules/Media/MediaFormInput';
import { Checkbox } from '../../../components/Forms/Checkbox';
import { StatusSelector } from '../../../components/Publishing/StatusSelector';
import { ShedulingSelector } from '../../../components/Publishing/ShedulingSelector';
import { MoveToTrash } from '../../../components/Publishing/MoveToTrash';
import { Duplicate } from '../../../components/Publishing/Duplicate';
import { GuidesList } from '../Guides/GuidesList';

// Redux
import { FullMeditations } from './redux/meditations.selectors';
import { updateMeditation, deleteMeditation, duplicateMeditation } from './redux/meditations.actions';
import { openToast } from '../../../redux/UI/ui.actions';
import { PostSelect } from '../../components/Content/PostSelect';
import { GuideCard } from '../Guides/GuideCard';
import { FullTags } from '../Tags/redux/tags.selectors';
import { searchTags, replaceNetworkTags } from '../Tags/redux/tags.actions';
import { MeditationStats } from './MeditationStats';

const FormMeditationAudio = ({ item, onChange }) => {

    return (
        <Surface width="100%" variant="inverse">
            <HorizontalStack flex>
                <PostSelect buttonText="Select Guide" ListComponent={GuidesList} PreviewComponent={GuideCard} value={item.guide} onChange={(value) => onChange(item, 'guide', value)} />
                <SmartContainer width="50%">
                    <MediaFormInput network={true} type="audio" buttonText="Select Audio" value={item?.file} onChange={(value) => onChange(item, 'file', value)} showDownload={true} />
                </SmartContainer>
            </HorizontalStack>
        </Surface>
    )
}

// ---------------------------
// Form Meditation
// ---------------------------

export const FormMeditation = ({ item, childrenMain, childrenSide }) => {

    const dispatch = useDispatch();
    const store = FullMeditations();
    const [activeTab, setActiveTab] = useState(0);
    const [status, setStatus] = useState('draft');
    const [scheduling, setScheduling] = useState('');

    // Name
    const [name, setName] = useState('');

    // Content
    const [contentEditorReady, setContentEditorReady] = useState(false);
    const [contentEditorState, setContentEditorState] = useState(null);
    const [defaultContentEditorState, setDefaultContentEditorState] = useState(null);

    // Prompt
    const [promptEditorReady, setPromptEditorReady] = useState(false);
    const [promptEditorState, setPromptEditorState] = useState(null);
    const [defaultPromptEditorState, setDefaultPromptEditorState] = useState(null);

    // Feature Image
    const [featureImage, setFeatureImage] = useState(null);
    const [socialSquare, setSocialSquare] = useState(null);

    // Excerpt
    const [excerpt, setExcerpt] = useState(null);

    // Audio
    const [audio, setAudio] = useState([]); //{guide:ID,file:ID}

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    // Sync Props
    useEffect(() => {

        // Publishing
        if (item?.status) setStatus(item.status);
        if (item?.publishedAt) setScheduling({ publishedAt: item.publishedAt, unpublishedAt: item.unpublishedAt });


        // Name
        if (item?.name) setName(item.name);
        else setName('');

        // Content
        if (item?.meta?.description) {
            const defaultEditorState = HTMLToRichText(item.meta.description);
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        } else {
            const defaultEditorState = HTMLToRichText('');
            setContentEditorState(defaultEditorState);
            setDefaultContentEditorState(defaultEditorState);
        }

        // Prompt
        if (item?.meta?.prompt) {
            const defaultPromptEditorState = HTMLToRichText(item.meta.prompt);
            setPromptEditorState(defaultPromptEditorState);
            setDefaultPromptEditorState(defaultPromptEditorState);
        } else {
            const defaultPromptEditorState = HTMLToRichText('');
            setPromptEditorState(defaultPromptEditorState);
            setDefaultPromptEditorState(defaultPromptEditorState);
        }

        // Feature Image
        if (item?.meta?.featureImage) setFeatureImage(item.meta?.featureImage);
        else setFeatureImage(null);

        // Feature Image
        if (item?.meta?.socialSquare) setSocialSquare(item.meta?.socialSquare);
        else setSocialSquare(null);

        // Excerpt
        if (item?.meta?.excerpt) setExcerpt(item.meta?.excerpt);
        else setExcerpt(null);

        // Excerpt
        if (item?.meta?.audio) setAudio(item.meta?.audio);
        else setAudio([]);


    }, [item])

    useEffect(() => {
        if (store.updateSuccess) dispatch(openToast(store.updateSuccess, 'success'))
        if (store.updateError) dispatch(openToast(store.updateError, 'error'))
    }, [store.updateLoading])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = () => {


        // Payload
        const payload = {
            name,
            status: status,
            publishedAt: scheduling.publishedAt,
            unpublishedAt: scheduling.unpublishedAt,
            meta: {
                featureImage,
                description: richTextToHTML(contentEditorState), // Convert Editor State to HTML
                prompt: richTextToHTML(promptEditorState), // Convert Editor State to HTML
                excerpt,
                audio,
                socialSquare
            }
        }
        dispatch(updateMeditation(item.id, payload));
    }

    const handleTabChange = (event, index) => setActiveTab(index);


    const repeaterOnChange = (updatedData) => {
        setAudio([...updatedData]);
    }

    // --------------------------
    // Slots
    // --------------------------

    const renderMainContentChildren = () => {
        if (!childrenMain) return (<div></div>)
        return (<SmartContainer>{childrenMain}</SmartContainer>)
    }

    const renderSideContentChildren = () => {
        if (!childrenSide) return (<div></div>)
        return (<SmartContainer>{childrenSide}</SmartContainer>)
    }




    // --------------------------
    // Render Form
    // --------------------------

    return (
        <SmartContainer thinking={store?.updateLoading} minWidth="300px">
            <LargeStack>

                <FormInput label="Title" type="text" onChange={setName} value={name} />


                <Stack>
                    <StackOnMobile flex align="center" justify="space-between">
                        <StyledTabs indicatorColor="primary" value={activeTab} onChange={handleTabChange} aria-label="Edit Form" variant="scrollable" scrollButtons="auto">
                            <StyledTab label="Details" id="screen-0" key="screen-0" aria-controls="screen-0" />
                            <StyledTab label="Assets" id="screen-1" key="screen-1" aria-controls="screen-1" />
                            <StyledTab label="Tags" id="screen-2" key="screen-2" aria-controls="screen-2" />
                            <StyledTab label="Publishing" id="screen-3" key="screen-3" aria-controls="screen-3" />
                            <StyledTab label="Stats" id="screen-4" key="screen-4" aria-controls="screen-4" />
                        </StyledTabs>
                        <StyledButton onClick={handleSubmit} color="primary" variant="contained">Save</StyledButton>
                    </StackOnMobile>

                    <Surface>

                        {/* EDIT DETAILS */}
                        <StyledTabPanel value={activeTab} index={0}>
                            <LargeStack>

                                <FormElement label="Intro Lessons" description="This will show up before they click Begin.">
                                    <StyledInlineRichText
                                        value={contentEditorState}
                                        defaultEditorState={defaultContentEditorState}
                                        onChange={(value) => { setContentEditorState(value) }} />
                                </FormElement>

                                <FormElement label="Short Description" description="This will show up on cards and in lists.">
                                    <FormInput label="Excerpt" rowsMax={4} multiline onChange={setExcerpt} value={excerpt} />
                                </FormElement>

                                <FormElement label="Journal Prompt" description="This will be the default journal prompt for this meditation.">
                                    <StyledInlineRichText
                                        value={promptEditorState}
                                        defaultEditorState={defaultPromptEditorState}
                                        onChange={(value) => { setPromptEditorState(value) }} />
                                </FormElement>

                            </LargeStack>
                        </StyledTabPanel>

                        {/* Media Assets */}
                        <StyledTabPanel value={activeTab} index={1}>
                            <LargeStack>
                                <SmartContainer><StyledH6>Media Assets</StyledH6></SmartContainer>
                                <FormElement label="Feature Image" description="This is the main image that will display in the feed.">
                                    <MediaFormInput network={true} type="image" label="" value={featureImage} onChange={(value) => setFeatureImage(value)} />
                                </FormElement>
                                <FormElement label="Social Graphic (Square)" description="This is the share image for Instagram.">
                                    <MediaFormInput network={true} type="image" label="" value={socialSquare} onChange={(value) => setSocialSquare(value)} />
                                </FormElement>
                                <SmartContainer width="100%">
                                    <StyledP><StyledB>Audio Guides</StyledB></StyledP>
                                    <StyledP light>Add the Audio for each Guide</StyledP>
                                    <Repeater
                                        id="meditationaudio"
                                        datasource={audio}
                                        component={FormMeditationAudio}
                                        onChange={repeaterOnChange}
                                        buttonText="New Audio Guide"
                                    />
                                </SmartContainer>

                            </LargeStack>
                        </StyledTabPanel>

                        {/* Tags */}
                        <StyledTabPanel value={activeTab} index={2}>
                            <LargeStack>
                                <SmartContainer><StyledH6>Categories</StyledH6></SmartContainer>
                                <FormElement label="Tags" description="Tag this Meditation for search and filtering">
                                    <TagEditor
                                        label="Tags"
                                        model="Meditation"
                                        modelId={item.id}
                                        selector={FullTags}
                                        action={searchTags}
                                        saveAction={replaceNetworkTags}
                                    />
                                </FormElement>
                            </LargeStack>
                        </StyledTabPanel>


                        {/* SETTINGS */}
                        <StyledTabPanel value={activeTab} index={3}>
                            <LargeStack>
                                <SmartContainer><StyledH6>Publishing Settings</StyledH6></SmartContainer>
                                <FormElement label="Status" description="Only published content will be visible on your site.">
                                    <StatusSelector onChange={setStatus} value={status} />
                                </FormElement>
                                <FormElement label="Sheduling" description="Set when this item should go live and if it should be removed.">
                                    <ShedulingSelector onChange={setScheduling} value={scheduling} />
                                </FormElement>
                                <FormElement label="Delete Item" description="This will move this item to the trash. This can be undone.">
                                    <StyledP>Are you sure?</StyledP>
                                    <MoveToTrash id={item.id} action={deleteMeditation} selector={FullMeditations} route="/blogs" />
                                </FormElement>
                                <FormElement label="Duplicate Item" description="This will create a copy of this item.">
                                    <StyledP>Are you sure?</StyledP>
                                    <Duplicate id={item.id} action={duplicateMeditation} selector={FullMeditations} route="/blogs" />
                                </FormElement>
                                <FormElement label="Advanced" description="Advanced Settings">
                                    {renderSideContentChildren()}
                                </FormElement>
                            </LargeStack>
                        </StyledTabPanel>

                        {/* STATS */}
                        <StyledTabPanel value={activeTab} index={4}>
                            <MeditationStats meditation={item} />
                        </StyledTabPanel>

                    </Surface>
                </Stack>

                {renderMainContentChildren()}
            </LargeStack>

        </SmartContainer>
    )
}