import React from 'react';

import { App } from '../../App';
import { Surface } from '../../styles/Containers'
import { AccountForm } from './AccountForm'
import { RouteBreadCrumbs } from '../../routes';

export const SettingsPage = () => {

    return (
        <App title="Settings" breadcrumbs={[RouteBreadCrumbs.dashboard]}>
            <Surface>
                <AccountForm />
            </Surface>
        </App>
    )    
}