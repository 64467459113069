import { useSelector } from 'react-redux';

export const FullSeries = () => useSelector(state => state.series);
export const findSeries = (id, series) => {
    return series?.find(item => item.id === id);
}
export const findSeriesForMediation = (meditationId, series, full = false) => {
    let rtn = null;
    if (!series) return null;

    series.map(s => {
        if (rtn) return null;
        s.meditations.map(m => {
            if (rtn) return null;
            if (m?.meditationId === meditationId) {
                rtn = s.id;
                if (full === true) rtn = s;
            }
        })
    })
    return rtn;
}