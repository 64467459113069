import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";

// Components
import { App } from "../../../App";
import { FullScreenLoader } from "../../../components/Loaders/FullScreenLoader";
import { respondTo } from "../../../styles/Theme";

// Styles
import {
  SmartContainer,
  Stack,
  StyledGrid,
  StyledGridItem,
  HorizontalStack,
  Surface,
} from "../../../styles/Containers";
import { StyledButton } from "../../../styles/matieralui/Button";

// Redux
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import "./pulsatingCircle.css";
import { StyledH1, StyledH3, StyledP } from "../../../styles/StyledHTML";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { FormElement } from "../../components/Forms/FormElement";
import { FormSelect } from "../../../components/Forms/FormSelect";
import { Screen } from "../../components/Display/Screen";
import { PageBreathingSelect } from "./PageBreathingSelect";
import { PageBreathingStart } from "./PageBreathingStart";
import { PageBreathingComplete } from "./PageBreathingComplete";
import { useAnimatedPageMount, useInterval } from "../../services/SpaceUtils";

// Paul's
import { animated } from 'react-spring';
import { BreathingFlow } from "./BreathingFlow";




export const PageBreathing = () => {





  // ------------------------
  // Paul's Rebuild
  // ------------------------
  const { animatedProps } = useAnimatedPageMount();
  return (
    <App stackNav="Breathing" back="back" backLabel="Back">
      <animated.div style={animatedProps}>
        <BreathingFlow />
      </animated.div>
    </App>
  )
};
