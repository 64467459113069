import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Styles
import { SmartContainer, Stack, Surface, StyledStyledSideBarContainer } from '../../../styles/Containers'

// Components
import { FormInput } from '../../../components/Forms/FormInput';
import { PublishingForm } from '../../../components/Publishing/PublishingForm';
import { richTextToHTML, HTMLToRichText } from '../../../components/Forms/RichText';
import { TagEditor } from '../../../components/Taxonomy/TagEditor';
import { MediaFormInput } from '../../../modules/Media/MediaFormInput';
import { StyledP } from '../../../styles/StyledHTML';

// ---------------------------
// Edit Example Taxonomy Form
// ---------------------------

export const EditTagForm = ({ item, childrenMain, childrenSide, selector, updateAction, deleteAction, route }) => {

    const dispatch = useDispatch();
    const store = selector();

    // Name
    const [name, setName] = useState('');

    // Feature Image
    const [featureImage, setFeatureImage] = useState(null);

    // Validate
    let disableSubmit = !name;

    // ---------------------------
    // Effects
    // ---------------------------

    // Sync Props
    useEffect(() => {

        // Name
        if (item?.name) setName(item.name);
        else setName('');

        // Feature Image
        if (item?.meta?.featureImage) setFeatureImage(item.meta?.featureImage);
        else setFeatureImage(null);


    }, [item])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (publishing) => {


        // Payload
        const payload = {
            name,
            meta: {
                featureImage
            }
        }
        dispatch(updateAction(item.id, payload));
    }

    // --------------------------
    // Slots
    // --------------------------

    const renderMainContentChildren = () => {
        if (!childrenMain) return (<div></div>)
        return (<SmartContainer>{childrenMain}</SmartContainer>)
    }

    const renderSideContentChildren = () => {
        if (!childrenSide) return (<div></div>)
        return (<SmartContainer>{childrenSide}</SmartContainer>)
    }




    // --------------------------
    // Render Form
    // --------------------------

    return (
        <StyledStyledSideBarContainer thinking={store?.updateLoading} minWidth="300px">
            <Stack>
                <Surface>
                    <Stack>
                        <FormInput variant="standard" label="Title" type="text" onChange={setName} value={name} />
                        <StyledP>Feature Image</StyledP>
                        <MediaFormInput network={true} type="image" label="" value={featureImage} onChange={(value) => setFeatureImage(value)} />
                    </Stack>
                </Surface>
                {renderMainContentChildren()}
            </Stack>
            <SmartContainer>
                <Stack>
                    <Surface>
                        <PublishingForm
                            item={item}
                            onSubmit={handleSubmit}
                            disable={disableSubmit}
                            deleteAction={deleteAction}
                            selector={selector}
                            route={route}
                            withScheduling={false}
                            withStatus={false}
                        />
                    </Surface>
                    {renderSideContentChildren()}
                </Stack>

            </SmartContainer>
        </StyledStyledSideBarContainer>
    )
}