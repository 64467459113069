import React, { useState, useEffect } from 'react';
import { SmartContainer, Stack } from '../../../styles/Containers';
import { StyledModal } from '../../../styles/matieralui/Modal';
import { NewContentButton } from './NewContentButton';
import { NewContentForm } from './NewContentForm';
export const NewContent = ({label,selector, createAction, titleLabel="Title", helper="Enter a title",children}) => {

    const [open,setOpen] = useState(false);
    const store = selector();

    useEffect(() => {
        if(store.createSuccess) setOpen(false);
    },[store?.createLoading])

    return (
        <SmartContainer>
            <NewContentButton onClick={() => setOpen(true)} />
            <StyledModal open={open} onClose={() => setOpen(false)} label={label}>
                <Stack>
                    {!children && (<NewContentForm titleLabel={titleLabel} helper={helper} selector={selector} createAction={createAction} />)}
                    {children}
                    
                </Stack>     
            </StyledModal>
        </SmartContainer>
    )
}