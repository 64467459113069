import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../../App';
import { FullScreenLoader } from '../../../components/Loaders/FullScreenLoader';
import { respondTo } from '../../../styles/Theme';

// Styles
import { SmartContainer, Stack, StyledGrid, StyledGridItem, Surface } from '../../../styles/Containers'
import { StyledButton } from '../../../styles/matieralui/Button';
import { StyledH5, StyledP } from '../../../styles/StyledHTML';
import { StyledList, StyledListItem } from '../../../styles/matieralui/List';

// Redux
import { getMeditation, searchMeditations } from '../Meditations/redux/meditations.actions';
import { FullMeditations } from '../Meditations/redux/meditations.selectors';
import { StyledH1, StyledImg } from '../../../styles/StyledHTML';

import { StyledMediaImage } from '../../components/Display/MediaAsset';

import { useAnimatedPageMount } from '../../services/SpaceUtils';
import { animated } from 'react-spring';
import { StackOnMobile } from '../../../styles/Containers';
import { MeditationListItem } from '../Meditations/MeditationListItem';
import { Favorite } from '../UserFavorites/Favorite';
import { Theme } from '../../../styles/Theme';
import { FullTags } from './redux/tags.selectors';
import { getTag } from './redux/tags.actions';
import { StyledMeditation } from '../Series/PageSeriesLanding';
import { FullSeries, findSeriesForMediation } from '../Series/redux/series.selectors';
import { searchSeries } from '../Series/redux/series.actions';

export const PageTagLanding = () => {

    // New Modal
    const dispatch = useDispatch();
    const fullSeries = FullSeries();
    const fullMeditations = FullMeditations();
    const fullTags = FullTags();
    const { id } = useParams();
    const [activeSeries, setActiveSeries] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const { animatedProps } = useAnimatedPageMount();


    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Load Everything
        const args = {};
        args.Tag = `[${id}]`;
        dispatch(searchMeditations(1, 999, null, 'published', args))

        // Tag
        dispatch(getTag(id));

        // Series
        if (!fullSeries?.items) dispatch(searchSeries(1, 999, null, 'published'))

    }, [])


    // Set Active Item
    useEffect(() => {
        if (fullTags?.activeItem) setActiveItem(fullTags.activeItem);
    }, [fullTags?.activeItem])

    const handleMeditationClick = (meditation) => {
        const series = findSeriesForMediation(meditation.id, fullSeries?.items?.rows)
        dispatch(push(`/series/${series}/play/${meditation.id}`))
    }

    const goTo = (path) => dispatch(push(path))

    // --------------------------
    // Renders
    // --------------------------


    const renderMeditations = () => {
        // Guard
        if (!fullMeditations?.items) return (<div></div>);

        let number = 0;
        return fullMeditations?.items?.rows.map(meditation => {

            if (!meditation?.id) return (<div></div>)
            number = number + 1;

            const series = findSeriesForMediation(meditation.id, fullSeries?.items?.rows, true);
            const free = (series?.meta?.plan === 'free') ? true : false;


            return (
                <StyledMeditation button key={meditation.id} onClick={() => handleMeditationClick(meditation)}>
                    <MeditationListItem meditation={meditation} free={free} />
                </StyledMeditation>
            )
        })
    }

    if (fullMeditations?.itemsLoading) return (<FullScreenLoader />)


    // Render Page
    return (
        <App stackNav="Tags" back="/" backLabel="Back">

            {fullMeditations?.items?.count > 0 && (
                <SmartContainer>
                    <animated.div style={animatedProps}>
                        <Stack>
                            {/* {activeItem?.meta?.featureImage && (<StyledMediaImage radius id={activeItem?.meta?.featureImage} network={true} />)} */}
                            <SmartContainer><StyledH5>{activeItem?.name}</StyledH5></SmartContainer>

                            <StyledList>
                                {renderMeditations()}
                            </StyledList>
                        </Stack>

                    </animated.div>

                </SmartContainer>
            )}
        </App>
    )
}