import React, { useState } from 'react';
import styled from 'styled-components'
import Popover from '@mui/material/Popover';
import { Theme, respondTo } from '../Theme'
import { SmartContainer, Surface, Stack } from '../Containers'

const PopoverStyles = styled(Popover)`
    .MuiPaper-root {
        box-shadow: none;
        overflow: inherit;
    }
    .popover-surface {
        outline: 0;
        border-radius: ${Theme.radius};
        box-shadow: ${Theme.shadow.three};
        background-color: ${Theme.colors.baseAlternate};
    }
    `

export const StyledPopover = ({
        open, 
        id, 
        anchorEl, 
        onClose, 
        children, 
        anchorOrigin = {vertical: 'bottom',horizontal: 'center'},
        transformOrigin = {vertical: 'top', horizontal: 'center'}
    }) => {

    return (
        <PopoverStyles
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            >
            <Surface className="popover-surface">{children}</Surface>
        </PopoverStyles>
    )

}