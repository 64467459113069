import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { App } from '../../App';
import { Surface, Stack, SmartContainer } from '../../styles/Containers'
import { StyledP, StyledH3 } from '../../styles/StyledHTML'
import { StyledButton } from '../../styles/matieralui/Button'
import { FullAccount } from '../../redux/Account/account.selectors';
import { push } from 'connected-react-router'
import { FullScreenLoader } from '../Loaders/FullScreenLoader';
import { getAccountInfo } from '../../redux/Account/account.actions';
import { getQueryParam } from '../../services/Utils';
import { useAnalytics } from '../../modules/Analytics/AnalyticsService';
import { CurrentNetwork } from '../../redux/Network/network.selectors';
export const BillingSuccessPage = () => {

    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const currentNetwork = CurrentNetwork();
    const { billing } = currentNetwork;
    const [pollAttempt, setPollAttempt] = useState(0);
    const pollLimit = 3; // Attempts
    const pollDelay = 4000 // Seconds
    const currentAttempt = (getQueryParam('reload')) ? parseInt(getQueryParam('reload')) : 0;
    const [subscriptionStatus, setSubscriptionStatus] = useState('none');
    const { trackEvent } = useAnalytics();

    const goTo = (path) => dispatch(push(path));

    const reloadPage = () => {
        if (currentAttempt < pollLimit) {
            const path = `/billing/success?reload=${currentAttempt + 1}`
            setTimeout(() => { window.location.href = path; }, pollDelay);
        } else {
            setSubscriptionStatus('error');
        }
    }

    useEffect(() => {
        if (fullAccount?.account?.subscriptionStatus === 'active') {
            let price = 0;
            const plans = billing.plans.map(plan => {
                if (plan?.monthly?.nickname === fullAccount?.account?.plan) price = plan?.monthly?.price;
                if (plan?.yearly?.nickname === fullAccount?.account?.plan) price = plan?.yearly?.price;
            })

            const trackArgs = {
                category: 'User',
                action: 'PURCHASE',
                facebook: {
                    value: price, // TODO: Replace with coded data.
                    currency: (fullAccount?.account?.currency) ? fullAccount.account.currency : 'USD', // TODO: replace with data form db
                    content_name: fullAccount?.account?.plan,
                    content_type: 'subscription'
                }
            }
            trackEvent('Purchase', trackArgs);
        }
    }, [fullAccount?.account?.subscriptionStatus])

    // Loader
    if (fullAccount.accountLoading) return (<FullScreenLoader />)

    // Waiting For Webhook
    if (!fullAccount?.account?.plan && subscriptionStatus !== 'error') {
        reloadPage();
        return (<FullScreenLoader />)

    }

    // No Active Payment
    // if(!fullAccount?.account?.plan) return (
    if (subscriptionStatus === 'error') return (
        <App title="Billing Status">

            <Surface>
                <Stack>
                    <StyledP>We're still waiting on your info to come through. Please refresh the page. If the problem persists please contact support.</StyledP>
                    <StyledButton onClick={() => window.location.reload()} variant="contained" color="primary">Reload Page</StyledButton>
                    {/* <StyledButton onClick={() => goTo('/billing') } variant="contained" color="primary">Choose A Plan</StyledButton> */}
                </Stack>
            </Surface>

        </App>
    )

    // Success Payment
    if (fullAccount?.account?.subscriptionStatus === 'active') return (
        <App title="Billing Status">

            <Surface>
                <Stack>
                    <SmartContainer><StyledH3>Success!</StyledH3></SmartContainer>
                    <StyledP>You are now subscribed!</StyledP>
                    <StyledButton onClick={() => goTo('/')} variant="contained" color="primary">Return to Dashboard</StyledButton>
                </Stack>
            </Surface>
        </App>
    )

    // Success Payment
    if (fullAccount?.account?.subscriptionStatus === 'trialing') return (
        <App title="Billing Status">

            <Surface>
                <Stack>
                    <SmartContainer><StyledH3>Success!</StyledH3></SmartContainer>
                    <StyledP>Your free trial has begun!</StyledP>
                    <StyledButton onClick={() => goTo('/')} variant="contained" color="primary">Return to Dashboard</StyledButton>
                </Stack>
            </Surface>
        </App>
    )

    // TODO: If you don't have an active payment. Show an error
    return (
        <App title="Billing Status">



        </App>
    )
}