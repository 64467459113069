import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

// Styled Components
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers';
import { StyledChip } from '../../styles/matieralui/Chip';
import { splitAndTrim } from '../../services/Utils';

// Components
import { Autocomplete } from '../Forms/Autocomplete';
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { FormSelect } from '../Forms/FormSelect';




export const TagSelect = ({ value, label, model, selector, searchAction, onChange }) => {


    // ------------------------------
    // State
    // ------------------------------

    const dispatch = useDispatch();
    const store = selector();

    // ------------------------------
    // Effects
    // ------------------------------

    // Load Tags for This Item
    useEffect(() => {

        // Load All Available Tags
        dispatch(searchAction(0, null, null, 'all'))

    }, [model])

    if (store.itemsLoading) return (<div>Loading...</div>)

    return (
        <FormSelect label={label} value={value} onChange={onChange}>
            <option key="all" value="all">All</option>
            {store?.items?.count > 0 && store?.items?.rows.map(tag => (
                <option key={tag.id} value={tag.id}>{tag.name}</option>
            ))}
        </FormSelect>
    )
}