import React, { useState, useEffect } from 'react';
import { SmartContainer } from '../../../styles/Containers';
import { StyledP } from '../../../styles/StyledHTML';
import { loadMeditation } from './redux/meditations.actions';

export const MeditationCard = ({ id }) => {

    const [meditation, setMeditation] = useState(null);
    const [loading, setLoading] = useState(true);


    const load = async (id) => {
        if (!id) return;
        setLoading(true);
        const res = await loadMeditation(id);
        if (res.status === 'success') {
            setMeditation(res.data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (meditation && meditation.id === id) return;
        load(id); // Load the Guide
    }, [id])

    return (
        <SmartContainer thinking={loading} width="100%">
            {meditation && (<StyledP>{meditation?.name}</StyledP>)}
        </SmartContainer>
    )
}