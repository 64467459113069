import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { MediaList } from './MediaList';
import { MediaUpload } from './MediaUpload';
import { UnsplashSearch } from './Unsplash/UnsplashSearch';

import { StyledTabs, StyledTab, StyledTabPanel } from '../../styles/matieralui/Tabs';

// Icons
import SpeakerIcon from '@mui/icons-material/Speaker';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

// Styles
import { StyledModal } from '../../styles/matieralui/Modal'
import { SmartContainer, Stack, StyledImagePlaceholder, Surface } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Redux
import { searchMedia, restoreMedia } from './redux/media.actions';
import { FullMedia } from './redux/media.selectors';
import { StyledB, StyledImg } from '../../styles/StyledHTML';

// The Component
export const MediaLibrary = ({ 
        onChange, 
        type = 'all', 
        network = false,
    }) => {

    // New Modal
    const dispatch = useDispatch();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    
    const store = FullMedia();



    // --------------------------
    // Effects
    // --------------------------


    // --------------------------
    // Interactions
    // --------------------------

    const handleTabChange = (event, index) => setActiveTab(index);

    const handleUploadChange = (files) => {
        console.log(files,"HANDLE UPLOAD CHANGED");
    }



    // --------------------------
    // Renders
    // --------------------------




    // Render Page
    return (
        <SmartContainer>

            <StyledTabs indicatorColor="primary" value={activeTab} onChange={handleTabChange} aria-label="Media Options" variant="scrollable" scrollButtons="auto">
                <StyledTab label="Library" id="library-0" key="library-0" aria-controls="library-0" />
                <StyledTab label="Upload" id="library-1" key="library-1" aria-controls="library-1" />
                <StyledTab label="Stock Photos" id="library-2" key="library-2" aria-controls="library-2" />
            </StyledTabs>

            <StyledTabPanel value={activeTab} index={0}>
                <Surface><MediaList type={type} network={network} onChange={onChange} /></Surface>
            </StyledTabPanel>
            
            <StyledTabPanel value={activeTab} index={1}>
                <Surface><MediaUpload network={network} /></Surface>
            </StyledTabPanel>

            <StyledTabPanel value={activeTab} index={2}>
                <Surface><UnsplashSearch network={network} /></Surface>
            </StyledTabPanel>

        </SmartContainer>
    )
}



