// VERSION NUMBER
const APP_VERSION = '0.4.1';

// These Must Match The API Exactly
const MODEL_STATUS_PUBLISHED = 'published';
const MODEL_STATUS_DRAFT = 'draft';
// const MODEL_STATUS_TRASHED = 'trashed';

// Order Status: Must Match API Exactly
const ORDER_STATUS_PENDING = 'pending';
const ORDER_STATUS_SHIPPED = 'shipped';
const ORDER_STATUS_COMPLETED = 'completed';
const ORDER_STATUS_REFUNDED = 'refunded';

export const config = {
    appVersion: APP_VERSION,
    allowTeammates: true,
    allowMultipleAccounts: true,
    sessionId: 'appstarter',
    roles: ['editor', 'admin', 'owner'],
    networkRoles: ['superadmin', 'networkadmin'],
    modelStatuses: [MODEL_STATUS_PUBLISHED, MODEL_STATUS_DRAFT],
    termsVersion: '1.0.0',
    currencies: ['usd', 'aud'],
    defaultCurrency: 'usd',
    orderStatuses: [ORDER_STATUS_PENDING, ORDER_STATUS_SHIPPED, ORDER_STATUS_COMPLETED, ORDER_STATUS_REFUNDED],
    buyMeACoffee: 'https://www.buymeacoffee.com/spacecultureco'
}


