import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SmartContainer } from '../../styles/Containers';
import { searchNetworkPlatformEvents } from './redux/platformevent.actions';
import { FullPlatformEvents } from './redux/platformevent.selectors';
import { StyledTimeline, StyledTimelineItem, StyledTimelineDot, StyledTimelineConnector, StyledTimelineContent, StyledTimelineSeparator } from '../../styles/matieralui/Timeline';
import { StyledP } from '../../styles/StyledHTML';
import { Collapse } from '../../components/Layout/Collapse';
export const PlatformEventsTimeline = ({ accountId }) => {

    const dispatch = useDispatch();
    const fullPlatformEvents = FullPlatformEvents();

    useEffect(() => {
        if (!accountId) return
        dispatch(searchNetworkPlatformEvents(1, 9999, '', null, { AccountId: accountId }))
    }, [accountId])

    return (
        <StyledTimeline>
            {fullPlatformEvents?.items?.rows?.map(item => (
                <StyledTimelineItem>
                    <StyledTimelineSeparator>
                        <StyledTimelineDot />
                        <StyledTimelineConnector />
                    </StyledTimelineSeparator>
                    <StyledTimelineContent>
                        <StyledP>{item.name}</StyledP>
                        <StyledP light>{item.createdAt}</StyledP>
                        {item?.meta && (
                            <Collapse title="Details" open={false}>
                                <pre>{JSON.stringify(item?.meta)}</pre>
                            </Collapse>
                        )}
                    </StyledTimelineContent>
                </StyledTimelineItem>
            ))}
        </StyledTimeline>
    )
}