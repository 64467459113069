import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { fullName } from '../../services/Utils';

// Styled Components
import { SmartContainer, Stack } from '../../styles/Containers';
import { StyledChip } from '../../styles/matieralui/Chip';
import { StyledList, StyledListItem, StyledListItemText, StyledListItemIcon } from '../../styles/matieralui/List';





// Components
import { Autocomplete } from '../Forms/Autocomplete';
import { StyledA, StyledB, StyledP } from '../../styles/StyledHTML';


export const RevisionSelector = ({ label, model, modelId, selector, action, onChange }) => {


    // ------------------------------
    // State
    // ------------------------------

    const dispatch = useDispatch();
    const data = selector();
    const [showAll, setShowAll] = useState(false);
    const [active,setActive] = useState(null);
    const limit = 3;


    // ------------------------------
    // Effects
    // ------------------------------

    // Load Tags for This Item
    useEffect(() => { 
        
        // Guard: Empty Model
        if(!modelId) return;

        // Load Revisions
        dispatch(action(modelId))


    },[modelId])




    // ------------------------------
    // Methods
    // ------------------------------

    const toggleShowAll = (e) => {
        e.preventDefault();
        setShowAll(!showAll);
    }

    const handleOnChange = (revision) => {
        if(revision.id === active?.id){
            setActive(null);
            onChange(null);
        } else {
            setActive(revision);
            onChange(revision);
        }
        
    }


    // ------------------------------
    // Render
    // ------------------------------

    const renderRevisions = () => {
        if(!data?.revisions) return (<div>Loading</div>)
        if(!data?.revisions.count) return (<div>Loading</div>)
        let i = 0;
        return data.revisions.rows.map(revision => {
            i++;
            if(showAll !== true && i > limit) return;
            return (
                <StyledListItem button selected={revision.id === active?.id} onClick={() => handleOnChange(revision)}>
                    <SmartContainer>
                        <StyledP>{moment(revision.createdAt).format('YYYY-MM-DD HH:mm:ss')}</StyledP>
                        {revision.user && (<StyledP light>{fullName(revision.user)}</StyledP>)}
                    </SmartContainer>
                </StyledListItem>
                
            )
        }
        )
    }

    const showAllText = (showAll) ? 'less' : 'more';

    return (
        <SmartContainer thinking={false}>
            <Stack>
                <StyledP><StyledB>{label}</StyledB></StyledP>
                <StyledList>
                    {renderRevisions()}
                </StyledList>
                <StyledA light small onClick={toggleShowAll}>{showAllText}</StyledA>
            </Stack>
        </SmartContainer>        
    )
}