import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// Starter Reducers
import AuthReducer from './redux/Auth/auth.reducer'
import UIReducer from './redux/UI/ui.reducer'
import UsersReducer from './redux/Users/users.reducer'
import AccountReducer from './redux/Account/account.reducer'
import BillingReducer from './redux/Billing/billing.reducer'
import NetworkReducer from './redux/Network/network.reducer'
import UserPreference from './modules/UserPreferences/redux/userpreferences.reducer'
import PlatformEvents from './modules/PlatformEvents/redux/platformevent.reducer';

// App Reducers
import ExampleContent from './modules/ExampleContent/redux/examplecontent.reducer'
import ExampleTaxonomy from './modules/ExampleTaxonomy/redux/exampletaxonomy.reducer'
import NetworkProducts from './modules/NetworkProducts/redux/networkproduct.reducer'
import NetworkOrders from './modules/NetworkOrders/redux/networkorder.reducer';
import Media from './modules/Media/redux/media.reducer';
import MediaTags from './modules/Media/redux/mediatag.reducer';
import NetworkContent from './modules/NetworkContent/redux/networkcontent.reducer';

// App Reducers
import Meditations from './space/modules/Meditations/redux/meditations.reducer';
import Guides from './space/modules/Guides/redux/guides.reducer';
import Series from './space/modules/Series/redux/series.reducer';
import Tags from './space/modules/Tags/redux/tags.reducer';
import UserRatings from './space/modules/UserRatings/redux/userrating.reducer';
import UserNotes from './space/modules/UserNotes/redux/usernotes.reducer';
import UserProgress from './space/modules/UserProgress/redux/userprogress.reducer';
import UserFavories from './space/modules/UserFavorites/redux/userfavorites.reducer';
import NotePrompts from './space/modules/NotePrompts/redux/noteprompts.reducer';
import Breathing from './space/modules/Breathing/redux/breathing.reducer';

export const globalReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: AuthReducer,
  ui: UIReducer,
  users: UsersReducer,
  account: AccountReducer,
  billing: BillingReducer,
  network: NetworkReducer,
  userpreference: UserPreference,
  networkproducts: NetworkProducts,
  networkorders: NetworkOrders,
  media: Media,
  mediatags: MediaTags,
  platformevents: PlatformEvents,
  // SPACE
  meditations: Meditations,
  guides: Guides,
  series: Series,
  tags: Tags,
  userratings: UserRatings,
  usernotes: UserNotes,
  userprogress: UserProgress,
  userfavorites: UserFavories,
  noteprompts: NotePrompts,
  breathing: Breathing
})