import React from 'react';
import { SmartContainer, StackOnMobile } from '../../../styles/Containers';
import { StyledP, StyledB } from '../../../styles/StyledHTML';


export const FormElement = ({ label, description, children }) => {
    return (
        <StackOnMobile flex data-form-element data-stack-on-mobile>
            <SmartContainer width="40%">
                <StyledP><StyledB>{label}</StyledB></StyledP>
                <StyledP light dangerouslySetInnerHTML={{ __html: description }}></StyledP>
            </SmartContainer>
            <SmartContainer width="60%">{children}</SmartContainer>
        </StackOnMobile>
    )
}