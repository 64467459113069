import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { App } from '../../App';
import { Surface, StyledGridByFour, StyledGridItem } from '../../styles/Containers'
import { StyledH5, StyledP } from '../../styles/StyledHTML'

export const LabsPage = () => {
    const dispatch = useDispatch();
    const goTo = (path) => dispatch(push(path));
    return (
        <App title="Labs">
            <StyledGridByFour>
                <StyledGridItem clickable onClick={() => goTo('/labs/repeater')}><Surface width="100%"><StyledH5>Repeater</StyledH5><StyledP>Form Element</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/textinputs')}><Surface width="100%"><StyledH5>Text Inputs</StyledH5><StyledP>Form Element</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/dateinputs')}><Surface width="100%"><StyledH5>Date Inputs</StyledH5><StyledP>Form Element</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/radio')}><Surface width="100%"><StyledH5>Radio and Checkbox</StyledH5><StyledP>Form Element</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/media')}><Surface width="100%"><StyledH5>Media Selection</StyledH5><StyledP>Form Element</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/paywall')}><Surface width="100%"><StyledH5>Paywall</StyledH5><StyledP>Test Restricted Content</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/video')}><Surface width="100%"><StyledH5>Video</StyledH5><StyledP>Player</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/colorpicker')}><Surface width="100%"><StyledH5>Color Picker</StyledH5><StyledP>Pick A Color</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/maps')}><Surface width="100%"><StyledH5>Maps</StyledH5><StyledP>Google Search Open Map</StyledP></Surface></StyledGridItem>
                <StyledGridItem clickable onClick={() => goTo('/labs/media/processing')}><Surface width="100%"><StyledH5>Media Processing</StyledH5><StyledP>Test Image Cropping</StyledP></Surface></StyledGridItem>
            </StyledGridByFour>

        </App>
    )
}