import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect} from 'react-router-dom'

// Redux
import { FullAuth } from '../../redux/Auth/auth.selectors';
import { resetPassword } from '../../redux/Auth/auth.actions';
import { openToast } from '../../redux/UI/ui.actions';

// Styles
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledH3 } from '../../styles/StyledHTML'

// Components
import { PasswordInput } from '../Forms/PasswordInput'
import { FormError } from '../Forms/FormError'

export const ResetForm = ({token}) => {

    const dispatch = useDispatch();
    const [password,setPassword] = useState('');
    const disableSubmit = !password;
    
    const handleSubmit = (e) => dispatch(resetPassword(password,token));
    const auth = FullAuth();
   

    // Toast Results
    useEffect(() => {
        if(auth.success === true){
            dispatch(openToast(auth.message,'success'))
        }
        if(auth.resetError) {
            dispatch(openToast(auth.resetError,'error'))
        }
    },[auth.resetLoader, auth.success, auth.resetError, auth.message, dispatch])

    // Redirect logged in users
    if(auth.session && auth.session.token) return (<Redirect to="/" />);

    // Login Errors
    const hasError = (auth?.error) ? auth.error : false;


    return (
        <SmartContainer thinking={auth.loading} minWidth="300px">
            <Stack>
                <StyledH3>Reset</StyledH3>
                {hasError && (<FormError error={hasError} />)}
                <PasswordInput label="Password" type="email" onChange={setPassword} value={password} />
                <StyledButton onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>Reset Password</StyledButton>
                <HorizontalStack>
                    <Link to="/login">Login</Link>
                    <Link to="/recover-password">Recover Password</Link>
                </HorizontalStack>
            </Stack>
        </SmartContainer>
    )
}