import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { ModelList } from '../../components/PaginatedList/ModelList';


// Styles
import { SmartContainer } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button';

// Redux
import { searchMedia, restoreMedia, forceDeleteMedia, searchNetworkMedia, restoreNetworkMedia, forceDeleteNetworkMedia } from './redux/media.actions';
import { FullMedia } from './redux/media.selectors';

import { MediaCard } from './MediaCard';
import { ImageCroppingPresets } from './MediaUtils';

// The Component
export const MediaList = ({ onChange, type = 'all', network = false }) => {

    // New Modal
    const dispatch = useDispatch();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [view, setView] = useState('all');
    const [mediaType, setMediaType] = useState(type);
    const [extraFilters, setExtraFilters] = useState(null);
    const store = FullMedia();

    // --------------------------
    // Effects
    // --------------------------
    useEffect(() => {
        if (type !== 'all') handleMediaTypeChange(type);
    }, [type])

    useEffect(() => {
        if (store.createSuccess) {

            // Close The Modal
            setOpenNewModal(false);

            // Redirect to edit page
            dispatch(push(`/media/${store.create.id}`))
        }
    }, [store.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {
        if (item.full.deletedAt) {
            // handleRestore(item);
        } else {
            onChange(item);
        }
    }

    // Filter on Media
    const handleMediaTypeChange = (type) => {
        setMediaType(type);
        const filters = { ...extraFilters }
        if (type !== 'all') filters.mediaType = `["${type}"]`;
        else delete filters.mediaType;
        setExtraFilters(filters)
    }

    // --------------------------
    // Renders
    // --------------------------

    const renderCustomControls = () => {
        if (type !== 'all') return (<div></div>)
        return (
            <SmartContainer>
                <StyledButton onClick={() => handleMediaTypeChange('all')}>All</StyledButton>
                <StyledButton onClick={() => handleMediaTypeChange('image')}>Images</StyledButton>
                <StyledButton onClick={() => handleMediaTypeChange('audio')}>Audio</StyledButton>
                <StyledButton onClick={() => handleMediaTypeChange('video')}>Video</StyledButton>
                <StyledButton onClick={() => handleMediaTypeChange('doc')}>Files</StyledButton>
            </SmartContainer>
        )
    }

    // Render Card
    const renderMediaCard = (item, onClickItem) => (<MediaCard network={network} item={item} onClick={onClickItem} crop={ImageCroppingPresets.thumbnail} />)

    // Render Page
    return (
        <SmartContainer>

            <ModelList
                model="Media"
                action={(network) ? searchNetworkMedia : searchMedia}
                restore={(network) ? restoreNetworkMedia : restoreMedia}
                forceDelete={(network) ? forceDeleteNetworkMedia : forceDeleteMedia}
                selector={FullMedia}
                onSelect={handleSelect}
                variant="cards"
                customRenderer={renderMediaCard}
                paginationControls={renderCustomControls}
                filters={extraFilters}
                columns={5}
            />

        </SmartContainer>
    )
}



