import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from './NetworkApp';
import { NoAccessPage } from '../../pages/Account/NoAccessPage'
import { SurfaceMessage } from '../Alerts/SurfaceMessage';
import { GoogleAnalyticsForm } from '../../modules/Integrations/GoogleAnalytics/GoogleAnalyticsForm';
import { FirstPromoterForm } from '../../modules/Integrations/FirstPromoter/FirstPromoterForm';
import { FacebookPixelForm } from '../../modules/Integrations/Facebook/FacebookPixelForm';
import { SegmentForm } from '../../modules/Integrations/Segment/SegmentForm';
import { HotJarForm } from '../../modules/Integrations/HotJar/HotJarForm';
import { AppStarterSupportForm } from '../../modules/AppStarterSupport/AppStarterSupportForm';

// Styled
import { Stack, StyledGrid, StyledGridItem, Surface } from '../../styles/Containers'
import { StyledB, StyledP } from '../../styles/StyledHTML';

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { getFullNetwork } from '../../redux/Network/network.actions'
import { hasNetworkAccess } from '../../redux/Auth/auth.actions'
import { FullAuth } from '../../redux/Auth/auth.selectors';
import { APIBase } from '../../services/API';
import { RouteBreadCrumbs } from '../../routes';
import { StyledButton } from '../../styles/matieralui/Button';
import { RevenueCatForm } from '../../modules/Integrations/RevenueCat/RevenueCatForm';

export const NetworkIntegrationPage = () => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [meta, setMeta] = useState({});
    const fullNetwork = FullNetwork();
    const fullAuth = FullAuth();
    const { id, app } = useParams();
    const networkId = parseInt(id);
    const activeApp = app;
    const goTo = (path) => dispatch(push(path))

    // --------------------------
    // Effects
    // --------------------------

    // Close Modal on Success
    useEffect(() => { dispatch(getFullNetwork(networkId)) }, [])

    // On Network Success
    useEffect(() => {
        setMeta(fullNetwork.getFullNetwork?.meta);
    }, [fullNetwork.getFullNetwork])

    // --------------------------
    // Permissions
    // --------------------------

    const hasAccess = hasNetworkAccess(fullAuth.me, networkId)
    if (!hasAccess) return (<NoAccessPage />)

    // --------------------------
    // Interactions
    // --------------------------

    const thisNetwork = (fullNetwork.getFullNetwork) ? fullNetwork.getFullNetwork : null;

    // --------------------------
    // Renders
    // --------------------------

    return (
        <NetworkApp title="Network Integrations" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            {!thisNetwork && (<SurfaceMessage title="Network Does Not Exist" />)}

            {thisNetwork && (
                <StyledGrid columns={3}>
                    <StyledGridItem>
                        <Surface width="100%">
                            <GoogleAnalyticsForm id='googleanalytics'
                                meta={meta}
                                open={app === 'googleanalytics'}
                                network={true}
                            />
                        </Surface>
                    </StyledGridItem>
                    <StyledGridItem>
                        <Surface width="100%">
                            <FirstPromoterForm id='firstpromoter'
                                meta={meta}
                                open={app === 'firstpromoter'}
                                network={true}
                            />
                        </Surface>
                    </StyledGridItem>
                    <StyledGridItem>
                        <Surface width="100%">
                            <FacebookPixelForm id='facebookpixel'
                                meta={meta}
                                open={app === 'facebookpixel'}
                                network={true}
                            />
                        </Surface>
                    </StyledGridItem>
                    <StyledGridItem>
                        <Surface width="100%">
                            <SegmentForm id='segment'
                                meta={meta}
                                open={app === 'segment'}
                                network={true}
                            />
                        </Surface>
                    </StyledGridItem>
                    <StyledGridItem>
                        <Surface width="100%">
                            <HotJarForm id='hotjar'
                                meta={meta}
                                open={app === 'hotjar'}
                                network={true}
                            />
                        </Surface>
                    </StyledGridItem>
                    <StyledGridItem>
                        <Surface width="100%">
                            <AppStarterSupportForm id='appstartersupport'
                                meta={meta}
                                open={app === 'appstartersupport'}
                                network={true}
                            />
                        </Surface>
                    </StyledGridItem>
                    <StyledGridItem>
                        <Surface width="100%">
                            <RevenueCatForm id='revenuecat'
                                meta={meta}
                                open={app === 'revenuecat'}
                                network={true}
                            />
                        </Surface>
                    </StyledGridItem>
                </StyledGrid>
            )}

        </NetworkApp>
    )
}