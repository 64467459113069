import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { convertCentsToPrice } from '../../services/Stripe';

// Components
import { NetworkApp } from '../../components/Network/NetworkApp';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader'
import { FormInput } from '../../components/Forms/FormInput';
import { RichTextEditor, richTextToHTML, HTMLToRichText } from '../../components/Forms/RichText';
import { HelperToDo } from '../../styles/Helpers';
import { PublishingForm } from '../../components/Publishing/PublishingForm';
import { OrderStatusSelector } from './OrderStatusSelector';

// Styles
import { Stack, Surface, SmartContainer, HorizontalStack, StyledStyledSideBarContainer } from '../../styles/Containers';

// Redux
import { getNetworkOrder, deleteNetworkOrder, updateNetworkOrder } from './redux/networkorder.actions';
import { FullNetworkOrders } from './redux/networkorder.selectors';
import { StyledB, StyledP, StyledH3 } from '../../styles/StyledHTML';
import { RouteBreadCrumbs } from '../../routes';


export const ViewNetworkOrderPage = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const thisId = parseInt(id)
    const fullNetworkOrder = FullNetworkOrders();

    const [name, setName] = useState('');
    const [meta, setMeta] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [defaultEditorState, setDefaultEditorState] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);

    // --------------------------
    // Effects
    // --------------------------

    // Load This Order
    useEffect(() => { dispatch(getNetworkOrder(thisId)) }, [])

    // Sync to Local Status
    useEffect(() => {
        if (fullNetworkOrder.activeItemSuccess) {

            if (fullNetworkOrder.activeItem?.name) setName(fullNetworkOrder.activeItem.name);
            if (fullNetworkOrder.activeItem?.meta) setMeta(fullNetworkOrder.activeItem.meta);
            if (fullNetworkOrder.activeItem?.orderStatus) setOrderStatus(fullNetworkOrder.activeItem.orderStatus);

            if (fullNetworkOrder.activeItem?.meta?.notes) {
                const defaultEditorState = HTMLToRichText(fullNetworkOrder.activeItem?.meta?.notes);
                setEditorState(defaultEditorState);
                setDefaultEditorState(defaultEditorState);
            }

        }
    }, [fullNetworkOrder.activeItem])

    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (publishing) => {

        // TODO: Save Notes to Meta.
        // meta.notes: richTextToHTML(editorState) // Convert Editor State to HTML
        const newMeta = {
            ...meta,
            notes: richTextToHTML(editorState)
        };
        const payload = {
            name,
            status: publishing.status,
            orderStatus,
            meta: newMeta
        }

        dispatch(updateNetworkOrder(fullNetworkOrder.activeItem.id, payload));
    }

    // --------------------------
    // Renderer
    // --------------------------


    // Loader
    if (fullNetworkOrder.activeItemLoading) return (<FullScreenLoader />)

    // Set Order
    const order = (fullNetworkOrder.activeItem?.createdAt) ? fullNetworkOrder.activeItem : {}

    let disableSubmit = !name && !meta;

    return (
        <NetworkApp title="Network Order Details" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network, RouteBreadCrumbs.networkOrders]}>
            <StyledStyledSideBarContainer thinking={fullNetworkOrder.updateLoading}>
                <Stack>

                    <SmartContainer flex align="flex-end" justify="space-between">
                        <SmartContainer>
                            <StyledB>Order Id: #{order?.id}</StyledB>
                            <StyledP light>{moment(order?.createdAt).format('YYYY-MM-DD')}</StyledP>
                        </SmartContainer>
                        <SmartContainer>
                            <OrderStatusSelector value={orderStatus} onChange={setOrderStatus} />
                        </SmartContainer>
                    </SmartContainer>

                    <FormInput label="Name" type="text" onChange={setName} value={name} />

                    {order?.meta?.lineItems.map(item => (
                        <Surface key={item.id}>
                            <Stack>
                                <StyledB>{item?.description}</StyledB>
                                <StyledP light>${convertCentsToPrice(item?.amount_total)} {item?.currency}</StyledP>
                                <StyledP light>Stripe Product Id: {item?.price?.product}</StyledP>
                                <StyledP light>Stripe Product Price: {item?.price?.id}</StyledP>
                            </Stack>
                        </Surface>
                    ))}

                    <SmartContainer flex justify="flex-end">
                        <HorizontalStack flex>
                            <StyledB>Total:</StyledB>
                            <StyledP>${convertCentsToPrice(order?.amount)}</StyledP>
                        </HorizontalStack>
                    </SmartContainer>

                    <Surface>
                        <Stack>
                            <RichTextEditor
                                label="Internal Notes"
                                value={editorState}
                                defaultEditorState={defaultEditorState}
                                onChange={(value) => { setEditorState(value) }}
                            />
                        </Stack>
                    </Surface>


                </Stack>
                <SmartContainer>
                    <Surface>
                        <Stack>
                            <PublishingForm
                                item={order}
                                onSubmit={handleSubmit}
                                disable={disableSubmit}
                                deleteAction={deleteNetworkOrder}
                                selector={FullNetworkOrders}
                                route="/network/orders"
                                withScheduling={false}
                            />
                        </Stack>
                    </Surface>
                </SmartContainer>
            </StyledStyledSideBarContainer>

        </NetworkApp>
    )
}