import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router'
import { App } from '../../App';

import { FullAuth } from '../../redux/Auth/auth.selectors'
import { FullAccount } from '../../redux/Account/account.selectors'
import { openToast } from '../../redux/UI/ui.actions';
import { Surface } from '../../styles/Containers'
import { UserForm } from './UserForm';
import { getAccountInfo, getTeamMembers, updateUserAccountRole, removeUserAccount } from '../../redux/Account/account.actions';
import { updateMe } from '../../redux/Auth/auth.actions'
import { FullScreenLoader } from '../Loaders/FullScreenLoader'
import { StyledAreYouSure } from '../../styles/matieralui/Dialog'
import { NoAccessPage } from '../../pages/Account/NoAccessPage'

// Redux
import { Me, Role } from '../../redux/Auth/auth.selectors'
import { RouteBreadCrumbs } from '../../routes';


export const UserPage = () => {

    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const auth = FullAuth();
    const { id } = useParams();
    const userId = parseInt(id)
    const [areYouSure, setAreYouSure] = useState(false);
    const me = Me();
    const currentUserRole = Role();
    const isMe = (me.id === userId) ? true : false;

    // Get Account Info
    useEffect(() => { if(!fullAccount.account) dispatch(getAccountInfo()) },[])

    // Get Team List
    useEffect(() => { if(fullAccount.account?.id) dispatch(getTeamMembers(fullAccount.account.id,1,20)) },[fullAccount.account])
    
    // Update Me
    useEffect(() => {
        if(auth.updateSuccess === true){
            dispatch(openToast(auth.message,'success'))
        }
        if(auth.updateError) {
            dispatch(openToast(auth.updateError,'error'))
        }
    },[auth.updateMeLoader])
    
    // Update Team Member
    useEffect(() => {
        if(fullAccount.activeTeamMemberSuccess){
            dispatch(openToast(fullAccount.activeTeamMemberSuccess,'success'))
        }
        if(fullAccount.activeTeamMemberError) {
            dispatch(openToast(fullAccount.activeTeamMemberError,'error'))
        }
    },[fullAccount.activeTeamMemberLoading])

    // Update Team Member
    useEffect(() => {
        if(fullAccount.removeTeamMemberSuccess){
            
            // Show Success
            dispatch(openToast(fullAccount.removeTeamMemberSuccess,'success'));

            // Redirct
            dispatch(push('/team'));

        }
        if(fullAccount.removeTeamMemberError) {
            dispatch(openToast(fullAccount.removeTeamMemberError,'error'))
        }
    },[fullAccount.removeTeamMemberLoading])

    // Loading
    if(fullAccount.teamLoading) return (<FullScreenLoader />)

    // Get This User
    const user = fullAccount.team?.filter(user => user.id === userId)[0]

    if(!user) return (<NoAccessPage />)

    const onSubmit = (userData) => {
        if(isMe) dispatch(updateMe(userData))
        else dispatch(updateUserAccountRole(fullAccount.account.id,user.id,userData.role))
    }

    const onRemove = (userData) => setAreYouSure(true)

    const onAreYouSure = (e) => {
        if(!e) setAreYouSure(false)
        else dispatch(removeUserAccount(fullAccount.account.id,user.id))
    }

    return (
        <App title="Edit Profile" breadcrumbs={[RouteBreadCrumbs.dashboard,RouteBreadCrumbs.team]}>
            <Surface thinking={fullAccount.activeTeamMemberLoading}>
                <UserForm user={user} onSubmit={onSubmit} loading={fullAccount.accountLoading} isMe={isMe} onRemove={onRemove} />
            </Surface>
            <StyledAreYouSure loading={false} open={areYouSure} onClose={onAreYouSure} label="Are you sure?" description="This will remove this user permanently from your account." agreeText="Yes, remove this user"/>
        </App>
    )    
}