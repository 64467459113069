import React from 'react';
import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SmartContainer } from '../Containers';


export const StyledTabs = styled(Tabs)``
export const StyledTab = styled(Tab)``
export const StyledTabPanel = (props) => {
    const { children, value, index, key, ...other } = props;
    return (
        <SmartContainer width="100%"
            role="tabpanel"
            hidden={value !== index}
            id={key}
            aria-labelledby={key}
            {...other}
        >
            {value === index && (
                <SmartContainer width="100%">{children}</SmartContainer>
            )}
        </SmartContainer>
    )
}