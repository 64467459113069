import React, { useState, useEffect } from 'react';

export const useHotJar = (fullNetwork) => {
    
    // First Promoter
    const [hotJar, setHotJar] = useState(null);

    // Load Network Id
    useEffect(() => {
        if(fullNetwork?.network?.meta?.hotjar?.enabled === true) setHotJar(fullNetwork?.network?.meta?.hotjar) 
    },[fullNetwork]);

    // Load Code
    useEffect(() => {
        
        
        if(!hotJar) return;

        function goHotJar (h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:fullNetwork?.network?.meta?.hotjar?.siteId,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        }
        
        goHotJar(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        
 
    },[hotJar])

    return {
        hotJar
    }

}