import { APIGet, APIPut, APIPost, APIDelete, APIBase } from '../../services/API';
import { dispatchLoading, dispatchError, dispatchSuccess, dispatchClearState } from '../utils'
import { openToast } from '../UI/ui.actions'
export const getNetwork = () => dispatch => {

    const action = '[NETWORK] Get Network';
    const prefix = 'network';
    const { hostname, port } = window.location
    
    let domain = hostname;
    if(port) domain += `:${port}`;

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/network`,{domain})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,null,json.data.network));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message)
        )})

}

export const getFullNetwork = (networkId) => dispatch => {

    const action = '[NETWORK] Get Full Network';
    const prefix = 'getFullNetwork';
    
    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIGet(`${APIBase}/v1/network/${networkId}`)
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,null,json.data.network));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message)
    )})

}

export const getNetworks = (page, limit, search) => dispatch => {

    const action = '[NETWORK] Get Networks';
    const prefix = 'networks';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/networks/search?page=${page}&limit=${limit}`,{search})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,null,json.data));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const createNetwork = (args) => dispatch => {

    const action = '[NETWORK] Create Network';
    const prefix = 'createNetwork';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/networks`,args)
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else {
        
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
    
                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action,prefix))},1500)
    
        }
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const updateNetwork = (networkId,args) => dispatch => {

    const action = '[NETWORK] Update Network';
    const prefix = 'updateNetwork';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPut(`${APIBase}/v1/networks/${networkId}`,args)
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else {
            
            
                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));
    
                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action,prefix))},1500)
    
        }
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const getNetworkAccounts = (page, limit, search) => dispatch => {

    const action = '[NETWORK] Get Network Accounts';
    const prefix = 'networkAccounts';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/networks/accounts/search?page=${page}&limit=${limit}`,{search})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,null,json.data));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const getNetworkAccount = (accountId) => dispatch => {

    const action = '[NETWORK] Get Account';
    const prefix = 'networkAccount';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIGet(`${APIBase}/v1/networks/accounts/${accountId}`)
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,null,json.data.account));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const updateNetworkAccount = (accountId, payload) => dispatch => {

    const action = '[NETWORK] Update Account';
    const prefix = 'updateNetworkAccount';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPut(`${APIBase}/v1/networks/accounts/${accountId}`,{...payload})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,json.message,json.data.account));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const getNetworkUsers = (page, limit, search) => dispatch => {

    const action = '[NETWORK] Get Network Users';
    const prefix = 'networkUsers';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/networks/users/search?page=${page}&limit=${limit}`,{search})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,null,json.data));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}





export const getUser = (userId) => dispatch => {

    const action = '[NETWORK] Get User';
    const prefix = 'user';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIGet(`${APIBase}/v1/networks/users/${userId}`)
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,null,json.data.user));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const updateUser = (userId, payload) => dispatch => {

    const action = '[NETWORK] Update User';
    const prefix = 'updateUser';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPut(`${APIBase}/v1/networks/users/${userId}`,{...payload})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,json.message,json.data.user));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const getNetworkTeam = (networkId) => dispatch => {

    const action = '[NETWORK] Get Network Team';
    const prefix = 'team';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIGet(`${APIBase}/v1/networks/${networkId}/team`)
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else dispatch(dispatchSuccess(action,prefix,json.message,json.data.team));
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const newNetworkAdmin = (networkId, userId, networkRole) => dispatch => {

    const action = '[NETWORK] New Network Admin';
    const prefix = 'newAdmin';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPost(`${APIBase}/v1/networks/${networkId}/admin`,{userId,networkRole})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else {
            dispatch(openToast(json.message,'success'))
            dispatch(dispatchSuccess(action,prefix,json.message,json.data));
        }
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const updateNetworkAdmin = (networkId, userId, networkRole) => dispatch => {

    const action = '[NETWORK] Update Network Admin';
    const prefix = 'updateAdmin';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIPut(`${APIBase}/v1/networks/${networkId}/admin/${userId}`,{networkRole})
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else {
            dispatch(openToast(json.message,'success'))
            dispatch(dispatchSuccess(action,prefix,json.message,json.data));
        }
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}

export const removeNetworkAdmin = (networkId, userId) => dispatch => {

    const action = '[NETWORK] Remove Network Admin';
    const prefix = 'removeAdmin';

    // Update The UI
    dispatch(dispatchLoading(action,prefix));

    // Make Request
    APIDelete(`${APIBase}/v1/networks/${networkId}/admin/${userId}`)
    .then(res => res.json())
    .then(json => {
        if(json.status === 'error') {
            dispatch(openToast(json.message,'error'))
            dispatch(dispatchError(action,prefix,json.message))
        }
        else {
            dispatch(openToast(json.message,'success'))
            dispatch(dispatchSuccess(action,prefix,json.message,json.data));
        }
    })
    .catch(e => {
        dispatch(openToast(e.message,'error'))
        dispatch(dispatchError(action,prefix,e.message))
    })

}