import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { ModelList } from '../../../components/PaginatedList/ModelList';

// Styles
import { SmartContainer, Surface } from '../../../styles/Containers'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// Redux
import { searchSeries, restoreSeries } from './redux/series.actions';
import { FullSeries } from './redux/series.selectors';

export const NetworkSeriesList = ({ onSelect }) => {

    // New Modal
    const dispatch = useDispatch();
    const [view, setView] = useState('all');
    const [extraFilters, setExtraFilters] = useState(null);
    const [tag, setTag] = useState('all');
    const store = FullSeries();

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => onSelect(item);


    // --------------------------
    // Renders
    // --------------------------


    // Render Page
    return (
        <ModelList
            model="Series"
            action={searchSeries}
            restore={restoreSeries}
            selector={FullSeries}
            onSelect={handleSelect}
            filters={extraFilters}
            variant="cards"
            defaultLimit={20}
        />
    )
}