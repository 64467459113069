import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../../redux/UI/ui.actions';

// Redux
import { FullAccount } from '../../redux/Account/account.selectors';
import { updateAccount } from '../../redux/Account/account.actions';

// Styles
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledH3 } from '../../styles/StyledHTML'

// Components
import { FormInput } from '../Forms/FormInput'
import { FormError } from '../Forms/FormError'
import { TimeZoneSelect } from '../Forms/TimeZoneSelect'

export const AccountForm = () => {

    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const [accountName,setAccountName] = useState('');
    const [timezone,setTimezone] = useState('');
    const disableSubmit = (!accountName);

    // Clone to user for updating
    useEffect(() => {
        if(!fullAccount.account) return;
        setAccountName(fullAccount.account.name);
        setTimeout(fullAccount.account.timezone)
    },[fullAccount.account])

    // Toast
    useEffect(() => {
        if(fullAccount.accountSuccess){
            dispatch(openToast(fullAccount.accountSuccess,'success'))
        }
        if(fullAccount.accountError) {
            dispatch(openToast(fullAccount.accountError,'error'))
        }
    },[fullAccount.accountLoading, fullAccount.accountSuccess, fullAccount.accountError, dispatch])


    // Handle Save
    const handleSubmit = () => dispatch(updateAccount({id:fullAccount.account.id, name: accountName, timezone: timezone}))    

    // Login Errors
    const hasError = (fullAccount?.accountError) ? fullAccount.accountError : false;

    return (
        <SmartContainer minWidth="300px" thinking={fullAccount.loading}>
            <Stack>
                <StyledH3>Update Account Info</StyledH3>
                {hasError && (<FormError error={hasError} />)}
                <FormInput label="Account Name" type="text" onChange={setAccountName} value={accountName} />
                <TimeZoneSelect label="Timezone" onChange={setTimezone} value={timezone} />
                <StyledButton onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>Update</StyledButton>
            </Stack>
        </SmartContainer>
    )
}