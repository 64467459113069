import { duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, getModelBySlug, getRevisions } from '../../../../redux/DefaultModelActions';

const model = 'UserRating';
const route = '/v1/userrating';

export const searchUserRatings = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createUserRating = (args) => createModel(model, route, args);
export const updateUserRating = (id, args) => updateModel(model, route, id, args);
export const deleteUserRating = (id) => deleteModel(model, route, id);
export const restoreUserRating = (id) => restoreModel(model, route, id);
export const getUserRating = (id) => getModel(model, route, id);