import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components

// Styles
import { SmartContainer, Stack, Surface } from '../../styles/Containers'
import { StyledH3, StyledP } from '../../styles/StyledHTML';
import { StyledButton } from '../../styles/matieralui/Button';

export const PublicNetworkProductSuccessPage = () => {

    // New Modal
    const dispatch = useDispatch();
    

    const goTo = (path) => dispatch(push(path))

    // --------------------------
    // Effects
    // --------------------------


    // --------------------------
    // Renders
    // --------------------------


    // Render Page
    return (
        <SmartContainer>

            <Surface>
                <Stack>
                    <SmartContainer><StyledH3>Success!</StyledH3></SmartContainer>
                    <StyledP>Thank you for your purchase!</StyledP>
                    <StyledButton onClick={() => goTo('/recover-password?verify=true') } variant="contained" color="primary">Reset your password and login</StyledButton>
                </Stack>
            </Surface>
        </SmartContainer>
    )
}