import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from './NetworkApp';
import { NetworkEditAccountForm } from './NetworkEditAccountForm';

import { FullNetwork } from '../../redux/Network/network.selectors';
import { switchAccount } from '../../redux/Auth/auth.actions'
import { RouteBreadCrumbs } from '../../routes';
import { Stack, SmartContainer } from '../../styles/Containers';
import { StyledH6, StyledB, StyledHr, StyledH5 } from '../../styles/StyledHTML';
import { StyledButton } from '../../styles/matieralui/Button';
import { getNetworkAccount } from '../../redux/Network/network.actions';
import { PlatformEventsTimeline } from '../../modules/PlatformEvents/PlatformEventsTimeline';

export const NetworkEditAccountsPage = () => {


    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();
    const { id } = useParams();
    const thisId = parseInt(id)

    // Load This Acccount
    useEffect(() => {
        dispatch(getNetworkAccount(thisId));
    }, [thisId])


    // --------------------------
    // Renders
    // --------------------------

    return (
        <NetworkApp title="Edit Account" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>
            <Stack>
                <NetworkEditAccountForm account={fullNetwork?.networkAccount} />
                {/* <StyledHr />
                <SmartContainer>
                    <StyledButton onClick={() => dispatch(switchAccount(thisId))} variant="outlined" color="primary">Switch to this account</StyledButton>
                </SmartContainer> */}
                <StyledHr />
                <SmartContainer flex align="flex-start" justify="flex-start">
                    <StyledH5>Platform Events for this Account</StyledH5>
                    <PlatformEventsTimeline accountId={thisId} />
                </SmartContainer>
            </Stack>
        </NetworkApp>
    )
}