import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from './NetworkApp';
import { NetworkUsersList } from './NetworkUsersList';

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { switchAccount } from '../../redux/Auth/auth.actions'
import { RouteBreadCrumbs } from '../../routes';

export const NetworkUsersPage = () => {

    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (user) => dispatch(push(`/network/users/${user.id}`))

    // --------------------------
    // Renders
    // --------------------------

    return (
        <NetworkApp title="Users" breadcrumbs={[RouteBreadCrumbs.dashboard,RouteBreadCrumbs.network]}>
            
            <NetworkUsersList onSelect={handleSelect} />

        </NetworkApp>
    )
}