import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Stack, SmartContainer } from '../../styles/Containers'
import { StyledH3, StyledP } from '../../styles/StyledHTML'
import { StyledButton } from '../../styles/matieralui/Button'
import { FullAccount } from '../../redux/Account/account.selectors';
import { getAccountInfo } from '../../redux/Account/account.actions';
import { restrictAccessBecauseOfPayment } from '../../services/Utils';

export const PayWall = ({
    title = 'Purchase',
    plans, 
    children, 
    upgradeMessage = 'Upgrade your account to unlock this feature', 
    buttonText = 'Upgrade',
    reverse = false
}) => {

    const dispatch = useDispatch();
    const goTo = (path) => dispatch(push(path))
    const planRestriction = plans?.split('|');

    const fullAccount = FullAccount();

    const [access,setAccess] = useState(false);

    useEffect(() => {

        // No Account: Get Account
        if(!fullAccount.account && fullAccount.accountLoading !== true) dispatch(getAccountInfo())

        // Fails if no plan
        if(!fullAccount.account?.plan) return;

        // Get Current Plan
        const accountPlan = fullAccount.account?.plan.toLowerCase();

        // Validate The Account isn't Canceled or Failing too many payments
        if(restrictAccessBecauseOfPayment(fullAccount.account)) return;
        
        // Check if account has access
        planRestriction.map(plan => {
            if(plan.toLowerCase() === accountPlan) setAccess(true);
            return plan;
        })
    },[fullAccount.account, planRestriction, fullAccount.accountLoading, dispatch])

    // Reverse Paywall
    if(reverse === true){

        if(access) return (<div></div>)
        return (<SmartContainer width="100%">{children}</SmartContainer>)

    } 


    if(!access){
        return (
            <Stack>
                <SmartContainer><StyledH3>{title}</StyledH3></SmartContainer>
                <StyledP light>{upgradeMessage}</StyledP>
                <StyledButton onClick={() => goTo('/billing') } variant="contained" color="primary">{buttonText}</StyledButton>
            </Stack>
        )
    }

    return (
        <SmartContainer width="100%">{children}</SmartContainer>
    )
}