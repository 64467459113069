import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router'

// Components
import { NetworkApp } from './NetworkApp';
import { NetworkForm } from './NetworkForm'
import { NoAccessPage } from '../../pages/Account/NoAccessPage'
import { SurfaceMessage } from '../Alerts/SurfaceMessage';
import { NetworkTeamList } from './NetworkTeamList';
import { Collapse } from '../Layout/Collapse';

// Styled
import { Stack, Surface } from '../../styles/Containers'
import { StyledB, StyledP } from '../../styles/StyledHTML';

// Redux
import { FullNetwork } from '../../redux/Network/network.selectors';
import { getFullNetwork } from '../../redux/Network/network.actions'
import { hasNetworkAccess } from '../../redux/Auth/auth.actions'
import { FullAuth } from '../../redux/Auth/auth.selectors';
import { APIBase } from '../../services/API';
import { RouteBreadCrumbs } from '../../routes';
import { StyledButton } from '../../styles/matieralui/Button';

export const EditNetworkPage = () => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const fullNetwork = FullNetwork();
    const fullAuth = FullAuth();
    const { id } = useParams();
    const networkId = parseInt(id)
    const goTo = (path) => dispatch(push(path))

    // --------------------------
    // Effects
    // --------------------------

    // Close Modal on Success
    useEffect(() => { dispatch(getFullNetwork(networkId))},[])

    // --------------------------
    // Permissions
    // --------------------------

    const hasAccess = hasNetworkAccess(fullAuth.me,networkId)
    if(!hasAccess) return (<NoAccessPage />)
   
    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    // const handleSelect = (item) => console.log(item); //dispatch(push('/user/'+user.id));

    // // Close Modal
    // const handleCloseModal = () => setOpenModal(false)

    const thisNetwork = (fullNetwork.getFullNetwork) ? fullNetwork.getFullNetwork : null;
    
    // --------------------------
    // Renders
    // --------------------------

    return (
        <NetworkApp title="Edit Network" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            {!thisNetwork && (<SurfaceMessage title="Network Does Not Exist" />)}

            {thisNetwork && (
                <Stack>
                    <Surface thinking={fullNetwork.getFullNetworkLoading}>
                        
                        <NetworkForm network={thisNetwork} />
                        
                    </Surface>

                    <Surface>
                        <StyledB>Webhook URL</StyledB>
                        <StyledP>Add this to your stripe account</StyledP>
                        <StyledP light>{APIBase}/v1/billing/webhook/{thisNetwork.id}</StyledP>
                    </Surface>

                    <Surface>
                        <Collapse title="Network Team" open={false}>
                            <NetworkTeamList networkId={networkId} />
                        </Collapse>
                    </Surface>

                    <Surface>
                        <Collapse title="Integrations" open={false}>
                            <Stack>
                                <StyledP>Manage Add ons and Integrations</StyledP>
                                <StyledButton variant="outlined" onClick={() => goTo(`/network/${networkId}/integrations`)}>App Library</StyledButton>
                            </Stack>
                           
                           
                        </Collapse>
                    </Surface>
                    

                </Stack>
            )}

        </NetworkApp>
    )
}