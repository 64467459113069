import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

// Redux
import { FullAuth } from '../../redux/Auth/auth.selectors';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { recoverPassword } from '../../redux/Auth/auth.actions';
import { openToast } from '../../redux/UI/ui.actions';
import { getQueryParam } from '../../services/Utils';

// Styles
import { SmartContainer, Stack, HorizontalStack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledH3, StyledP } from '../../styles/StyledHTML'
import { isEmail } from '../../services/Utils'

// Components
import { FormInput } from '../Forms/FormInput'
import { FormError } from '../Forms/FormError'

export const RecoverForm = () => {

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const currentNetwork = CurrentNetwork();

    const verify = getQueryParam('verify');



    const handleLogin = (e) => dispatch(recoverPassword(currentNetwork.id, email));
    const handleEmailChange = (value) => {
        if (isEmail(value)) setEmailError('')
        else setEmailError('Please enter a valid email')
        setEmail(value)
    }

    const auth = FullAuth();

    // Toast Results
    useEffect(() => {
        if (auth.success === true && auth.session?.visitor !== true) {
            dispatch(openToast(auth.message, 'success'))
        }
        if (auth.recoverError) {
            dispatch(openToast(auth.recoverError, 'error'))
        }
    }, [auth.recoverLoader, auth.success, auth.recoverError, auth.message, auth.session, dispatch])

    // Redirect logged in users
    if (auth.session && auth.session.token && auth.session.visitor !== true) return (<Redirect to="/" />);

    // Login Errors
    const hasError = (auth?.error) ? auth.error : false;
    const disableSubmit = (!email || !isEmail(email));
    const title = (verify) ? 'Verify Account' : 'Recover';

    return (
        <SmartContainer thinking={auth.loading} minWidth="300px">
            <Stack>
                {/* <StyledH3>{title}</StyledH3> */}
                <StyledP light>Enter your email to reset your password</StyledP>
                {hasError && (<FormError error={hasError} />)}
                <FormInput label="Email" type="email" onChange={handleEmailChange} value={email} helperText={emailError} error={(emailError)} />
                <StyledButton width="100%" onClick={handleLogin} variant="contained" color="primary" disabled={disableSubmit}>Recover Password</StyledButton>
                <HorizontalStack textAlign="center">
                    <Link to="/login">Login</Link>
                    <Link to="/signup">Create an account</Link>
                </HorizontalStack>
            </Stack>
        </SmartContainer>
    )
}