import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HorizontalStack, SmartContainer, Stack, StyledGrid, StyledGridByFour, StyledGridItem } from '../../../styles/Containers';
import { Surface } from '../../../styles/Containers';
import { FullAccount } from '../../../redux/Account/account.selectors';
import { Me } from '../../../redux/Auth/auth.selectors';
import { push } from 'connected-react-router';
import { UpNext } from '../../modules/UserProgress/UpNext';
import { StyledH5, StyledH6, StyledP, StyledB, StyledImg } from '../../../styles/StyledHTML';
import { HorizontalScroller } from '../Display/HorizontalScroller';
import { FullSeries } from '../../modules/Series/redux/series.selectors';
import { searchSeries } from '../../modules/Series/redux/series.actions';
import { FeaturedSeries } from '../../modules/Series/FeaturedSeries';
import { Welcome } from '../Display/Welcome';
import { FeaturedTopics } from '../../modules/Tags/FeaturedTopics';
import { StyledMediaImage } from '../Display/MediaAsset';
import { Divider } from '@mui/material';
import { NotePromptShuffler } from '../../modules/NotePrompts/NotePromptShuffler';
import { useAnimatedPageMount, validPlans } from '../../services/SpaceUtils';
import { animated } from 'react-spring';
import { InstallApp } from '../Display/InstallApp';
import { TopicWidget } from '../../modules/Tags/TopicWidget';
import { Breathing } from '../../modules/Breathing/Breathing';
import { BuyMeACoffeePanel } from '../BuyMeACoffee/BuyMeACoffeePanel';
import { isPayingCustomer } from '../../../services/Utils';

export const SpaceDashboard = () => {
    const dispatch = useDispatch();
    const fullAccount = FullAccount();
    const fullSeries = FullSeries();
    const me = Me();
    const goTo = (path) => dispatch(push(path));
    const { animatedProps } = useAnimatedPageMount();
    const customerIsPaying = isPayingCustomer(fullAccount?.account, validPlans);

    useEffect(() => {

        // Load All Series
        if (!fullSeries?.items) dispatch(searchSeries(1, 999, null, 'published'))

    }, [])

    return (
      <Stack width="100%">
        <Stack>
          <TopicWidget />
        </Stack>

        <Divider />

        <Stack>
          {/* <SmartContainer>
                    <StyledP><StyledB>Up Next</StyledB></StyledP>
                    <StyledP light>Take your next step</StyledP>
                </SmartContainer> */}
          <UpNext />
        </Stack>

        <Divider />

        <Stack>
          <SmartContainer>
            <Stack flex justify="space-between">
              <SmartContainer>
                <StyledP>
                  <StyledB>Start A Series</StyledB>
                </StyledP>
                <StyledP light>Begin a multi-day meditation</StyledP>
              </SmartContainer>
              <StyledP light onClick={() => goTo("/series")}>
                View All Series
              </StyledP>
            </Stack>
          </SmartContainer>
          <FeaturedSeries limit={999} />
        </Stack>
        <Divider />

        <Surface shadow="none" textAlign="center">
          <NotePromptShuffler />
        </Surface>

        {!customerIsPaying && (
          <Surface shadow="none">
            <BuyMeACoffeePanel small={true} />
          </Surface>
        )}
      </Stack>
    );
}