import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { App } from '../../App';
import { SmartContainer, Surface, Stack, StyledRichTextContent } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { FormInput } from '../../components/Forms/FormInput';
import { StyledH1, StyledH6, StyledP } from '../../styles/StyledHTML';
import { MediaFormInput } from '../Media/MediaFormInput';


export const MediaLab = () => {

    const [mediaItem, setMediaItem] = useState('')
    const [audio, setAudioItem] = useState('');
    const [video, setVideoItem] = useState('');
    const [image,setImage] = useState(4050);
    
    const handleMediaSelection = (item) => {
        console.log(item,"Media Selection")
    }
    const handleImageSelection = (item) => {
        console.log(item, "Image Selection")
        setImage(item.id);
    }

    return (
        <App title="Lab: Media">
            <Stack>
                <Surface>
                    <Stack>
                        <MediaFormInput network={true} label="Media Selection" value={mediaItem} onChange={(value) => handleMediaSelection(value)} />
                        <MediaFormInput label="Audio Selection" type="audio" buttonText="Select Audio" value={audio} onChange={(value) => handleMediaSelection(value)} />
                        <MediaFormInput label="Video Selection" type="video" buttonText="Select Video" value={video} onChange={(value) => handleMediaSelection(value)} />
                        <MediaFormInput label="Image Selection" type="image" buttonText="Select Image" value={image} onChange={(value) => handleImageSelection(value)} />
                    </Stack>
                </Surface>
            </Stack>
        </App>
    )
}