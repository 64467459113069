import React from 'react';
import styled, { css } from 'styled-components';

// Styled
import { SmartContainer, Surface, Stack, HorizontalStack, StyledImagePlaceholder } from '../../../styles/Containers';
import { StyledImg, StyledB, StyledP, StyledH5 } from '../../../styles/StyledHTML';
import { Theme } from '../../../styles/Theme';
import { SkeletonAnimation } from '../../../styles/GlobalStyles';
import { StyledButton } from '../../../styles/matieralui/Button';

import { MediaPreview } from '../../../modules/Media/MediaPreview';
import { MediaAsset, StyledMediaImage } from '../../components/Display/MediaAsset';
import { SpaceIconStar } from '../../styles/Icons';
import moment from 'moment';
export const StyledTag = styled.p`
    background-color: white;
    color: black;
    border-radius: 1000px;
    font-size: 0.75em;
    padding: 0em 1em;
    display: inline-block;
    margin-left: 0.5em;
    ${props => (props?.variant == 'success') ? css`background-color: black; color: white;` : ''}
`

const StyledCard = styled(SmartContainer)`
    
    transition: ${Theme.transition.one} !important;
    margin-bottom: 2em;
    
    &:hover {}
    img {  box-shadow: ${Theme.shadow.one}; }

    // STAR
    .MuiSvgIcon-root {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        color: white;
    }
    [data-tags]{
        position: absolute;
        top: 0.5em;
        right: 0.5em; 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`


const StyledHorizontalStack = styled(HorizontalStack)`
     > * {
        margin-right: 0.25em !important;
    }
    margin-top:0.25em;
`

// Card
export const SeriesCard = (props) => {

    const { item } = props;

    const handleClick = () => {
        if (!props.onClick) return;
        props.onClick(item)
    }

    const free = (item?.meta?.plan === 'free') ? true : false;
    const twoWeeksAgo = moment().subtract(14, 'days');
    const isNew = (moment(item.publishedAt).isAfter(twoWeeksAgo)) ? true : false;

    return (
        <StyledCard width="100%" clickable onClick={handleClick} data-series-card>
            <Stack>
                {item?.meta?.featureImage && (
                    <SmartContainer width="100%" position="relative">
                        <StyledMediaImage radius id={item?.meta?.featureImage} network={true} />
                        <SmartContainer data-tags>
                            {isNew && (<StyledTag variant="success">New</StyledTag>)}
                            {free && <StyledTag data-tag>Free</StyledTag>}

                        </SmartContainer>

                    </SmartContainer>
                )}
                {!item?.meta?.featureImage && (
                    <SmartContainer width="100%" position="relative">
                        <StyledImagePlaceholder minHeight="180px" />
                    </SmartContainer>)}
                <SmartContainer>
                    <StyledH5>{item.name}</StyledH5>
                    {item?.meditations?.length && (<StyledP light>{item?.meditations?.length} Meditations</StyledP>)}
                </SmartContainer>
            </Stack>

        </StyledCard>
    )
}