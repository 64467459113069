import React from 'react';
import moment from 'moment';

import { Surface, Stack, SmartContainer } from '../../styles/Containers';
import { StyledB, StyledP } from '../../styles/StyledHTML';

import { convertCentsToPrice } from '../../services/Stripe';


export const NetworkOrderCard = ({order, onClick}) => {

    return (
        <Surface width="100%" clickable onClick={() => onClick(order)}>
            <Stack>

                <SmartContainer flex justify="space-between">
                    <StyledP light>{moment(order.createdAt).format('YYYY-MM-DD')}</StyledP>
                    <StyledP light small>{order.orderStatus}</StyledP>
                </SmartContainer>  
                 
                <SmartContainer>
                    <StyledP light>{order.name}</StyledP>
                </SmartContainer>
                {order?.meta?.lineItems.map(item => (
                        <Stack>
                            <StyledB>{item?.description}</StyledB>
                            <StyledP light >${convertCentsToPrice(item?.amount_total)} {item?.currency}</StyledP>
                        </Stack>
                ))} 
                <SmartContainer flex justify="flex-end">
                    <StyledP>${convertCentsToPrice(order.amount)}</StyledP> 
                </SmartContainer>  
                
            </Stack>
        </Surface>
    )
}