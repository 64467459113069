import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { App } from '../../../App';
import { createUserNote } from './redux/usernotes.actions';
import { FullUserNotes } from './redux/usernotes.selectors';
import { SmartContainer, Stack, Surface } from '../../../styles/Containers';
import { NewContent } from '../../components/Content/NewContent';
// Space
import { UserNotesList } from './UserNotesList';
import { StyledH6, StyledP } from '../../../styles/StyledHTML';
import { Divider } from '@mui/material';
import { NotePromptShuffler } from '../NotePrompts/NotePromptShuffler';
import { StyledTab, StyledTabPanel, StyledTabs } from '../../../styles/matieralui/Tabs';
import { NewContentForm } from '../../components/Content/NewContentForm';

export const PageJournal = () => {
    const dispatch = useDispatch();
    const store = FullUserNotes();
    const [activeTab, setActiveTab] = useState(0);
    useEffect(() => { if (store.createSuccess) dispatch(push(`/journal/${store.create.id}`)) }, [store?.createLoading])
    const handleTabChange = (event, index) => setActiveTab(index);
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="space-between" width="100%">
                <StyledH6>Reflect</StyledH6>
                <NewContent label="New Entry" selector={FullUserNotes} createAction={createUserNote} />
            </SmartContainer>
        )

    }
    return (
        <App title='' stackNav="Notes">


            <StyledTabs indicatorColor="primary" value={activeTab} onChange={handleTabChange} aria-label="Edit Form" variant="scrollable" scrollButtons="auto">
                <StyledTab label="Prompts" id="screen-1" key="screen-1" aria-controls="screen-1" />
                <StyledTab label="My Notes" id="screen-0" key="screen-0" aria-controls="screen-0" />

                <StyledTab label="New Note" id="screen-2" key="screen-2" aria-controls="screen-2" />
            </StyledTabs>
            <StyledP>&nbsp;</StyledP>

            <StyledTabPanel value={activeTab} index={0}>
                <Surface shadow="none" textAlign="center">
                    <NotePromptShuffler />
                </Surface>
            </StyledTabPanel>
            <StyledTabPanel value={activeTab} index={1}>
                <UserNotesList />
            </StyledTabPanel>
            <StyledTabPanel value={activeTab} index={2}>
                <NewContentForm titleLabel="New Note" selector={FullUserNotes} createAction={createUserNote} />
            </StyledTabPanel>

        </App>
    )
}