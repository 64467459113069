import { useDispatch } from 'react-redux';
import { createUserRating } from './userrating.actions';
import { FullUserRatings } from './userrating.selectors';



export const useUserRating = ({ type = 'unknown', meditationId = null, seriesId = null }) => {

    const dispatch = useDispatch();
    const store = FullUserRatings();

    // New User Rating
    const newUserRating = (rating) => {
        const args = {
            type,
            MeditationId: meditationId,
            SeriesId: seriesId,
            meta: {
                rating
            }
        }

        dispatch(createUserRating(args))

    }

    // Get User Rating
    const getUserRating = ({ type, mId, sId }) => {
        // TODO...
    }

    return {
        newUserRating,
        getUserRating
    }

}


