import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

// Components
import { App } from "../../../App";

// Styles
import { SmartContainer, Stack, Surface } from "../../../styles/Containers";
import { StyledButton } from "../../../styles/matieralui/Button";

// Redux
import "./pulsatingCircle.css";
import {
  StyledB,
  StyledH1,
  StyledH6,
  StyledP,
} from "../../../styles/StyledHTML";

import { Screen } from "../../components/Display/Screen";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { isNativeApp, postMessage } from "../../../services/Utils";

const StyledLink = styled.a`
  display: block;
`;

export const PageBreathingComplete = (props) => {
  const screenAlign = "flex-start";
  const dispatch = useDispatch();
  const goTo = (path) => dispatch(push(path))
  const nativeApp = isNativeApp();
  const handleLinkClick = (e, payload) => {

    // Guard: Only run native code in native app
    if (!nativeApp) return;

    // Don't Leave
    e.preventDefault();

    // Share:
    postMessage('SHARE', payload);


  }
  // Render Page
  return (
    <SmartContainer
      height="100vh"
      flex
      direction="column"
      align="center"
      justify={screenAlign}
      width="100%"
      border="10px"
    >

      <StyledH1>Complete</StyledH1>
      <Stack width="100%">
        <SmartContainer flex direction="column" align="center">
          <StyledH6>👏👏👏</StyledH6>
          <StyledB>Well Done Today!</StyledB>
          <StyledP light>Here's your next steps</StyledP>
        </SmartContainer>

        <StyledLink href="/billing">
          <Surface>
            <StyledP>
              <StyledB>Buy me a coffee</StyledB>
            </StyledP>
            <StyledP light>Help keep this free for others.</StyledP>
          </Surface>
        </StyledLink>

        <StyledLink
          onClick={(e) =>
            handleLinkClick(e, {
              type: "link",
              title: "Invite A Friend",
              description:
                "Send this to someone that you think could need it.",
              url: "https://spaceculture.co/invite",
            })
          }
          target="_blank"
          href="https://spaceculture.co/invite"
        >
          <Surface>
            <StyledP>Invite A Friend</StyledP>
            <StyledP light>
              Send this to someone that you think could need it.
            </StyledP>
          </Surface>
        </StyledLink>

        <StyledLink
          target="_blank"
          href="https://discord.com/invite/DjMrdG5nMG"
        >
          <Surface>
            <StyledP>Join The Community</StyledP>
            <StyledP light>Chat about today's meditation</StyledP>
          </Surface>
        </StyledLink>

        <Stack flex justify="center" align="center" direction="column">
          <SmartContainer>
            <StyledButton variant="contained" onClick={() => goTo("/")}>
              Finish
            </StyledButton>
          </SmartContainer>
        </Stack>
        <SmartContainer flex align="center" justify="center">
          <StyledButton
            variant="contained"
            onClick={() => props.screen("select")}
          >
            Restart
          </StyledButton>
        </SmartContainer>
      </Stack>
    </SmartContainer>
  );
};
