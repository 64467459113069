import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { App } from '../../App';

import { FullAuth } from '../../redux/Auth/auth.selectors'
import { FullAccount } from '../../redux/Account/account.selectors'
import { openToast } from '../../redux/UI/ui.actions';
import { SmartContainer, Surface, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { StyledB, StyledP } from '../../styles/StyledHTML';
import { UserForm } from './UserForm';
import { getAccountInfo, getTeamMembers, updateUserAccountRole, removeUserAccount } from '../../redux/Account/account.actions';
import { updateMe } from '../../redux/Auth/auth.actions'
import { FullScreenLoader } from '../Loaders/FullScreenLoader'
import { StyledAreYouSure } from '../../styles/matieralui/Dialog'

// Components
import { StyledModal } from '../../styles/matieralui/Modal'
import { NewAccountForm } from '../Account/NewAccountForm'

// Redux
import { Me } from '../../redux/Auth/auth.selectors'
import { switchAccount } from '../../redux/Auth/auth.actions'
import { RouteBreadCrumbs } from '../../routes';


export const AccountSwitcherPage = () => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const me = Me();

    const handleCloseModal = () => setOpenModal(false)
    
    const handleSwitchAccount = (account) => dispatch(switchAccount(account.id))
    
    // Render Accounts
    const renderAccounts = () => {
        return me.accounts.map(account => {
            return (<Surface clickable key={account.id} clickable onClick={() => handleSwitchAccount(account)}>{account.name}</Surface>)
        })
    }

    // Render Top Bar
    const renderTopBarChildren = () => {
        return (
            <SmartContainer flex align="center" justify="flex-end">
                <StyledButton variant="contained" color="primary" onClick={() => setOpenModal(true)}>Create a new account</StyledButton>
            </SmartContainer>
        )
    }

    return (
        <App title="Switch Accounts" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard]}>
            <Stack>
                {renderAccounts()}
                <StyledModal
                    open={openModal}
                    onClose={handleCloseModal}
                    label="Create a new account"
                    description="Create a completely new account"
                    >
                        <Stack>
                            <StyledP>This is a completely separately billed account.</StyledP>
                            <NewAccountForm />
                        </Stack>
                    
            </StyledModal>
            </Stack>
        </App>
    )    
}