import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import moment from 'moment';
// Components
import { ModelList } from '../../../components/PaginatedList/ModelList';
import { PaginatedList } from '../../../components/PaginatedList/PaginatedList';


// Styles
import { SmartContainer, Surface } from '../../../styles/Containers'
import { StyledButton } from '../../../styles/matieralui/Button'

// Redux
import { restoreUserNote, searchUserNotes } from './redux/usernotes.actions';
import { FullUserNotes } from './redux/usernotes.selectors';
import { StyledP } from '../../../styles/StyledHTML';


export const UserNotesList = () => {

    // New Modal
    const dispatch = useDispatch();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [view, setView] = useState('all');
    const store = FullUserNotes();
    const limit = 999;

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => {

        // Load All Series
        if (!store?.items) dispatch(searchUserNotes(1, limit, null, null))

    }, [])

    // --------------------------
    // Renders
    // --------------------------

    const handleSelect = (item) => dispatch(push(`/journal/${item.id}`))

    const formatted = (store.items) ? store.items.rows.map(item => {
        const prettyData = moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')
        return { id: item.id, title: item.name, description: prettyData, full: item }
    }) : []
    const total = (store.items) ? store?.items?.count : 0;
    const pageCount = Math.ceil(total / limit);

    // Render Page
    return (
        <SmartContainer>

            <ModelList
                model="UserNote"
                action={searchUserNotes}
                restore={restoreUserNote}
                selector={FullUserNotes}
                onSelect={handleSelect}
                variant="cards"
            />
            <StyledP light small>Titles are searchable. Note content is fully encrypted.</StyledP>

        </SmartContainer>
    )
}

