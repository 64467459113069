import { combineReducers } from 'redux'

// Generic Model Reducers
import { DefaultModelReducers, modelActionName } from '../../../../redux/DefaultModelReducers';
import { clearActionType, failedActionType, loadingActionType, successActionType, wipeActionType } from '../../../../redux/utils'

// Extra Options
const otherReducers = (model, state, action) => {
    switch (action.type) {
        case modelActionName(model, 'Toggle'):
        case failedActionType(modelActionName(model, 'Toggle')):
        case clearActionType(modelActionName(model, 'Toggle')):
            return Object.assign({}, state, { ...action.payload })

        // Success
        case successActionType(modelActionName(model, 'Toggle')):
            const newCreationState = Object.assign({}, state, { ...action.payload })

            // Remove Favorite From Favorites
            if (action.payload.toggle.favorite !== true) {
                const remainingFavorites = newCreationState.items.rows.filter(item => item.id !== action.payload.toggle.id)
                newCreationState.items.rows = [...remainingFavorites];
                newCreationState.items.count -= 1;
                return newCreationState;
            }

            // Update Existing Item
            if (action.payload.toggle.favorite === true) {
                let isUpdate = false;
                const updatedFavorites = newCreationState.items.rows.map(item => {
                    if (item.id === action.payload.toggle.id) {
                        isUpdate = true;
                        return action.payload.toggle;
                    }
                    return item;
                })
                newCreationState.items.rows = [...updatedFavorites];

                // Push New Item
                if (isUpdate !== true) {
                    newCreationState.items.rows.push(action.payload.toggle);
                    newCreationState.items.count += 1;
                }

                return newCreationState;
            }

    }
    return false;
}


// Generic Model Reducers
const defaults = DefaultModelReducers('UserFavorite', otherReducers);

export default defaults;