import React from 'react';
import { SmartContainer, Stack } from '../../styles/Containers';
import { StyledB, StyledImg, StyledP } from '../../styles/StyledHTML';

export const IntegrationCard = ({title,logo,description, onClick}) => {
    return (
        <Stack clickable onClick={onClick}>
            <SmartContainer flex justify="center">
                <StyledImg src={logo} maxWidth="50px" />
            </SmartContainer>
            <SmartContainer flex justify="center" direction="column">
                <StyledP textAlign="center"><StyledB>{title}</StyledB></StyledP>
                <StyledP light textAlign="center">{description}</StyledP>
            </SmartContainer>
        </Stack>
    )
}