import React from 'react';
import { StyledSelect, StyledSelectControl } from '../../styles/matieralui/Form'
import InputLabel from '@mui/material/InputLabel';

export const FormSelect = (props) => {

    const handleChange = (e) => {
        props.onChange(e.target.value)
    }

    return (
        <StyledSelectControl variant="filled">
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <StyledSelect
                native
                {...props}
                onChange={handleChange}
                
            >
            {props.children}
            </StyledSelect>
      </StyledSelectControl>
    );
}