import React from 'react';

// Components
import { SignupForm } from './SignupForm'

// Styles
import { SmartContainer, Stack, Surface } from '../../styles/Containers'

// SPACE CULTURE
import logo from '../../space/assets/logo-black-short.png';
import { StyledImg } from '../../styles/StyledHTML';

export const SignupPage = () => {
    return (

        <SmartContainer padding={10}>
            <Stack flex direction="column" align="center" justify="center">
                <SmartContainer maxWidth="200px">
                    <StyledImg alt="Space Maker" src={logo} />
                </SmartContainer>

                <SignupForm />

            </Stack>
        </SmartContainer>
    )
}