import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { StyledIconButton } from '../../../styles/matieralui/IconButton';

// Redux
import { FullFavorites, isFavorited } from './redux/userfavorites.selectors';
import { toggleFavorite, searchFavorites } from './redux/userfavorites.actions';
import { StyledButton } from '../../../styles/matieralui/Button';




export const Favorite = ({ modelId, modelType, withText = false }) => {


    // State
    const dispatch = useDispatch();
    const store = FullFavorites();


    const favorited = isFavorited(store, modelId, modelType);

    // Load Favorites
    useEffect(() => {

        // Load all favorites by type only if we haven't loaded them already.
        if (!store.items?.count) dispatch(searchFavorites(0, 999, null, null, {}));


    }, [modelId, modelType])

    const handleFavoriteClick = () => {
        if (store.itemsLoading === true) return;
        if (store.createLoading === true) return;
        // Toggle Favorite
        dispatch(toggleFavorite({ modelId, modelType }))
    }

    let text = (!favorited) ? 'Favorite' : 'Unfavorite';
    if (withText !== true) text = ''

    return (
        <StyledButton onClick={handleFavoriteClick} startIcon={!favorited ? (<FavoriteBorderOutlinedIcon />) : (<FavoriteOutlinedIcon />)}>
            {text}
        </StyledButton>
    )
}