import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { RouteBreadCrumbs } from '../../routes';

import { App } from '../../App';
import { PaginatedList } from '../PaginatedList/PaginatedList'
import { StyledModal } from '../../styles/matieralui/Modal'
import { SmartContainer } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'
import { NewUserForm } from '../User/NewUserForm'

// Redux
import { FullAccount } from '../../redux/Account/account.selectors';
import { Role } from '../../redux/Auth/auth.selectors';
import { getTeamMembers } from '../../redux/Account/account.actions';





export const TeamPage = () => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const fullAccount = FullAccount();
    const currentUserRole =  Role();

    // Get Team List
    useEffect(() => { if(fullAccount.account?.id) dispatch(getTeamMembers(fullAccount.account.id,1,20)) },[fullAccount.account])

    // Close Modal on Success
    useEffect(() => {if(fullAccount.addTeamMemberSuccess){setOpenModal(false)}},[fullAccount.addTeamMemberLoading])

    // On Select
    const handleUserClick = (user) => dispatch(push('/user/'+user.id));

    // Format Data
    const formattedTeam = (fullAccount.team) ? fullAccount.team.map(user => { return {id: user.id, title: `${user.firstName} ${user.lastName}`, description: user.role}}) : []

    const handleCloseModal = () => setOpenModal(false)

    const renderTopBarChildren = () => {

        if(currentUserRole === 'owner' || currentUserRole === 'admin') {
            return (
                <SmartContainer flex align="center" justify="flex-end">
                    <StyledButton variant="contained" color="primary" onClick={() => setOpenModal(true)}>Add A New User</StyledButton>
                </SmartContainer>
            )
        }
    
        return (<div />)
        
    }

    return (
        <App title="Team" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard]}>
            
            
            <PaginatedList 
                onClickItem={handleUserClick}
                loading={fullAccount?.teamLoading} 
                items={formattedTeam}
                variant="cards"
                noControls={true}
                />
            
            {(currentUserRole === 'owner' || currentUserRole === 'admin') &&(<StyledModal
                open={openModal}
                onClose={handleCloseModal}
                label="Add a new user"
                description="Create a new user on your account"
                >
                    <NewUserForm />
            </StyledModal>)}
        </App>
    )    
}