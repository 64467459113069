import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

// Components
import { App } from '../../App';
import { Surface, Stack } from '../../styles/Containers';
import { PayWall } from '../../components/Billing/PayWall';


export const PayWallLab = () => {



    return (
        <App title="Lab: PayWall">
            <Stack>
                <Surface>
                    <PayWall plans="notarealplan" title="You should see a payment button" upgradeMessage="Yep its working">
                        <p>You Shouldn't See This</p>
                    </PayWall>
                </Surface>

                <Surface>
                    <PayWall plans="basic_monthly|basic_yearly" title="This is a basic only feature">
                        <p>You will see this if you are subscribed to basic_monthly or basic_yearly</p>
                    </PayWall>
                </Surface>

                <Surface>
                    <PayWall plans="premium_monthly|premium_yearly" title="You need to upgrade to Premium">
                        <p>You will see this if you are subscribed to premium_monthly or premium_yearly</p>
                    </PayWall>
                </Surface>

                <Surface>
                    <PayWall reverse={true} plans="basic_monthly|basic_yearly|premium_monthly|premium_yearly" title="Reverse Paywall">
                        <p>You should only see this if you are not on a plan</p>
                    </PayWall>
                </Surface>
            </Stack>
        </App>
    )
}