import React from "react";
import { SmartContainer, Stack, Surface } from "../../../styles/Containers";
import { StyledB, StyledH3, StyledP } from "../../../styles/StyledHTML";
import { BuyMeACoffeeButton } from "./BuyMeACoffeeButton";
export const BuyMeACoffeePanel = ({ small = false }) => {
    return (

        <Surface>

            <Stack>
                <SmartContainer>
                    {!small && (<StyledH3>Help us keep this App free for as many people as possible. </StyledH3>)}
                    {small && (<StyledP><StyledB>Help us keep this App free for as many people as possible.</StyledB><br /><br /></StyledP>)}

                    <StyledP>It costs roughly $4 for each person to use this app.</StyledP>
                    <StyledP> We want this to help people and we know we can help the most people by making this free. Thank you for your support!</StyledP>
                </SmartContainer>
                <BuyMeACoffeeButton />
            </Stack>


        </Surface>
    )
}