import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SmartContainer, Stack } from '../../../styles/Containers';
import { searchTags } from './redux/tags.actions';
import { FullTags } from './redux/tags.selectors';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { SeriesCard } from '../Series/SeriesCard';
import { HorizontalScroller } from '../../components/Display/HorizontalScroller';
import { StyledList, StyledListItem, StyledListItemText } from '../../../styles/matieralui/List';
import { Theme } from '../../../styles/Theme';
import { StyledB, StyledP } from '../../../styles/StyledHTML';
import { Divider } from '@mui/material';
import { EmojiEmotions } from '../UserRatings/UserRating';
const StyledTopicList = styled(StyledList)`
    display: flex;
    flex-wrap: wrap;
`
const StyledTopic = styled(StyledListItem)`
    flex: 1 !important;
    padding: 0.5em 0 !important;
    text-align: left !important;
    background-color: ${Theme.colors.baseAlternate} !important;
    margin: 0.25em !important;
    min-width: 200px !important;
`
const StyledEmojiTopicList = styled(StyledList)`
    display: flex;
    width: 100%;
`
const StyledEmjoi = styled(StyledListItem)`
    flex: 1 !important;
    padding: 0.25em 0 !important;
    text-align: center !important;
    /* margin: 0.25em !important; */
    align-items: center !important;
    justify-content: center !important;
    font-size: 1.5em;
`

const mapEmojiToTopic = (allEmojis, allTopics) => {

    if (!allTopics) return;
    const newArr = [];
    const res = allEmojis.map(emoji => {

        const emojiName = emoji.description.toLowerCase();
        const found = allTopics.find(topic => {
            const topicName = topic.name.toLowerCase();

            if (topicName.indexOf(emojiName) > -1) return topic;
            return null;
        })
        if (!found?.id) return null;
        const item = { ...emoji, topicId: found.id };
        newArr.push(item);
        return item;

    });
    return newArr;
}

export const TopicWidget = ({ limit = 999 }) => {
    const dispatch = useDispatch();
    const store = FullTags();
    const goTo = (path) => dispatch(push(path));
    const [emotions, setEmotions] = useState([]);

    const handleSelect = (item) => {
        dispatch(push(`/topic/${item.id}`))
    }
    const handleEmojiSelect = (item) => {
        dispatch(push(`/topic/${item.topicId}`))
    }

    useEffect(() => {

        // Load All Series
        if (!store?.items) dispatch(searchTags(1, limit, null, null))

    }, [])

    useEffect(() => {
        if (!store?.items) return;
        const availableEmotions = mapEmojiToTopic(EmojiEmotions, store?.items?.rows);
        setEmotions(availableEmotions);
    }, [store?.items])

    const renderTopic = () => {
        if (!store?.items) return (<div>Loading</div>)
        // return store?.items?.rows.map(item => (<SeriesCard key={item?.id} item={item} onClick={(s) => handleSelect(s)} />));
        return store?.items?.rows.map(item => (
            <StyledTopic key={item?.id} onClick={(s) => handleSelect(item)} button>
                <StyledListItemText>{item?.name}</StyledListItemText>
            </StyledTopic>));
    }

    const renderEmojis = () => {
        if (!emotions) return;
        return emotions.map(item => {
            return (
                <StyledEmjoi key={item?.id} onClick={(s) => handleEmojiSelect(item)} button>{item?.title}</StyledEmjoi>
            )
        })
    }

    const width = (store?.items) ? `${store?.items.count * 300}px` : '100%';

    return (
        <SmartContainer>
            <StyledP></StyledP>
            <SmartContainer>
                <StyledP><StyledB>How are you feeling?</StyledB></StyledP>
            </SmartContainer>
            <StyledEmojiTopicList>{renderEmojis()}</StyledEmojiTopicList>

        </SmartContainer>
    )
}