import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { App } from '../../App';

import { FullAuth } from '../../redux/Auth/auth.selectors'
import { updateMe } from '../../redux/Auth/auth.actions'
import { openToast } from '../../redux/UI/ui.actions';
import { Stack, Surface } from '../../styles/Containers'
import { UserForm } from './UserForm';
import { UserPreferencesForm } from './UserPreferencesForm';
import { RouteBreadCrumbs } from '../../routes';
import { AccountDeletion } from './AccountDeletion';
import { FullAccount, ThisAccount } from '../../redux/Account/account.selectors';
import { Policies } from './Policies';

export const ProfilePage = () => {

    const dispatch = useDispatch();
    const auth = FullAuth();
    const thisAccount = ThisAccount()

    const onSubmit = (userData) => {
        dispatch(updateMe(userData))
    }

    // TOATS
    useEffect(() => {
        if (auth.updateSuccess === true) {
            dispatch(openToast(auth.message, 'success'))
        }
        if (auth.updateError) {
            dispatch(openToast(auth.updateError, 'error'))
        }
    }, [auth.updateMeLoader])

    return (
        <App stackNav="Menu" back="/account" backLabel="Back">
            <Stack>
                <Surface>
                    <UserForm user={auth.me} onSubmit={onSubmit} loading={auth.loading} isMe={true} />
                </Surface>
                <Surface>
                    <UserPreferencesForm me={auth.me} />
                </Surface>
                <Surface>
                    <AccountDeletion user={auth.me} account={thisAccount} />
                </Surface>
                <Surface>
                    <Policies/>
                </Surface>
            </Stack>
        </App>
    )
}