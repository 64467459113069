import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import { getCurrentUser } from '../redux/Auth/auth.actions'

import { Role } from '../redux/Auth/auth.selectors'
import { FullAccount } from '../redux/Account/account.selectors';
import { getAccountInfo } from '../redux/Account/account.actions'

import { NoAccessPage } from '../pages/Account/NoAccessPage'
import { FullScreenLoader } from './Loaders/FullScreenLoader'
import { PlanRestrictionPage } from './Billing/PlanRestrictionPage'

import { LoginPage } from './Auth/LoginPage'

// Redux
import { FullNetwork } from '../redux/Network/network.selectors'
import { useDocumentTitle } from '../redux/Network/network.hooks';
import { useFirstPromoter } from '../modules/Integrations/FirstPromoter/FirstPromoterService';
import { useAnalytics } from '../modules/Analytics/AnalyticsService';
import { useHotJar } from '../modules/Integrations/HotJar/HotJarService';

// Loaders
import { NetworkLoader } from './Loaders/NetworkLoader'
import { ScopedVisitorLoader } from './Loaders/ScopedVisitorLoader';

// Creates a visitor token for account or network
export const PublicScopedRoute = ({ children, ...rest }) => {

    
    // Load Dependencies
    const dispatch = useDispatch();

    // Auth
    const auth = useSelector(state => state.auth)

    // Load Network 
    const fullNetwork = FullNetwork();

    // HTML Title
    useDocumentTitle(rest.title); 
    useFirstPromoter(fullNetwork);
    useAnalytics(true);
    useHotJar(fullNetwork);
    
    // Network
    if(!fullNetwork.network) return (<NetworkLoader />);

    // Visitor Token
    if(!auth.session) return (<ScopedVisitorLoader networkId={fullNetwork.network.id} />)
    
    // Show If Authenticated
    return (<Route {...rest} />)

  }