import React, { useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { FullNetwork } from '../../redux/Network/network.selectors'
import { getNetwork } from '../../redux/Network/network.actions'

// Components
import { FullScreenLoader } from './FullScreenLoader';

export const NetworkLoader = () => {

    const dispatch = useDispatch();

    // Get The Network
    const fullNetwork = FullNetwork();
    useEffect(() => { if(!fullNetwork.networkLoading && !fullNetwork.network) dispatch(getNetwork()); }, []);
    return (<FullScreenLoader />)

}