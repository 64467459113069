import { Divider } from '@mui/material';
import React, { useEffect } from 'react';
import { App } from '../../../App';
import { SmartContainer, Stack } from '../../../styles/Containers';
import { StyledB, StyledP } from '../../../styles/StyledHTML';
import { FeaturedTopics } from '../Tags/FeaturedTopics';
import { FeaturedSeries } from './FeaturedSeries';

// Space
import { SeriesList } from './SeriesList'

export const SeriesPage = () => {
    return (
        <App>
            {/* <SeriesList /> */}
            <Stack>
                <SmartContainer>
                    <StyledP><StyledB>Start A Series</StyledB></StyledP>
                    <StyledP light>Begin a multi-day meditation</StyledP>
                </SmartContainer>
                <FeaturedSeries limit={999} />
                <Divider />
                <SmartContainer>
                    <StyledP><StyledB>Browse By Topic</StyledB></StyledP>
                    <StyledP light>Find a meditation for today</StyledP>
                </SmartContainer>
                <FeaturedTopics limit={999} />
            </Stack>
        </App>
    )
}