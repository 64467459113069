import React from 'react';
import { config } from '../../config'
import { FormSelect } from '../Forms/FormSelect'

import { Role } from '../../redux/Auth/auth.selectors';

export const UserRoleSelect = (props) => {
    const currentUserRole = Role();
    
    // Role Restrictions
    let disableRole = true;
    if(currentUserRole === 'owner' || currentUserRole === 'admin') disableRole = false;
    if(currentUserRole !== 'owner' && props.value === 'owner') disableRole = true;
    if(props.isMe) disableRole = true;
    
    let availableRoles = [...config.roles]
    if(currentUserRole !== 'owner') availableRoles = availableRoles.filter(role => role !== 'owner')

    return (
        <FormSelect disabled={disableRole} label="Account Role" value={props.value} onChange={props.onChange}>
            {availableRoles.map(role => (<option key={role} value={role}>{role}</option>))}
        </FormSelect>
    )

}