import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HorizontalStack, SmartContainer, Stack, StyledFadeIn, Surface } from '../../../styles/Containers';
import { getUpNextContent } from './redux/userprogress.actions';
import { FullSeries } from '../Series/redux/series.selectors';
import { searchSeries } from '../Series/redux/series.actions';
import { FullMeditations } from '../Meditations/redux/meditations.selectors';
import { getMeditation } from '../Meditations/redux/meditations.actions';
import { StyledMediaImage } from '../../components/Display/MediaAsset';
import { StyledB, StyledH6, StyledP } from '../../../styles/StyledHTML';
import { StyledIconButton } from '../../../styles/matieralui/IconButton';
import { SpaceIconPlay } from '../../styles/Icons';
import { push } from 'connected-react-router';
import styled, { css } from 'styled-components';
import { respondTo } from '../../../styles/Theme';
import { StyledCircularProgress } from '../../../styles/matieralui/Progress';

const StyledContainer = styled(Surface)`
    display: flex;
    align-items: center;
    width: 100%;
    > *:first-child {
        width: 70px;
        margin-right: 1em;
        margin-bottom: 0 !important;
    }
    > *:last-child {
        width: 100%;
    }
    ${respondTo('mobile', css`
        display: block;
        > *:first-child {
            width: 100%;
            margin-right: 0em;
            margin-bottom: 1em !important;
        }
    `)}
`

export const UpNext = () => {

    const [meditation, setMeditation] = useState(null);
    const [sId, setSeriesId] = useState(null);
    const [series, setSeries] = useState(null);
    const dispatch = useDispatch();
    const fullSeries = FullSeries();
    const fullMeditations = FullMeditations();

    const scopedPrefix = 'upnext'
    const args = {}
    args['prefix'] = scopedPrefix; // Removing because we load full series everywhere. We can bring it back one day when we have too many series.

    const play = () => {
        dispatch(push(`/series/${sId}/play/${meditation.id}`))
    }

    const getCurrentSeries = (seriesId) => {

        if (!fullSeries?.items) return null;
        const s = fullSeries?.items?.rows?.filter(item => item?.id === seriesId);
        if (s) return s[0];
    }

    useEffect(() => {


        // Load All Series
        if (!fullSeries?.items) dispatch(searchSeries(1, 999, null, 'published'))

    }, [])

    useEffect(() => {
        if (sId) {
            const s = getCurrentSeries(sId)
            if (s) setSeries(s);
        }
    }, [sId])

    useEffect(() => {
        if (!fullSeries?.items) return;
        const loadData = async () => {
            try {
                const { meditationId, seriesId } = await getUpNextContent(fullSeries?.items);
                if (!seriesId) return;
                if (!meditationId) return;
                setSeriesId(seriesId); // To display the series name.
                if (meditationId) dispatch(getMeditation(meditationId))
            } catch (error) {

            }

        }
        loadData();
    }, [fullSeries?.items])


    useEffect(() => {
        if (fullMeditations?.activeItem?.id !== meditation?.id) setMeditation(fullMeditations?.activeItem);
    }, [fullMeditations?.activeItem])

    if (!sId && !meditation) return (
        <StyledContainer minHeight="200px" flex align="center" justify="center">
            <SmartContainer opacity={0.25} flex align="center" justify="center" minHeight="400px">
                <StyledCircularProgress />
            </SmartContainer>
        </StyledContainer>
    )

    return (
        <StyledFadeIn visible={(meditation && sId) ? true : false}>
            <Stack clickable>
                {!meditation && (<SmartContainer minHeight="200px">Take a deep breath.</SmartContainer>)}
                {meditation && (
                    <StyledContainer shadow="none" onClick={play}>
                        {meditation?.meta?.socialSquare && (<StyledMediaImage shadow="one" radius id={meditation?.meta?.socialSquare} network={true} />)}
                        <Stack>
                            {/* <Surface shadow="none"> */}
                            <HorizontalStack flex align="center" justify="space-between">
                                <SmartContainer>
                                    {/* <StyledH6>{meditation?.name}</StyledH6> */}
                                    <StyledP><StyledB>{meditation?.name}</StyledB></StyledP>
                                    {/* {series && (<StyledP light small>{series?.name}</StyledP>)} */}
                                    <StyledP light small>Up next</StyledP>
                                </SmartContainer>

                                <StyledIconButton spacify onClick={play} noShadow><SpaceIconPlay /></StyledIconButton>
                            </HorizontalStack>
                            {/* </Surface> */}
                        </Stack>
                    </StyledContainer>
                )}

            </Stack>
        </StyledFadeIn>
    )
}