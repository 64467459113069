import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { createNetwork, updateNetwork } from '../../redux/Network/network.actions';
import { FullNetwork } from '../../redux/Network/network.selectors'
import { openToast } from '../../redux/UI/ui.actions';

// Styles
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledButton } from '../../styles/matieralui/Button'

// Components
import { FormInput } from '../Forms/FormInput'
import { isEmail } from '../../services/Utils'


export const NetworkForm = ({network}) => {

    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();

    // --------------------------
    // Form Fields
    // --------------------------

    // Name
    const [name,setName] = useState('');
    const [nameError,setNameError] = useState(false);
    const [nameHelperText,setNameHelperText] = useState('The Name Of the Network')

    // Domain
    const [domain,setDomain] = useState('');
    const [domainError,setDomainError] = useState(false);
    const [domainHelperText,setDomainHelperText] = useState('The Domain. DO NOT include https://')

    // Send Grid Email
    const [email,setEmail] = useState('');
    const [emailError,setEmailError] = useState(false);
    const [emailHelperText,setEmailHelperText] = useState('The Email Connected to SendGrid')

    // Send Grid Email
    const [sendGridKey,setSendGridKey] = useState('');
    const [sendGridKeyError,setSendGridKeyError] = useState(false);
    const [sendGridKeyHelperText,setSendGridKeyHelperText] = useState('Your SendGrid API Key')

    // Stripe Secret Key
    const [stripeSecretKey,setStripeSecretKey] = useState('');
    const [stripeSecretKeyError,setStripeSecretKeyError] = useState(false);
    const [stripeSecretKeyHelperText,setStripeSecretKeyHelperText] = useState('Stripe Secret Key. This is encrypted before stored')

    // Stripe Public Key
    const [stripePublicKey,setStripePublicKey] = useState('');
    const [stripePublicKeyError,setStripePublicKeyError] = useState(false);
    const [stripePublicKeyHelperText,setStripePublicKeyHelperText] = useState('Stripe Public Key. This is encrypted before stored')

    // Stripe Webhook Secret
    const [stripeWebhookSecret,setStripeWebhookSecret] = useState('');
    const [stripeWebhookSecretError,setStripeWebhookSecretError] = useState(false);
    const [stripeWebhookSecretHelperText,setStripeWebhookSecretHelperText] = useState('Stripe Webhook Secret. This is encrypted before stored')

    // --------------------------
    // Effects
    // --------------------------

    // Set Default Values For Editing
    useEffect(() => {

        const defaultName = (network?.name) ? setName(network.name) : setName('');
        const defaultDefault = (network?.domain) ? setDomain(network.domain) : setDomain('');
        const defaultEmail = (network?.email) ? setEmail(network.email) : setEmail('');

        const defaultSendGridKey = (network?.sendGridKey) ? setSendGridKey(network.sendGridKey) : setSendGridKey('');
        const defaultStripeSecretKey = (network?.stripeSecretKey) ? setStripeSecretKey(network.stripeSecretKey) : setStripeSecretKey('');
        const defaultStripePublicKey = (network?.stripePublicKey) ? setStripePublicKey(network.stripePublicKey) : setStripePublicKey('');
        const defaultStripeWebhookSecret = (network?.stripeWebhookSecret) ? setStripeWebhookSecret(network.stripeWebhookSecret) : setStripeWebhookSecret('');

    },[network])

    // On Result
    useEffect(() => {
        if(fullNetwork.createNetworkSuccess){
            dispatch(openToast(fullNetwork.createNetworkSuccess,'success'))
        }
        if(fullNetwork.createNetworkError) {
            dispatch(openToast(fullNetwork.createNetworkError,'error'))
        }
    },[fullNetwork.createNetworkLoading])

    // Update Result
    useEffect(() => {
        if(fullNetwork.updateNetworkSuccess){
            dispatch(openToast(fullNetwork.updateNetworkSuccess,'success'))
        }
        if(fullNetwork.updateNetworkError) {
            dispatch(openToast(fullNetwork.updateNetworkError,'error'))
        }
    },[fullNetwork.updateNetworkLoading])


    // --------------------------
    // Interactions
    // --------------------------

    const handleSubmit = (e) => {

        const args = {
            name,
            domain,
            email,
            sendGridKey,
            stripeSecretKey,
            stripePublicKey,
            stripeWebhookSecret
        }

        if(network) {
            dispatch(updateNetwork(network.id, args));
        } else {
            dispatch(createNetwork(args));
        }

        
    }
    const handleEmailChange = (value) => {
        if(isEmail(value)) setEmailError('')
        else setEmailError('Please enter a valid email')
        setEmail(value)
    }

    


    // Validate
    const disableSubmit = (!name || !domain || !isEmail(email));

    // Button Text
    const buttonText = (network) ? 'Update Network' : 'Add Network'

    return (
        <SmartContainer thinking={fullNetwork?.createNetworkLoading || fullNetwork?.updateNetworkLoading} minWidth="300px">
            <Stack>
                <FormInput label="Name" type="text" onChange={setName} value={name} helperText={nameHelperText} error={nameError} />
                <FormInput label="Domain" type="text" onChange={setDomain} value={domain} helperText={domainHelperText} error={domainError} />
                <FormInput label="Send Grid Email" type="email" onChange={handleEmailChange} value={email} helperText={emailHelperText} error={emailError} />
                <FormInput label="Send Grid API KEY" type="text" onChange={setSendGridKey} value={sendGridKey} helperText={sendGridKeyHelperText} error={sendGridKeyError} />
                <FormInput label="Stripe Secret Key" type="text" onChange={setStripeSecretKey} value={stripeSecretKey} helperText={stripeSecretKeyHelperText} error={stripeSecretKeyError} />
                <FormInput label="Stripe Public Key" type="text" onChange={setStripePublicKey} value={stripePublicKey} helperText={stripePublicKeyHelperText} error={stripePublicKeyError} />
                <FormInput label="Stripe Webhook Secret" type="text" onChange={setStripeWebhookSecret} value={stripeWebhookSecret} helperText={stripeWebhookSecretHelperText} error={stripeWebhookSecretError} />
                <StyledButton onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>{buttonText}</StyledButton>
            </Stack>
        </SmartContainer>
    )
}