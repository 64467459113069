import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { App } from '../../App';
import { FullAccount } from '../../redux/Account/account.selectors';
import { SpaceDashboard } from '../../space/components/Dashboard/SpaceDashboard';

import { Surface, StyledGridByFour, StyledGridItem } from '../../styles/Containers'
import { StyledH5, StyledP } from '../../styles/StyledHTML'

export const DashboardPage = () => {

    const dispatch = useDispatch();
    const fullAccount = FullAccount();

    return (
        <App>

            <SpaceDashboard />
        </App>
    )
}