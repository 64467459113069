import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'

// Components
import { App } from '../../../App';
import { NewContent } from '../../components/Content/NewContent';

// Styles
import { SmartContainer } from '../../../styles/Containers'
import { TagsList } from './TagsList';

// Redux
import { restoreTag, createTag } from './redux/tags.actions';
import { FullTags } from './redux/tags.selectors';
import { RouteBreadCrumbs } from '../../../routes';


export const PageNetworkTags = () => {

    // New Modal
    const dispatch = useDispatch();
    const store = FullTags();

    // --------------------------
    // Effects
    // --------------------------

    useEffect(() => { if (store.createSuccess) dispatch(push(`/network/tags/${store.create.id}`)) }, [store?.createLoading])

    // --------------------------
    // Interactions
    // --------------------------

    // On Select
    const handleSelect = (item) => {

        if (item?.full?.deletedAt) dispatch(restoreTag(item.id))
        else dispatch(push(`/network/tags/${item.id}`))
    }

    // --------------------------
    // Renders
    // --------------------------
    const renderTopBarChildren = () => {

        return (
            <SmartContainer flex align="center" justify="flex-end">
                <Link to="/network/tags">Tags</Link>
                <NewContent label="New Tags" selector={FullTags} createAction={createTag} />
            </SmartContainer>
        )

    }

    // Render Page
    return (
        <App title="Tags" topBarChildren={renderTopBarChildren()} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            <TagsList onSelect={handleSelect} />

        </App>
    )
}