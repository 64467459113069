import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { SmartContainer } from '../../styles/Containers'
import { StyledA, StyledP } from '../../styles/StyledHTML';
import { StyledAreYouSure } from '../../styles/matieralui/Dialog'
import { openToast } from '../../redux/UI/ui.actions';
export const MoveToTrash = ({id, action, selector, route}) => {

    const dispatch = useDispatch();
    const state = selector();
    
    // Are You Sure
    const [showAreYouSure, setShowAreYouSure] = useState(false);
    const onAreYouSure = (e) => {
        if(!e) setShowAreYouSure(false)
        else {
            setShowAreYouSure(false)
            dispatch(action(id));
        }
    }

    // Delete Item
    useEffect(() => {
        if(state.deleteSuccess) {
            
            // Display Success
            dispatch(openToast(state.deleteSuccess,'success'));

            // Redirect to List
            dispatch(push(route))
        }
        if(state.deleteError) dispatch(openToast(state.deleteError,'error'))
    },[state.deleteLoading, state.deleteError,state.deleteSuccess,dispatch,route])

    return (
        <SmartContainer>
            {id && (<StyledP><StyledA light small onClick={() => setShowAreYouSure(true)}>move to trash</StyledA></StyledP>)}
            <StyledAreYouSure loading={false} open={showAreYouSure} onClose={onAreYouSure} label="Are you sure?" description="This will delete this item." agreeText="Yes, delete this."/>
        </SmartContainer>
    )
}