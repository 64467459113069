import React, { useEffect, useState } from 'react';
import { HorizontalStack, SmartContainer, Stack } from '../../styles/Containers';
import { StyledAreYouSureButton, StyledButton } from '../../styles/matieralui/Button';
import { StyledModal } from '../../styles/matieralui/Modal';
import { StyledA, StyledP } from '../../styles/StyledHTML';
import { FrameModalButton } from './iFrameButton';
export const Policies = ({ user, account }) => {
    return (
        <Stack >
            <SmartContainer>
                <StyledP>Space Culture Policies</StyledP>
            </SmartContainer>
            <HorizontalStack flex>
                <FrameModalButton text="Privacy Policy" url="https://www.spaceculture.co/privacy/" />
                <FrameModalButton text="Terms & Conditions" url="https://www.spaceculture.co/terms/" />
            </HorizontalStack>
        </Stack>
    )
}