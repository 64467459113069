import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { PaginatedList } from './PaginatedList'

// Styled
import { SmartContainer, Stack } from '../../styles/Containers'
import { StyledAreYouSure } from '../../styles/matieralui/Dialog'
import { StyledA, StyledHr } from '../../styles/StyledHTML';
import { StyledAreYouSureButton } from '../../styles/matieralui/Button';

// Redux
import { openToast } from '../../redux/UI/ui.actions';

// ----------------------------
// Model List Component
// ----------------------------

export const ModelList = ({
    model,
    defaultLimit = 10,
    defaultView = 'all',
    action,
    selector,
    onSelect,
    restore,
    customRenderer,
    variant,
    paginationControls,
    filters,
    columns,
    forceDelete, // action
    noControls = false
}) => {

    const dispatch = useDispatch();

    const [view, setView] = useState(defaultView);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(defaultLimit);
    const [searchText, setSearchText] = useState('');
    const [showAreYouSureRestore, setShowAreYouSureRestore] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    // Generic Selector
    const data = selector();

    // Load Post With Pagination and Search
    const loadPosts = useCallback(
        (pageNumber, searchString, currentView = null, args = {}) => dispatch(action(pageNumber, limit, searchString, currentView, args)
        ), [dispatch, action, limit])


    // --------------------------
    // Effects
    // --------------------------

    // On Load: Load posts
    // useEffect(() => { loadPosts(page,searchText) },[])

    // Active vs Trashed
    useEffect(() => {
        setPage(1);
        setSearchText('');
        setLimit(defaultLimit);
        loadPosts(1, '', view)
    }, [view, defaultLimit, loadPosts])

    // Apply External Filters
    useEffect(() => {
        if (!filters) return;
        // Load Items with filters applied
        loadPosts(1, searchText, view, filters)

    }, [filters, searchText, view, loadPosts])

    // Restore
    useEffect(() => {
        if (data.restoreSuccess) {

            // Display Success
            dispatch(openToast(data.restoreSuccess, 'success'));

            // Reload Active.
            setView('all');
        }
        if (data.restoreError) dispatch(openToast(data.restoreError, 'error'))
    }, [data.restoreLoading, data.restoreSuccess, data.restoreError, dispatch])

    // Force Delete
    useEffect(() => {

        if (data.forceDeleteSuccess) {

            // Display Success
            dispatch(openToast(data.forceDeleteSuccess, 'success'));

            // Reload Active.
            setView('all');
        }
        if (data.forceDeleteError) dispatch(openToast(data.forceDeleteError, 'error'))
    }, [data.forceDeleteLoading, data.forceDeleteSuccess, data.forceDeleteError, dispatch])


    // --------------------------
    // Interactions
    // --------------------------

    // Handle Item Click
    const handleItemClick = (item) => {

        if (view === 'trashed') {
            setShowAreYouSureRestore(true);
            setSelectedItem(item);
            return;
        }

        onSelect(item);
    }

    // Handle Pagination Click
    const handlePaginationClick = (pageNumber) => {
        setPage(pageNumber)
        loadPosts(pageNumber, searchText)
    }

    // Handle Search
    const handleSearch = (searchString) => {
        setPage(1)
        loadPosts(1, searchText)
    }

    const onAreYouSureRestore = (e) => {
        if (!e) setShowAreYouSureRestore(false)
        else {
            setShowAreYouSureRestore(false)
            dispatch(restore(selectedItem.id));
        }
    }

    const handleForceDelete = (e) => {
        setShowAreYouSureRestore(false)
        dispatch(forceDelete(selectedItem.id));
    }

    // --------------------------
    // Pagination Formating
    // --------------------------

    const formatted = (data.items) ? data.items.rows.map(item => { return { id: item.id, title: item.name, description: item.status, full: item } }) : []
    const total = (data.items) ? data?.items?.count : 0;
    const pageCount = Math.ceil(total / limit);

    const renderView = () => {
        if (defaultView !== 'all') return null;
        if (view === 'all') return (<StyledA small light onClick={() => setView('trashed')}>View Trashed</StyledA>)
        return (<StyledA small light onClick={() => setView('all')}>View All</StyledA>)
    }

    return (
        <SmartContainer thinking={data?.itemsLoading || data?.restoreLoading || data?.forceDeleteLoading}>



            <PaginatedList
                onClickItem={handleItemClick}
                loading={data?.itemsLoading || data?.restoreLoading}
                items={formatted}
                total={total}
                pages={pageCount}
                currentPage={page}
                onPaginationClick={handlePaginationClick}
                searchText={searchText}
                onSearchChange={(value) => setSearchText(value)}
                onSearchSubmit={handleSearch}
                customRenderer={customRenderer}
                variant={variant}
                paginationControls={paginationControls}
                columns={columns}
                noControls={noControls}
            />
            <SmartContainer flex justify="flex-start">{renderView()}</SmartContainer>

            <StyledAreYouSure
                loading={false}
                open={showAreYouSureRestore}
                onClose={onAreYouSureRestore}
                label="Are you sure?"
                description="This will restore this item."
                agreeText="Yes, restore this."
            >
                {forceDelete && (
                    <Stack>
                        <StyledHr />
                        <StyledAreYouSureButton variant="outlined" width="100%" onClick={handleForceDelete}>Delete Permenantly</StyledAreYouSureButton>
                    </Stack>

                )}
            </StyledAreYouSure>

        </SmartContainer>
    )
}