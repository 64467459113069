import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";

// Components
import { App } from "../../../App";
import { FullScreenLoader } from "../../../components/Loaders/FullScreenLoader";
import { respondTo } from "../../../styles/Theme";

// Styles
import {
  SmartContainer,
  Stack,
  StyledGrid,
  StyledGridItem,
  HorizontalStack,
  Surface,
} from "../../../styles/Containers";
import { StyledButton } from "../../../styles/matieralui/Button";

// Redux
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import "./pulsatingCircle.css";
import { StyledH3, StyledP } from "../../../styles/StyledHTML";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { makeStyles } from "@mui/styles";
import { FormElement } from "../../components/Forms/FormElement";
import { FormSelect } from "../../../components/Forms/FormSelect";
import { Screen } from "../../components/Display/Screen";
import { FullBreathing } from "./redux/breathing.selectors";
import { searchBreathing } from "./redux/breathing.actions";
import { SpaceIconClose } from "../../styles/Icons";
import { StyledBottomDrawer } from "../../../styles/matieralui/Drawer";


const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    // position: absolute;
    // bottom: 0;
    // width: 100vw !important;
    // height: 100vh;
    // justifycontent: space-evenly;
  }
  & .MuiDialog-paper {
    justifycontent: space-evenly;
  }
`;

const StyledCloseButton = styled(SmartContainer)`
 text-align: right;
`;

const StyledWrap = styled(SmartContainer)`

  height: 100vh;
 
`

const StyledPlayWrap = styled(SmartContainer)`
  width: 100%;
  position: relative;
`
const StyledPlayButton = styled(SmartContainer)`
  height: 100px;
  width: 100px;
  background-image: linear-gradient(rgb(135, 225, 228), rgb(122, 188, 255));
  border-radius: 50%;
  display: flex;
`

const StyledPulse = styled(SmartContainer)`
  height: 120px;
  width: 120px;
  border: 5px solid #6f3826;
  border-radius: 70px;
  animation: playButton 5.5s ease-out infinite;
  opacity: 0;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: -1;
`

export const PageBreathingSelect = (props) => {
  const store = FullBreathing();
  const screenAlign = "space-between";
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const [time, setTime] = useState();
  const [allExercises, setAllExercises] = useState({});
  const [action, setAction] = useState();

  // Generate List of Minutes
  const arrayValue = [...new Array(20)].map((each, index) => ({ label: index + 1, value: index + 1 }));

  const maxValue = arrayValue.map((value) => value.value);

  const setScreen = () => {
    props.screen("loading");
  };

  useEffect(() => {
    props.time(time);
    props.exercise(action);
  });

  useEffect(() => {
    // Load All Series
    if (!store?.items) dispatch(searchBreathing(1, 999, null, null));
    if (store?.items) setAllExercises(store.items.rows);
  }, [store?.items]);

  // Render Page
  return (
    <StyledWrap data-styled-wrap align="center" justify="center" flex>
      <StyledPlayWrap width="100%" clickable>
        <StyledPlayButton>
          <PlayArrowRoundedIcon onClick={openDialog} style={{ color: "white", fontSize: "100px" }} />
        </StyledPlayButton>
        <StyledPulse />
      </StyledPlayWrap>
      <StyledBottomDrawer open={open} anchor="bottom">
        <DialogContent>

          <Stack>
            <StyledCloseButton>
              <SpaceIconClose onClick={closeDialog} />
            </StyledCloseButton>

            <FormSelect label="Pick A Time" value={time} onChange={(e) => setTime(e)}>
              <option key={null} value={null}>
                Please select a time
              </option>
              {[1, 2, 3].map((value) => (<option key={value} value={value}>{value} Minutes</option>))}
            </FormSelect>


            <FormSelect
              label="Select A Topic"
              value={action}
              onChange={(e) => {
                setAction(e);
              }}
            >
              <option key={null} value={null}>
                Please select an Exercise
              </option>
              {Object.values(allExercises).map((value) => (
                <option key={value?.id} value={value?.id}>
                  {value.name}
                </option>
              ))}
              ;
            </FormSelect>


            {action && time && (
              <StyledButton onClick={setScreen} variant="contained" fullWidth>
                Get Started
              </StyledButton>
            )}
          </Stack>
        </DialogContent>
      </StyledBottomDrawer>

    </StyledWrap>
  );
};
