import React from 'react';
import { config } from '../../config'
import { FormSelect } from '../Forms/FormSelect'

import { NetworkRole } from '../../redux/Auth/auth.selectors';
import { SmartContainer } from '../../styles/Containers';

export const NetworkRoleSelect = (props) => {
    const currentUserNetworkRole = NetworkRole(props.networkId);
    
        
    // A Network Admin can't change a Super Admins Role
    if(props.value === 'superadmin' && currentUserNetworkRole !== 'superadmin') {
        return (
            <SmartContainer>You do not have permission to edit this user.</SmartContainer>
        )
    }
    
    let availableRoles = [...config.networkRoles]
    if(currentUserNetworkRole !== 'superadmin') availableRoles = availableRoles.filter(role => role !== 'superadmin')

    return (
        <FormSelect label="Network Role" value={props.value} onChange={props.onChange}>
            {availableRoles.map(role => (<option key={role} value={role}>{role}</option>))}
        </FormSelect>
    )

}