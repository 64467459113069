import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import { App } from '../../App';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader';
import { FormSelect } from '../../components/Forms/FormSelect';
import { convertCentsToPrice } from '../../services/Stripe';
import { BuyNowButton } from '../../components/Billing/BuyNowButton';

// Styles
import { StyledStyledSideBarContainer, Stack, Surface, StyledRichTextContent, StyledImagePlaceholder } from '../../styles/Containers'

// Redux
import { getNetworkProduct } from './redux/networkproduct.actions';
import { FullNetworkProducts } from './redux/networkproduct.selectors';
import { RouteBreadCrumbs } from '../../routes';

export const ViewNetworkProductPage = () => {

    // New Modal
    const dispatch = useDispatch();
    const fullNetworkProducts = FullNetworkProducts();
    const { id } = useParams();
    const thisId = parseInt(id)
    const [selectedPrice, setSelectedPrice] = useState(null)

    // --------------------------
    // Effects
    // --------------------------

    // Load Product
    useEffect(() => { dispatch(getNetworkProduct(thisId)) },[dispatch,thisId])

    // Set Default Values
    useEffect(() => {
        if(fullNetworkProducts?.activeItem?.prices) setSelectedPrice(fullNetworkProducts?.activeItem?.prices[0].priceId)
    },[fullNetworkProducts.activeItem,fullNetworkProducts])

    // --------------------------
    // Renders
    // --------------------------


    // Render Page
    return (
        <App title={fullNetworkProducts?.activeItem?.name} breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.billing]}>
            
            {fullNetworkProducts?.activeItemLoading && (<FullScreenLoader />)}
            {!fullNetworkProducts?.activeItemLoading &&(
                <StyledStyledSideBarContainer>
                    <Surface>
                        <Stack>
                            <StyledImagePlaceholder minHeight="150px" />
                            <StyledRichTextContent dangerouslySetInnerHTML={{__html:fullNetworkProducts?.activeItem?.meta?.description}}/>         
                        </Stack>
                    </Surface>
                    <Stack>
                        {fullNetworkProducts?.activeItem?.prices && (
                            <FormSelect label="Options" value={selectedPrice} onChange={(value)=>setSelectedPrice(value)}>
                                <option key="none" disabled>Select A Price</option>
                                {fullNetworkProducts?.activeItem?.prices.map((price,index) => (<option key={index} value={price.priceId}>{price.nickname} ${convertCentsToPrice(price.price)}</option>))}
                            </FormSelect>
                        )}
                        <BuyNowButton fullWidth priceId={selectedPrice} successUrl={`billing/products/${thisId}/success`} cancelUrl={`billing/products/${thisId}`}>Buy Now</BuyNowButton>
                        {/* <StyledButton variant="outlined" color="primary" fullWidth>Add To Cart</StyledButton> */}
                    </Stack> 
                </StyledStyledSideBarContainer>
            )}
        </App>
    )
}