export default (state = {}, action) => {

    switch (action.type) {
        case '[NETWORK] Wipe':
            return {};
        case '[NETWORK] Get Network':
        case '[NETWORK] Get Network Failed':
        case '[NETWORK] Get Network Success':
        case '[NETWORK] Get Network Clear':
        case '[NETWORK] Get Networks':
        case '[NETWORK] Get Networks Failed':
        case '[NETWORK] Get Networks Success':
        case '[NETWORK] Get Networks Clear':
        case '[NETWORK] Create Network':
        case '[NETWORK] Create Network Failed':
        case '[NETWORK] Create Network Clear':
        case '[NETWORK] Get Full Network':
        case '[NETWORK] Get Full Network Failed':
        case '[NETWORK] Get Full Network Success':
        case '[NETWORK] Get Full Network Clear':
        case '[NETWORK] Update Network':
        case '[NETWORK] Update Network Failed':
        case '[NETWORK] Update Network Success':
        case '[NETWORK] Update Network Clear':
        case '[NETWORK] Get Network Accounts':
        case '[NETWORK] Get Network Accounts Failed':
        case '[NETWORK] Get Network Accounts Success':
        case '[NETWORK] Get Network Accounts Clear':
        case '[NETWORK] Get Network Users':
        case '[NETWORK] Get Network Users Failed':
        case '[NETWORK] Get Network Users Success':
        case '[NETWORK] Get Network Users Clear':
        case '[NETWORK] Get User':
        case '[NETWORK] Get User Failed':
        case '[NETWORK] Get User Success':
        case '[NETWORK] Get User Clear':
        case '[NETWORK] Get Account':
        case '[NETWORK] Get Account Failed':
        case '[NETWORK] Get Account Success':
        case '[NETWORK] Get Account Clear':
        case '[NETWORK] Update User':
        case '[NETWORK] Update User Failed':
        case '[NETWORK] Update User Success':
        case '[NETWORK] Update User Clear':
        case '[NETWORK] Update Account':
        case '[NETWORK] Update Account Failed':
        case '[NETWORK] Update Account Success':
        case '[NETWORK] Update Account Clear':
        case '[NETWORK] Get Network Team':
        case '[NETWORK] Get Network Team Failed':
        case '[NETWORK] Get Network Team Success':
        case '[NETWORK] Get Network Team Clear':
        case '[NETWORK] New Network Admin':
        case '[NETWORK] New Network Admin Failed':
        case '[NETWORK] New Network Admin Clear':
        case '[NETWORK] Update Network Admin':
        case '[NETWORK] Update Network Admin Failed':
        case '[NETWORK] Update Network Admin Clear':
        case '[NETWORK] Remove Network Admin':
        case '[NETWORK] Remove Network Admin Failed':
        case '[NETWORK] Remove Network Admin Clear':
            const newState = Object.assign({},state,{...action.payload})
            return newState;
        case '[NETWORK] Create Network Success':
            const newNetworkState = Object.assign({},state,{...action.payload})
            newNetworkState.networks.items.push(action.payload.createNetwork.network)
            newNetworkState.networks.count += 1;
            return newNetworkState;

        case '[NETWORK] New Network Admin Success':
            const newNetworkAdminState = Object.assign({},state,{...action.payload})
            newNetworkAdminState.team.push(action.payload.newAdmin.admin)
            return newNetworkAdminState;
        
        case '[NETWORK] Update Network Admin Success':
            const updateNetworkAdminState = Object.assign({},state,{...action.payload})
            const team = updateNetworkAdminState.team.map(user => {
                if(user.id === action.payload.updateAdmin.admin.id) {
                    return action.payload.updateAdmin.admin;
                }
                return user;
            })
            // Replace the iteam
            updateNetworkAdminState.team = [...team];
            return updateNetworkAdminState;
        
        case '[NETWORK] Remove Network Admin Success':
            const deleteNetworkAdminState = Object.assign({},state,{...action.payload})
            const newTeam = deleteNetworkAdminState.team.filter(user => user.id !== action.payload.removeAdmin.admin.id)
            // Replace the team
            deleteNetworkAdminState.team = [...newTeam];
            return deleteNetworkAdminState; 
        default:
            return state
    }
}
