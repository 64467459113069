import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { SmartContainer } from '../../../styles/Containers';
import { searchSeries } from '../../modules/Series/redux/series.actions';
import { FullSeries } from './redux/series.selectors';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { SeriesCard } from './SeriesCard';
import { HorizontalScroller } from '../../components/Display/HorizontalScroller';
import { StyledIconButton } from '../../../styles/matieralui/IconButton';
import { SpaceIconArrowForward } from '../../styles/Icons';

export const FeaturedSeries = ({ limit = 999 }) => {
    const dispatch = useDispatch();
    const fullSeries = FullSeries();
    const goTo = (path) => dispatch(push(path));

    const handleSelect = (item) => {
        dispatch(push(`/series/${item.id}`))
    }

    useEffect(() => {

        // Load All Series
        if (!fullSeries?.items) dispatch(searchSeries(1, limit, null, 'published'))

    }, [])

    const renderSeries = () => {
        if (!fullSeries?.items) return (<div>Breathe...</div>)
        return fullSeries?.items?.rows.map(item => (<SeriesCard key={item?.id} item={item} onClick={(s) => handleSelect(s)} />));
    }

    const width = (fullSeries?.items) ? `${fullSeries?.items.count * 300}px` : '100%';

    return (
        <HorizontalScroller width={width}>
            {renderSeries()}
            {/* <SmartContainer forceMargin="3.5em 1.5em 0em 0em">
                <StyledIconButton spacify onClick={() => goTo('/series')}>
                    <SpaceIconArrowForward />
                </StyledIconButton>
            </SmartContainer> */}
        </HorizontalScroller>
    )
}