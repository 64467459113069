import React, { useState } from 'react';

// Components
import { App } from '../../App';
import { Surface, Stack } from '../../styles/Containers'

import { Repeater } from '../../components/Forms/Repeater'
import { FormInput } from '../../components/Forms/FormInput';

const TestComponent = ({item,onChange}) => {
    return (
        <Surface width="100%">
            <p>{item.id}</p>
            <Stack>
                <FormInput label="Name" value={item.name} onChange={(value) => onChange(item,'name',value)} />
                <FormInput label="URL" value={item.url} onChange={(value) => onChange(item,'url',value)} />
            </Stack>
        </Surface>
    )
}

export const RepeaterLab = () => {

    // Sample Data
    const datasource = [
        {
            name: "Hello World",
            url: "https://hello.com",
            id: "one"
        },
        {
            name: "The Church Co",
            url: "https://thechurchco.com",
            id: "two"
        },
        {
            name: "BoxStreet",
            url: "https://boxstreet.com",
            id: "three"
        }
    ]

    const [data,setData] = useState(datasource)

    // ---------------------------
    // Handlers
    // ---------------------------

    const repeaterOnChange = (updatedData) => {
        setData([...updatedData]);
    }


    return (
        <App title="Lab: Repeater">
            <Repeater
                id="my-custom-repeater"
                datasource={data}
                component={TestComponent}
                onChange={repeaterOnChange}
                limit={5}
                />
        </App>
    )
}