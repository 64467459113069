import React, { useState, useEffect }  from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

// Components
import { StyledButton } from '../../styles/matieralui/Button';
import { Stack } from '../../styles/Containers'

// Redux
import { FullBilling } from '../../redux/Billing/billing.selectors'
import { openToast } from '../../redux/UI/ui.actions';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { createCheckoutSessionPurchase } from '../../redux/Billing/billing.actions'

export const BuyNowButton = ({priceId, successUrl, cancelUrl, mode = 'payment', ...props}) => {

    const disable = (!priceId) ? true : false;
    const dispatch = useDispatch();
    const fullBilling = FullBilling();
    const [stripeLoading, setStripeLoading] = useState(false);
    const currentNetwork = CurrentNetwork();
    const { billing } = currentNetwork;

    // -------------------------
    // Effects
    // -------------------------

    // Listen For Session Changes
    useEffect(() => {
        if(fullBilling.purchaseCheckoutSessionSuccess) redirectToCheckout(fullBilling.purchaseCheckoutSession.id)
        if(fullBilling.purchaseCheckoutSessionError) dispatch(openToast(fullBilling.purchaseCheckoutSessionError,'error'))
    },[fullBilling.purchaseCheckoutSessionLoading,fullBilling.purchaseCheckoutSessionSuccess,fullBilling.purchaseCheckoutSessionError])

    


    // -------------------------
    // Methods
    // -------------------------

    // Dispatch: Create Stripe Session
    const sendToStripe = () => {
        const lineItems = [
            {
              price: priceId,
              quantity: 1,
            },
        ]

        const args = {
            line_items: lineItems,
            mode: mode,
            success_url: `https://${currentNetwork.domain}/${successUrl}`,
            cancel_url: `https://${currentNetwork.domain}/${cancelUrl}`,
            billing_address_collection: 'required',
        }
        dispatch(createCheckoutSessionPurchase(currentNetwork.id, args))

    }

    // Redirect to Checkout
    const redirectToCheckout = async (sessionId) => {

        setStripeLoading(true)
        const stripePromise = loadStripe(currentNetwork.stripePublicKey);
    
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({sessionId: sessionId});
    
        if(result.error){
            dispatch(openToast(result.error.message,'error'))
            setStripeLoading(false)
        }
    }

    // -------------------------
    // Render
    // -------------------------

    return (
        <Stack>
            <StyledButton 
                disabled={disable || fullBilling.purchaseCheckoutSessionLoading || stripeLoading} 
                variant="contained" 
                color="primary"
                onClick={sendToStripe}
                {...props}
                >{props.children}</StyledButton>
        </Stack>
    )
}