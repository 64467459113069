import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'


// Components
import { NetworkApp } from '../../components/Network/NetworkApp';
import { FullScreenLoader } from '../../components/Loaders/FullScreenLoader'
import { ModelList } from '../../components/PaginatedList/ModelList';
import { OrderStatusSelector } from './OrderStatusSelector';

// Styles
import { SmartContainer, Stack, Surface } from '../../styles/Containers';

// Redux
import { searchNetworkOrders, restoreNetworkOrder } from './redux/networkorder.actions';
import { FullNetworkOrders } from './redux/networkorder.selectors';
import { FullAccount } from '../../redux/Account/account.selectors';
import { CurrentNetwork } from '../../redux/Network/network.selectors'
import { NetworkOrderCard } from './NetworkOrderCard';
import { RouteBreadCrumbs } from '../../routes';
import { StyledH3, StyledH6 } from '../../styles/StyledHTML';
import { OrderSummaries } from './OrderSummaries';


export const NetworkOrdersPage = () => {

    const dispatch = useDispatch();
    const [extraFilters, setExtraFilters] = useState(null);
    const [orderStatus, setOrderStatus] = useState('all');
    const fullAccount = FullAccount();
    const currentNetwork = CurrentNetwork();
    const { billing } = currentNetwork;
    const goTo = (path) => dispatch(push(path))
    const handleSelect = (item) => dispatch(push('/network/orders/' + item.id))

    // Loader
    if (fullAccount.accountLoading) return (<FullScreenLoader />)

    // Filter on Media
    const handleStatusChange = (status) => {
        setOrderStatus(status);
        const filters = { ...extraFilters }
        if (status !== 'all') filters.orderStatus = `["${status}"]`;
        else delete filters.orderStatus;
        setExtraFilters(filters)
    }

    // Custom Controls
    const renderCustomControls = () => {

        return (
            <SmartContainer>
                <OrderStatusSelector withAll={true} value={orderStatus} onChange={handleStatusChange} />
            </SmartContainer>
        )
    }


    return (
        <NetworkApp title="Network Orders" breadcrumbs={[RouteBreadCrumbs.dashboard, RouteBreadCrumbs.network]}>

            <Stack>


                <Surface>
                    <Stack>
                        <StyledH6>Order Summaries</StyledH6>
                        <OrderSummaries />
                    </Stack>
                </Surface>


                <ModelList
                    model="NetworkOrders"
                    action={searchNetworkOrders}
                    selector={FullNetworkOrders}
                    restore={restoreNetworkOrder}
                    onSelect={handleSelect}
                    variant="cards"
                    defaultLimit="12"
                    customRenderer={renderCards}
                    paginationControls={renderCustomControls}
                    filters={extraFilters}
                />

            </Stack>

        </NetworkApp>
    )
}

const renderCards = (item, onClick) => {

    // Guard
    if (!item.full) return (<Surface key={item.id} width="100%">Loading...</Surface>)

    // Product
    return (<NetworkOrderCard key={item.id} order={item.full} onClick={onClick} />)

}