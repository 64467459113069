import { duplicateModel, searchModels, createModel, updateModel, deleteModel, restoreModel, getModel, replaceTags, getRevisions } from '../../../../redux/DefaultModelActions';
import { APIGet, APIBase } from '../../../../services/API';

const model = 'NotePrompt';
const route = '/v1/noteprompt';

export const searchNotePrompts = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);
export const createNotePrompt = (args) => createModel(model, route, args);
export const updateNotePrompt = (id, args) => updateModel(model, route, id, args);
export const deleteNotePrompt = (id) => deleteModel(model, route, id);
export const restoreNotePrompt = (id) => restoreModel(model, route, id);
export const getNotePrompt = (id) => getModel(model, route, id);
export const getNotePromptRevision = (id) => getRevisions(model, route, id);
export const duplicateNotePrompt = (id) => duplicateModel(model, route, id);


export const loadGuide = async (id) => {

    try {
        const res = await APIGet(`${APIBase}${route}/${id}`)
        const json = await res.json();
        return json;
    } catch (error) {
        return error;
    }

}