import React, { useState, useEffect } from 'react';
import { SmartContainer } from '../../../styles/Containers';
import { StyledP } from '../../../styles/StyledHTML';
import { loadGuide } from './redux/guides.actions';

export const GuideCard = ({ id }) => {

    const [guide, setGuide] = useState(null);
    const [loading, setLoading] = useState(true);


    const load = async (id) => {
        if (!id) return;
        setLoading(true);
        const res = await loadGuide(id);
        if (res.status === 'success') {
            setGuide(res.data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (guide && guide.id === id) return;
        load(id); // Load the Guide
    }, [id])

    return (
        <SmartContainer thinking={loading}>
            {guide && (<StyledP>{guide?.name}</StyledP>)}
        </SmartContainer>
    )
}