import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { SmartContainer } from '../../../styles/Containers';
import { searchTags } from './redux/tags.actions';
import { FullTags } from './redux/tags.selectors';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { SeriesCard } from '../Series/SeriesCard';
import { HorizontalScroller } from '../../components/Display/HorizontalScroller';


export const FeaturedTopics = ({ limit = 999 }) => {
    const dispatch = useDispatch();
    const store = FullTags();
    const goTo = (path) => dispatch(push(path));

    const handleSelect = (item) => {
        dispatch(push(`/topic/${item.id}`))
    }

    useEffect(() => {

        // Load All Series
        if (!store?.items) dispatch(searchTags(1, limit, null, null))

    }, [])

    const renderSeries = () => {
        if (!store?.items) return (<div>NOTHING</div>)
        return store?.items?.rows.map(item => (<SeriesCard key={item?.id} item={item} onClick={(s) => handleSelect(s)} />));
    }

    const width = (store?.items) ? `${store?.items.count * 300}px` : '100%';

    return (
        <HorizontalScroller width={width}>
            {renderSeries()}
        </HorizontalScroller>
    )
}