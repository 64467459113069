import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'
import { NetworkApp } from './NetworkApp';
import { FullNetwork } from '../../redux/Network/network.selectors';
import { NetworkRole } from '../../redux/Auth/auth.selectors'
import { SmartContainer, Surface, StyledGridByFour, StyledGridItem } from '../../styles/Containers'
import { StyledB, StyledH5, StyledP } from '../../styles/StyledHTML'
import { RouteBreadCrumbs } from '../../routes';

export const NetworkDashboard = () => {

    const dispatch = useDispatch();
    const fullNetwork = FullNetwork();
    const currentNetworkRole = NetworkRole(fullNetwork.network?.id);
    const goTo = (path) => dispatch(push(path))

    return (
        <NetworkApp title="Dashboard" breadcrumbs={[RouteBreadCrumbs.dashboard]}>

            <StyledGridByFour>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/noteprompts/')}>
                        <StyledB>Journal Prompts</StyledB>
                        <StyledP light>To help get you started</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/meditations/')}>
                        <StyledB>Meditations</StyledB>
                        <StyledP light>Episodes</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/series/')}>
                        <StyledB>Series</StyledB>
                        <StyledP light>List of Series</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/guides/')}>
                        <StyledB>Guides</StyledB>
                        <StyledP light>List of Voices</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/breathing/')}>
                        <StyledB>Breathing</StyledB>
                        <StyledP light>List of Breathing Exercises</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/media/')}>
                        <StyledB>Media</StyledB>
                        <StyledP light>Photos and Assets</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo(`/network/${fullNetwork.network.id}/info/`)}>
                        <StyledB>Network Info / Onboarding</StyledB>
                        <StyledP light>Network Content</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo(`/network/${fullNetwork.network.id}/stats/`)}>
                        <StyledB>Stats</StyledB>
                        <StyledP light>Content Stats</StyledP>
                    </Surface>
                </StyledGridItem>
            </StyledGridByFour>


            <StyledGridByFour>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/users')}>
                        <StyledB>Users</StyledB>
                        <StyledP light>Search Users</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/accounts')}>
                        <StyledB>Accounts</StyledB>
                        <StyledP light>Search Accounts</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/edit/' + fullNetwork.network.id)}>
                        <StyledB>Edit</StyledB>
                        <StyledP light>Edit Network </StyledP>
                    </Surface>
                </StyledGridItem>
                {/* <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/products/') }>
                        <StyledH5>Products</StyledH5>
                        <StyledP light>Purchases</StyledP>
                    </Surface>
                </StyledGridItem>
                <StyledGridItem>
                    <Surface clickable width="100%" onClick={() => goTo('/network/orders/') }>
                        <StyledH5>Orders</StyledH5>
                        <StyledP light>Purchase Orders &amp; Fulfillment</StyledP>
                    </Surface>
                </StyledGridItem> */}
                {currentNetworkRole === 'superadmin' && (
                    <StyledGridItem>
                        <Surface clickable width="100%" onClick={() => goTo('/network/networks')}>
                            <StyledB>Networks</StyledB>
                            <StyledP light>All Network Application</StyledP>
                        </Surface>
                    </StyledGridItem>
                )}
            </StyledGridByFour>
        </NetworkApp>
    )
}