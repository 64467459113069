import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'


// TCC Redux
import { FullUI } from './redux/UI/ui.selectors'
import { ThisAccount } from './redux/Account/account.selectors'
import { closeToast } from './redux/UI/ui.actions'


// Styles
import { SmartContainer } from './styles/Containers'

// Components
import { Toast, PersistentToast } from './components/Toast/Toast'
import { MainNav } from './components/Navigation/MainNav'
import { TopBar } from './components/Layout/TopBar'
import { MainBody } from './components/Layout/MainBody';
import { SuperAdminBar } from './components/Network/SuperAdminBar';
import { FullNetwork } from './redux/Network/network.selectors';
import { NetworkRole } from './redux/Auth/auth.selectors'
import { AppStarterSupportWidget } from './modules/AppStarterSupport/AppStarterSupportWidget';
import { SpaceBottomNav } from './space/components/Display/BottomNavigation';
import { UpdateApp } from './components/UpdateApp/UpdateApp';
import { SpaceTopNav } from './space/components/Display/TopNavigation';
import { isNativeApp } from './services/Utils';

/**
 * App
 * This is the main wrapper for the entire application.
 * It hands auth and setup.
 */

export const App = ({ title, subtitle, breadcrumbs, children, topBarChildren, noNav = false, stackNav, back, backLabel, padding }) => {

    // Read State
    const dispatch = useDispatch()
    const ui = FullUI();
    const account = ThisAccount();
    const fullNetwork = FullNetwork();
    const currentNetworkRole = NetworkRole(fullNetwork.network?.id);

    const topBarSubtitle = (subtitle) ? subtitle : account?.name;
    const topBarSubtitleSpace = (subtitle) ? subtitle : null;
    const nativeApp = isNativeApp();

    return (
        <SmartContainer flex width="100%" SafeArea={nativeApp} data-app-container>
            {noNav !== true && (<SpaceTopNav hide={ui?.meditationPlaying} stackNav={stackNav} back={back} backLabel={backLabel} />)}
            {/* <MainNav /> */}
            <MainBody noNav={noNav} padding={padding}>
                {/* {currentNetworkRole === 'superadmin' && (<SuperAdminBar />)} */}
                <TopBar subtitle={topBarSubtitleSpace} title={title} breadcrumbs={breadcrumbs}>{topBarChildren}</TopBar>
                <SmartContainer>{children}</SmartContainer>
            </MainBody>
            {noNav !== true && (<SpaceBottomNav hide={ui?.meditationPlaying} />)}
            {ui.toast?.open && (<Toast onClose={() => dispatch(closeToast())} open={ui.toast?.open} severity={ui.toast?.severity} message={ui.toast?.message} />)}
            {/* {account?.paymentStatus === 'pastdue' && (<PersistentToast open={true} severity="warning" message="Your account has missed a payment please update your billing info" buttonText="update" onClick={() => dispatch(push('/billing'))} />)} */}
            {/* {account?.paymentStatus === 'failed' && (<PersistentToast open={true} severity="error" message="Your account has missed too many payments. Update your card to continue." buttonText="update" onClick={() => dispatch(push('/billing'))} />)} */}
            {/* {account?.subscriptionStatus === 'canceled' && (<PersistentToast open={true} severity="error" message="Your account has been canceled. Head to billing to reactivate." buttonText="reactivate" onClick={() => dispatch(push('/billing'))} />)} */}
            {fullNetwork?.network?.meta?.appstartersupport?.enabled === true && noNav !== true && (<AppStarterSupportWidget />)}

        </SmartContainer>
    )
}