import { createModel, searchModels } from '../../../../redux/DefaultModelActions';
import { dispatchLoading, dispatchError, dispatchSuccess, dispatchClearState } from '../../../../redux/utils';
import { APIPost, APIBase } from '../../../../services/API';

const model = 'UserFavorite';
const route = '/v1/favorite';

export const searchFavorites = (page, limit, search, view, args) => searchModels(model, route, page, limit, search, view, args);

export const toggleFavorite = (args) => dispatch => {

    const prefix = 'toggle';
    const action = `[${model.toUpperCase()}] Toggle ${model}`;

    // Update The UI
    dispatch(dispatchLoading(action, prefix));

    // Make Request
    APIPost(`${APIBase}${route}`, args)
        .then(res => res.json())
        .then(json => {
            if (json.status === 'error') {

                dispatch(dispatchError(action, prefix, json.message))
            }
            else {

                // Dispatch Success
                dispatch(dispatchSuccess(action, prefix, json.message, json.data));

                // Dispatch Clear State
                setTimeout(() => { dispatch(dispatchClearState(action, prefix)) }, 1500)

            }
        })
        .catch(e => {
            // dispatch(openToast(e.message,'error'))
            dispatch(dispatchError(action, prefix, e.message))
        })

}
